import menuQrTypes from '@/store/types/menuQr';

const state = {
  typeLanguage: 'spanish'
};

const getters = {
  [menuQrTypes.getters.typeLanguage]: (state) => {
    return state.typeLanguage;
  }
};

const actions = {
};

const mutations = {
  [menuQrTypes.mutations.setTypeLanguage]: (state, value) => {
    state.typeLanguage = value;
  }
};

export default { state, getters, actions, mutations };
