<template>
  <div id="ModalExperiences" :style="!isMobileC ? `max-height: ${maxHeight}px; max-width: ${maxWidth}px;` : ''">
    <b-modal
      :active.sync="showSingleExperience"
      :can-cancel="false"
      has-modal-card
    >
      <div class="modal-card">
        <div class="modal-card-body only-exp">
          <div class="container_icon_experience">
            <i class="icons icon_user" style="background-color: #00ADC6; position: relative; left: 18px; height: 50px; width: 43px;"></i>
            <i class="icons icon_cerrar_view" @click="showSingleExperience = false"
              style="font-size: 30px; position: relative; bottom: 20px; left: 46%; bottom:30px; cursor: pointer; color: #363636;"
            ></i>
          </div>
          <div class="number_client" style="text-align: center; font-size: 14px;">
            Comensal {{experienceData.person}}
          </div>
          <div class="name_experience" style="font-size: 16px;">
            <span>{{experienceData.name}}</span>
          </div>
          <div style="text-align: center; margin: 1px 0px;" v-html="experienceData.description"></div>
        </div>
      </div>
    </b-modal>
    <div class="container_experiences" :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}">
      <div class="close_modal">
        <i class="icons icon_cerrar_view" @click="$parent.close()"></i>
      </div>
      <div class="header_experiences" :class="{ 'flex-column': cols === 1 }">
        <i class="icons icon_experiences"></i>
        <div>Experiencias seleccionadas: {{experiences.length}}</div>
      </div>
      <div class="columns is-multiline content_experiences">
        <div class="column column_content_experiences" v-for="(item,index) in experiencesData" :key="'personPlate-'+index">
          <div class="card_experiences" :style="!isMobileC ? `height: ${maxCardHeight}px;` : ''">
            <div class="container_icon_experience">
              <i class="icons icon_user"></i>
            </div>
            <div class="number_client">
              <span>Comensal {{item.person}}</span>
            </div>
            <div class="container_info_experience">
              <div class="name_experience">
                <span>{{item.name}}</span>
              </div>
              <div class="description_experience">
                <div v-if="isMobileC" v-html="item.truncatedDescription"></div>
                <div v-else v-html="item.description"></div>
                <span v-if="isMobileC && item.truncatedDescription?.length > maxLength"
                  @click="showSingleExpirience(item)"
                  style="text-decoration: underline; cursor: pointer;"
                >Ver más</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['experiences', 'type', 'listMainPlates'],
  data () {
    return {
      showSingleExperience: false,
      experienceData: {},
      experiencesData: [],
      maxLength: 35,
      maxLengthCard: 75,
      maxCardHeight: 172,
      cols: 3,
      rows: 1
    };
  },
  mounted () {
    let maxHeight = 0;
    this.experiencesData = this.experiences.length ? this.experiences.map(item => {
      const fullDescription = this.searchExperienceDescription(item.experienceId);
      const truncatedDescription = this.truncateDescription(fullDescription, this.maxLengthCard);
      if (truncatedDescription.length > this.maxLengthCard) {
        if (maxHeight < 204) maxHeight = 204;
      } else {
        if (maxHeight < 172) maxHeight = 172;
      }
      return {
        ...item,
        name: this.searchExperienceName(item.experienceId),
        description: fullDescription,
        truncatedDescription: this.truncateDescription(fullDescription, this.maxLength)
      };
    }) : [];
    this.cols = this.experiencesData.length > 3 ? 3 : this.experiencesData.length;
    this.rows = Math.ceil(this.experiencesData.length / 3) > 3 ? 3 : Math.ceil(this.experiencesData.length / 3);
    this.maxCardHeight = maxHeight;
  },
  methods: {
    searchExperienceName (id) {
      const obj = this.listMainPlates.find(x => x._id === id);
      return obj?.name ? obj.name : '';
    },
    searchExperienceDescription (id) {
      const obj = this.listMainPlates.find(x => x._id === id);
      return obj?.description ? obj.description : '';
    },
    truncateDescription (description, length) {
      let rawDescription = description.replace(/<[^>]*>/g, '');
      if (rawDescription.length > length) {
        rawDescription = rawDescription.substring(0, length) + '...';
      }
      return rawDescription;
    },
    showSingleExpirience (data) {
      this.experienceData = data;
      this.showSingleExperience = true;
    }
  },
  computed: {
    isMobileC () {
      return this.windowWidth < 767;
    },
    maxHeight () {
      let maxHeight = 0;
      if (this.windowHeight < 780) {
        const ratio = this.rows > 2 ? 2 : this.rows;
        maxHeight = (this.maxCardHeight + 20) * ratio + 130;
        if (this.cols === 1) maxHeight += 40;
      } else maxHeight = (this.maxCardHeight + 20) * this.rows + 140;
      const modalContent = document.querySelector('.animation-content');
      if (modalContent && !this.isMobileC) {
        modalContent.style.setProperty('max-height', maxHeight + 'px', 'important');
      }
      return maxHeight;
    },
    maxWidth () {
      let maxWidth = 0;
      maxWidth = this.cols * 228 + 90;
      if (this.cols === 1) maxWidth += 40;
      const modalContent = document.querySelector('.animation-content');
      if (modalContent && !this.isMobileC) {
        modalContent.style.setProperty('max-width', maxWidth + 'px', 'important');
      }
      return maxWidth;
    }
  },
  watch: {
    experiences () {
      let maxHeight = 0;
      this.experiencesData = this.experiences.length ? this.experiences.map(item => {
        const fullDescription = this.searchExperienceDescription(item.experienceId);
        const truncatedDescription = this.truncateDescription(fullDescription, this.maxLengthCard);
        if (truncatedDescription.length > this.maxLengthCard) {
          if (maxHeight < 204) maxHeight = 204;
        } else if (!this.maxCardHeight) {
          if (maxHeight < 172) maxHeight = 172;
        }
        return {
          ...item,
          name: this.searchExperienceName(item.experienceId),
          description: fullDescription,
          truncatedDescription: this.truncateDescription(fullDescription, this.maxLength)
        };
      }) : [];
      this.maxCardHeight = maxHeight;
    }
  }
};
</script>
<style lang="scss">
@use '../../assets/styles/ViewReservation/modalExperiences.scss';
.container_icon_experience {
  padding: 0px !important;
}
.container_info_experience {
  max-height: 90px;
  margin-top: 10px;
  overflow-y: auto;
}
.container_info_experience::-webkit-scrollbar {
  width: 4px !important;
}
.card_experiences {
  padding: 20px 0px;
  box-sizing: border-box;
}
.number_client {
  margin-top: 10px !important;
}
.description_experience {
  margin-top: 10px !important;
  p {
    font-size: 10px !important;
  }
}

.modal-card-body.only-exp {
  padding: 30px !important;
}

.flex-column {
  flex-direction: column;
}

@media screen and (min-width: 767px) and (max-width: 768px){
  #ModalExperiences{
    max-width: 740px !important;
    .columns:not(.is-desktop) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .column {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: 33.33333%;
    }
    .content_experiences {
      padding: 20px;
    }
  }
}
</style>
