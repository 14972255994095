<template>
<div>
  <div
    v-if="Object.keys(paginatorData).length > 0 && dataResult.length > 0"
    class="footer-bar"
  >
    <div class="contBar"></div>
    <div v-if="total != 0" class="paginator-polls">
      <b-pagination
        @change= "changePag"
        :total="total"
        :current.sync="current"
        :range-before="rangeBefore"
        :range-after="rangeAfter"
        :order="order"
        :per-page="perPage"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        icon-pack="fa"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        size="is-small"
      >
      </b-pagination>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['giftsLength', 'paginatorData', 'giftVendorId', 'query', 'isLoading', 'giftCards'],
  data () {
    return {
      dataResult: [],
      total: 0,
      current: 0,
      perPage: 0,
      rangeBefore: 1,
      rangeAfter: 1,
      order: 'is-centered',
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    };
  },
  mounted () {
    /* this.total = this.paginatorData.total;
    this.current = this.paginatorData.current_page;
    this.perPage = this.paginatorData.per_page;
    this.dataResult = this.paginatorData.data; */
  },
  methods: {
    changePag (e) {
      this.$emit('refreshLoading', true);
      const this_ = this;
      const filter = document.querySelector('input[name="filterType"]:checked').value;
      this.$store
        .dispatch({
          type: 'reservation:getGiftCardVendor',
          data: { vendorId: this.vendorId, filter: this.query, page: e }
        })
        .then((data) => {
          switch (filter) {
            case 'new':
              this_.$emit('refreshGiftCardArray', data.giftCardRecibidas.data);
              this_.$emit('refreshLoading', false);
              break;
            case 'redeem':
              this_.$emit('refreshGiftCardArray', data.giftCardRedimidas.data);
              this_.$emit('refreshLoading', false);
              break;
            case 'overdue':
              this_.$emit('refreshGiftCardArray', data.giftCardVencidas.data);
              this_.$emit('refreshLoading', false);
              break;
            default:
              break;
          }
        });
    }
  },
  watch: {
    giftCards () {
      this.dataResult = this.paginatorData.data;
    },
    paginatorData () {
      this.total = this.paginatorData.total;
      this.current = this.paginatorData.current_page;
      this.perPage = this.paginatorData.per_page;
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/giftCards/_paginator";
</style>
