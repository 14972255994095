<template>
  <div id="search-index">
    <div v-if="getStatusOnline === false">
      <i></i>Señor Usuario
      <p>En estos momentos no tienes acceso a internet, por favor verifica que tengas conexión.</p>
    </div>
      <a class="btn-filters" @click="activity()">
        <i class="icon_search icon-mks icon-mks-search white"></i>
      </a>
    <div class="columns">
      <div class="column is-3">
        <Filters :value="value" @valuehijo="value=$event" class="filter-box"></Filters>
      </div>
      <div class="column">
        <Information></Information>
      </div>
    </div>
   <!-- <a class="btn-filters"></a> -->
  </div>

</template>

<script>
import Filters from './filters';
import Information from './information';
export default {
  name: 'SearchsVue',
  components: { Filters, Information },
  data () {
    return {
      value: false
    };
  },

  methods: {
    activity () {
      if (this.value === false) {
        this.value = true;
      } else {
        this.value = false;
      }
    }
  }
};
</script>

<style lang="scss">
#search-index{
  margin:5px 15px 20px 10px;
  height: calc(100vh - 77px);
  overflow: auto;
  .columns{
    height: 100%;
  }
}
</style>
