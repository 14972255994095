<template>

    <label :for="filterType" v-if="label_available">
      <div :class="`navbar__option navbar__option--${status}`">
          <img style="margin-left:5px;" :src="require(`@/assets/icons/giftCards/${value}_${status}.svg`)" alt="">
          <div>{{title}}</div>
          <input type="radio" class="d-none" :checked="checked" name="filterType" :id="filterType" :value="value" @change="$emit('filterGiftCards', $event)">
      </div>
    </label>
    <div class="navbar__option__container" @click="$emit('openModalGiftCardConfig')" v-else>
      <div class="navbar__option navbar__option--inactive navbar__option__config">
        <img :src="require(`@/assets/icons/giftCards/config.svg`)" alt="">
        <div>{{title}}</div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'GiftCardOption',
  props: {
    status: {
      type: String,
      default: ''
    },
    filterType: {
      type: String,
      default: ''
    },
    label_available: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    filterGiftCards (e) {
      this.$emit('filterGiftCards', e);
    }
  }
};
</script>
