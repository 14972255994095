<template>
  <div id="select-input-personalizado">
    <b-field :type="{ 'is-danger': errors.has('number') }" style="border-color: red;">
      <div :class="{openList:opened}" class="select-input-personalizado">
        <div
          :class="{'select-input-error': errors.has(field)}"
          @click="showList()"
          :style="{'min-width': (mode === 'identify') ? '60px' : '92px'}"
          class="select-input-personalizado-icon-selector"
        >
          <!--BANDERAS-->
          <div class="countriesSelectorContainer" v-if="mode === 'phone'">
            <img
              :src="countrieInfo.bandera"
              @error="imageNotFound"
              alt="bandera"
              class="select-input-personalizado-bandera"
            />
            <p>(+{{countrieInfo.indicativo}})</p>
          </div>
          <!--FIN BANDERAS-->
          <!--IDENTIFICACION-->
          <div class="countriesSelectorContainer" v-if="mode === 'identify'">
            <p>{{ identifyLabel }}</p>
          </div>
          <!--FIN IDENTIFICACION-->
          <div class="dropdown-list">
            <span class="material-icons">{{!opened? 'expand_more': 'expand_less'}}</span>
          </div>
          <div class="divisor"></div>
        </div>
        <div class="select-input-personalizado-center" v-if="mode === 'phone'">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 10 15" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.125 0H6.875C7.7035 0.000992411 8.49778 0.330551 9.08361 0.916387C9.66945 1.50222 9.99901 2.2965 10 3.125V11.875C9.99901 12.7035 9.66945 13.4978 9.08361 14.0836C8.49778 14.6694 7.7035 14.999 6.875 15H3.125C2.2965 14.999 1.50222 14.6694 0.916387 14.0836C0.330551 13.4978 0.000992411 12.7035 0 11.875V3.125C0.000992411 2.2965 0.330551 1.50222 0.916387 0.916387C1.50222 0.330551 2.2965 0.000992411 3.125 0ZM6.875 1.25H3.125C2.62772 1.25 2.15081 1.44754 1.79917 1.79917C1.44754 2.15081 1.25 2.62772 1.25 3.125V10H8.75V3.125C8.75 2.62772 8.55246 2.15081 8.20083 1.79917C7.84919 1.44754 7.37228 1.25 6.875 1.25ZM3.125 13.75H6.875C7.37228 13.75 7.84919 13.5525 8.20083 13.2008C8.55246 12.8492 8.75 12.3723 8.75 11.875V11.25H1.25V11.875C1.25 12.3723 1.44754 12.8492 1.79917 13.2008C2.15081 13.5525 2.62772 13.75 3.125 13.75ZM5.62521 12.5C5.62521 12.8452 5.34539 13.125 5.00021 13.125C4.65503 13.125 4.3752 12.8452 4.3752 12.5C4.3752 12.1548 4.65503 11.875 5.00021 11.875C5.34539 11.875 5.62521 12.1548 5.62521 12.5Z" fill="#444B57"/>
          </svg>
          <input
            autocomplete="off"
            class="select-input-personalizado-center-text"
            name="number"
            placeholder="Celular"
            type="number"
            v-model="value"
            v-validate="validatedRules"
          />
        </div>
        <div class="select-input-personalizado-center" v-if="mode === 'identify'">
          <input
            autocomplete="off"
            class="select-input-personalizado-center-text"
            name="number"
            placeholder="Número de documento"
            type="number"
            v-model="value"
            style="width: 100% !important;"
            v-validate="validatedRules"
          />
        </div>
      </div>
      <div :style="{display: opened ? 'flex' : 'none', zIndex: 2}" class="dropdown-list-content" v-if="mode === 'phone'">
        <div class="filterFlagsContainer">
          <input
            @input="(e)=>filter=e.target.value "
            class="filterFlags"
            placeholder="Escriba el país o el indicativo"
            type="text"
          />
          <span></span>
        </div>
        <div
          :key="'countries#' + IType"
          @click="()=>{valueSelector = itemType.image; showList(); changedPais(itemType)}"
          class="dropdown-list-content-item"
          v-for="(itemType, IType) in filteredCountries"
        >
          <div class="dropdown-list-content-item-left">
            <img :src="itemType.image" @error="imageNotFound" alt="countries" />
          </div>
          <div class="dropdown-list-content-item-right">(+{{itemType.value}}) {{ itemType.label }}</div>
        </div>
      </div>
      <div :style="{display: opened ? 'flex' : 'none', zIndex: 2, paddingBottom: '10px'}" class="dropdown-list-content" v-if="mode === 'identify'">
        <div class="filterFlagsContainer">
          <input
            @input="(e)=> filterList(e.target.value)"
            v-model="search"
            class="filterFlags"
            placeholder="Escriba tipo de documento"
            type="text"
          />
          <span></span>
        </div>
        <div
          :key="'countries#' + IType"
          @click="()=>{ identifyLabel = itemType.id; showList(); clear(); }"
          class="dropdown-list-content-item"
          style="width: 90%;"
          v-for="(itemType, IType) in listItemInfo"
        >
          <span style="
          color: #444B57;
          font-family: 'Source Sans Pro';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;">{{ itemType.name }}</span>
        </div>
      </div>
    </b-field>
    <div v-if="errors.first('number') && mode === 'phone'" style="color: red;">
      {{ errors.first('number') }}
    </div>
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';

export default {
  props: {
    iconLeft: {
      type: String
    },
    iconRight: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    fieldSelector: {
      type: String
    },
    type: {
      type: String
    },
    typeData: {
      type: String
    },
    rules: {
      type: String
    },
    required: {
      type: Boolean
    },
    selector: {
      type: Boolean
    },
    indicative: {
      type: String
    },
    flag: {
      type: String
    },
    errorShow: {
      type: Boolean
    },
    initDocument: {
      type: String
    },
    initInputValue: {
      type: String
    },
    initIndicativo: {
      type: Number
    },
    initInfo: {
      type: Object
    },
    formInfo: {
      type: Object
    },
    mode: {
      type: String,
      default: 'phone'
    },
    modeInfo: {
      type: Array
    }
  },
  data () {
    return {
      filter: '',
      value: '',
      countries: [],
      opened: false,
      option: {
        value: 57,
        label: 'Colombia',
        image: 'https://api.precompro.com/banderas/co.png',
        selected: true
      },
      validatedRules: 'numeric|min:10|max:10',
      validated: this.required || false,
      error: null,
      identifyLabel: 'CC',
      countrieInfo: {
        bandera: 'https://api.precompro.com/banderas/co.png',
        indicativo: 57,
        pais: 'Colombia'
      },
      listItemInfo: [],
      search: ''
    };
  },
  mounted () {
    if (this.mode === 'phone') {
      this.listCountries();
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    } else {
      this.$emit('value', { countrieInfo: this.identifyLabel, inputValue: this.value });
      this.getIdentification();
    }
  },
  methods: {
    getIdentification () {
      if (this.params.paymentType === 'MP') {
        this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
          if (data.code === 200) {
            const dataResult = data.data;
            const items = [];
            dataResult.forEach((element) => {
              items.push({
                name: element.name,
                value: element.id
              });
            });
            this.listItemInfo = items;
          } else {
            this.listItemInfo = [];
          }
        });
      } else {
        this.listItemInfo = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
          { value: 'TI', name: 'TI - Tarjeta de Identidad' },
          { value: 'PP', name: 'PP - Pasaporte' },
          {
            value: 'CEL',
            name: 'CEL - En caso de identificarse a través de la línea del móvil'
          },
          { value: 'RC', name: 'RC - Registro civil de nacimiento' },
          { value: 'DE', name: 'DE - Documento de identificación extranjero' }
        ];
      }
    },
    formatFilter () {
      return this.filter.toLowerCase();
    },
    showList () {
      this.opened = !this.opened;
    },
    imageNotFound (e) {
      e.target.src = 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg';
    },
    listCountries () {
      const data = [];
      const indicative = null;
      const flag = null;
      _.forEach(BanderaPais, function (item) {
        const info = callingCode.find(i => i.country === item.country);
        if (info) {
          if (info.calling_code !== null) {
            const image = item.abbreviation ? 'https://api.precompro.com/banderas/' + item.abbreviation.toLowerCase() + '.png' : null;
            if (indicative != null && indicative === info.calling_code && flag != null && flag === image && info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image,
                selected: true
              });
            } else if (info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image
              });
            }
          }
        }
      });
      this.countries = data;
    },
    indicativeParam (indicative) {
      const selectIndicative = this.countries.find(value => parseInt(value.value) === parseInt(indicative));
      this.option = selectIndicative;
      this.valueSelector = selectIndicative.image;
      this.changedPais(this.option);
    },
    changedPais (indicative) {
      this.option = indicative;
      let aux2 = null;
      const aux3 = indicative.label;
      const ind = indicative.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country;
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.countrieInfo = { pais: indicative.label, indicativo: indicative.value, bandera: aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null };
      this.validateTelIndicative(indicative.value);
    },
    validateTelIndicative (indicative) {
      switch (indicative) {
        case 57:
          this.validatedRules = 'numeric|min:10|max:10';
          this.validated = true;
          this.$emit('validatedRulesMin', 10);
          this.$emit('validatedRulesMax', 10);
          break;
        case 507:
          this.validatedRules = 'numeric|min:7|max:8';
          this.validated = true;
          this.$emit('validatedRulesMin', 7);
          this.$emit('validatedRulesMax', 8);
          break;
        default:
          this.validatedRules = 'numeric|min:10|max:10';
          this.validated = true;
          this.$emit('validatedRulesMin', 10);
          this.$emit('validatedRulesMax', 10);
          break;
      }
    },
    isValidTel () {
      setTimeout(() => {
        if (this.value !== null && this.value !== '' && this.value !== undefined && !isNaN(this.value)) {
          if (this.errors.first('input') !== undefined) {
            this.error = 'Por favor ingrese un Teléfono válido';
          } else {
            this.error = null;
          }
        } else {
          this.error = null;
        }
      }, 100);
    },
    filteredCountriesMethod () {
      return this.countries.filter((country) =>
        country.label.toLowerCase().includes(this.formatFilter()) || ('+' + country.value.toString()).includes(this.formatFilter())
      );
    },
    filterList (item) {
      if (item.length > 0) {
        this.search = item;
        this.listItemInfo = this.modeInfo.filter((country) =>
          country.name.toLowerCase().includes(item)
        );
      }
    },
    clear () {
      this.listItemInfo = this.modeInfo;
      this.search = '';
    }
  },
  watch: {
    countrieInfo () {
      if (this.mode === 'phone') {
        this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
      } else {
        this.$emit('value', { countrieInfo: this.identifyLabel, inputValue: this.value });
      }
    },
    value () {
      if (this.mode === 'phone') {
        this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
      } else {
        this.$emit('value', { countrieInfo: this.identifyLabel, inputValue: this.value });
      }
    },
    identifyLabel () {
      if (this.mode === 'phone') {
        this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
      } else {
        this.$emit('value', { countrieInfo: this.identifyLabel, inputValue: this.value });
      }
    }
  },
  computed: {
    filteredCountries () {
      if (this.filter === '') {
        return this.countries;
      } else {
        return this.filteredCountriesMethod();
      }
    }

  }
};
</script>
<style lang="scss" scoped>
#select-input-personalizado {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    outline: unset;
  }
}

.filterFlagsContainer {
  background-color: #ecedee;
  padding: 2px;
  display: flex;
  align-items: center;
  width: 90%;
  min-height: 25px;
  margin-top: 5px;
  border-radius: 3px;
  overflow: hidden;
  input {
    height: 100%;
    width: 100% !important;
    background-color: transparent;
    color: #606773;
    @media screen and (min-width: 650px) and (max-width: 1025px) {
      font-size: 10.3px;
    }
  }
  span {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 15px;
    height: 14px;
    position: relative;
    right: 3px;
  }
}
#select-input-personalizado {
  margin: unset;
  max-height: 30px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #A1A5AB;
  margin: unset;
  position: relative;
  .filterFlags {
    border: unset;
    outline: none;
  }
  .dropdown-list-content {
    position: absolute;
    top: 30px;
    left: 0px;
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    max-height: 180px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    flex-direction: column;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-top: solid 1px transparent;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #606773 !important;
      box-shadow: unset !important;
      border: unset;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-height: 28px;
      max-height: 29px;
      padding: 0px 5px;
      cursor: pointer;
      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        margin: 5px;
        width: 30px;
        font-size: 13px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 99%;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 12px;
      }
      &:hover {
        background-color: #ddd;
      }
    }
  }
  .select-input-personalizado {
    display: flex;
    width: 100%;
    height: 100%;
    border: unset;
    border-radius: 6px;
    overflow: hidden;
    &.openList {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;
      &::after {
        content: " ";
        display: block;
        background-color: #a8adb5;
        height: 1px;
        width: 90%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0px;
      }
    }
    &-icon-selector {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 95%;
      min-width: 92px;
      background-color: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      @media screen and (max-width: 600px) {
        width: 90px;
      }
      .dropdown-list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 5px;
        img {
          cursor: pointer;
          width: 15px;
          height: 7px;
        }
        span{
          font-size: 17px;
          opacity: 0.55;
        }
      }
      .divisor {
        width: 1px;
        height: 28px;
        background-color: #a8adb5;
        margin-left: 2px;
      }
    }
    .with-selector {
      border-left: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: relative;
      &-text {
        width: 100%;
        height: 100%;
        border-left: none;
        border: unset;
        border-right: none;
        text-indent: 1px;
        text-align: left;
        min-height: 28px;
        font-size: 12px;
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 60px;
      border-left: none;
      position: relative;
      &-item {
        display: flex;
        width: 60px;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
      }
    }
    .borderRight {
      .select-input-personalizado-center-text {
        margin-right: 3px;
      }
    }
    &-bandera {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      cursor: pointer;
    }
    &-text {
      height: 25px;
      width: 25px;
      cursor: pointer;
    }
  }
  .select-input-item {
    border: none;
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    height: 100%;
    width: 50px;
  }
  .w-100px {
    width: 100px;
  }
}
.countriesSelectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  p {
    font-size: 14px;
  }
}
.cellphone {
  position: absolute;
  left: 0;
  font-size: 20px;
}
span.error {
  color: red;
  position: absolute;
  right: 6px;
  top: 0;
  left: unset;
  bottom: 0;
  margin: auto;
  font-size: 21px;
  width: fit-content;
  height: 22px;
  transform: translateY(1px);
}
</style>
