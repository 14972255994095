<template>
  <div class="footer-bar" v-if="Object.keys(paginatorData).length > 0 && dataResult.length > 0">
    <div class="contBar"></div>
    <div class="paginator-polls" v-if="total != 0">
      <b-pagination
        :current.sync="current"
        :icon-next="nextIcon"
        :icon-prev="prevIcon"
        :order="order"
        :per-page="perPage"
        :range-after="rangeAfter"
        :range-before="rangeBefore"
        :total="total"
        @change="changePag"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        icon-pack="fa"
        size="is-small"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import pollTypes from '@/store/types/poll';
import globalTypes from '@/store/types/global';
export default {
  props: ['pollLenght', 'paginatorData', 'pollId', 'filterDate', 'filterDateEnd', 'DayPollFilter', 'query', 'group'],
  data () {
    return {
      dataResult: [],
      total: 0,
      current: 0,
      perPage: 0,
      rangeBefore: 1,
      rangeAfter: 1,
      order: 'is-centered',
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    };
  },
  mounted () {
    this.total = this.paginatorData.total;
    this.current = this.paginatorData.current_page;
    this.perPage = this.paginatorData.per_page;
    this.dataResult = this.pollInformation;
  },
  methods: {
    changePag (e) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      let orderDate = null;
      let answerNull = null;
      let dayFilter = null;
      let small = null;
      if (this.group === 'monthAsc') {
        orderDate = 'asc';
      }
      if (this.group === 'monthDesc') {
        orderDate = 'desc';
      }
      if (this.group === 'answered') {
        answerNull = 'desc';
      }
      if (this.group === 'notAnswered') {
        answerNull = 'asc';
      }
      if (this.group === '3') {
        small = 3;
      }
      if (this.group === '2') {
        small = 2;
      }
      if (this.group === '1') {
        small = 3;
      }
      if (this.filterDate !== null && this.filterDateEnd !== null) {
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, page: e, pollId: this.pollId, smaller: small, orderDate: orderDate, answerNull: answerNull, filterDate: this.filterDate, filterDateEnd: this.filterDateEnd } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } else if (this.DayPollFilter !== 'Rango de fecha') {
        dayFilter = this.DayPollFilter;
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, page: e, pollId: this.pollId, smaller: small, orderDate: orderDate, answerNull: answerNull, dayRangeFilter: dayFilter } })
          .then(({ data }) => {
            if (data.code === 200) {
              this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            } else {
              this.error = data.message;
            }
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          });
      } else {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    }
  },
  watch: {
    pollInformation () {
      this.dataResult = this.pollInformation;
    },
    paginatorData () {
      this.total = this.paginatorData.total;
      this.current = this.paginatorData.current_page;
      this.perPage = this.paginatorData.per_page;
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/polls/_pagination";
</style>
