<template>
  <div id="editUserModal">
    <i class="icons icon_close_modal" @click="$parent.close()"></i>
    <div class="header_modal">
      <span class="title_modal ffr">Editar usuario</span>
    </div>
    <div class="content_modal">
      <div class="first_line">
        <div class="input_name">
          <input class="inputs_add name ffr" v-validate="'required'" name="displayName" type="text" v-model="localDataUser.name" placeholder="Nombre">
          <div v-show="errors.has('displayName')" class="error_add">
            El nombre no es válido.
          </div>
        </div>
        <div class="user_vip" :class="{'isActive' : dataUser.isVip}" @click="localDataUser.isVip = !localDataUser.isVip">
          <span class="vip_add ffr">VIP</span>
        </div>
      </div>
      <div class="second_line">
        <div class="container_phone">
          <div class="container_icon">
            <i class="icons icon_phone"></i>
          </div>
          <div class="container_select">
            <img :src="bandera" class="bandera_list">
            <select class="select_indicative ffr" v-if="option" v-model="option" @change="changedPais(option)">
              <option class="ffr" :value="p" v-for="(p, i) in paises" :key="i">{{ p.label_show }}</option>
            </select>
          </div>
          <div class="input_phone">
            <input v-validate="validateTel"
              type="text"
              name="phone"
              v-model="localDataUser.phone"
              placeholder="Celular"
              class="inputs_add phone ffr">
            <div v-show="errors.has('phone')" class="error_add">
              El celular no es válido.
            </div>
          </div>
        </div>
        <div class="input_mail">
          <div class="container_icon">
            <i class="icons icon_mail"></i>
          </div>
          <input name="email" v-validate="'required|email'" class="inputs_add email ffr" placeholder="Mail" v-model="localDataUser.email">
          <div v-show="errors.has('email')" class="error_add">
            El mail no es válido.
          </div>
        </div>
      </div>
      <div class="third_line">
        <div class="input_document">
          <div class="container_icon">
            <i class="icons icon_document"></i>
          </div>
          <input type="text" class="inputs_add document ffr" placeholder="Documento" v-model="localDataUser.identityDocument" />
        </div>
        <div class="input_birthday"  v-if="params.activeBirthDate">
          <div class="container_icon">
            <i class="icons icon_calendar"></i>
          </div>
          <div class="button_birthday">
            <v-date-picker class="inline-block h-full" v-model="birthday">
              <template v-slot="{ togglePopover }">
                <div>
                  <button
                    class="btn_birthday"
                    @click="togglePopover()"
                  >
                    <span class="ffr" v-if="birthday != null">{{ birthday | moment('MMMM DD') }} de {{ birthday | moment('YYYY') }}</span>
                  </button>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
      <div class="last_line">
        <button class="btn_guardar" @click="updateUser">Guardar</button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import globalTypes from '@/store/types/global';

export default {
  name: 'editUserModal',
  props: ['dataUser'],
  data () {
    return {
      localDataUser: { ...this.dataUser },
      validateTel: '',
      bandera: null,
      paises: [],
      option: {
        value: 57,
        label: 'Colombia ( +57 )',
        label_show: '+57',
        selected: true
      },
      birthday: null,
      weekdays: ['LUN', 'MAR', 'MIE', 'JUV', 'VIE', 'SAB', 'DOM'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    };
  },
  mounted () {
    this.listPaises();
    if (this.localDataUser.indicativoUser === null || this.localDataUser.indicativoUser === undefined || this.localDataUser.indicativoUser === '') {
      this.localDataUser.indicativoUser = this.localDataUser.indicative;
    }
    this.findFlag(parseInt(this.localDataUser.indicativoUser));
    if (this.localDataUser.birthday !== undefined && this.localDataUser.birthday !== null) {
      this.birthday = this.localDataUser.birthday;
    }
  },
  methods: {
    listPaises () {
      var data = [];
      var ind = this.dataUser.indicative != null ? this.dataUser.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null && item.country !== 'Bouvet Island') {
          if (ind != null && ind === item.calling_code) {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    findFlag (indicative) {
      const option = this.paises.find(e => e.value === indicative);
      if (option !== undefined) {
        let aux2 = null;
        const aux3 = option.label;
        _.forEach(BanderaPais, function (item) {
          if (indicative !== null && indicative !== undefined) {
            const data = item.country + ' ( +' + indicative + ' )';
            if (data === aux3) {
              aux2 = item.abbreviation.toLowerCase();
            }
          }
        });
        this.bandera = aux2 != null ? '/dashboard/banderas/' + aux2 + '.png' : null;
      }
    },
    changedPais (indicativo) {
      this.option = indicativo;
      this.pais = indicativo.label;
      this.localDataUser.indicative = indicativo.value;
      const ind = indicativo.value;
      this.option.label_show = '+' + ind;

      switch (indicativo.value) {
        case 57:
          this.validateTel = 'numeric|min:10|max:10';
          break;
        case 507:
          this.validateTel = 'numeric|min:7|max:8';
          break;
        default:
          this.validateTel = 'numeric|min:7|max:11';
          break;
      };
      this.findFlag(this.option.value);
    },
    updateUser () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const dataUpdate = {
        userId: this.localDataUser._id,
        email: this.localDataUser.email,
        phone: this.localDataUser.phone,
        identityDocument: this.localDataUser.identityDocument,
        indicative: parseInt(this.localDataUser.indicative),
        flagLabel: this.localDataUser.flagLabel,
        tags: this.userTags,
        notes: this.userNotes,
        birthday: this.birthday,
        isVip: this.localDataUser.isVip
      };
      this.$store.dispatch({
        type: 'people:update',
        data: dataUpdate
      }).then((data) => {
        if (parseInt(data.code) === 200) {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i>Datos actualizados',
            type: 'is-sucess'
          });
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$emit('updateUser');
          this.$parent.close();
        }
      });
    }
  }
};
</script>
<style lang="scss">
@use '../../../assets/styles/users/editUserModal.scss';
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
</style>
