<template>
  <div>
    <LoaderPrecompro />
    <Onboarding v-if="vendor && vendorParams"/>
  </div>
</template>

<script>
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import Onboarding from '@/components/Onboarding';
import globalTypes from '@/store/types/global';
import authTypes from '@/store/types/auth';
import moment from 'moment-timezone';
export default {
  name: 'OnboardingPage',
  components: {
    LoaderPrecompro,
    Onboarding
  },
  mounted () {
    window.startingTime = Date.now();
    const subdomain = this.subDomain();
    const vendorUrl = subdomain;
    this.$store.commit(authTypes.mutations.setUser);
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store.commit(globalTypes.mutations.setLoaderPrecompro, {
      status: true,
      typeMessage: 1
    });
    this.$store
      .dispatch({
        type: 'global:vendorId',
        data: {
          url: vendorUrl
        }
      })
      .then((response) => {
        if (response.data.vendorId) {
          this.$store.dispatch({
            type: 'global:getPermissions',
            data: { userId: this.user.id }
          });
          this.$store.dispatch({
            type: 'reservation:getModules',
            data: {
              vendorId: this.idVendor,
              userId: this.user.id
            }
          });
          this.$store
            .dispatch({
              type: 'global:vendor',
              data: {
                vendorId: this.idVendor
              }
            })
            .then((resp) => {
              if (resp.data.vendor.timezone != null) {
                moment.tz.setDefault(resp.data.vendor.timezone);
              } else {
                moment.tz.setDefault('America/Bogota');
              }
              this.load = false;
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, {
                status: false
              });
            }).finally(() => {
              document.querySelector('title').text = this.vendor.displayName + ' - Pago sin contacto';
              document.querySelector("link[rel*='icon']").href = this.vendor.thumbnailURL;
            });
        }
      });
  },
  methods: {
  }
};
</script>

<style>

</style>
