<template>
  <div id="payment-marketing" >
    <b-loading :active="loading" :is-full-page="true"></b-loading>
    <div class="quantity-email-available" v-if="type !== 'sms'">
      <div class="quantity-email-available__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="81" height="60" viewBox="0 0 81 60" fill="none">
          <path d="M79.5 45H75V7.5C75 5.51088 74.2098 3.60322 72.8033 2.1967C71.3968 0.790176 69.4891 0 67.5 0H13.5C11.5109 0 9.60322 0.790176 8.1967 2.1967C6.79018 3.60322 6 5.51088 6 7.5V45H1.5C1.10218 45 0.720645 45.158 0.43934 45.4393C0.158036 45.7206 0 46.1022 0 46.5V52.5C0 54.4891 0.790176 56.3968 2.1967 57.8033C3.60322 59.2098 5.51088 60 7.5 60H73.5C75.4891 60 77.3968 59.2098 78.8033 57.8033C80.2098 56.3968 81 54.4891 81 52.5V46.5C81 46.1022 80.842 45.7206 80.5607 45.4393C80.2794 45.158 79.8978 45 79.5 45ZM9 7.5C9 6.30653 9.4741 5.16193 10.318 4.31802C11.1619 3.47411 12.3065 3 13.5 3H67.5C68.6935 3 69.8381 3.47411 70.682 4.31802C71.5259 5.16193 72 6.30653 72 7.5V45H9V7.5ZM78 52.5C78 53.6935 77.5259 54.8381 76.682 55.682C75.8381 56.5259 74.6935 57 73.5 57H7.5C6.30653 57 5.16193 56.5259 4.31802 55.682C3.47411 54.8381 3 53.6935 3 52.5V48H78V52.5Z" fill="#444B57"/>
          <path d="M48.5833 13H33.4167C31.9806 13.0016 30.6039 13.5289 29.5884 14.4662C28.573 15.4036 28.0017 16.6744 28 18V30C28.0017 31.3256 28.573 32.5964 29.5884 33.5338C30.6039 34.4711 31.9806 34.9984 33.4167 35H48.5833C50.0194 34.9984 51.3961 34.4711 52.4116 33.5338C53.427 32.5964 53.9983 31.3256 54 30V18C53.9983 16.6744 53.427 15.4036 52.4116 14.4662C51.3961 13.5289 50.0194 13.0016 48.5833 13ZM33.4167 15H48.5833C49.232 15.0012 49.8655 15.1815 50.4022 15.5178C50.9389 15.8541 51.3543 16.3309 51.595 16.887L43.2988 24.546C42.6883 25.1073 41.8617 25.4225 41 25.4225C40.1383 25.4225 39.3117 25.1073 38.7012 24.546L30.405 16.887C30.6457 16.3309 31.0611 15.8541 31.5978 15.5178C32.1345 15.1815 32.768 15.0012 33.4167 15ZM48.5833 33H33.4167C32.5547 33 31.7281 32.6839 31.1186 32.1213C30.5091 31.5587 30.1667 30.7956 30.1667 30V19.5L37.1693 25.96C38.1862 26.8963 39.5638 27.422 41 27.422C42.4362 27.422 43.8138 26.8963 44.8307 25.96L51.8333 19.5V30C51.8333 30.7956 51.4909 31.5587 50.8814 32.1213C50.2719 32.6839 49.4453 33 48.5833 33Z" fill="#444B57"/>
        </svg>
      </div>
      <div class="quantity-email-available__copy">
        <div class="quantity-email-available__copy__title">Tienes <strong>{{ totalFull }}</strong></div>
        <div class="quantity-email-available__copy__subtitle">Correos disponibles</div>
      </div>
    </div>
    <div class="payment-title" :class="getClass()">
      <span class="info">{{ (type === 'sms') ? title.sms : title.mail }}</span>
    </div>
    <div class="payment-input" :style="(!edit) ? 'background: #F3F3F3;' : 'background: #FFFFFF;'" :class="{'error-border': (errors.has('quantityPayment') || errorText != '')}">
      <div class="text-input">
        <img class="icon-input" src="@/assets/icons/marketing/lapiz.svg" @click="setEdit"/>
        <span class="center" @click="setEdit" v-if="!edit">{{ (type === 'sms') ? description.sms : description.mail }}</span>
        <input autofocus="false" @change.stop @mousedown.stop @click.stop v-show="edit" v-validate="'required'" name="quantityPayment" id="quantityPayment" class="input-center" type="text" v-model="quantityFormat" :placeholder="(type === 'sms') ? description.sms : description.mail" @input="valueFormat" @keyup.enter="setSale()"/>
      </div>
      <span v-if="errors.has('quantityPayment') || errorText" class="alert-error">
        {{ (errors.has('quantityPayment')) ? 'Campo obligatorio' : errorText }}
      </span>
    </div>
    <div class="payment-cost">
      <div class="item value" v-if="type === 'sms'">
        <span class="item-text">{{ cost.sms }}</span>
        <span class="item-text-right">{{ (costPer === 0) ? '$--' : '$ ' + costPer  }}</span>
      </div>
      <div class="item value" v-if="type === 'email'">
        <span class="item-text">{{ cost.mail }}</span>
        <span class="item-text-right">{{ (costPer === 0) ? '$--' : '$ ' + costPer }}</span>
      </div>
      <div class="item fix-value">
        <span class="item-text">Valor iva</span>
        <span class="item-text-right">{{iva}}%</span>
      </div>
    </div>
    <div class="payment-cost">
      <div class="item value fix-value" v-if="type === 'sms'">
        <span class="item-value">Total a pagar</span>
        <span class="item-value-right">{{ (total === 0) ? '$--' : '$ ' + total }}</span>
      </div>
      <div class="item fix-value" v-if="type === 'email'">
        <span class="item-value">Total a pagar</span>
        <span class="item-value-right">{{ (total === 0) ? '$--' : '$ ' + total }}</span>
      </div>
    </div>
    <div class="payment-cost">
      <span class="facture-info">Te enviaremos tu factura en los próximos días al correo de facturación asignado. {{ vendor.email }}</span>
      <button class="facture-info-action" @click="setSale()" :class="{disabled: quantity === null}">Comprar</button>
    </div>
  </div>
</template>
<script>
import marketingTypes from '@/store/types/marketing';
export default {
  name: 'MarketingPayment',
  props: {
    type: {
      type: String
    },
    items: {
      type: Array
    },
    minT: {
      type: Number
    },
    totalFull: {
      type: Number
    },
    stepFor: {
      type: Number
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data () {
    return {
      title: {
        mail: '¿Cuántos correos deseas comprar?',
        sms: 'Si no tienes mensajes o quieres comprar más, ¡hazlo aquí!'
      },
      description: {
        mail: 'Escribe la cantidad de correos',
        sms: 'Escribe la cantidad de mensajes'
      },
      cost: {
        mail: 'Valor por correo',
        sms: 'Valor por mensaje'
      },
      costPer: 0,
      totalWithOut: 0,
      total: 0,
      quantityFormat: null,
      quantity: null,
      edit: false,
      loading: false,
      errorText: '',
      iva: 19
    };
  },
  methods: {
    handleResize () {
      /* if (window.innerWidth <= 1000 && this.totalFull > 0) {
        document.getElementById('payment-marketing').style.order = 3;
      }
      if (window.innerWidth > 1000) {
        document.getElementById('payment-marketing').style.order = 1;
      }
      if (window.innerWidth > 500 && this.totalFull > 0) {
        document.getElementById('payment-marketing').style.order = 3;
      } else {
        document.getElementById('payment-marketing').style.order = 1;
      } */
    },
    getClass () {
      return this.type;
    },
    setEdit () {
      this.edit = !this.edit;
      this.quantity = null;
      this.quantityFormat = null;
      setTimeout(() => {
        document.getElementById('quantityPayment').focus();
      }, 300);
    },
    setSale () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          const data = {
            type: this.type,
            perMessage: this.costPer,
            total: this.totalWithOut,
            vendorId: this.vendor.id,
            quantity: this.quantity,
            date: this.$moment().format('YYYY-MM-DD hh:mm:ss')
          };
          this.$store.commit(marketingTypes.mutations.setPackage, JSON.stringify(data));
          this.$emit('changePage', 5);
          setTimeout(() => {
            this.loading = false;
            this.$emit('paymentData', data);
            this.$emit('paymentShow', true);
          }, 800);
        } else {
          this.edit = !this.edit;
          setTimeout(() => {
            document.getElementById('quantityPayment').focus();
          }, 300);
        }
      });
    },
    valueFormat (e) {
      const minValue = (this.items.length > 0) ? this.items[0].valueIn : 2000;
      let valueIva = 0;
      let number = e.target.value;
      if (/[^0-9.]/g.test(number)) {
        number = number.slice(0, -1);
      }
      if (number === null || number === undefined || number === '') {
        this.quantity = null;
        this.quantityFormat = null;
        this.costPer = 0;
        this.iva = 19;
        this.total = 0;
        this.totalWithOut = 0;
        this.$emit('setTotalPayment', 0);
        return;
      }
      const numberWithoutFormat = parseInt(number.replace(/[.,]/g, ''));
      if (numberWithoutFormat < minValue) {
        this.costPer = 0;
        this.iva = 19;
        this.total = 0;
        this.$emit('setTotalPayment', 0);
        this.totalWithOut = 0;
        this.errorText = 'El valor mínimo es ' + minValue;
      } else {
        this.errorText = '';
        const options = { maximumFractionDigits: 0, currency: 'COP' };
        const numberFormat = new Intl.NumberFormat('es-CO', options);
        this.quantity = numberWithoutFormat;
        this.quantityFormat = numberFormat.format(numberWithoutFormat);
        this.costPer = 0;
        this.iva = 19;
        this.totalWithOut = 0;
        this.total = 0;
        this.$emit('setTotalPayment', 0);
        this.items.find(i => {
          if (this.costPer === 0 && this.total === 0) {
            if (parseInt(i.diference) !== 0) {
              if (numberWithoutFormat >= i.valueIn && numberWithoutFormat <= i.valueOut) {
                this.costPer = parseInt(i.perMessage);
                this.iva = i.iva;
                valueIva = (parseInt(i.perMessage) * parseInt(numberWithoutFormat)) * (i.iva / 100);
                this.total = numberFormat.format((parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva));
                this.$emit('setTotalPayment', numberFormat.format((parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva)));
                this.totalWithOut = parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva;
              }
            } else {
              if (numberWithoutFormat >= i.valueIn) {
                this.costPer = parseInt(i.perMessage);
                this.iva = i.iva;
                valueIva = parseInt(i.perMessage) * parseInt(numberWithoutFormat) * (i.iva / 100);
                this.total = numberFormat.format((parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva));
                this.$emit('setTotalPayment', numberFormat.format((parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva)));
                this.totalWithOut = parseInt(i.perMessage) * parseInt(numberWithoutFormat) + valueIva;
              }
            }
          }
        });
      }
    }
  },
  watch: {
    minT () {
      /* if (window.innerWidth <= 414) {
        if (this.minT === 0) {
          document.getElementById('payment-marketing').style.display = 'grid';
        } else {
          document.getElementById('payment-marketing').style.display = 'none';
        }
      } else {
        document.getElementById('payment-marketing').style.display = 'grid';
      } */
    },
    totalFull () {
      /*  if (window.innerWidth <= 1000 && this.totalFull > 0) {
        document.getElementById('payment-marketing').style.order = 3;
      }
      if (window.innerWidth > 1000) {
        document.getElementById('payment-marketing').style.order = 1;
      } */
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss">
#module-email{
  .module-payment {
    @media screen and (max-width: 500px) {
      margin-top: -33px;
    }
  }
  .module-payment.fix-container-payment {
    padding-top: 45px!important;
  }
  #payment-marketing{
    .payment-title{
      margin-bottom: 20px;
    }
  }
}
#payment-marketing {
  width: 344px;
  /* height: 388px; */
  font-family: "Source Sans Pro";
  height: fit-content;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 30px 22px;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    padding: 30px 85px;
    width: 466px;
    margin: 0 auto;
  }
  @media screen and (max-width: 500px) {
    width: 356px;
    /* height: 400px; */
    height: fit-content;
    padding: 30px 28px;
  }
  .quantity-email-available{
    display: flex;
    justify-content: center;
    color: #444B57;
    gap: 10px;
    margin-bottom: 20px;
    &__icon{
      display: flex;
    }
    &__copy{
      text-align: right;
      align-content: center;
      &__title{
        line-height: 30px;
        font-size: 30px;
      }
      &__subtitle{
        line-height: normal;
        font-size: 12px;
      }
    }
  }
  .payment-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 30px;
    .info {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 300px;
    }
    @media screen and (max-width: 500px) {
      .sms {
        width: 268px;
      }
      .email {
        width: 282px;
      }
    }
  }
  .payment-cost {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    margin: 0 auto;
    .facture-info {
      display: flex;
      margin: 24px 0px 0px 0px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: #444B57;
      font-family: 'Source Sans Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 500px) {
        margin-top: 28px;
      }
      &-action {
        margin: 26px 0px 0px 0px;
        border-radius: 10px;
        border: 1px solid #444B57;
        background: #FFF;
        width: 300px;
        height: 34px;
        flex-shrink: 0;
        color: #444B57;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          margin-top: 35px;
        }
        &:hover {
          background-color: #444B57;
          color: #FFF;
        }
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      margin-top: 30px;
      &-text {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &-right {
          color: #444B57;
          text-align: right;
          font-family: 'Source Sans Pro';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &-value {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &-right {
          color: #444B57;
          text-align: right;
          font-family: 'Source Sans Pro';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      &.fix-value{
        margin-top: 12px!important;
      }
    }
    .value {
      margin: 20px 0px 0px 0px;
    }
  }
  .payment-input {
    height: 40px;
    flex-shrink: 0;
    border-bottom: 1px solid #444B57;
    @media screen and (max-width: 1000px) {
    }
    @media screen and (max-width: 500px) {
    }
    .alert-error {
      font-size: 10px;
      color: red;
    }
    .input-center {
      width: 100%;
      height: 100%;
      border: unset;
    }
    .text-input {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      display: flex;
      align-items: center;
      height: 100%;
      width: 300px;
      .icon-input {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin: 8px 28px 8px 8px;
        cursor: pointer;
      }
    }
  }
  .error-border {
    border-bottom: 1px solid red;
  }
}
</style>
