<template>
  <div id="ModalDecorations">
    <div class="close_modal">
      <i class="icons icon_cerrar_view" @click="$parent.close()"></i>
    </div>
    <div class="header_card">
      <div>
        <i class="icons icon_star"></i>
      </div>
      <span class="titleModal">
        Tipo de decoración:
      </span>
    </div>
    <div class="content_card">
      <div class="container_decoration" v-for="(dec, i) in decorations" :key="i">
        <span class="span_decorations">{{dec.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['decorations']
};
</script>
<style lang="scss">
@use '../../assets/styles/ViewReservation/modalDecorations.scss';
</style>
