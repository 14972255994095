<template>
  <div id="terms-html">
    <p>&nbsp;</p>
    <p style="margin-bottom: 0.14in;line-height: 115%;text-align: left;">
      <strong>1. Autorización.&nbsp;</strong>De manera voluntaria, previa,
      expresa, informada e inequívoca autorizo a
      <strong>Click2buy S.A.S. (“Precompro”)&nbsp;</strong>para tratar mis datos
      personales de acuerdo con la Política de Tratamiento de Datos personales
      de la empresa y para los fines relacionados con su objeto social. Así
      mismo manifiesto que los datos son suministrados de forma voluntaria y
      corresponden a la realidad.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      <strong>2. Responsable del tratamiento.&nbsp;</strong>Click2buy
      S.A.S.(“Precompro”), sociedad identificada con NIT No.900.974.092-1 , con
      domicilio principal en la ciudad de Bogotá D.C, con teléfono 3132195332 y
      correo electrónico contacto@precompro.com, por medio de la presente se
      permite establecer y hacer públicos los principios y parámetros mediante
      los cuales adelantará el tratamiento de los datos personales que recolecte
      en desarrollo de su objeto social, en las calidades tanto de responsable,
      como de encargado del manejo de la información.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      Todo lo anterior tiene como fundamento la ley estatutaria 1581 de 2012,
      reglamentada por el Decreto Nacional 1377 de 2013, y el Decreto 1074 de
      2015, circulares complementarias y sus fundamentos constitucionales
      respecto a la protección de la intimidad, protección de los datos de las
      personas y el derecho al habeas data.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      <strong>3. Tratamiento y finalidades.&nbsp;</strong>En atención del modelo
      de negocio implementado, Precompro actuará como responsable y/o encargado
      del manejo de datos. En tales funciones Precompro recolectará, almacenará,
      procesará, usará, administrará, circulará y transmitirá datos personales
      de los usuarios.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>3.1. Datos de&nbsp;</strong><strong>usuarios</strong
      ><strong>.&nbsp;</strong>Se entiende por usuario cualquier persona que
      ingrese a la plataforma Precompro y/o a la página web del restaurante para
      realizar una reserva. Si usted cumple esta condición es considerado un
      usuario y le informamos que sus datos serán tratados para los siguientes
      fines:
    </p>
    <ol type="a">
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          a)&nbsp;Realizar el proceso de sus reservas;
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          b)&nbsp;Atender requerimientos posteriores a la reserva;
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          c)&nbsp;Verificar y analizar la recurrencia de sus visitas a las páginas web
          de los restaurantes. Con esta información vamos a obtener estadísticas
          para verificar sus hábitos. Esta información la podremos usar para:
          (i) enviarle promociones y publicidad a través de mecanismos tales
          como mensajes de texto, correos electrónicos, llamadas notificaciones
          “push”, pop ups entre otros; (ii) compartir los resultados con los
          restaurantes quienes a su vez utilizarán la información para realizar
          ofertas y publicidad por los mismos medios.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          d)&nbsp;Enviar notificaciones sobre sus reservas, enviar publicidad,
          invitaciones a eventos y alertas de reservas múltiples a los
          restaurantes.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          e)&nbsp;Establecer y publicar calificaciones del usuario respecto de su
          comportamiento en la plataforma y con los restaurantes.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          f)&nbsp;Transferir y/o transmitir sus datos personales a: (i) los restaurantes
          con quien se hace la reserva; (ii) centrales de riesgo y centrales de
          análisis de datos tales como datacredito si llegara a ser necesario;
          (iv) agencias de publicidad; (v) empresas dedicadas al análisis de
          información para realizar actividades propias de “data analitics” y a
          cualquier persona natural o jurídica con la que Precompro mantenga una
          relación comercial o contractual en el marco de su objeto social.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          g)&nbsp;Crear un archivo con los datos de contacto del usuario con el fin de
          llevar un registro interno de sus datos personales.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          h)&nbsp;Hacer seguimiento al cumplimiento y calidad de la prestación de
          servicios de los restaurantes.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          i)&nbsp;Poder atender peticiones, quejas, reclamos, inquietudes, y solicitudes
          elevadas por usted como usuario y por los restaurantes.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          j)&nbsp;Informar sobre prospectos, novedades, eventos importantes e
          información que a discreción consideremos de su interés.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          k)&nbsp;Remitir cualquier tipo de publicidad sobre los servicios prestados y
          promocionados por parte de Precompro.
        </p>
      </li>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
          l)&nbsp;Cumplimiento de obligaciones contractuales, legales y tributarias.
        </p>
      </li>
    </ol>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      <strong>4. Datos que serán recolectados.&nbsp;</strong>Precompro
      recolectará los siguientes datos personales: (i) nombre y apellido; (ii)
      número de identificación; (iii) número de teléfono móvil; (iv) número de
      teléfono fijo; (v) dirección de notificaciones; (vi) datos relacionados
      con sus cuentas bancarias.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      En cuanto a los datos de medios de pago, usted debe saber que Precompro
      utiliza pasarelas de pago para realizar las transacciones. Estas pasarelas
      de pago a su vez utilizan API´s que enlazan la plataforma de Precompro o
      de los restaurantes con sus cuentas bancarias para realizar las
      transacciones electrónicas. Los datos financieros de sus medios de pago
      son tomados por estas pasarelas de pagos quienes a su vez utilizan
      tecnología especializada para encriptar sus datos financieros. Sobre el
      particular usted debe saber que Precompro no tiene acceso a los datos
      financieros.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      <strong>5. Con quién compartimos su información.&nbsp;</strong>La
      plataforma Precompro y/o a la página web del restaurante recolecta su
      información, la cual será directamente almacenada tanto en el servidor de
      Precompro como en el servidor del restaurante aliado.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-top: 0.17in;margin-bottom: 0.17in;background: rgb(255, 255, 255);"
    >
      La información que se recolecta tanto para Precompro como para los
      restaurantes aliados, tiene como finalidad facilitar las reservaciones,
      esta información suministrada al restaurante será administrada por
      Precompro conforme a las políticas de privacidad propias en su calidad de
      responsable, así como por cada uno de los restaurantes que sean clientes o
      aliados de Precompro conforme a sus propias políticas de privacidad y
      tratamiento de datos personales.<strong>&nbsp;</strong>
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>6. Ámbito de aplicación.&nbsp;</strong>La presente política se
      aplicará al tratamiento de datos personales efectuado en territorio
      colombiano, o cuando le sea aplicable la norma al responsable y/o
      encargado ubicado fuera del territorio colombiano, en virtud de tratados
      internacionales, relaciones contractuales, entre otros.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      Las disposiciones contenidas en esta política se aplicarán a cualquier
      base de datos personales que se encuentren en custodia de Precompro, bien
      sea en calidad de responsable y/o encargado del tratamiento.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>7. Niños y adolescentes menores de edad:&nbsp;</strong>En
      cumplimiento con lo dispuesto por la ley 1581 de 2012 y el decreto 1074 de
      2015, estos datos siempre serán solicitados bajo el consentimiento del
      representante del menor quien estará previamente informado sobre el
      tratamiento que se les dará, las finalidades, y en pleno conocimiento que
      no se encuentra en la obligación de entregar los mismos.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      De igual forma se tratarán de conformidad con lo desarrollado por la Corte
      Constitucional Colombiana, esto es, siempre respetando sus derechos
      fundamentales y buscando la consecución de su bienestar y el desarrollo de
      sus intereses superiores.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>8. Estándares de Seguridad:&nbsp;</strong>Los estándares de
      seguridad, para todas las bases de datos que reposan en custodia de
      Precompro se evidencia en la asignación de claves para todos los equipos
      de computación, toda la información que se encuentra en la nube por medio
      de Digital Ocean, es accesible al empleado de Precompro en el cargo de
      “Programador”, quien cuenta con clave personal, para el ingreso a la
      información.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      Adicional a lo anterior, todas las páginas que crea Precompro a los
      restaurantes están certificadas con certificado SSL, expedido por Let’s
      Encrypt Authority X3.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      Por su parte, las bases de datos almacenadas por Precompro cumplen con
      todos los estándares de seguridad, cada una cuenta con claves
      independientes, que permiten encriptar toda la información recibida por
      los usuarios dentro de un sistema de tokenización, lo que permite
      vinculación directa entre el software de la plataforma de Precompro y
      todas las bases de datos.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>9. Derechos</strong
      ><strong>&nbsp;de los titulares de la información.&nbsp;</strong>Los
      titulares de la información contenida en las bases de datos que trate
      Precompro sea como responsable, o como encargado del tratamiento tienen
      los derechos que a continuación se relacionan de conformidad con lo
      dispuesto por la Constitución Política de Colombia y la normativa
      aplicable y vigente.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      El ejercicio de dichos derechos solo podrá adelantarse de forma exclusiva
      por el titular del dato o por las personas que este autorice de
      conformidad con lo establecido por la ley, y la presente política de
      tratamiento.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      a) Conocer, actualizar y rectificar sus datos personales frente a los
      Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se
      podrá ejercer, entre otros frente a datos parciales, inexactos,
      incompletos, fraccionados, que induzcan a error, o aquellos cuyo
      Tratamiento esté expresamente prohibido o no haya sido autorizado.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.59in;text-indent: -0.5in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      b) Solicitar prueba de la autorización otorgada al Responsable del
      Tratamiento salvo cuando expresamente se exceptúe como requisito para el
      Tratamiento.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      c) Ser informado por el Responsable del Tratamiento o el Encargado del
      Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos
      personales.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.59in;text-indent: -0.5in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      d) Presentar ante la Superintendencia de Industria y Comercio quejas por
      infracciones a lo dispuesto en la presente política y las leyes que
      regulan la materia. No obstante, se aclara que antes de presentar
      cualquier queja por infracción ante las autoridades competentes, es
      indispensable agotar el requisito de procedibilidad ante Precompro, con el
      fin de dirimir cualquier inconformidad presentada.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.59in;text-indent: -0.5in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      e) Revocar la autorización y/o solicitar la supresión del dato cuando en
      el Tratamiento no se respeten los principios, derechos y garantías
      constitucionales y legales. La revocatoria y/o supresión procederá cuando
      la Superintendencia de Industria y Comercio haya determinado que en el
      Tratamiento el Responsable o Encargado han incurrido en conductas
      contrarias a esta ley y a la Constitución.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.59in;text-indent: -0.5in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      f) Acceder en forma gratuita a sus datos personales que hayan sido objeto
      de Tratamiento. Esto a través de los medios que considere pertinentes,
      como el derecho de petición.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong
        >10. Procedimiento para la atención de solicitudes y reclamos por parte
        de los titulares de la información.&nbsp;</strong
      >En desarrollo del derecho fundamental de los titulares al Habeas Data
      respecto de los derechos de acceso, consulta, actualización,
      rectificación, o cancelación se seguirá el siguiente procedimiento:
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      a) Los derechos sobre los datos personales sólo podrán ser ejercidos
      directamente por el titular, o por un tercero habilitado legalmente de
      acuerdo con lo establecido por la ley 1581 de 2012. Esta condición se
      acreditará mediante copia del documento de identidad, y en caso de ser
      autorizado, el poder o autorización debidamente otorgados.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.59in;text-indent: -0.5in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      b) La solicitud para ejercer cualquiera de estos derechos deberá hacerse a
      través del canal delimitado por Precompro dentro de la presente Política
      de Tratamiento de datos personales, se aclara que este canal, es el correo
      electrónico descrito en la sección 8, denominada “<em
        >Canal designado para la recepción de peticiones y reclamos de habeas
        data</em
      >”.
      <br />
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      c) La solicitud de ejercicio de los derechos deberá contener los
      requisitos mínimos de identificación y especificación de la petición
      consagrados en el Código de Procedimiento Administrativo y de lo
      Contencioso Administrativo.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-left: 0.2in;text-indent: -0.2in;margin-bottom: 0in;border: none;padding: 0in;"
    >
      d) Por su parte los términos para atender las consultas o reclamos y las
      particularidades de la información a contener se ceñirá a lo dispuesto por
      los artículos 14 y 15 de la ley 1581 de 2012.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0in;border: none;padding: 0in;"
    >
      <br />
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      En caso de pretender acudir a la Superintendencia de Industria y Comercio
      en ejercicio de las acciones legales contempladas para los titulares de
      datos o interesados, se deberá agotar previamente el trámite acá descrito.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong
        >11. Canal designado para la recepción de peticiones y reclamos de
        habeas data.&nbsp;</strong
      >En todo caso, Precompro actuando como Encargado o como Responsable de la
      información recibirá peticiones y reclamos en ejercicio de los derechos de
      los titulares de la información en el correo electrónico:
    </p>
    <ul>
      <li>
        <p style="line-height: 115%;text-align: justify;margin-bottom: 0in;">
          contacto@precompro.com
          <br />
          <br />
        </p>
      </li>
    </ul>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      Dicho correo será controlado y manejado por el área de atención al cliente
      de la empresa, quien conoce sus obligaciones legales con el fin de atender
      los requerimientos que por dicha vía se eleven.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>12. Temporalidad de los datos.&nbsp;</strong>La permanencia de los
      datos tratados por Precompro sea como responsable o como encargado del
      tratamiento estará determinada por la finalidad por la cual dichos datos
      fueron recogidos o entregados a Precompro, según sea el caso. Por lo
      tanto, una vez se agote la finalidad o cuando el titular solicite la
      eliminación de sus datos, Precompro procederá a su eliminación,
      destrucción o devolución, según como se estime pertinente.
    </p>
    <p style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;">
      De todas formas, Precompro es consciente que existen obligaciones de
      carácter legal, laboral, contable y tributario que le obligan a conservar
      por cierto tiempo determinado algunos datos, por lo que se compromete a
      hacerlo sólo y únicamente para tal efecto, adoptando las medidas técnicas
      y de seguridad para un tratamiento adecuado.
    </p>
    <p
      style="line-height: 115%;text-align: justify;margin-bottom: 0.14in;border: none;padding: 0in;"
    >
      <strong>13. Vigencia.&nbsp;</strong>La presente política de tratamiento de
      datos personales a cargo de Precompro quien actúa como responsable y
      encargado del tratamiento de información de terceros paralelamente ha sido
      aprobada y por lo tanto regirá a partir del {{fecha}}, y
      se aplicará de igual forma a los datos recogidos con anterioridad a esta
      fecha; con un término indefinido hasta que se adelante una reforma
      sustancial a la misma, Fenómeno que será debidamente informado a través de
      los canales dispuestos.
    </p>
    <p style="line-height: 115%;text-align: center;margin-bottom: 0.14in;">
      *** Hasta acá la política de protección de datos***
    </p>
    <table style="margin: 0 auto;" cellpadding="7" cellspacing="0" width="365">
      <tbody>
        <tr>
          <td
            bgcolor="#cccccc"
            style="background: #cccccc;"
            width="16.09907120743034%"
          >
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              <strong>Versión&nbsp;</strong>
            </p>
          </td>
          <td
            bgcolor="#cccccc"
            style="background: #cccccc;"
            width="39.31888544891641%"
          >
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              <strong>Fecha Publicación&nbsp;</strong>
            </p>
          </td>
          <td
            bgcolor="#cccccc"
            style="background: #cccccc;"
            width="44.582043343653254%"
          >
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              <strong>Temas incorporados</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border: none;padding: 0in;" width="16.09907120743034%">
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              2.0
            </p>
          </td>
          <td style="border: none;padding: 0in;" width="44.31888544891641%">
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              {{fecha}}
            </p>
          </td>
          <td style="border: none;padding: 0in;" width="39.582043343653254%">
            <p
              style="line-height: 115%;text-align: center;margin-bottom: 0in;border: none;padding: 0in;"
            >
              Reforma integral.
            </p>
            <p
              style="margin-bottom: 0.1in;line-height: 115%;text-align: center;border: none;padding: 0in;"
            >
              Segunda publicación.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-bottom: 0.14in;line-height: 115%;text-align: left;">
      <br />
      <br />
    </p>
  </div>
</template>
<script>
export default {
  name: 'MarketingTerms',
  props: ['fecha']
};
</script>
<style lang="scss">
#terms-html {
  height: 500px;
  overflow-y: auto;
  text-align: justify;
  padding-right: 5px;
  background: white;
  padding: 30px;
  &::-webkit-scrollbar {
    width: 3px;
  }
}
</style>
