<template>
  <div class="container-menu-qr-config container-menu-qr-config-global">
    <div v-if="!createEditCategorySection">
      <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
      <b-tabs v-model="activeTab" v-if="!viewCategories">
        <b-tab-item label="Botones/Menús" class="menus-tab">
          <div id="menuQR">
            <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
            <div v-if="typeMenu !== 'normal' && !viewCategories" class="container-menu-qr">
              <SelectLanguages :type="'multimenu'"/>
              <div class="container-menu-qr__cards container-menu-qr__cards--create" @click="createNewButton">
                <div>
                  <i class="icon-mks icon_plus_alternative_thin" />
                  <span>Crear nuevo botón</span>
                </div>
              </div>
              <CardBtnMenu v-for="(menu, index) in menuItemsMultiple" :key="index" :restaurant="restaurant" :menu="menu" @changeStatusLoader="changeStatusLoader" @getMultiMenu="getMultiMenu" :restaurantData="restaurantData" @changeToCategoriesProducts="changeToCategoriesProducts" />
            </div>
            <div class="container-menu" v-if="typeMenu === 'normal' && !viewCategories">
              <SelectLanguages :type="'menu'" @changeTypeLanguage="changeTypeLanguage"/>
              <h2 class="container-menu__title">{{ menusCreated ? 'Editar menú como imagen' : 'Crear menú como imagen' }}</h2>
              <b-upload
                multiple
                v-model="selectedImages"
                :disabled="isForDelete"
                :style="!isForDelete ? {cursor: 'pointer'} : {opacity: '0.5'}"
                v-if="menuItems.length > 0"
                class="card-image__upload-aux"
              >
                <div class="container-menu__content__cards__card-image container-menu__content__cards__card-image__upload-aux" :class="`${!isForDelete ? 'with-shadow':''}`">
                  <div class="container-menu__content__cards__card-image__section">
                    <div class="container-menu__content__cards__card-image__section__img">
                      <img src="@/assets/icons/menuQr/no_image_dark.svg" alt="no image">
                    </div>
                    <div>Agregar imagen</div>
                  </div>
                </div>
              </b-upload>
              <div class="container-menu__content">
                <div v-if="!menusCreated">
                  <h3 class="container-menu__content__title">Puedes agregar una o varias imágenes.</h3>
                  <div class="divider other-c"></div>
                </div>
                <div class="container-menu__content__cards" :class="{'container-menu__content__cards__massive-delete' : isForDelete}" :style="menuItems.length === 0 && menusForCreate.length === 0 ? { justifyContent: 'center' }: ''">
                  <div class="container-menu__content__cards__massive-delete__check" v-if="menuItems.length > 0 && isForDelete">
                    <div>Seleccionar todas</div>
                    <b-checkbox v-model="massiveDelete"></b-checkbox>
                  </div>
                  <b-upload
                    multiple
                    v-model="selectedImages"
                    draggable="false"
                    :disabled="isForDelete"
                    :style="!isForDelete ? {cursor: 'pointer'} : {opacity: '0.5'}"
                    v-if="menuItems.length === 0"
                  >
                    <div class="container-menu__content__cards__card-image" :class="`${!isForDelete ? 'with-shadow':''}`">
                      <div class="container-menu__content__cards__card-image__section">
                        <div class="container-menu__content__cards__card-image__section__img">
                          <img src="@/assets/icons/menuQr/no_image_dark.svg" alt="no image">
                        </div>
                        <div>Agregar imagen</div>
                      </div>
                    </div>
                  </b-upload>
                  <draggable
                    :list="menuItems"
                    :disabled="isMobile"
                    class="list-group"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="handleDragEnd"
                    v-if="!isForDelete"
                  >
                    <CardMenuImage v-for="(menu, index) in menuItems" :key="index" :menu="menu" typeMenu="menu" :isEdit="menuItems.length > 0"  @changeStatusLoader="changeStatusLoader" @getMenu="getMenu"/>
                  </draggable>
                  <div class="list-group" v-if="isForDelete">
                    <CardMenuImage v-for="(menu, index) in menuItems" :key="index" :menu="menu" typeMenu="menu" :isEdit="menuItems.length > 0"  @changeStatusLoader="changeStatusLoader" @getMenu="getMenu" :isForDelete="true" :arrayDeleteMassive="arrayDeleteMassive" @modifyMassiveDeleteArray="modifyMassiveDeleteArray" />
                  </div>
                  <CardMenuImage v-for="(menuForCreate, index) in menusForCreate" :key="index" :menu="menuForCreate" typeMenu="menu" @deleteMenuLocal="deleteMenuLocal(index)" />
                </div>
              </div>
              <div class="container-menu__footer">
                <p style="margin-bottom: 0px">Las imágenes deben estar en formato jpg y no superar los 2MB de peso cada una, y el total del peso de todas las imágenes no debe super los {{totalSizeForAllImages}}MB.</p>
                <p><b>Nota:</b>
                {{
                  !isMobile ?
                    ' para cambiar el orden de las imágenes puedes hacer clic sobra la imagen que quieres cambiar y la arrastras al lugar que desees o puedes cambiar el número en el campo orden.'
                  :
                    ' para cambiar el orden de las imágenes puedes cambiar el número en el campo orden.'
                }}
                </p>
                <div class="container-menu__footer__actions">
                  <button :disabled="menusForCreate.length === 0" class="container-menu__footer__actions__save" @click="setMenuMultiple" v-if="menuItems.length === 0">
                    <img src="@/assets/icons/menuQr/save_menu.svg" alt="no image">
                    <div>Guardar menú</div>
                  </button>
                  <button class="container-menu__footer__actions__close" v-if="menuItems.length === 0">
                    <img src="@/assets/icons/menuQr/close.svg" alt="" srcset="">
                    <div>Cerrar</div>
                  </button>
                  <button class="container-menu__footer__actions__select-images" v-if="menusCreated && !isForDelete" @click="() => isForDelete = true">
                    <div>Seleccionar imagenes</div>
                  </button>
                  <button :disabled="arrayDeleteMassive.length === 0" class="container-menu__footer__actions__delete-images" v-if="menusCreated && isForDelete" @click="alertDeleteAll">
                    <div>Eliminar imagenes</div>
                  </button>
                  <button class="container-menu__footer__actions__close-delete-images" v-if="menusCreated && isForDelete" @click="() => isForDelete = false">
                    <div>Cerrar</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Categoría" class="categories-tab">
          <div class="categories-tab__actions">
            <div class="categories-tab__actions--back" @click="$emit('goBackMenus')">
              <i style="display: none;" class="icon-mks icon_arrow_down_general"></i>
              <div style="display: none;">Comida</div>
            </div>
            <div class="categories-tab__actions__create-search">
              <b-button @click="() => {
                this.changeStatusSectionCreateEditCategories(true);
                this.typeSectionCategory = 'create';
              }">
                Crear categoría
              </b-button>
              <div class="categories-tab__actions__create-search--searcher-list-products">
                <img src="@/assets/icons/menuQr/search.svg" alt="">
                <b-input placeholder="Bucar por nombre" v-model="keyword" autocomplete="off" />
              </div>
            </div>
          </div>
          <section class="container-menu-qr-config__categories" id="listCategories" v-if="activeTab === 1">
            <!-- <div class="container-menu-qr-config__categories__loader" v-if="isLoadingSpinner">
              <i class="icon-mks icon_precompro animation-spin-prec"></i>
            </div> -->
            <h3 v-if="listCategories.length === 0">No hay categorías disponibles</h3>
            <div class="container-list-categories-aux">
              <CardCategories :globalCategories="true" :idMenuMultipleForCategoriesProducts="idMenuMultipleForCategoriesProducts" :restaurant="restaurant" v-for="(category, index) in listCategories" :key="index" :category="category" @getListCategories="getListCategories" @changeStatusLoader="changeStatusLoader" @setCategoryDetail="setCategoryDetail" @clearListCategories="clearListCategories" />
            </div>
          </section>
        </b-tab-item>
        <b-tab-item label="Productos" class="products-tab">
          <section class="container-menu-qr-config__products" v-if="activeTab === 2">
            <ListProducts :restaurant="restaurant" :idMenuMultipleForCategoriesProducts="null" :listCategories="listCategories" />
          </section>
        </b-tab-item>
        <b-tab-item label="Información" class="info-tab">
          <InfoOptimizer />
        </b-tab-item>
      </b-tabs>
      <ConfigCategoriesProducts @goBackMenus="goBackMenus" :restaurant="restaurant" :idMenuMultipleForCategoriesProducts="idMenuMultipleForCategoriesProducts" v-if="viewCategories"/>
    </div>
    <div v-else class="">
      <SectionCreateEditCategory :restaurant="restaurant" :idMenuMultipleForCategoriesProducts="null" :type="typeSectionCategory" :dataCategorySelected="dataCategorySelected" @changeStatusSectionCreateEditCategories="changeStatusSectionCreateEditCategories" @getListCategories="() => {
        clearListCategories();
        getListCategories();
      }" />
    </div>
  </div>
</template>

<script>
import CardBtnMenu from '@/components/schedules/menu-qr/complements/CardBtnMenu.vue';
import CardMenuImage from '@/components/schedules/menu-qr/complements/CardMenuImage.vue';
import CreateNewButton from '@/components/schedules/menu-qr/CreateNewButton.vue';
import SelectLanguages from '@/components/schedules/menu-qr/complements/SelectLanguages.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import ConfigCategoriesProducts from '@/components/schedules/menu-qr/ConfigCategoriesProducts.vue';

import CardCategories from '@/components/schedules/menu-qr/complements/categories/CardCategories.vue';
import ListProducts from '@/components/schedules/menu-qr/complements/products/ListProducts.vue';
import SectionCreateEditCategory from '@/components/schedules/menu-qr/complements/categories/SectionCreateEditCategory.vue';
import InfoOptimizer from '@/components/schedules/menu-qr/complements/info/InfoOptimizer.vue';

import serviceQr from '@/helpers/apis/serviceQr.js';
import globalTypes from '@/store/types/global';
import menuQrTypes from '@/store/types/menuQr';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'MenuQr',
  props: ['restaurant'],
  components: {
    CardBtnMenu,
    CardMenuImage,
    draggable,
    SelectLanguages,
    ConfigCategoriesProducts,
    CardCategories,
    ListProducts,
    SectionCreateEditCategory,
    InfoOptimizer
  },
  data () {
    return {
      // valores tabs
      activeTab: 0,
      createEditCategorySection: false,
      listCategories: [],
      currentPage: 1,
      noMoreData: false,
      // fin valores tabs
      menuItemsMultiple: [],
      menuItems: [],
      menusForCreate: [],
      typeMenu: 'normal',
      urlPdf: 'https://www.ilovepdf.com/es/pdf_a_jpg',
      urlOptimizar: 'https://imagecompressor.com/es/',
      isLoading: false,
      menusCreated: false,
      selectedImages: null,
      imagesSend: [],
      dragging: false,
      isForDelete: false,
      massiveDelete: false,
      arrayDeleteMassive: [],
      totalSizeForAllImages: 10,
      isMobile: false,
      viewCategories: false,
      idMenuMultipleForCategoriesProducts: null,
      keyword: '',
      timeout: null
    };
  },
  computed: {
    ...mapGetters({
      restaurantData: [globalTypes.getters.vendor],
      typeLanguage: menuQrTypes.getters.typeLanguage
    })
  },
  mounted () {
    if (this.restaurant.isActiveMenuDigital && (this.restaurant.typeMenuDigital === 'basic' || this.restaurant.typeMenuDigital === 'medium' || this.restaurant.typeMenuDigital === 'intermediate')) {
      this.getMenu();
      this.typeMenu = 'normal';
    }
    if (this.restaurant.multiMenu && (this.restaurant.typeMenuDigital === 'intermediate' || this.restaurant.typeMenuDigital === 'advanced')) {
      this.getMultiMenu();
      this.typeMenu = 'multimenu';
    }
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    const categoriesMenu = document.getElementById('listCategories');
    if (categoriesMenu) {
      categoriesMenu.addEventListener('scroll', this.handleScroll);
      /* categoriesMenu.addEventListener('touchend', this.handleScroll); */
    }
  },
  beforeDestroy () {
    const categoriesMenu = document.getElementById('listCategories');
    if (categoriesMenu) {
      categoriesMenu.removeEventListener('scroll', this.handleScroll);
      /* categoriesMenu.removeEventListener('touchend', this.handleScroll); */
    }
  },
  methods: {
    checkScreenSize () {
      this.isMobile = window.innerWidth <= 1024;
    },
    getMultiMenu () {
      this.isLoading = true;
      serviceQr.get('/menudigital/multimenu/' + this.restaurant.vendorId).then(({ data }) => {
        this.menuItemsMultiple = data.data;
        this.menuItemsMultiple.forEach(element => {
          if (element.dayOfWeek != null) {
            const strDaysArray = element.dayOfWeek.split(',');
            element.dayOfWeek = strDaysArray.map(Number);
          }
        });
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Ha ocurrido un error en el servidor',
          type: 'is-danger'
        });
      });
    },
    createNewButton () {
      const instance = this;
      const data = {};
      data.vendorId = this.restaurant.vendorId;
      data.image = null;
      data.imageEnglish = null;
      data.imageFrench = null;
      data.title = null;
      data.titleEnglish = null;
      data.titleFrench = null;
      data.param = 0;
      data.order = this.menuItemsMultiple.length > 0 ? this.menuItemsMultiple.length : 0;
      data.programing = 0;
      data.dayOfWeek = null;
      data.initPrograming = null;
      data.finishPrograming = null;
      this.$buefy.modal.open({
        parent: this,
        component: CreateNewButton,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-create-new-button',
        props: {
          id: null,
          info: data,
          restaurantData: instance.restaurantData,
          restaurant: instance.restaurant
        },
        events: {
          getMultiMenu () {
            instance.getMultiMenu();
          }
        }
      });
    },
    getMenu () {
      this.isLoading = true;
      serviceQr.get(`/menudigital/single/${this.restaurant.vendorId}?filter=${this.typeLanguage}`).then(({ data }) => {
        this.menuItems = data.data;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Ha ocurrido un error en el servidor',
          type: 'is-danger'
        });
      });
    },
    changeStatusLoader (status) {
      this.isLoading = status;
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    },
    uploadImages () {
      this.isLoading = true;
      const imgsForUpload = [...this.selectedImages];
      const sortedArray = imgsForUpload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      let totalSize = 0;
      sortedArray.forEach((file) => {
        file.realSize = this.bytesToSize(file.size);
        totalSize += file.size;
      });
      const totalSizeConverted = totalSize / (1024 * 1024);
      const pass = sortedArray.filter(sa => (sa.realSize.format === 'MB' && sa.realSize.size >= 2));
      const instance = this;
      if (totalSizeConverted > this.totalSizeForAllImages) {
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete alert-max-size',
          props: {
            idMenu: null,
            type: 'file_size',
            title: '¡ATENCIÓN!',
            subtitle: `
              <div style="margin-bottom: 10px;">Una o varias imágenes que estas usando exceden el peso máximo permitido.</div>
              <div>El peso actual total de las imagenes es de <b>${totalSizeConverted.toFixed(1)}MB</b></div>
              `,
            typeDelete: ''
          },
          events: {
            onCancel () {
              instance.isLoading = false;
              instance.selectedImages = null;
            }
          }
        });
      } else {
        if (pass.length > 0) {
          this.$buefy.modal.open({
            parent: this,
            component: AlertConfirmDelete,
            hasModalCard: true,
            canCancel: false,
            customClass: 'alert-confirm-delete alert-capacity',
            props: {
              idMenu: null,
              type: 'optimize',
              title: 'Imagen muy pesada.',
              subtitle: `
                <div style='margin-bottom:10px;'>Una o varias imagenes tienen un peso superior al recomendado, se recomienda subir imágenes con un tamaño inferior a 2 MB.</div>
                <div>¿Desea que optimicemos las imagenes que estas cargando?</br></div>
                `,
              typeDelete: ''
            },
            events: {
              onConfirm () {
                instance.serviceForUploadImagesMultiple(sortedArray);
              },
              onCancel () {
                instance.isLoading = false;
                instance.selectedImages = null;
              }
            }
          });
          return;
        }
        this.serviceForUploadImagesMultiple(sortedArray);
      }
    },
    serviceForUploadImagesMultiple (sortedArray) {
      this.isLoading = true;
      this.uploadImageMultiple(sortedArray, this.vendor, 'menu').then(({ data, code, message }) => {
        if (code === 200) {
          data.data.forEach((url, index) => {
            this.menusForCreate.push({
              url: url,
              order: this.menuItems.length > 0 ? this.menuItems.length + 1 : index + 1,
              vendorId: this.restaurant.vendorId,
              titleImg: null,
              language: 'spanish',
              isActive: 1
            });
          });
          this.selectedImages = null;
          if (this.menuItems.length > 0) {
            this.setMenuMultiple();
          } else {
            this.isLoading = false;
          }
        } else {
          this.$buefy.toast.open({
            message: 'Ha ocurrido un error',
            type: 'is-danger'
          });
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    setMenuMultiple () {
      this.isLoading = true;
      serviceQr.post('/menudigital/multiple', { images: this.menusForCreate, vendorId: this.restaurant.vendorId, userId: this.user.id, language: this.typeLanguage }).then(({ data }) => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.isLoading = true;
        this.menusForCreate = [];
        this.isForDelete = false;
        this.getMenu();
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    deleteMenuLocal (index) {
      this.menusForCreate.splice(index, 1);
    },
    handleDragEnd: function () {
      this.changeStatusLoader(true);
      this.updatePositions();
    },
    updatePositions: function () {
      this.menuItems.forEach((item, index) => {
        item.order = index + 1;
      });
      this.dragging = false;
    },
    changeTypeLanguage (typeLang = 'spanish') {
      this.$store.commit(menuQrTypes.mutations.setTypeLanguage, typeLang);
      this.arrayDeleteMassive = [];
      this.massiveDelete = false;
    },
    modifyMassiveDeleteArray (id, mode = 'push') {
      if (mode === 'push') {
        this.arrayDeleteMassive.push(id);
      } else {
        this.arrayDeleteMassive = this.arrayDeleteMassive.filter((itemId) => itemId !== id);
      }
    },
    alertDeleteAll () {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete',
        props: {
          idMenu: null,
          type: 'delete',
          title: 'Confirmar',
          subtitle: '¿Estás seguro(a) que quieres eliminar estas imágenes?',
          typeDelete: 'menu_massive'
        },
        events: {
          changeStatusLoader (status) {
            instance.changeStatusLoader(status);
          },
          getMenu () {
            instance.$emit('getMenu');
          },
          executeMassiveDelete () {
            instance.executeMassiveDelete();
          }
        }
      });
    },
    executeMassiveDelete () {
      this.isLoading = true;
      serviceQr.post('/menudigital/single/delete/all', { ids: this.arrayDeleteMassive, userId: this.user.id }).then(({ data }) => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.menusForCreate = [];
        this.massiveDelete = false;
        this.arrayDeleteMassive = [];
        this.getMenu();
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    changeToCategoriesProducts (status, idMenu) {
      this.viewCategories = status;
      this.idMenuMultipleForCategoriesProducts = idMenu;
    },
    goBackMenus () {
      this.viewCategories = false;
    },
    // funciones de categorias y productos
    changeStatusSectionCreateEditCategories (status) {
      this.createEditCategorySection = status;
    },
    setCategoryDetail (data) {
      this.dataCategorySelected = data;
      this.changeStatusSectionCreateEditCategories(true);
      this.typeSectionCategory = 'edit';
    },
    getListCategories (withKeyWord = false) {
      if (this.noMoreData) return;
      this.isLoading = true;
      const params = {
        page: this.currentPage
      };
      if (this.keyword) {
        params.q = this.keyword;
      }
      serviceQr.get(`/menuCustom/listCategory/${this.restaurant.vendorId}`, { params }).then(({ data }) => {
        const newCategories = data.data.data;
        this.listCategories = this.listCategories.concat(newCategories);
        if (withKeyWord) {
          this.listCategories = newCategories;
        }
        if (data.data.last_page === this.currentPage) {
          this.noMoreData = true;
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
    },
    handleScroll (event) {
      if (this.listCategories.length > 0) {
        const categoriesSection = event.target;
        if (((categoriesSection.scrollHeight - Math.round(categoriesSection.scrollTop)) === categoriesSection.clientHeight)) {
          // Si el usuario ha llegado al final de la tabla
          if (!this.noMoreData) {
            this.currentPage++;
            this.getListCategories();
          }
        }
      }
    },
    clearListCategories () {
      this.currentPage = 1;
      this.noMoreData = false;
      this.listCategories = [];
    },
    // fin funciones de categorias y productos
    updateMenu (index, updatedMenu) {
      this.$set(this.menuItemsMultiple, index, updatedMenu); // Actualizar reactivamente el array
    }
  },
  watch: {
    keyword () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.currentPage = 1;
        this.noMoreData = false;
        this.getListCategories(true);
      }, 1500);
    },
    menuItems () {
      if (this.menuItems.length > 0) {
        this.menusCreated = true;
      } else {
        this.menusCreated = false;
      }
    },
    selectedImages () {
      if (this.selectedImages !== null) {
        this.uploadImages();
      }
    },
    typeLanguage () {
      if (this.typeMenu === 'normal') {
        this.getMenu();
      }
    },
    massiveDelete (value) {
      if (value) {
        this.arrayDeleteMassive = [];
        this.menuItems.forEach(menu => {
          this.arrayDeleteMassive.push(menu.id);
        });
      } else {
        this.arrayDeleteMassive = [];
      }
    },
    activeTab (value) {
      this.keyword = '';
      if (value === 0) {
        this.getMultiMenu();
      }
      if (value === 1) {
        // Caso cuando activeTab es 1
        this.clearListCategories();
        this.getListCategories();
        // Si el elemento existe, elimina el event listener y detiene el intervalo
        const existElementInterval = setInterval(() => {
          const categoriesMenu = document.getElementById('listCategories');
          if (categoriesMenu) {
            categoriesMenu.addEventListener('scroll', this.handleScroll);
            /* categoriesMenu.addEventListener('touchend', this.handleScroll); */
            clearInterval(existElementInterval);
          }
        }, 300);
      } else {
        const categoriesMenu = document.getElementById('listCategories');
        if (categoriesMenu) {
          categoriesMenu.removeEventListener('scroll', this.handleScroll);
          /* categoriesMenu.removeEventListener('touchend', this.handleScroll); */
        }
      }
    },
    createEditCategorySection (value) {
      if (!value) {
        const existElementInterval = setInterval(() => {
          const categoriesMenu = document.getElementById('listCategories');
          if (categoriesMenu) {
            categoriesMenu.addEventListener('scroll', this.handleScroll);
            /* categoriesMenu.addEventListener('touchend', this.handleScroll); */
            clearInterval(existElementInterval);
          }
        }, 300);
      }
    },
    'restaurant.isActiveMenuDigital': function (newVal) {
      if (newVal && (this.restaurant.typeMenuDigital === 'basic' || this.restaurant.typeMenuDigital === 'medium')) {
        this.getMenu();
        this.typeMenu = 'normal';
      }
    },
    'restaurant.multiMenu': function (newVal) {
      if (newVal && (this.restaurant.typeMenuDigital === 'intermediate' || this.restaurant.typeMenuDigital === 'advanced')) {
        this.getMultiMenu();
        this.typeMenu = 'multimenu';
      }
    },
    'restaurant.typeMenuDigital': function (newVal) {
      if (this.restaurant.isActiveMenuDigital) {
        if (newVal === 'basic' || newVal === 'medium') {
          this.getMenu();
          this.typeMenu = 'normal';
        } else if (newVal === 'intermediate' || newVal === 'advanced') {
          this.getMultiMenu();
          this.typeMenu = 'multimenu';
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/_index.scss";
@import "@/assets/styles/menuQr/_config_categories_products.scss";
</style>
