<template>
  <div class="content-modal_calendar" id="BlockBYRange">
    <button class="close_button" @click.prevent.stop="isOpen = false" v-if="!isLoading"><i class="fa fa-times"></i></button>
    <div class="right-block-side">
      <div class="Litle-text">Para bloquear un rango por favor <b>seleccione la fecha inicial y la fecha final</b> y <b> pulse OK </b> </div>
      <div class="columns box-dates is-mobile" v-if="dates_range">
        <div class="column fisrt-data">
          {{ dates_range[0] }}
        </div>
        <div class="column last-data">
          {{ dates_range[1] }}
        </div>
      </div>
      <button class="button-white-ok" :disabled="range.length !== 2" @click.prevent.stop="blockRange" >
        <i class="fa fa-check"></i>  Ok
      </button>
    </div>
    <div class="left-block-side">
        <BlockCalendar v-model="range" @dates="GetDates"></BlockCalendar>
        <div class="bottom-butons"><div style="height:15px;"></div></div>
    </div>
  </div>
</template>
<script>
import BlockCalendar from './BlockCalendar';
import _ from 'lodash';
export default {
  name: 'BlockRange',
  components: { BlockCalendar },
  props: ['value'],
  data: () => ({
    isLoading: false,
    range: [],
    dates_range: null,
    isOpen: false
  }),
  methods: {
    blockRange () {
      this.isLoading = true;
      const slots = _(this.getDaysFromRange(this.range)).value();
      this.$store.dispatch({
        type: 'reservation:blockedRange',
        data: {
          vendorId: this.vendorId,
          slots: _.zipObject(slots, _.map(slots, () => true))
        }
      }).then(() => {
        this.isOpen = false;
        this.$emit('eventBlockCalendar');
      }).catch(() => {
        this.isOpen = false;
      });
    },
    GetDates (dates) {
      const first = this.$moment(dates[0]).format('dddd DD MMM YYYY');
      const last = this.$moment(dates[1]).format('dddd DD MMM YYYY');
      this.dates_range = [first, last];
    },
    getDaysFromRange (range) {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      return _.map(_.range((range[1] - range[0]) / ONE_DAY + 1), i => this.$moment(new Date(i * ONE_DAY + range[0])).format('YYYY-MM-DD'));
    }
  }
};
</script>
<style lang="scss" scoped>

#BlockBYRange {
  &.content-modal_calendar {
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    width: calc(100% + 340px);
    border-right:1px solid #fff;
    padding:1.5rem 1rem 1rem 0px;
    right: 0px;
    border-radius: 10px 0 10px 10px;
    z-index: 9999;
    background:rgba(68, 75, 87, 0.7);
  }
  .close_button {
    background: transparent;
    color: #fff;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }
  .Atention {
    font-weight:bold;
    color:#fff !important;
    font-size:20px;
  }
  .Litle-text{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  .box-dates{
    margin-top:10px;
    border:1px solid #fff;
    border-radius:50px;
    width:100%;
    .fisrt-data {
      border-right:1px solid #fff;
    }
    .fisrt-data, .last-data{
      padding: 10px;
      color: #fff;
      text-transform: capitalize;
      font-size:10px;
    }
  }

  .right-block-side{
    height: calc(100% + 2.5rem);
    background: #444B57;
    padding: 1.5rem 1rem 1rem !important;
    top: -4px;
    position: relative;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    border-right: 1px solid #fff;
    margin-right: 10px;
  }

  button.button-white-ok{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    font-size: 13px;
    font-weight: 700;
    i{
      padding: 0px;
      margin-top:0px;
      font-size:20px;
      height:auto;
      width:auto;
      margin-right: 5px;
    }
 }
}

@media(max-width:550px) {

  #BlockBYRange.content-modal_calendar {
    flex-direction: column-reverse !important;
    align-items: center !important;
    height: 100% !important;
    justify-content: space-evenly !important;
    width: 100% !important;
    border-right: 1px solid #fff !important;
    padding: 1.5rem 1rem 1rem !important;
    right: 0px !important;
    border-radius: 10px 0 10px 10px !important;
    top: 62px !important;
    border: none !important;
  }
  #BlockBYRange .right-block-side[data-v-5e33df8c] {
    height: calc(100%) !important;
    padding: 1.5rem 1rem 1rem !important;
    top: -25px !important;
    position: relative !important;
    border-radius: 0px 0 10px 10px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-evenly !important;
    text-align: center !important;
    border-right: none !important;
    margin-right: 0 !important;
    width: calc( 100% + 2rem) !important;
  }

  .left-block-side{
    width: 100% !important;
  }
  #calendar{
    width: 100% !important;
  }
  #BlockBYRange.content-modal_calendar{
    width: 100% !important;
    .calendar{
      width: 100% !important;
    }
  }
  #BlockBYRange .close_button{
    top: -62px !important;
  }
}

</style>
