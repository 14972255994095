<template>
  <div class="campaign-header">
    <div class="campaign-header-image">
      <img src="@/assets/icons/marketing/email-background-active.svg" alt="precompro.com" v-if="step === 1"/>
      <img src="@/assets/icons/marketing/email-background-inactive.svg" alt="precompro.com" v-if="step === 2"/>
      <span class="campaign-header-one" :class="{'campaign-header-one-active': step === 1, 'campaign-header-one-inactive': step !== 1}">1</span>
    </div>
    <span class="campaign-header-text" :class="{'campaign-header-text-active': step === 1, 'campaign-header-text-inactive': step !== 1}">Configuración del correo</span>
    <div class="campaign-header-divider"></div>
    <div class="campaign-header-image">
      <img src="@/assets/icons/marketing/email-background-active.svg" alt="precompro.com" v-if="step === 2"/>
      <img src="@/assets/icons/marketing/email-background-inactive.svg" alt="precompro.com" v-if="step === 1"/>
      <span class="campaign-header-one" :class="{'campaign-header-one-active': step === 2, 'campaign-header-one-inactive': step !== 2}">2</span>
    </div>
    <span class="campaign-header-text" :class="{'campaign-header-text-active': step === 2, 'campaign-header-text-inactive': step !== 2}">Diseño del correo</span>
  </div>
</template>
<script>
export default {
  name: 'EmailStepper',
  props: {
    step: {
      type: Number
    }
  }
};
</script>
<style lang="scss">
.campaign-header {
  display: flex;
  justify-content: center;
  align-items: center;
  &-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    position: relative;
    margin-right: 7px;
  }
  &-divider {
    width: 100px;
    height: 2px;
    flex-shrink: 0;
    border-radius: 1px;
    background: #444B57;
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (max-width: 500px) {
      width: 50px;
    }
  }
  &-one {
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    user-select: none;
    &-active {
      color: #FFF;
      font-weight: 600;
    }
    &-inactive {
      color: #C9C9C9;
      font-weight: 400;
    }
  }
  &-text {
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    user-select: none;
    &-active {
      color: #444B57;
      font-weight: 600;
    }
    &-inactive {
      color: #C9C9C9;
      font-weight: 400;
    }
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
}
</style>
