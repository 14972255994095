<template>
  <drop
    :class="{'border-rounded': mesa.isRounded, 'isDobleAlert': isAlertable(mesa) }"
    :style="{
    width: (mesa.width) ? mesa.width  + 'px' : '100px',
    height: (mesa.height) ? mesa.height + 'px' : '100px',
    transform: 'matrix(1, 0, 0, 1,' + mesa.x + ',' + mesa.y + ')' }"
    @dragleave="over = false"
    @dragover="HoverTable(mesa)"
    @drop="handleDrop"
    @mouseleave="showFinish = []"
    class="item-map drop"
    v-if="isEvent(mesa)"
  >
    <img
      @click="toggleAlert(mesa.id)"
      class="icon-mks table-alert"
      src="../../../../assets/icons/alert.svg"
    />
    <div
      :class="getClassForState(mesa, infoReservationTable, over)"
      :id="'cssTableId' + mesa.id"
      :style="[(mesa.angle !== null) ? { transform: 'rotate('+ mesa.angle +'deg)'} : '']"
      @click.prevent.stop="isSelected(mesa)"
      @mouseover="((isReservaSeated(mesa, infoReservationTable) || isWalkinSeated(mesa, infoReservationTable) || isPrecompraSeated(mesa, infoReservationTable)) && Object.keys(infoReservationTable).length > 0) ? showIsFinish(infoReservationTable) : showFinish = []"
      class="intern-table"
    >
      <div class="midle-full"></div>
      <div
        :class="{'extra-table-rounded': mesa.isRounded}"
        :style="{
          top: mesa.isDouble == 'vertical' ? 'calc(' + mesa.height + 'px - 26px)' : 0,
          right: mesa.isDouble == 'horizontal' ? 'calc(' + mesa.width + 'px - 26px)' : 0,
          bottom: mesa.isDouble == 'vertical' ? 'auto' : 0,
          left: mesa.isDouble == 'horizontal' ? 'auto' : 0,
          width: 'calc(' + mesa.width + 'px - 26px)',
          height: 'calc(' + mesa.height + 'px - 26px)'
        }"
        v-show="mesa.isDouble && !mesa.extratables"
      ></div>
      <div
        :class="{
          'color-background-precompra': isPrecompraSeated(mesa, infoReservationTable),
          'color-background-precompra-select': (getSelected() == mesa.id && isPrecompraSelected(mesa, infoReservationTable)),
          'color-background-reserva': isReservaSeated(mesa, infoReservationTable),
          'color-background-reserva-select': (getSelected() == mesa.id && isReservaSelected(mesa, infoReservationTable)),
          'color-background-walkin': isWalkinSeated(mesa, infoReservationTable),
          'color-background-walkin-select': (getSelected() == mesa.id && isWalkinSelected(mesa, infoReservationTable)),
          'color-background-next': (getSelected() == mesa.id) ? isNext(mesa, infoReservationTable) : false,
          tableBlocked: getTableIsBlockedValidate(mesa.blocked),
          tableBorderBlocked: getTableIsBlockedValidate(mesa.blocked),
          tableBlockedSelect: (getSelected() ==  mesa.id && getTableIsBlockedValidate(mesa.blocked)),
          'border-rounded': mesa.isRounded,
          'color-background-semi': isReservaMiddleSeated(mesa, infoReservationTable),
          'color-background-semi-pre': isPrecompraMiddleSeated(mesa, infoReservationTable),
          isCurrentReserva: isSoonReserva(mesa, infoReservationTable),
          isCurrentPrecompra: isSoonPrecompra(mesa, infoReservationTable),
          isCurrentPrecompraPending: isSoonPrecompraPending(mesa, infoReservationTable)
        }"
        :key="'table-item-' + i"
        :style="{
          top: extra.y + 'px',
          left: extra.x + 13 + 'px',
          width: 'calc(' + mesa.width + 'px - 26px)',
          height: 'calc(' + mesa.height + 'px - 26px)',
          position: 'absolute',
          transform: 'translate3d(0, 0, 0)',
          'will-change': 'transform'
        }"
        v-for="(extra, i) in mesa.extratables"
        v-show="mesa.id == extra.seatingTableId"
      ></div>
      <div :style="[(mesa.angle !== null) ? { transform: 'rotate(-'+ mesa.angle +'deg)'} : '']" class="intern-t">
        <div
          :class="{ tableTextBlocked: getTableIsBlockedValidate(mesa.blocked)}"
          class="table-name"
        >
          {{ mesa.displayName }}
          <span
            class="privada"
            title="Privada"
            v-show="mesa.preventReservations == 1"
          >
            <i class="icon-mks white icon_key"></i>
          </span>
          <span
            @mouseleave="delShowAlert(mesa.id)"
            @mouseover="addShowAlert(mesa.id, infoReservationTable)"
            class="alert-yellow"
            tittle="Usuario con otras Reservas"
            v-if="infoReservationTable.hasDuplicatedReservations && !is_touch_device()"
          >
            <img class="icon-mks icon-alert-yellow" src="../../../../assets/icons/alert_yellow.svg" />
          </span>
          <span
            @click.prevent.stop="addShowAlertMobile(mesa.id, infoReservationTable)"
            class="alert-yellow"
            tittle="Usuario con otras Reservas"
            v-if="infoReservationTable.hasDuplicatedReservations && is_touch_device()"
          >
            <img class="icon-mks icon-alert-yellow" src="../../../../assets/icons/alert_yellow.svg" />
          </span>
        </div>
        <div class="aling">
          <span v-show="Object.keys(infoReservationTable).length == 0">-</span>
          <span
            :class="{ tableTextBlocked: getTableIsBlockedValidate(mesa.blocked) }"
            v-show="Object.keys(infoReservationTable).length > 0"
          >{{infoReservationTable.people}}</span>
          <span
            :class="{ tableTextBlocked: getTableIsBlockedValidate(mesa.blocked) }"
          > / {{mesa.maxSize}}</span>
        </div>
        <div class="timer-content">
          <div
            class="timer"
            v-show="infoReservationTable.date && (infoReservationTable.codeStatus == 0 || infoReservationTable.codeStatus == 1 || infoReservationTable.codeStatus == 7)"
          >
            <!--  <i class="icon_dashboard_clock icon-mks black"></i> -->
            {{dateReservation(infoReservationTable.date)}}
          </div>
        </div>
      </div>
    </div>
  </drop>
</template>

<script>
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';

const THREE_HOURS = 1000 * 60 * 60 * 3;
// poner la mesa en amarillo hasta faltando media hora de la siguiente
const YELLOW_HOURS = 1000 * 60 * 60 * 0.5;

export default {
  name: 'TableVue',
  props: ['mesa'],
  data () {
    return {
      infoReservationTable: [],
      now: null,
      clock: null,
      reservas: [],
      reservationsTable: [],
      alerts: [],
      mselected: null,
      info: '',
      over: false,
      logos: [],
      alertShow: {
        show: false,
        vendors: []
      }
    };
  },
  mounted () {
    this.now = this.$moment().valueOf();
    this.clock = this.$moment().valueOf();
    this.getNextReservation();
    setInterval(() => {
      this.now = this.$moment().valueOf();
    }, 1000);
    this.interval = setInterval(() => {
      this.clock = this.$moment().valueOf();
    }, 1000);
  },
  destroyed () {
    clearInterval(this.interval);
  },
  methods: {
    getClassForState (mesa, infoReservationTable, over) {
      // obtiene los div para procesar las clases que se le agregaran o quitaran
      const table = document.getElementById('cssTableId' + mesa.id);
      let cssTable = [];
      if (table != null) {
        cssTable = table.classList;
      }
      let classSelected = '';
      if (mesa.isRounded) {
        classSelected += ' border-rounded';
      }
      if (this.isPrecompraSeated(mesa, infoReservationTable)) {
        classSelected += ' color-background-precompra';
      }
      if (this.getSelected() === mesa.id && this.isPrecompraSelected(mesa, infoReservationTable)) {
        classSelected += ' color-background-precompra-select';
      }
      if (this.isReservaSeated(mesa, infoReservationTable)) {
        classSelected += ' color-background-reserva';
      }
      if (this.getSelected() === mesa.id && this.isReservaSelected(mesa, infoReservationTable)) {
        classSelected += ' color-background-reserva-select';
      }
      if (this.isWalkinSeated(mesa, infoReservationTable)) {
        classSelected += ' color-background-walkin';
      }
      if (this.getSelected() === mesa.id && this.isWalkinSelected(mesa, infoReservationTable)) {
        classSelected += ' color-background-walkin-select';
      }
      if (this.getSelected() === mesa.id && this.isNext(mesa, infoReservationTable)) {
        classSelected += ' color-background-next';
      }
      if (this.getSelected() === mesa.id && this.getTableIsBlockedValidate(mesa.blocked)) {
        classSelected += ' tableBlockedSelect';
      }
      if (this.isReservaMiddleSeated(mesa, infoReservationTable)) {
        classSelected += ' color-background-semi';
      }
      if (this.isPrecompraMiddleSeated(mesa, infoReservationTable)) {
        classSelected += ' color-background-semi-pre';
      }
      if (this.getTableIsBlockedValidate(mesa.blocked)) {
        classSelected += ' hourBlock';
      }
      if (this.isSoonReserva(mesa, infoReservationTable)) {
        classSelected += ' isCurrentReserva';
        // buscamos si hay una reserva previa a la actual
        const prev = this.getPrevReservationId(infoReservationTable);
        if (prev !== false) {
          if (infoReservationTable.id !== prev.id) {
            // si la reserva previa no se sento ni finalizo ni se hizo nada con ella aparte de confirmarla
            if (prev.codeStatus === 0 || prev.codeStatus === 1) {
              // se obtiene la fecha y hora ctual
              const actualHour = this.$moment().format('x');
              // y a la hora de reserva actual se le quita la YELLOW_HOURS y si la hora actual no pasa ese tiempo se mantiene
              // la clase de alerta amarilla y se quita la de siguiente reserva
              if (parseInt(actualHour) < (parseInt(infoReservationTable.date) - YELLOW_HOURS)) {
                if (cssTable.length > 0) {
                  cssTable.add('isReservaRetrasada');
                  cssTable.remove('isCurrentReserva');
                }
              }
            }
          }
        }
      }
      if (this.isSoonPrecompra(mesa, infoReservationTable)) {
        // lo mismo de isSoonReserva
        classSelected += ' isCurrentPrecompra';
        const prev = this.getPrevReservationId(infoReservationTable);
        if (prev !== false) {
          if (infoReservationTable.id !== prev.id) {
            if (prev.codeStatus === 0 || prev.codeStatus === 1) {
              const actualHour = this.$moment().format('x');
              if (parseInt(actualHour) < (parseInt(infoReservationTable.date) - YELLOW_HOURS)) {
                if (cssTable.length > 0) {
                  cssTable.add('isReservaRetrasada');
                  cssTable.remove('isCurrentPrecompra');
                  cssTable.remove('isCurrentPrecompraPending');
                }
              }
            }
          }
        }
      }
      if (this.isSoonPrecompraPending(mesa, infoReservationTable)) {
        // lo mismo de isSoonReserva
        classSelected += ' isCurrentPrecompraPending';
        const prev = this.getPrevReservationId(infoReservationTable);
        if (prev !== false) {
          if (infoReservationTable.id !== prev.id) {
            if (prev.codeStatus === 0 || prev.codeStatus === 1 || prev.codeStatus === 7) {
              const actualHour = this.$moment().format('x');
              if (parseInt(actualHour) < (parseInt(infoReservationTable.date) - YELLOW_HOURS)) {
                if (cssTable.length > 0) {
                  cssTable.add('isReservaRetrasada');
                  cssTable.remove('isCurrentPrecompra');
                  cssTable.remove('isCurrentPrecompraPending');
                }
              }
            }
          }
        }
      }
      if (this.alertTable(mesa, infoReservationTable)) {
        // se le quita las clases si las tiene de current precompra y reserva
        if (cssTable.length > 0) {
          cssTable.remove('isCurrentPrecompraPending');
          cssTable.remove('isCurrentPrecompra');
          cssTable.remove('isCurrentReserva');
        }

        if (this.now > parseInt(infoReservationTable.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1)) {
          classSelected += ' isReservaRetrasada';
        } else if (this.now > parseInt(infoReservationTable.date)) {
          classSelected += ' IsAlertTablenoSeated';
        }
      }
      if (over) {
        classSelected += ' over';
      }
      return classSelected;
    },
    is_touch_device () {
      return !!('ontouchstart' in window || navigator.maxTouchPoints);
    },
    isResponsive () {
      if (window.innerWidth <= 1000) { return true; } else { return false; }
    },
    handleDrop (data) {
      var send = {
        name: data.reservation.name,
        reservation: data.reservation.id,
        mesa: [this.mselected]
      };
      this.toggleMenuDrop(send);
    },
    HoverTable (data) {
      this.mselected = data.id;
      this.over = true;
    },
    toggleMenuDrop (send) {
      var els = document.getElementsByClassName('show-menu-messager');
      this.$emit('infoDrop', send);
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(document.getElementById('MesaMovida#' + send.mesa), 'show-menu-messager')
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(document.getElementById('MesaMovida#' + send.mesa), 'show-menu-messager')
        // eslint-disable-next-line
        classie.add(document.body, 'show-mobile-map')
      }
    },
    alertTable (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus !== 2 && info.codeStatus !== 6 && !this.getTableIsBlockedValidate(mesa.blocked) && info.referrer !== 'Walk-In') {
          if (this.now > parseInt(info.date)) {
            status = true;
          }
        }
      }
      return status;
    },
    toggleMenuM (mesa) {
      function mover () {
        if (window.innerWidth > 1000) {
          window.setTimeout(() => {
            var element = document.querySelector('.Map-canvas');
            if (mesa.x > (element.offsetWidth - 270)) {
              element.scrollLeft += 270;
            }
            window.setTimeout(() => {
              if (mesa.y > (element.offsetHeight - 200)) {
                element.scrollTop = (element.offsetHeight + 100) + parseFloat(getComputedStyle(document.querySelector('.navbar'), null).getPropertyValue('height').replace('px', ''));
              }
            }, 50);
          }, 100);
        }
      }
      var els = document.getElementsByClassName('show-menu-messager');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(document.getElementById('Mesa#' + mesa.id), 'show-menu-messager')
            mover();
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(document.getElementById('Mesa#' + mesa.id), 'show-menu-messager')
        // eslint-disable-next-line
        classie.add(document.body, 'show-mobile-map')
        mover();
      }
      this.closeAlertYellow();
    },
    toggleAlert (mesa) {
      var els = document.getElementsByClassName('show-menu-alert');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-alert');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(document.getElementById('MesaAlert#' + mesa), 'show-menu-alert')
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(document.getElementById('MesaAlert#' + mesa), 'show-menu-alert')
        // eslint-disable-next-line
        classie.add(document.body, 'show-mobile-map')
      }
      this.$emit('alertable', this.alerts);
    },
    dateReservation (date) {
      return this.$moment(new Date(parseInt(date))).format('hh:mm');
    },
    isEvent (table) {
      if (table.isEvent) {
        const h = new Date(this.selectedDate + ' 00:00:00');
        const c = this.$moment(h).valueOf();
        if (this.allEvents !== undefined) {
          if (this.allEvents[c] !== undefined) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    showIsFinish (data) {
      this.showFinish = data;
    },
    showReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      let type = '';
      if (infoReserva.referrer !== 'Walk-In') {
        type = (infoReserva.balancePaid === null || parseInt(infoReserva.balancePaid) === 0) ? 'Reserva' : 'Precompra';
      } else {
        type = 'Walk-In';
      }
      infoReserva.edit = true;
      this.$store.commit(reservationTypes.mutations.setType, type);
      this.$store.commit(reservationTypes.mutations.setInfoReservation, infoReserva);
      this.$store.commit(reservationTypes.mutations.setViewReservation, true);
    },
    isObject (data) {
      const isArray = Array.isArray(data);
      return isArray;
    },
    statusDisplay (data) {
      if (data.codeStatus === 2 && data.referrer !== 'Walk-In') {
        return this.$moment(new Date(this.clock - parseInt(data.isSeated))).add(5, 'hours').format('HH:mm:ss');
      }
      if (data.codeStatus === 2 && data.referrer === 'Walk-In') {
        const h2 = parseInt(data.isSeatedWalkIn);
        return this.$moment(new Date(this.clock - h2)).add(5, 'hours').format('HH:mm:ss');
      }
    },
    isSoonReserva (mesa, info) {
      let status = false;
      if (!this.isObject(this.info)) {
        if (info.balancePaid === 0 || info.balancePaid === null) {
          if (parseInt(info.date) > this.now - THREE_HOURS && parseInt(info.date) < this.now + THREE_HOURS) {
            status = true;
          }
        } else {
          status = false;
        }
      }
      return status;
    },
    isSoonPrecompra (mesa, info) {
      let status = false;
      if (!this.isObject(this.info)) {
        if (info.balancePaid > 0 && info.codeStatus !== 7) {
          if (parseInt(info.date) > this.now - THREE_HOURS && parseInt(info.date) < this.now + THREE_HOURS) {
            status = true;
          }
        } else {
          status = false;
        }
      }
      return status;
    },
    isSoonPrecompraPending (mesa, info) {
      let status = false;
      if (!this.isObject(this.info)) {
        if (info.balancePaid > 0 && info.codeStatus === 7) {
          if (parseInt(info.date) > this.now - THREE_HOURS && parseInt(info.date) < this.now + THREE_HOURS) {
            status = true;
          }
        } else {
          status = false;
        }
      }
      return status;
    },
    isPrecompraSeated (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus === 2 && info.referrer !== 'Walk-In' && parseInt(info.balancePaid) > 0) {
          status = true;
        }
      }
      return status;
    },
    isPrecompraSelected (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        const tabl = info.tableId.split(', ');
        const tables = tabl.map(Number);
        if (tables.includes(mesa.id) && info.referrer !== 'Walk-In' && parseInt(info.balancePaid) > 0 && info.codeStatus !== 7) {
          status = true;
        }
      }
      return status;
    },
    isReservaSeated (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus === 2 && info.referrer !== 'Walk-In' && (info.balancePaid === null || parseInt(info.balancePaid) === 0)) {
          status = true;
        }
      }
      return status;
    },
    isReservaMiddleSeated (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus === 6 && info.referrer !== 'Walk-In' && (info.balancePaid === null || parseInt(info.balancePaid) === 0)) {
          status = true;
        }
      }
      return status;
    },
    isPrecompraMiddleSeated (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus === 6 && info.referrer !== 'Walk-In' && parseInt(info.balancePaid) > 0 && info.codeStatus !== 7) {
          status = true;
        }
      }
      return status;
    },
    isReservaSelected (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        const tabl = info.tableId.split(', ');
        const tables = tabl.map(Number);
        if (tables.includes(mesa.id) && info.referrer !== 'Walk-In' && (info.balancePaid === null || parseInt(info.balancePaid) === 0)) {
          status = true;
        }
      }
      return status;
    },
    isWalkinSeated (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        if (info.codeStatus === 2 && info.referrer === 'Walk-In') {
          status = true;
        }
      }
      return status;
    },
    isWalkinSelected (mesa, info) {
      let status = false;
      if (!this.isObject(info)) {
        const tabl = info.tableId.split(', ');
        const tables = tabl.map(Number);
        if (tables.includes(mesa.id) && info.referrer === 'Walk-In') {
          status = true;
        }
      }
      return status;
    },
    isNext (mesa, info) {
      let status = false;
      if (info.length === 0) {
        status = true;
      } else {
        if ((info.codeStatus === 3 || info.codeStatus === 4)) {
          status = true;
        }
      }
      return status;
    },
    isSelected (mesa) {
      this.toggleMenuM(mesa);
      this.$store.commit(reservationTypes.mutations.setSelectTableId, mesa.id);
      this.$store.commit(reservationTypes.mutations.setSelectTableInfo, mesa);
      this.closeAlert();
    },
    closeAlert () {
      var els = document.getElementsByClassName('show-menu-alert');
      if (els.length > 0) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-alert');
        }
      }
    },
    closeAlertYellow () {
      var alertYellow = document.getElementsByClassName('show-alert-yellow');
      if (alertYellow.length > 0) {
        for (var i = 0; i < alertYellow.length; i++) {
          alertYellow[i].classList.remove('show-alert-yellow');
        }
      }
    },
    getSelected () {
      return this.tableId;
    },
    getTableIsBlockedValidate (blocked) {
      if (blocked !== undefined) {
        let arrayHourBlock = [];
        arrayHourBlock = _.filter(blocked, { fecha: this.selectedDate });
        if (arrayHourBlock.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // se encarga de buscar la reserva anterior a la que se envia
    getPrevReservationId (reservationInfo) {
      const info = _(this.reservations[this.selectedDate])
        .sortBy(reservation => (parseInt(reservation.date)))
        .filter(reservation => {
          return reservation && (!_.size(this.selectedDates) || _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')));
        }).value();
      const tableId = this.mesa.id;
      const reserP = [];
      if (info !== undefined) {
        Object.keys(info).forEach(function (key) {
          if (info[key].tableId != null) {
            const tabl = info[key].tableId.split(', ');
            const tables = tabl.map(Number);
            if (tables.includes(tableId)) {
              if (info[key].codeStatus === 2 || info[key].codeStatus === 6) {
                reserP.push(info[key]);
              } else if ((info[key].codeStatus === 0 || info[key].codeStatus === 1 || info[key].codeStatus === 7)) {
                reserP.push(info[key]);
              }
            }
          }
        });
      }
      const myOrderedArray = _.sortBy(reserP, o => new Date(parseInt(o.date)));
      // buscamos la posicion de la reserva actual
      const position = _.findIndex(myOrderedArray, { id: reservationInfo.id });
      if (myOrderedArray.length > 0) {
        // si la posicion es mayor a cero toma una posicion anteior si no toma la posicion 0
        if (position > 0) {
          return myOrderedArray[position - 1];
        } else if (position === 0) {
          return myOrderedArray[position];
        }
      }
      return false;
    },
    // se encarga de buscar la reserva anterior
    getPrevReservation () {
      const info = _(this.reservations[this.selectedDate])
        .sortBy(reservation => (parseInt(reservation.date)))
        .filter(reservation => {
          return reservation && (!_.size(this.selectedDates) || _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')));
        }).value();
      const tableId = this.mesa.id;
      const reserP = [];
      if (info !== undefined) {
        Object.keys(info).forEach(function (key) {
          if (info[key].tableId != null) {
            const tabl = info[key].tableId.split(', ');
            const tables = tabl.map(Number);
            if (tables.includes(tableId)) {
              if (info[key].codeStatus === 2 || info[key].codeStatus === 6) {
                reserP.push(info[key]);
              } else if ((info[key].codeStatus === 0 || info[key].codeStatus === 1 || info[key].codeStatus === 7)) {
                reserP.push(info[key]);
              }
            }
          }
        });
      }
      let datos = [];
      const myOrderedArray = _.sortBy(reserP, o => new Date(parseInt(o.date)));
      _.forEach(myOrderedArray, function (data) {
        if (Object.keys(datos).length === 0 && (data.codeStatus === 2 || data.codeStatus === 6)) {
          datos = data;
        } else if (Object.keys(datos).length === 0 && data.codeStatus !== 2 && data.codeStatus !== 6) {
          datos = data;
        } else if (Object.keys(datos).length > 0 && parseInt(datos.date) <= parseInt(data.date) && (data.codeStatus === 2 || data.codeStatus === 6)) {
          datos = data;
        }
      });
      return datos;
    },
    getNextReservation () {
      const that = this;
      const info = _(this.reservations[this.selectedDate])
        .sortBy(reservation => (parseInt(reservation.date)))
        .filter(reservation => {
          return reservation && (!_.size(this.selectedDates) || _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')));
        }).value();
      const table = this.mesa.id;
      const reserP = [];
      const rotationTime = this.getVendor.params.rotationTime / 3600000;
      if (info !== undefined) {
        Object.keys(info).forEach(function (key) {
          if (info[key].tableId != null) {
            const tabl = info[key].tableId.split(', ');
            const tables = tabl.map(Number);
            if (tables.includes(table)) {
              if (info[key].codeStatus === 2 || info[key].codeStatus === 6) {
                reserP.push(info[key]);
              } else if ((info[key].codeStatus === 0 || info[key].codeStatus === 1 || info[key].codeStatus === 7)) {
                reserP.push(info[key]);
              }
            }
          }
        });
      }
      let datos = [];
      const myOrderedArray = _.sortBy(reserP, o => new Date(parseInt(o.date)));
      _.forEach(myOrderedArray, function (data) {
        if (Object.keys(datos).length === 0 && (data.codeStatus === 2 || data.codeStatus === 6)) {
          datos = data;
        } else if (Object.keys(datos).length === 0 && data.codeStatus !== 2 && data.codeStatus !== 6) {
          const actualHour = that.$moment().format('x');
          const hours = new Date(data.fechaCompleta).getHours() + rotationTime;
          let reservetionTime = new Date(data.fechaCompleta).setHours(hours);
          reservetionTime = that.$moment(parseInt(reservetionTime)).format('x');
          if (actualHour < reservetionTime) {
            datos = data;
          }
        } else if (Object.keys(datos).length > 0 && parseInt(datos.date) <= parseInt(data.date) && (data.codeStatus === 2 || data.codeStatus === 6)) {
          datos = data;
        }
      });
      // si no hay reserva proxima busqueme una anterior
      if (datos.length === 0) {
        datos = this.getPrevReservation();
      }
      this.infoReservationTable = datos;
      if (this.infoReservationTable.hasDuplicatedReservations) {
        this.logos[this.mesa.id] = this.infoReservationTable.relatedReservations;
        this.$emit('logos', this.logos);
      }
    },
    isConfigRotation (reservation) {
      let response = null;
      const dayOfWeek = this.$moment(parseInt(reservation.date)).format('d');
      if (Object.values(this.rotation).length > 0) {
        this.rotation.map(item => {
          if (item.dayOfWeek == null && item.people != null) {
            if (reservation.people === item.people) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people != null) {
            if (reservation.people === item.people && item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people == null) {
            if (item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          }
        });
      }
      return response;
    },
    isReservationPrev (data, reservation) {
      const items = JSON.parse(JSON.stringify(data));
      const reservations = _.sortBy(items, 'date');
      const filter = reservations.map(item => {
        if (item.codeStatus !== 3 && item.codeStatus !== 4 && item.codeStatus !== 5 && item.codeStatus !== 7) {
          return item;
        }
      });
      const index = _.findIndex(filter, { id: reservation.id });
      let response = null;
      if (index >= 0) {
        if (filter.length > 1) {
          if (filter[index - 1]) {
            if (filter[index - 1].date < reservation.date) {
              response = filter[index - 1];
            } else if (filter[index - 1].date === reservation.date) {
              response = filter[index - 1];
            }
          }
        }
      }
      return response;
    },
    isReservationNext (data, reservation) {
      const items = JSON.parse(JSON.stringify(data));
      const reservations = _.sortBy(items, 'date');
      const filter = reservations.map(item => {
        if (item.codeStatus !== 3 && item.codeStatus !== 4 && item.codeStatus !== 5 && item.codeStatus !== 7) {
          return item;
        }
      });
      const index = _.findIndex(filter, { id: reservation.id });
      let response = null;
      if (index >= 0) {
        if (filter.length > 1) {
          if (filter[index + 1]) {
            if (filter[index + 1].date > reservation.date) {
              response = filter[index + 1];
            } else if (filter[index + 1].date === reservation.date) {
              response = filter[index + 1];
            }
          }
        }
      }
      return response;
    },
    isAlertable (mesa) {
      const instance = this;
      let inforAlert = [];
      let isAlert = false;
      if (mesa.isShared || mesa.isEvent) {
        return isAlert;
      }
      const items = this.dateFilterSelect || [];
      const itemData = this.reservations[this.selectedDate] || [];
      const reser = itemData.filter(reservation => _.includes(items, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')) || !items.length);
      const rotationOriginal = (this.getVendor.params.rotationTime != null) ? parseInt(this.getVendor.params.rotationTime) : 0;
      const ti = mesa.id;
      const data = [];
      if (reser !== undefined) {
        Object.keys(reser).forEach(function (key) {
          if (reser[key].tableId != null) {
            const tabl = reser[key].tableId.split(', ');
            const tables = tabl.map(Number);
            if (tables.includes(ti)) {
              data.push(reser[key]);
            }
          }
        });
      }
      const total = data.length;
      let rotationTime = rotationOriginal;
      for (var w = 0; w < total; w++) {
        rotationTime = rotationOriginal;
        if (data[w].codeStatus !== 3 && data[w].codeStatus !== 4 && data[w].codeStatus !== 5 && data[w].codeStatus !== 7) {
          const prev = instance.isReservationPrev(data, data[w]);
          const next = instance.isReservationNext(data, data[w]);
          if (prev) {
            const rotationSpec = instance.isConfigRotation(prev);
            if (rotationSpec) {
              rotationTime = parseInt(rotationSpec);
              const diff = parseInt(data[w].date) - parseInt(prev.date);
              if (diff < rotationTime) {
                inforAlert.push(data[w]);
                isAlert = true;
              }
            } else {
              const reRotationSpec = instance.isConfigRotation(data[w]);
              if (reRotationSpec) {
                rotationTime = parseInt(reRotationSpec);
                const diff = parseInt(data[w].date) - parseInt(prev.date);
                if (diff < rotationTime) {
                  inforAlert.push(data[w]);
                  isAlert = true;
                }
              } else {
                const diff = parseInt(data[w].date) - parseInt(prev.date);
                if (diff < rotationTime) {
                  inforAlert.push(data[w]);
                  isAlert = true;
                }
              }
            }
          }
          rotationTime = rotationOriginal;
          if (next) {
            const rotationSpec = instance.isConfigRotation(next);
            if (rotationSpec) {
              rotationTime = parseInt(rotationSpec);
              const diff = parseInt(next.date) - parseInt(data[w].date);
              if (diff < rotationTime) {
                inforAlert.push(data[w]);
                isAlert = true;
              }
            } else {
              const reRotationSpec = instance.isConfigRotation(data[w]);
              if (reRotationSpec) {
                rotationTime = parseInt(reRotationSpec);
                const diff = parseInt(next.date) - parseInt(data[w].date);
                if (diff < rotationTime) {
                  inforAlert.push(data[w]);
                  isAlert = true;
                }
              } else {
                const diff = parseInt(next.date) - parseInt(data[w].date);
                if (diff < rotationTime) {
                  inforAlert.push(data[w]);
                  isAlert = true;
                }
              }
            }
          }
        }
      }
      inforAlert = _.uniqBy(inforAlert, 'id');
      inforAlert = _.sortBy(inforAlert, 'date');
      this.alerts = inforAlert;
      return isAlert;
    },
    addShowAlert (tableId, data) {
      if (data.hasDuplicatedReservations) {
        const $elementAlert = document.getElementById('alertYellow-' + tableId);
        $elementAlert.style.display = null;
        Object.keys(data.relatedReservations).map((key) => {
          return data.relatedReservations[key];
        });
      }
    },
    addShowAlertMobile (tableId, data) {
      const $alertYellow = document.getElementById('alertYellow-' + tableId);
      if ($alertYellow.classList.contains('is-touch') && !this.isResponsive()) {
        if ($alertYellow.style.display) {
          $alertYellow.style.display = null;
        } else {
          $alertYellow.style.display = 'none';
        }
      }

      if (!this.alertShow.show) {
        this.alertShow.show = true;
        if (data.hasDuplicatedReservations) {
          this.alertShow.vendors = Object.keys(data.relatedReservations).map((key) => {
            return data.relatedReservations[key];
          });
        }
      } else {
        this.alertShow.show = false;
        this.alertShow.vendors = [];
      }
      this.$emit('dataAlertYellow', this.alertShow);
    },
    delShowAlert (tableId) {
      const $elementAlert = document.getElementById('alertYellow-' + tableId);
      $elementAlert.style.display = 'none';
    }
  },
  watch: {
    mesa () {
      if (this.tableId === this.mesa.id) {
        this.$store.commit(reservationTypes.mutations.setSelectTableInfo, this.mesa);
      }
      this.getNextReservation();
    },
    selectedDate () {
      this.infoReservationTable = [];
      this.getNextReservation();
    },
    selectedDates () {
      this.infoReservationTable = [];
      this.getNextReservation();
    },
    reservations () {
      this.infoReservationTable = [];
      this.getNextReservation();
    }
  }
};
</script>

<style lang="scss">
.item-map {
  position: absolute;
  font-size: 13px !important;
  padding: 13px;
  z-index: 9;
  .over {
    border: 2px solid #444b57 !important;
  }
}
.timer-content {
  width: calc(100% - 26px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  bottom: -15px;
  left: 10px + 3px;
  .timer {
    border: 1px solid #000;
    background: #fff;
    font-size: 9px;
    border-radius: 3px;
    padding: 1px 5px;
    font-weight: 900;
  }
}
.privada {
  position: absolute;

  background: #444b55;
  left: -12px;
  top: -12px;
  padding: 2px 3px 3px 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border: 1px solid #444b55;
  line-height: 0;
  .icon_key {
    width: 77%;
    margin: 0;
    margin-top: -1px;
  }
}
.alert-yellow {
  position: absolute;
  left: -12px;
  top: -12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  line-height: 0;
  .icon-alert-yellow {
    margin: 0;
  }
}
.table-alert {
  position: absolute;
  top: 8px;
  left: 7px;
  z-index: 9;
  display: none;
  cursor: pointer;
}
.isDobleAlert .table-alert {
  display: flex;
}
.border-rounded .table-alert {
  position: absolute;
  top: 14px;
  left: 13px;
}

.border-rounded {
  border-radius: 50%;
  .privada {
    left: -5px;
    top: -5px;
  }
}
.aling {
  text-align: center;
}
.intern-t {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.table-name {
  text-align: center;
  border-bottom: 1px #000 solid;
  margin-bottom: 5px;
  padding-bottom: 0px;
  padding: 0 3px;
}
.intern-table {
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebeced;
  cursor: pointer;
  position: relative;
}
.interior {
  height: 20px;
  width: 20px;
  background: #ccc;
  color: #000;
  border: 1px solid #000;
}

.midle-full {
  display: none;
}
.color-background-semi {
  border: 3px solid #00adc6;
}
.color-background-semi-pre {
  border: 3px solid #cad300;
}
.color-background-semi .midle-full {
  display: block;
  background: #00adc6;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  z-index: 0;
  background: linear-gradient(
    -45deg,
    #ebeced,
    #ebeced 49%,
    #ebeced 49%,
    #ebeced 51%,
    #00adc6 51%
  );
}
.color-background-semi-pre .midle-full {
  display: block;
  background: #cad300;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  z-index: 0;
  background: linear-gradient(
    -45deg,
    #ebeced,
    #ebeced 49%,
    #ebeced 49%,
    #ebeced 51%,
    #cad300 51%
  );
}

.color-background-semi.border-rounded .midle-full,
.color-background-semi-pre.border-rounded .midle-full {
  border-radius: 50%;
}
.hourBlock {
  background: #fdeaee;
  border: 1px solid #f43b5f;
}
.isCurrentReserva {
  border: 3px solid #00adc6;
}
.isCurrentReserva.hourBlock {
  border: 1px solid #f43b5f;
}
.isCurrentPrecompra {
  border: 3px solid #cad300;
}
.isCurrentPrecompraPending {
  border: 3px solid #D9D9D9;
}
.table-wrapper.blocked {
  .table-item {
    border-color: #f43b5f !important;
    background: #fdeaee !important;
  }
}
.title-text.blocked {
  border-color: #f43b5f !important;
  background: #fdeaee !important;
  color: #f43b5f;
}
.title-text.blocked.selected {
  border-color: #f43b5f !important;
  background: #f43b5f !important;
}
</style>
