<template>
  <div class="component-box">
    <div class="header-component">
      <i class="icon-mks black icon_zone_add_reservation i-zone-component"></i>Zona
    </div>
    <div class="zone-component" v-if="sectionSeating.length > 0 && !notHour && !isLoading" :class="{one:sectionSeating.length === 1,two:sectionSeating.length === 2,three:sectionSeating.length === 3,four:sectionSeating.length === 4,five:sectionSeating.length === 5,six:sectionSeating.length >= 6 }">
      <div
        :class="{ selectedZone: isSelectZone(section.value) }"
        :key="'zone-item' + d"
        @click="setZone(section)"
        class="zone-item"
        v-for="(section, d) in sectionSeating"
      >
      <p>{{ section.label }}</p>
      </div>
    </div>
    <div class="select-please" v-if="notHour">Selecciona una Hora</div>
    <loader-custom-section v-if="isLoading" />
  </div>
</template>
<script>
import _ from 'lodash';
import LoaderCustomSection from '@/components/_shared/LoaderCustomSection.vue';
export default {
  name: 'FormReservationZone',
  props: ['timeHour', 'people', 'zoneSelected'],
  components: { LoaderCustomSection },
  data () {
    return {
      zoneId: [],
      sectionSeating: [],
      isLoading: false,
      sectionsTemp: [],
      selectZone: [],
      initializedComponent: 0,
      notHour: false
    };
  },
  mounted () {
    this.getSectionSeating();
    this.initValues();
  },
  methods: {
    initValues () {
      if (this.zoneSelected) {
        this.zoneSelected.forEach(id => {
          if (this.zoneId.indexOf(id) >= 0) {
          } else {
            this.zoneId.push(id);
          }
        });
      }
    },
    getSectionSeating () {
      this.isLoading = true;
      this.$emit('loading', true);
      if (this.timeHour) {
        this.$store
          .dispatch({
            type: 'reservation:sectionStatus',
            data: {
              vendorId: this.idVendor,
              date: this.timeHour,
              people: this.people
            }
          })
          .then((data) => {
            const aux = [];
            let auxId = 0;
            let name = '';
            const sections = data.data;
            this.sectionsTemp = data.data;
            if (sections.length === 1) {
              sections.map(function (data) {
                name = data.displayName;
                if (data.availabilityStatus === false) {
                  name += ' (No Disp.) ';
                }
                auxId = data.id;
                aux.push({ value: data.id, label: name, selected: true });
              });
              this.selectIdZone = auxId;
              this.setZone(aux[0]);
            } else {
              sections.map(function (data) {
                name = data.displayName;
                if (data.availabilityStatus === false) {
                  name += ' (No Disp.) ';
                }
                aux.push({ value: data.id, label: name });
              });
            }
            this.sectionSeating = [];
            this.sectionSeating = aux;
            this.isLoading = false;
            this.$emit('loading', false);
          })
          .catch(() => {
            this.sectionSeating = [];
            this.isLoading = false;
            this.$emit('loading', false);
          });
      } else {
        this.isLoading = false;
        this.$emit('loading', false);
        this.notHour = true;
      }
    },

    setZone (zone) {
      const is = this.zoneId.filter((zoneData) => {
        if (zoneData === zone.value) {
          return zoneData;
        }
      });
      if (this.sectionsTemp.length === 1) {
        if (is.length === 0) {
          this.zoneId.push(zone.value);
        }
        return;
      }

      if (is.length === 0) {
        this.zoneId.push(zone.value);
      } else {
        if (_.includes(this.zoneId, zone.value)) {
          const i = this.zoneId.indexOf(zone.value);
          this.zoneId.splice(i, 1);
        }
      }
    },
    isSelectZone (zone) {
      if (this.sectionsTemp.length > 1) {
        const is = this.zoneId.filter((zoneData) => {
          if (zoneData === zone) {
            return zoneData;
          }
        });
        if (is.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    shortText (text) {
      let name = text;
      if (text.length > 7) {
        name = text.substr(0, 7) + '...';
      }
      return name;
    }
  },
  watch: {
    timeHour () {
      if (this.timeHour !== null) {
        this.notHour = false;
        this.getSectionSeating();
        setTimeout(() => {
          // const data = [];
          // this.selectZone.map(function (info) {
          //   data.push(parseInt(info));
          // });
          // this.zoneId = data;
        }, 500);
      } else {
        this.notHour = true;
        this.selectZone = [];
        this.zoneId = [];
      }
    },
    zoneId () {
      this.$emit('seatSelected', this.zoneId);
    },
    zoneSelected () {
      this.initValues();
    }
  }
};
</script>
<style scoped lang="scss">
.zone-component {
  display:grid;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  width: 100%;
  max-width: 100%;
  grid-template-columns: repeat(5, minmax(50px, 1fr));
  margin: unset;
  padding: unset;
  background-color: #F0F1F2;
  gap: 1px;
    &.one{
    grid-template-columns:repeat(1, minmax(50px, 1fr));
  }
  &.two{
    grid-template-columns:repeat(2, minmax(50px, 1fr));
  }
  &.three{
    grid-template-columns:repeat(3, minmax(50px, 1fr));
  }
  &.four{
    grid-template-columns:repeat(4, minmax(50px, 1fr));
  }
  &.five{
    grid-template-columns:repeat(5, minmax(50px, 1fr));
  }
 @media screen and (min-width:600px) {
  &.six{
    grid-template-columns:repeat(6, minmax(50px, 1fr));
  }
 }
  .zone-item {
    box-sizing: content-box;
    margin: unset;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    width: 100%;
    max-width: 100%;
    flex-direction:row;
    font-size: 13px;
    color:#444B57;
    font-family: 'Roboto';
    // border: 1px solid #dcdede;
    border-top: unset;
    background-color: white;
    overflow: hidden;
    // border-top: 1px solid #dcdede;
    p{
      font-size: 13px;
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
          text-overflow: ellipsis;
    }
    cursor:pointer;
    i {
      margin-right:5px;
    }
    // &:last-child {
    //   flex-direction: row;
    //   font-size: 12px;
    //   color: #000;
    // }
    // &:nth-child(3n+1) {
    //             @media screen and (min-width:1000px) and (max-width:1300px){
    //               border-left: unset;
    //   }
    // }
    // &:nth-child(3n+3) {
    //             @media screen and (min-width:1000px) and (max-width:1300px){
    //               border-right: unset;
    //   }
    // }

    &.selectedZone {
      background:#444B57!important;
      color:#fff !important;
      border: unset;
      i{
        color:#fff !important;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 1px !important;
  }
  &::-webkit-scrollbar-track {
    background: #444B57 !important;
  }
  .selectedZone {
    background-color: #000 !important;
    color: #fff !important;
    font-weight: 700 !important;
  }
}
.i-zone-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}

@media only screen and (min-width: 1001px) and (max-width: 1280px) {
  // .zone-component {
  //   width: 541px;
  //   min-width: 541px;
  //   max-width: 541px;
  // }
  // .zone-item {
  //   width: 180px;
  //   min-width: 180px;
  //   max-width: 180px;
  // }
}
</style>
