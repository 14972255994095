<template>
  <div class="avatar" :style="{ color: color, border: border }">
    <span class="initials">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#4a4a4a' // Color de fondo por defecto
    },
    border: {
      type: String,
      default: '1px solid #4a4a4a' // Color de fondo por defecto
    }
  },
  computed: {
    initials () {
      const nameParts = this.name.split(' ');
      const firstInitial = nameParts[0] ? nameParts[0][0] : '';
      const secondInitial = nameParts[1] ? nameParts[1][0] : '';
      return (firstInitial + secondInitial).toUpperCase();
    }
  }
};
</script>

<style scoped>
.avatar {
  font-size: 19px;
  font-weight: 400;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Color del texto */
}
.initials{
  margin-top: 0;
}
</style>
