<template>
  <v-chart class="chart" :key="chartKey" :option="option" />
  <!-- autoresize -->
</template>

<script>
/*!
* Copyright 2024 CLICK2BUY SAS
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import { use } from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { defineComponent } from 'vue';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SVGRenderer
]);

export default defineComponent({
  name: 'PieChartPrec',
  props: ['data', 'sectionId'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: ''
  },
  data () {
    return {
      chartKey: 0,
      convertedData: [],
      dataLegends: [],
      option: {
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            // Puedes personalizar el contenido del tooltip aquí
            return `
              <div>
                <strong>${params.name}</strong><br/>
                Reservas: ${params.value}<br/>
              </div>
            `;
          }
        },
        legend: {
          orient: 'horizontal',
          left: 'left',
          data: []
        },
        series: [
          {
            name: 'Efectivas',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    };
  },
  mounted () {
    this.generateDataChart();
  },
  methods: {
    generateDataChart () {
      this.resetChart();
      this.data.rows.forEach((item) => {
        this.dataLegends.push(item.name);
        this.convertedData.push({
          value: item.reservations,
          name: item.name,
          itemStyle: {
            color: item.color
          }
        });
      });
      this.option.series[0].data = this.convertedData;
      this.option.legend.data = this.dataLegends;
    },
    resetChart () {
      // Restablece las propiedades relacionadas con la gráfica
      this.option.series[0].data = [];
      this.option.legend.data = [];
      this.localDataChart = [];
      this.convertedData = [];
      this.dataLegends = [];
    }
  },
  watch: {
    data: {
      deep: true, // Detecta cambios profundos en el objeto
      handler () {
        this.generateDataChart();
      }
    },
    sectionId (value) {
      this.chartKey = value;
    }
  }
});
</script>

<style scoped>
.chart {
}
</style>
