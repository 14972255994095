<template>
  <div class="vendor-groups-modal">
    <i class="icon-mks icon_close_full" @click="closeModal"></i>
    <div class="header">
      <div class="header__title">Selecciona la o las bases de datos que quieres descargar</div>
      <div class="header__bottom">
        <div class="up">
          <div v-if="showInfo > 0" class="selected-databases-text">
            Tienes {{ selectedDatabasesInfo.databasesText }}
          </div>
          <div v-if="showInfo > 0" class="selected-databases-contacts">
            Total contactos {{ selectedDatabasesInfo.totalContacts }}
          </div>
        </div>
        <button @click="selectAll" :class=btnClass>{{ btnText }}</button>
      </div>
    </div>
    <div class="vendor-group-container">
      <div v-for="(group, index) in vendorGroups" :key="index" class="vendor-group">
        <div class="logo">
          <img :src="group.thumbnailURL" alt="logo" />
        </div>
        <div class="info">
          <div class="info__dname">{{ group.displayName }}</div>
          <div class="info__pcount">{{ group.peopleCount }} Contactos.</div>
        </div>
        <div class="selector">
          <label class="custom-checkbox">
            <input type="checkbox" :value="index" v-model="selectedGroups" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="checkbox-container">
        <label>
          <input type="checkbox" :checked="downloadOption === 'separate'" @change="setDownloadOption('separate')" /> Descargar por separado
        </label>
        <label>
          <input type="checkbox" :checked="downloadOption === 'unified'" @change="setDownloadOption('unified')" /> Descargar unificando bases
        </label>
      </div>
      <button class="download-btn" :disabled="!selectedGroups.length" @click="download">
        Descargar base de datos
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vendorGroups: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedGroups: [],
      downloadOption: 'separate' // Default to separate download
    };
  },
  computed: {
    btnText () {
      return this.selectedGroups.length === this.vendorGroups.length ? 'Quitar selección' : 'Seleccionar todas';
    },
    btnClass () {
      return this.selectedGroups.length === this.vendorGroups.length ? 'select-all-btn deselect' : 'select-all-btn';
    },
    showInfo () {
      return this.selectedGroups.length > 0;
    },
    selectedDatabasesInfo () {
      if (!this.selectedGroups.length) {
        return null;
      }
      const selectedVendors = this.selectedGroups.map(index => this.vendorGroups[index]);
      const totalContacts = selectedVendors.reduce((sum, vendor) => sum + vendor.peopleCount, 0);
      const databasesText = selectedVendors.length === 1
        ? '1 base de datos seleccionada'
        : `${selectedVendors.length} bases de datos seleccionadas`;

      return {
        databasesText,
        totalContacts
      };
    }
  },
  methods: {
    selectAll () {
      if (this.selectedGroups.length === this.vendorGroups.length) {
        this.selectedGroups = [];
      } else {
        this.btnText = 'Quitar selección';
        this.selectedGroups = this.vendorGroups.map((_, index) => index);
      }
    },
    setDownloadOption (option) {
      this.downloadOption = option;
    },
    download () {
      const selected = this.selectedGroups.map(index => this.vendorGroups[index]);
      const stringIds = selected.map(group => group.id).join(',');
      const isUnified = this.downloadOption === 'unified';
      this.$emit('download', { stringIds, isUnified });
      this.closeModal();
    },
    closeModal () {
      this.$emit('closeModal');
      this.$parent.close();
    }
  }
};
</script>

<style lang="scss">
.vendorGroupsModal {
  .animation-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vendor-groups-modal {
    background-color: #FFFFFF;
    width: 370px;
    border-radius: 8px;
    position: relative;
    @media (min-width: 600px) {
      width: 400px;
    }
    .header {
      width: 100%;
      height: 120px;
      background-color: #444B57;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px 8px 0 0;
      padding: 10px;
    }
    .icon_close_full {
      position: absolute;
      top: 14px;
      right: 14px;
      cursor: pointer;
      background-color: #fff;
      width: 14px;
      height: 14px;
      margin: 0;
    }
    .header__title {
      padding-top: 13px;
      color: #FFF;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; font-size: 16px;
      width: 232px;
    }
    .header__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .selected-databases-text {
      color: #FFF;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .selected-databases-contacts {
      color: #FFF;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .select-all-btn {
      all: unset;
      width: 75px;
      border-radius: 8px;
      color: #444B57;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: #FFF;
      cursor: pointer;
      padding: 6px 20px 5px 21px;
      margin-left: auto;
    }
    .select-all-btn.deselect {
      color: #fff;
      background: #444B57;
      border: 1px solid #fff;
    }
    .vendor-group-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      max-height: 400px;
    }
    .vendor-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100px;
      width: 100%;
      padding: 20px 12px 20px 12px;
      border-bottom: 1px solid #e0e0e0;
      @media (min-width: 600px) {
        padding: 20px 20px 20px 20px;
      }
    }

    .logo img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .info__dname {
      color: #444B57;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .info__pcount {
      color: #444B57;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    /* Custom checkbox styles */
    .selector {
      margin-left: auto;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .custom-checkbox {
      cursor: pointer;
    }

    .custom-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .custom-checkbox .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border-radius: 50%; /* Makes the checkbox round */
      border: 1px solid #444B57;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    .custom-checkbox input:checked ~ .checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .custom-checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .custom-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    .custom-checkbox .checkmark:after {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #444B57;
    }
    .bottom {
      padding: 20px 24px 20px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        label {
          display: flex;
          align-items: center;
          gap: 4px;
          font-family: "Source Sans Pro";
          font-size: 12px;
          color: #444B57;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .checkbox-container input[type="checkbox"]:checked {
      accent-color: #444B57; /* Cambia #ff0000 por el color que desees */
    }
    .download-btn {
      background-color: #444B57;
      color: #FFF;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 8px 12px 9px 12px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      width: 169px;
    }

    .download-btn:disabled {
      background-color: #a2a5ab;
      cursor: not-allowed;
    }
  }
}
</style>
