<template>
  <div id="rangeDates">
    <div class="range_dates_container text-center">
        <div class="d-block text-center">
          <div class="close_container" @click="$emit('close')"><img src="@/assets/icons/polls/close_black.svg" alt=""></div>
            <h2 class="text-center">Seleccione fecha de la búsqueda</h2>
            <div>
                <div>
                    <label for="">Fecha Inicial - Fecha Final</label>
                    <v-date-picker v-model="dateRange" :locale="{ id: 'es', firstDayOfWeek: 1, masks: { weekdays: 'WWW' } }" is-range is-dark class="datePickerPoll" />
                </div>
            </div>
            <button @click="filterDateRange">Filtrar por fecha</button>
        </div>
    </div>
  </div>
</template>
<script>
import 'vue-date-pick/dist/vueDatePick.css';
export default {
  data () {
    return {
      dateRange: {
        start: null,
        end: null
      }
    };
  },
  methods: {
    filterDateRange () {
      if (!this.dateRange.start || !this.dateRange.end) {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Por favor seleccione un rango de fechas, asegúrese de seleccionar una fecha de inicio y una fecha de fin.',
          type: 'is-danger',
          position: 'is-bottom'
        });
        return;
      }
      this.$emit('filterDateRange', this.dateRange);
      this.$emit('close');
    }
  },
  watch: {
  }
};
</script>
<style lang="scss">
  @use "@/assets/styles/polls/_calendar";
</style>
