<template>
  <div id="marketing" style="height: 100%;">
    <div style="height: 100%;">
      <Sidenav @change-full="toggle"></Sidenav>
      <Toolbar
        :moduleLoad="moduleSelected"
        @changeModule="setModuleSelected($event)"
        @changeView="setModuleSelected($event)"
        @setChangeToggle="setMenu($event)"
      />
      <div class="content-wrap" style="height: calc(100% - 52px);">
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
        <SelectorMarketing @showModule="setModuleSelected($event)" v-if="moduleSelected === null"></SelectorMarketing>
        <Sms v-if="moduleSelected === 'sms' && !history" @resetModule="setModuleSelected($event)" :provider="moduleSelected" @changeModuleSms="setModuleSelected($event)"></Sms>
        <Email v-if="moduleSelected === 'email' && !history" @resetModule="setModuleSelected($event)" :provider="moduleSelected" @changeModuleEmail="setModuleSelected($event)"></Email>
        <History v-if="history" :provider="moduleSelected" @resetModule="setModuleSelected($event)"></History>
        <ModalInfo
          :cardNumber="cardItem"
          :isPse="pse"
          :status="state"
          :vendorInfo="vendor"
          :mercadopago="isMercadoPago"
          @close="openModal = $event"
          v-if="openModal"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SelectorMarketing from '@/components/Marketing/selectorMarketing.vue';
import Sidenav from '@/components/_shared/Sidenav';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import Toolbar from '@/components/Marketing/toolbar.vue';
import Sms from '@/components/Marketing/sms';
import Email from '@/components/Marketing/email';
import History from '@/components/Marketing/history';
import ModalInfo from '@/components/Marketing/payment/TransactionStatusModal';
import globalTypes from '@/store/types/global';

export default {
  name: 'MarketingVue',
  components: {
    SelectorMarketing,
    Sidenav,
    ChangedPassword,
    Toolbar,
    Sms,
    Email,
    History,
    ModalInfo
  },
  data () {
    return {
      moduleSelected: null,
      history: false,
      openModal: false,
      cardItem: null,
      pse: false,
      isMercadoPago: false,
      state: ''
    };
  },
  mounted () {
    window.localStorage.removeItem('marketing_package');
    const trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        this.state = 'schedule';
        this.pse = true;
        this.cardItem = null;
        this.openModal = true;
      }
      this.showStatusTransactionPayU(trans);
    }
  },
  methods: {
    setMenu (data) {
      this.$store.commit(globalTypes.mutations.setShowSideBar, data);
    },
    setStatus (data) {
      this.state = data.state;
      this.pse = data.pse;
      this.cardItem = data.cardItem;
      this.isMercadoPago = data.isMercadoPago || false;
      this.openModal = true;
    },
    showStatusTransactionPayU (transaction) {
      if (
        parseInt(transaction.polTransactionState) === 4 &&
        parseInt(transaction.polResponseCode) === 1
      ) {
        this.state = 'ok';
        this.pse = true;
        this.cardItem = null;
        this.openModal = true;
      }
      if (
        parseInt(transaction.polTransactionState) === 6 &&
        (parseInt(transaction.polResponseCode) === 4 ||
          parseInt(transaction.polResponseCode) === 5 ||
          parseInt(transaction.polResponseCode) === 19)
      ) {
        this.state = 'error';
        this.pse = true;
        this.cardItem = null;
        this.openModal = true;
      }
      if (
        (parseInt(transaction.polTransactionState) === 12 ||
          parseInt(transaction.polTransactionState) === 14) &&
        (parseInt(transaction.polResponseCode) === 9994 ||
          parseInt(transaction.polResponseCode) === 25)
      ) {
        this.state = 'schedule';
        this.pse = true;
        this.cardItem = null;
        this.openModal = true;
      }
    },
    setModuleSelected (data) {
      this.moduleSelected = null;
      switch (data.module) {
        case 'sms':
          this.moduleSelected = 'sms';
          break;
        case 'email':
          this.moduleSelected = 'email';
          break;
        case 'history':
          this.moduleSelected = data.item;
          this.history = data.show;
          break;
        case 'reset':
          this.moduleSelected = null;
          this.history = false;
          break;
      }
    },
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  }
};
</script>
