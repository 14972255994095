<template>
  <div @mouseleave="opened = false" id="select-input-personalizado">
    <div :class="{openList:opened}" class="select-input-personalizado">
      <div
        :class="{'select-input-error': errors.has(field), 'w-100px': typeData === 'document'}"
        @click="showList()"
        class="select-input-personalizado-icon-selector"
        v-if="selector"
      >
        <!--BANDERAS-->
        <div class="countriesSelectorContainer" v-if="typeData == 'countries'">
          <img
            :src="valueSelector"
            @error="imageNotFound"
            alt="bandera"
            class="select-input-personalizado-bandera"
          />
          <p>(+{{countrieInfo.indicativo}})</p>
        </div>
        <!--FIN BANDERAS-->
        <!--DOCUMENTOS-->
        <span
          class="select-input-personalizado-text"
          v-if="typeData === 'document'"
        >{{ valueSelector }}</span>
        <!--FIN DOCUMENTOS-->
        <div class="dropdown-list">
          <img alt="banderas" src="@/assets/icons/addReservation/arrow-down.svg" v-if="!opened" />
          <img alt="banderas" src="@/assets/icons/addReservation/arrow-up-big.svg" v-if="opened" />
        </div>
        <div class="divisor" v-if="typeData === 'countries' || typeData === 'document'"></div>
      </div>
      <div
        :class="{'select-input-error': errors.has(field), 'with-selector': selector}"
        class="select-input-personalizado-icon-left"
        v-if="iconLeft"
      >
        <img
          alt="icon"
          src="@/assets/icons/addReservation/phone.svg"
          style="width: 10px;height: 15px;"
          v-if="iconLeft == 'phone'"
        />
      </div>
      <div class="select-input-personalizado-center">
        <input
          :class="{'borderRight': !iconRight}"
          :placeholder="placeholder"
          :type="type"
          autocomplete="off"
          class="select-input-personalizado-center-text"
          v-if="!validated"
          v-model="value"
        />
        <input
          :class="{'select-input-error': errors.has(field), 'borderRight': !iconRight, 'borderRight-red': !iconRight &&  errors.has(field)}"
          :name="field"
          :placeholder="placeholder"
          :type="type"
          autocomplete="off"
          class="select-input-personalizado-center-text"
          v-if="validated"
          v-model="value"
          v-validate="validatedRules"
        />
      </div>
      <div
        :class="{'select-input-error': errors.has(field)}"
        class="select-input-personalizado-icon-right"
        v-if="iconRight"
      >
        <div
          :class="{'select-input-error': errors.has(field)}"
          @click="yateToogle"
          class="select-input-personalizado-icon-right-item"
          v-if="vendor.id == 231"
        >
          <img
            alt="icon"
            src="@/assets/icons/yate.jpeg"
            style="width: 18px;"
            v-if="isYate === false"
          />
          <img alt="icon" src="@/assets/icons/yate.jpeg" style="width: 18px;" v-else />
        </div>
        <div
          :class="{'select-input-error': errors.has(field)}"
          @click="vipToogle"
          class="select-input-personalizado-icon-right-item"
        >
          <img
            alt="icon"
            src="@/assets/icons/vipActive.svg"
            style="width: 20px;"
            v-if="isVip === true || isVip === 1"
          />
          asdsadsad
          <img
            alt="icon"
            src="@/assets/icons/icon_VIP_grey.svg"
            style="width: 20px;"
            v-if="isVip === false || isVip === 0"
          />
        </div>
      </div>
    </div>
    <div
      :style="{display: opened ? 'flex' : 'none', zIndex: 2}"
      class="dropdown-list-content"
      v-if="typeData === 'countries'"
    >
      <div class="filterFlagsContainer">
        <input
          @input="(e)=>filter=e.target.value "
          class="filterFlags"
          placeholder="Escriba el país o el indicativo"
          type="text"
        />
        <span></span>
      </div>
      <div
        :key="'countries#' + IType"
        @click="()=>{valueSelector = itemType.image; showList(); changedPais(itemType)}"
        class="dropdown-list-content-item"
        v-for="(itemType, IType) in filteredCountries"
      >
        <div class="dropdown-list-content-item-left">
          <img :src="itemType.image" @error="imageNotFound" alt="countries" />
        </div>
        <div class="dropdown-list-content-item-right">(+{{itemType.value}}) {{ itemType.label }}</div>
      </div>
    </div>
    <div
      :style="{display: opened ? 'flex' : 'none', zIndex: 1}"
      class="dropdown-list-content"
      v-if="typeData === 'document'"
    >
      <div
        :key="'document#' + IType"
        @click="valueSelector = itemType.value, showList()"
        class="dropdown-list-content-item"
        v-for="(itemType, IType) in typeIdentify"
      >
        <div class="dropdown-list-content-item-left">
          <strong>{{ itemType.value }}</strong>
        </div>
        <div class="dropdown-list-content-item-right">{{ itemType.label }}</div>
      </div>
    </div>
    <!-- <span class="text-error" v-if="errors.has(field)">* {{ errors.first(field) }}</span> -->
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import validator from 'validator';
import _ from 'lodash';

export default {
  props: {
    iconLeft: {
      type: String
    },
    iconRight: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    fieldSelector: {
      type: String
    },
    type: {
      type: String
    },
    typeData: {
      type: String
    },
    rules: {
      type: String
    },
    required: {
      type: Boolean
    },
    selector: {
      type: Boolean
    },
    indicative: {
      type: String
    },
    flag: {
      type: String
    },
    errorShow: {
      type: Boolean
    },
    initDocument: {
      type: String
    },
    initInputValue: {
      type: String
    },
    initIndicativo: {
      type: Number
    },
    initInfo: {
      type: Object
    },
    formInfo: {
      type: Object
    },
    fromSelectorUser: {
      type: Boolean
    }
  },
  data () {
    return {
      filter: '',
      value: '',
      valueSelector: 'CC',
      typeIdentify: [
        { label: 'Cédula de ciudadania', value: 'CC' },
        { label: 'Cédula de extranjeria', value: 'CE' },
        { label: 'Registro civil', value: 'RC' },
        { label: 'Número de identificación tributario', value: 'NIT' }
      ],
      countries: [],
      opened: false,
      option: {
        value: 57,
        label: 'Colombia',
        image: 'https://api.precompro.com/banderas/co.png',
        selected: true
      },
      validatedRules: this.rules || '',
      validated: this.required || false,
      error: null,
      countrieInfo: {}
    };
  },
  mounted () {
    if (this.typeData === 'countries') {
      this.listCountries();
      const indicative = this.$store.getters['global:vendor'].params.indicativeRegion;
      this.indicativeParam(indicative);
      if (this.indicative !== undefined) {
        this.indicativeParam(this.indicative);
      }
      if (this.flag !== undefined) {
        this.valueSelector = this.flag;
      }
    }
    if (this.initInfo) {
      if (this.field === 'phone') {
        if (this.initInfo.phone) {
          this.value = parseInt(this.initInfo.phone);
          this.indicativeParam(this.initInfo.indicativo);
        }
      }
      if (this.field === 'identityDocument') {
        if (this.initInfo.identityDocument) {
          this.value = this.initInfo.identityDocument;
        }
      }
    }
  },
  methods: {
    formatFilter () {
      return this.filter.toLowerCase();
    },
    showList () {
      this.$emit('changeFromSelectorUser', false);
      this.opened = !this.opened;
    },
    imageNotFound (e) {
      e.target.src = 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg';
    },
    listCountries () {
      const data = [];
      const indicative = this.initIndicativo ?? null;
      const flag = this.flag ?? null;
      _.forEach(BanderaPais, function (item) {
        const info = callingCode.find(i => i.country === item.country);
        if (info) {
          if (info.calling_code !== null) {
            const image = item.abbreviation ? 'https://api.precompro.com/banderas/' + item.abbreviation.toLowerCase() + '.png' : null;
            if (indicative != null && indicative === info.calling_code && flag != null && flag === image && info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image,
                selected: true
              });
            } else if (info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image
              });
            }
          }
        }
      });
      this.countries = data;
    },
    isValidEmail () {
      if (this.value !== null && this.value !== '' && this.value !== undefined) {
        if (validator.isEmail(this.value)) {
          this.error = null;
          return true;
        } else {
          this.error = 'Por favor ingrese un Email válido';
          return false;
        }
      } else {
        this.error = null;
      }
    },
    indicativeParam (indicative) {
      if (this.typeData === 'countries') {
        const selectIndicative = this.countries.find(value => parseInt(value.value) === parseInt(indicative));
        this.option = selectIndicative;
        this.valueSelector = selectIndicative.image;
        this.changedPais(this.option);
      }
    },
    changedPais (indicative) {
      this.option = indicative;
      let aux2 = null;
      const aux3 = indicative.label;
      const ind = indicative.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country;
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.countrieInfo = { pais: indicative.label, indicativo: indicative.value, bandera: aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null };
      this.validateTelIndicative(indicative.value);
    },
    validateTelIndicative (indicative) {
      switch (indicative) {
        case 57:
          this.validatedRules = 'numeric|min:10|max:10';
          this.validated = true;
          break;
        case 507:
          this.validatedRules = 'numeric|min:7|max:8';
          this.validated = true;
          break;
        default:
          this.validatedRules = 'numeric|min:7|max:11';
          this.validated = true;
          break;
      }
    },
    isValidTel () {
      setTimeout(() => {
        if (this.value !== null && this.value !== '' && this.value !== undefined && !isNaN(this.value)) {
          if (this.errors.first('input') !== undefined) {
            this.error = 'Por favor ingrese un Teléfono válido';
          } else {
            this.error = null;
          }
        } else {
          this.error = null;
        }
      }, 100);
    },
    filteredCountriesMethod () {
      return this.countries.filter((country) =>
        country.label.toLowerCase().includes(this.formatFilter()) || ('+' + country.value.toString()).includes(this.formatFilter())
      );
    }
  },
  watch: {
    value () {
      if (this.field === 'email') {
        this.isValidEmail();
      }
      if (this.field === 'phone') {
        this.isValidTel();
      }
      this.$emit('changeValue', { [this.field]: this.value, [this.fieldSelector]: this.valueSelector, error: this.error, ...this.countrieInfo });
    },
    valueSelector () {
      this.$emit('changeValue', { [this.field]: this.value, [this.fieldSelector]: this.valueSelector, error: this.error, ...this.countrieInfo });
    },
    formInfo: {
      handler (data) {
        if (this.fromSelectorUser) {
          this.indicativeParam(data.indicative);
        }
        if (this.field === 'phone') {
          if (data.phone) {
            this.value = parseInt(data.phone);
          }
        }
        if (this.field === 'identityDocument') {
          if (data.identityDocument) {
            this.value = data.identityDocument;
          }
          if (data.typeDocument) {
            this.valueSelector = data.typeDocument;
          }
        }
      },
      deep: true

    },
    initInfo: {
      handler (data) {
        if (this.field === 'phone') {
          this.value = parseInt(data.phone);
        }
        if (this.field === 'identityDocument') {
          if (data.identityDocument) {
            this.valueSelector = data.typeDocument ? data.typeDocument : 'CC';
          }
        }
      },
      deep: true

    }
  },
  computed: {
    filteredCountries () {
      if (this.filter === '') {
        return this.countries;
      } else {
        return this.filteredCountriesMethod();
      }
    }
  }
};
</script>
<style lang="scss">
.filterFlagsContainer {
  background-color: #ecedee;
  padding: 2px;
  display: flex;
  align-items: center;
  width: 90%;
  min-height: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  overflow: hidden;

  input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: #606773;
    @media screen and (min-width: 650px) and (max-width: 1025px) {
      font-size: 10.3px;
    }
  }
  span {
    background-image: url("../../assets/icons/searchIcon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 15px;
    height: 14px;
    position: relative;
    right: 3px;
  }
}

#select-input-personalizado {
  margin-bottom: 10px;
  position: relative;
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    margin-bottom: 5px;
  }
  .filterFlags {
    border: unset;
    outline: none;
  }
  .dropdown-list-content {
    position: absolute;
    top: 40px;
    left: 0px;
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    max-height: 180px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    flex-direction: column;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-top: solid 1px transparent;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    margin-bottom: 10px;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #606773 !important;
      box-shadow: unset !important;
      border: unset;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-height: 28px;
      max-height: 29px;
      padding: 0px 5px;
      cursor: pointer;
      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        margin: 5px;
        width: 30px;
        font-size: 13px;
        img {
          width: 25px;
          height: 25px;
          border-radius: 99%;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        width: 100%;
        font-size: 12px;
      }
      &:hover {
        background-color: #ddd;
      }
    }
  }
  .select-input-personalizado {
    display: flex;
    width: 100%;
    height: 40px;
    border: solid 1px rgba(68, 75, 87, 0.5);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    &.openList {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;
      &::after {
        content: " ";
        display: block;
        background-color: #a8adb5;
        height: 1px;
        width: 90%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0px;
      }
    }
    &-icon-selector {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      min-width: 92px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      .dropdown-list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 5px;
        img {
          cursor: pointer;
          width: 15px;
          height: 7px;
        }
      }
      .divisor {
        width: 1px;
        height: 28px;
        background-color: #a8adb5;
        margin-left: 2px;
      }
      @media screen and (max-width: 600px) {
        width: 90px;
      }
    }
    &-icon-left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50px;
    }
    .with-selector {
      border-left: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      &-text {
        width: 100%;
        height: 40px;
        border-left: none;
        // border-top: 1px solid rgba(68, 75, 87, .5);
        // border-bottom: 1px solid rgba(68, 75, 87, .5);
        border: unset;
        border-right: none;
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 60px;
      border-left: none;
      position: relative;
      &-item {
        display: flex;
        width: 60px;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
      }
    }
    .borderRight {
      .select-input-personalizado-center-text {
        margin-right: 3px;
      }
    }
    &-bandera {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      cursor: pointer;
    }
    &-text {
      height: 25px;
      width: 25px;
      cursor: pointer;
    }
  }
  .select-input-item {
    border: none;
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    height: 30px;
    width: 50px;
  }
  .w-100px {
    width: 100px;
  }
}
.doucmentContainer{
  .select-input-personalizado {
    &-icon-selector {
      min-width: 68px!important;
    }
  }
}
.countriesSelectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}
</style>
