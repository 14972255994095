import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=436db9a2&scoped=true"
import script from "./Error404.vue?vue&type=script&lang=js"
export * from "./Error404.vue?vue&type=script&lang=js"
import style0 from "./Error404.vue?vue&type=style&index=0&id=436db9a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436db9a2",
  null
  
)

export default component.exports