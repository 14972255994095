import { render, staticRenderFns } from "./MailchimpForm.vue?vue&type=template&id=a74e56d4"
import script from "./MailchimpForm.vue?vue&type=script&lang=js"
export * from "./MailchimpForm.vue?vue&type=script&lang=js"
import style0 from "./MailchimpForm.vue?vue&type=style&index=0&id=a74e56d4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports