<template>
  <div class="general-metrics-layer__container__charts-general" id="metrics-body-old">
    <!-- <AreaChartPrec /> -->
    <div class="general-metrics-layer__container__charts-general__card" v-if="loadedFull">
      <div class="content">
        <!-- <ve-line :colors="colors" :data="temporalDataOldGraphs.byMonthD" :settings="chartSettings2"></ve-line> -->
        <AreaChartLinePrec :colors="colorsArea" v-if="sectionId === 4" :data="temporalDataOldGraphs.byMonth" :sectionId="sectionId" />
        <AreaChartLinePrec :colors="colorsArea" v-else :data="temporalDataOldGraphs.byMonthD" :sectionId="sectionId" />
      </div>
      <!-- <LineTimeGeneral v-else></LineTimeGeneral> -->
    </div>
    <BarChartPrec
      :sectionId="sectionId"
      titleChart="Cantidad por día"
      :filtersBy="typeByDay"
      :data="peoplePerDay"
      :dataConventions="dataConventionsPerDay"
      graph="perDay"
      type="group"
      @changeFilter="changeFilter"
      :walkingData="walkingData"
      :enableFilters="true"
      :enableConventions="true"
      :generalData="generalData"
    />
    <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId !== 3">
      <h2>No efectivas</h2>
      <div class="flex old_graphs" style="gap: 0 90px; justify-content: center;">
        <div style="align-content:center; width: 100%;" >
          <div v-if="temporalDataOldGraphs.notEffectiveD.rows && temporalDataOldGraphs.notEffectiveD.rows.length > 0" >
            <DonutChartPrec :sectionId="sectionId" :data="temporalDataOldGraphs.notEffectiveD" type="noEffective" />
          </div>
          <div v-else class="col-3 noresults">
            <div class="wrapper__noresults">Sin Resultados</div>
          </div>
        </div>
        <div class="col padding-top flex" style="min-width: 300px!important;max-width: 300px!important;">
          <div class="text small bold black">TOTAL</div>
          <div class="flex width-height border-top-bot">
            <div class="col flex margin-top" v-if="temporalDataOldGraphs.typeNotEffective == 1 ">
              <div :key="index" v-for=" (data,index) in temporalDataOldGraphs.byIsCancelledD">
                <img
                  :key="index"
                  :src="getUrlImageReferrer(data.referrer)"
                  style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                  v-if="getUrlImageReferrer(data.referrer) !== null"
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                />
                <i
                  :class="iconReferrer(data.referrer)"
                  :key="data.referrer"
                  class="icon-mks gray-blue small-grafica"
                  v-else
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                ></i>
              </div>
            </div>
            <div class="col flex margin-top" v-if="temporalDataOldGraphs.typeNotEffective == 2 ">
              <div :key="index" v-for=" (data,index) in temporalDataOldGraphs.byNotShowD">
                <img
                  :key="index"
                  :src="getUrlImageReferrer(data.referrer)"
                  style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                  v-if="getUrlImageReferrer(data.referrer) !== null"
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                />
                <i
                  :class="iconReferrer(data.referrer)"
                  :key="data.referrer"
                  class="icon-mks gray-blue small-grafica"
                  v-else
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                ></i>
              </div>
            </div>
            <div class="col flex" v-if="typeNotEffective == 1">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byIsCancelledD"
              >{{ data.reservations}}</p>
            </div>
            <div class="col flex" v-if="typeNotEffective == 1">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byIsCancelledD"
              >{{ data.people}}</p>
            </div>
            <div class="col flex" v-if="typeNotEffective == 1">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byIsCancelledD"
              >{{ ((data.reservations*100) / getTotalsByType(temporalDataOldGraphs.byIsCancelledD,'reservations')).toFixed(decimals)}}%</p>
            </div>
            <div class="col flex" v-if="typeNotEffective == 2">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byNotShowD"
              >{{ data.reservations}}</p>
            </div>
            <div class="col flex" v-if="typeNotEffective == 2">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byNotShowD"
              >{{ data.people}}</p>
            </div>
            <div class="col flex" v-if="typeNotEffective == 2">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byNotShowD"
              >{{ ((data.reservations*100) / getTotalsByType(temporalDataOldGraphs.byNotShowD,'reservations')).toFixed(decimals)}}%</p>
            </div>
          </div>
          <div
            class="text small bold black"
            v-if="typeNotEffective == 1"
          >Total: {{getTotalsByType(temporalDataOldGraphs.byIsCancelledD , 'reservations')}}</div>
          <div
            class="text small bold black"
            v-if="typeNotEffective == 2"
          >Total: {{getTotalsByType(temporalDataOldGraphs.byNotShowD , 'reservations')}}</div>
          <div class="flex radio-grafica width-height" style="color: white;">
            <div class="flex radio-center">
              <b-radio
                :native-value="1"
                name="reservasEffective"
                type="is-white"
                v-model="typeNotEffective"
              >
                <p class="text radio">Canceladas</p>
              </b-radio>
            </div>
            <div class="flex radio-center">
              <b-radio
                :native-value="2"
                name="reservasEffective"
                type="is-white"
                v-model="typeNotEffective"
              >
                <p class="text radio">No-llegaron</p>
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BarChartPrec
      :sectionId="sectionId"
      titleChart="Cantidad reservas / por grupos de personas"
      :filtersBy="typeByRange"
      :data="peoplePerGroup"
      :dataConventions="dataConventionsPerGroup"
      graph="perGroup"
      type="group"
      @changeFilter="changeFilter"
      :walkingData="walkingData"
      :enableFilters="true"
      :enableConventions="true"
      :generalData="generalData"
    />
    <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId !== 3 && sectionId !== 4">
      <h2>Efectivas</h2>
      <div class="flex old_graphs" style="gap: 0 80px; justify-content: center;">
        <div v-if="temporalDataOldGraphs.byAllReservationsD.rows && temporalDataOldGraphs.byAllReservationsD.rows.length" style="width: 100%;">
          <PieChartPrec :data="temporalDataOldGraphs.byAllReservationsD" :sectionId="sectionId" />
        </div>
        <div class="col padding-top flex" style="min-width: 300px!important;max-width: 300px!important;">
          <div class="text small bold black">TOTAL</div>
          <div class="flex width-height border-top-bot">
            <div class="col flex margin-top">
              <div :key="index" v-for=" (data,index) in temporalDataOldGraphs.byAllReservations">
                <img
                  :key="index"
                  :src="getUrlImageReferrer(data.referrer)"
                  style="height: 20px; width: 20px; margin-right: 5px; margin-bottom: 3px;"
                  v-if="getUrlImageReferrer(data.referrer) !== null"
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                />
                <i
                  :class="iconReferrer(data.referrer)"
                  :key="data.referrer"
                  class="icon-mks gray-blue small-grafica"
                  v-else
                  v-tooltip="{content: (data.referrer === 'whitelabel') ? 'página web' : (data.referrer === 'dashboard') ? 'telefono' : data.referrer, placement: 'right'}"
                ></i>
              </div>
            </div>
            <div class="col flex">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byAllReservations"
              >{{ data.value}}</p>
            </div>
            <div class="col flex">
              <p
                :key="index"
                :style="{ color: data.color}"
                class="text black small-grafica reserva"
                v-for=" (data,index) in temporalDataOldGraphs.byAllReservations"
              >{{((data.value*100) / getTotalsByType(temporalDataOldGraphs.byAllReservations,'value')).toFixed(decimals)}}%</p>
            </div>
          </div>
          <div
            class="text small bold black"
          >Total: {{getTotalsByType(temporalDataOldGraphs.byAllReservations , 'value')}}</div>
        </div>
      </div>
    </div>
    <BarChartPrec
      :sectionId="sectionId"
      titleChart="Tiempo de estadía por número de personas"
      :data="peoplePerTime"
      graph="perTime"
      v-if="sectionId !== 2"
      :type="'group'"
      :colors="sectionId !== 3 ? colorsReservations : colorsWalkin"
      :enableConventions="sectionId === 4 ? true : false"
      :generalData="generalData"
    />
    <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId !== 3">
      <h2>Horas de mayor conversión</h2>
      <div class="header-index grafica flex-1">
        <div class="col-3 ">
          <div v-if="sectionId !== 4">
            <div class="col-3" v-if="(temporalDataOldGraphs.byHourD.rows && temporalDataOldGraphs.byHourD.rows.length > 0)">
              <LineChartPrec :colors="colorsArea" :data="temporalDataOldGraphs.byHourD" :sectionId="sectionId" />
              <!-- ve-line :colors="colors" :data="temporalDataOldGraphs.byHourD" :settings="chartSettings3"></ve-line> -->
            </div>
            <div class="col-3 noresults" v-else>
              <div class="wrapper__noresults">Sin Resultados</div>
            </div>
          </div>
          <div v-else>
            <div class="col-3" v-if="sectionId === 4 && (temporalDataOldGraphs.byHourDGeneral.rows && temporalDataOldGraphs.byHourDGeneral.rows.length > 0)">
              <!-- ve-line :colors="['#00ADC6','#CAD300']" :data="temporalDataOldGraphs.byHourDGeneral" :settings="chartSettings3"></ve-line> -->
              <LineChartPrec :colors="colorsArea" :data="temporalDataOldGraphs.byHourDGeneral" :sectionId="sectionId" />
            </div>
            <div class="col-3 noresults" v-else>
              <div class="wrapper__noresults">Sin Resultados</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId === 2">
      <h2>Promedio de transacción</h2>
      <div class="flex metric-cont grafica">
        <div class="header-index grafica flex-1" v-if="sectionId === 2">
          <div class="col-3" v-if="temporalDataOldGraphs.globalD != null && temporalDataOldGraphs.globalD.length > 0">
            <column-chart
              :colors="getChartColors(temporalDataOldGraphs.globalD)"
              :data="temporalDataOldGraphs.globalD"
              :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
              class="graph"
            ></column-chart>
          </div>
          <div class="col-3 noresults" v-else>
            <div class="wrapper__noresults">Sin Resultados</div>
          </div>
          <div
            class="col padding-top flex mt-10"
            v-if="temporalDataOldGraphs.globalD != null && temporalDataOldGraphs.globalD.length > 0"
          >
            <div class="flex radio-grafica-transacciones width-height">
              <div class="flex radio-center">
                <b-radio
                  :native-value="1"
                  name="reservasRange"
                  type="is-white"
                  v-model="typeByTransaction"
                >
                  <p class="text radio">Global</p>
                </b-radio>
              </div>
              <div class="flex radio-center">
                <b-radio
                  :native-value="2"
                  name="reservasRange"
                  type="is-white"
                  v-model="typeByTransaction"
                >
                  <p class="text radio">Pse</p>
                </b-radio>
              </div>
              <div class="flex radio-center">
                <b-radio
                  :native-value="3"
                  name="reservasRange"
                  type="is-white"
                  v-model="typeByTransaction"
                >
                  <p class="text radio">Tarjeta</p>
                </b-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" :style="`${sectionId === 1 ? 'grid-template-columns: 1fr 1fr;': 'grid-template-columns: 1fr;'} display: grid; gap: 10px;`">
      <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId === 1 || sectionId === 3">
        <h2>Últimos Movimientos</h2>
        <div class="header-index grafica flex-1">
          <div class="col-3">
            <span class="text-left">Reservas</span>
            <span class="text-right">Ultima Hora</span>
            <div class="col-3">
              <column-chart
                :colors=" sectionId == 3 ? ['#9453CB'] : ['#00ADC6']"
                :data="temporalDataOldGraphs.byLastHourD"
                :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                class="graph"
              ></column-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId === 1">
        <h2>Total reservas sentadas finalizadas</h2>
          <div class="header-index grafica flex-1">
            <div class="col-3">
              <column-chart
                :colors="['#00ADC6', '#4A4A4A']"
                :data="temporalDataOldGraphs.byReservationS"
                :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
                class="graph"
              ></column-chart>
            </div>
          </div>
      </div>
    </div>
    <div :style="`${sectionId !== 3 ? 'grid-template-columns: 1fr 1fr;': 'grid-template-columns: 1fr;'} display: grid; gap: 10px;`">
      <div class="general-metrics-layer__container__charts-general__card">
        <h2>Cantidad de {{ getTypeText() }}</h2>
        <div class="header-index grafica flex-1" style="justify-content: center; gap: 90px" v-if="sectionId !== 4">
          <div class="" v-if="(temporalDataOldGraphs.byGuestD.rows && temporalDataOldGraphs.byGuestD.rows.length > 0)" :style="`${sectionId !== 3 ? 'width: 100%;': 'width: 50%;'}`" >
            <!-- <ve-ring
              :data="temporalDataOldGraphs.byGuestD"
              :settings="chartSettings"
              class="ringCheck"
              height="300px"
              width="380px"
            ></ve-ring> -->
            <DonutChartPrec :sectionId="sectionId" :data="temporalDataOldGraphs.byGuestD" type="quantityReservations" />
          </div>
          <div class="col-3 noresults" v-else>
            <div class="wrapper__noresults">Sin Resultados</div>
          </div>
          <div class="col padding-small flex" style="width: 300px">
            <div class="text small bold black">TOTAL</div>
            <div class="w-100 border-top-grey">
              <p class="margin-top flex align-center justify-between" v-for="(data, index) in temporalDataOldGraphs.byGuestD.rows" :key="index">
                <span class="text black small-grafica text-small">{{ data.name }}</span>
                <span class="text black small-grafica">{{ ((data.value*100) / temporalDataOldGraphs.byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0)).toFixed(2) }}%</span>
              </p>
            </div>
            <div class="total-grey">
              <p>Total {{ getTypeText() }}</p>
              <p>{{ temporalDataOldGraphs.byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0) }}</p>
            </div>
          </div>
        </div>
        <div class="header-index grafica flex-1" style="justify-content: center; gap: 90px" v-else>
          <div class="" v-if="(temporalDataOldGraphs.byGuestD.rows && temporalDataOldGraphs.byGuestD.rows.length > 0)" style="width: 100%">
            <DonutChartPrec :sectionId="sectionId" :data="temporalDataOldGraphs.byGuestD" type="quantityReservations" />
            <!-- <ve-ring
              :colors="['#446096', '#fc8452']"
              :data="temporalDataOldGraphs.byGuestD"
              :settings="chartSettings"
              class="ringCheck"
              height="300px"
              width="380px"
            ></ve-ring> -->
          </div>
          <div class="col-3 noresults" v-else>
            <div class="wrapper__noresults">Sin Resultados</div>
          </div>
          <div class="col padding-small flex" :style="`${sectionId === 3 ? 'max-width: 300px;': 'width: 100%'}`">
            <div class="text small bold black">TOTAL</div>
            <div class="w-100 border-top-grey">
              <p class="margin-top flex align-center justify-between" v-for="(data, index) in temporalDataOldGraphs.byGuestD.rows" :key="index">
                <span class="text black small-grafica text-small">{{ data.name }}</span>
                <span class="text black small-grafica">{{ ((data.value*100) / temporalDataOldGraphs.byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0)).toFixed(2) }}%</span>
              </p>
            </div>
            <div class="total-grey">
              <p>Total</p>
              <p>{{ temporalDataOldGraphs.byGuestD.rows.reduce((sum, objeto) => sum + objeto.value, 0) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="general-metrics-layer__container__charts-general__card">
        <h2>Cantidad de requerimientos en reservas</h2>
        <div class="header-index grafica flex-1" style="justify-content: center; gap: 90px">
          <div class="" :style="`${sectionId !== 3 ? 'width: 100%;': 'width: 40%;'}`"  v-if="(temporalDataOldGraphs.byRequirementsD.rows && temporalDataOldGraphs.byRequirementsD.rows.length > 0)">
            <DonutChartPrec :sectionId="sectionId" :data="temporalDataOldGraphs.byRequirementsD" type="byRequeriments" />

            <!-- <ve-ring
              :data="byRequirementsD"
              :settings="byRequirementsD.rows.length > 10 ? chartSettingsReqXL : chartSettingsReq"
              class="ringCheck"
              :height="getHeightReqRing(byRequirementsD.rows)"
              width="380px"
            ></ve-ring> -->
          </div>
          <div class="col-3 noresults" v-else>
            <div class="wrapper__noresults">Sin Resultados</div>
          </div>
          <div class="col padding-small flex" :style="`${sectionId === 3 ? 'width: 300px;': 'width: 100%'}`">
          <div class="text small bold black">TOTAL</div>
            <div class="requirementStyleTableParent">
              <div class="margin-top flex align-center justify-between requirementStyleTable" v-for="(data, index) in temporalDataOldGraphs.byRequirementsD.rows" :key="index">
                <div class="requirementRingData" style="width: 30px">
                  <img v-tooltip="data.name" :src="data.iconURL" class="requirementRingData__image" />
                </div>
                <span class="text black small-grafica">{{ data.value }}</span>
                <span class="text black small-grafica" style="margin-left: auto;">{{ ((data.value*100) / temporalDataOldGraphs.byRequirementsD.rows.reduce((sum, objeto) => sum + objeto.value, 0)).toFixed(2) }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="general-metrics-layer__container__charts-general__card" v-if="params.activeSelectorClient == 1">
      <h2>Reservas con tipo de cliente</h2>
      <!-- <div class="col-3">
        <column-chart
          :colors="colors2"
          :data="temporalDataOldGraphs.byClientTypeDGeneral"
          :library="{ legend:{ verticalAlign: 'top', symbolRadius:3,symbolWidth:40}}"
          class="graph"
        ></column-chart>
      </div> -->
    </div>
    <div class="general-metrics-layer__container__charts-general__card" v-if="sectionId !== 4 && sectionId !== 3">
      <h2>Mesas más reservadas</h2>
      <!-- <TablesChart
        :sectionId="sectionId"
        v-if="(temporalDataOldGraphs.metricReservations.length > 0)"
      ></TablesChart> -->
      <HeatMapChartPrec :sectionId="sectionId" />
    </div>
    <div class="general-metrics-layer__container__charts-general__card">
      <h2>País de origen de la reserva</h2>
      <MapChart :sectionId="sectionId" v-if="(sectionId !== 4 && temporalDataOldGraphs.byCountry && temporalDataOldGraphs.byCountry.length > 0)"></MapChart>
      <MapChartGeneral
        :sectionId="sectionId"
        v-if="(sectionId === 4 && ((Array.isArray(temporalDataOldGraphs.byCountryReservations) && temporalDataOldGraphs.byCountryReservations.length > 0)))"
      ></MapChartGeneral>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BarChartPrec from '@/components/MetricsV2/Charts/BarChartPrec';
import PieChartPrec from '@/components/MetricsV2/Charts/PieChartPrec';
import DonutChartPrec from '@/components/MetricsV2/Charts/DonutChartPrec';
import AreaChartLinePrec from '@/components/MetricsV2/Charts/AreaChartLinePrec';
import LineChartPrec from '@/components/MetricsV2/Charts/LineChartPrec';
import HeatMapChartPrec from '@/components/MetricsV2/Charts/HeatMapChartPrec';
import MapChart from '@/components/Metrics/MapChart';
import MapChartGeneral from '@/components/Metrics/MapChartGenerals';
export default {
  name: 'GeneralCharts',
  props: [
    'sectionId',
    'peoplePerDay',
    'dataConventionsPerDay',
    'peoplePerGroup',
    'dataConventionsPerGroup',
    'peoplePerTime',
    'typeByDay',
    'typeByRange',
    'walkingData',
    'generalData',
    'temporalDataOldGraphs'
  ],
  components: {
    BarChartPrec,
    PieChartPrec,
    DonutChartPrec,
    AreaChartLinePrec,
    LineChartPrec,
    HeatMapChartPrec,
    MapChart,
    MapChartGeneral
  },
  data () {
    this.chartSettings2 = {
      area: true
    };
    this.chartSettings3 = {
      area: false,
      curve: false
    };
    this.chartSettings = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '50', '80'
      ],
      offsetY: 175
    };
    this.chartExtend = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const people = this.temporalDataOldGraphs.notEffectiveD.rows.find((data) => data.name === params.name)?.people;
          return `${params.marker} ${params.name}: ${params.value} reservas, personas ${people} (${params.percent}%)`;
        }
      }
    };
    this.chartSettings4 = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '50', '80'
      ],
      offsetY: 180
    };
    this.chartSettingsPie = {
      area: true,
      dimension: 'name',
      metrics: 'value',
      radius: [
        '0', '80'
      ],
      offsetY: 175
    };
    this.vRing = {
      radius: [40, 100],
      offsetY: 200
    };
    return {
      typeNotEffective: 1,
      typeByTransaction: 1,
      decimals: 1,
      loadedFull: false,
      colors: ['#00ADC6', '#82888E'],
      colorsArea: ['#00ADC6', '#CAD300', '#9453cb'],
      colors2: ['rgba(89, 87, 93, 1)', 'rgba(89, 196, 213, 1)', 'rgba(207, 37, 125, 1)', 'rgba(60, 81, 147, 1)', 'rgba(10, 213, 97, 1)', 'rgba(60, 147, 104, 1)', 'rgba(202, 211, 0, 1)', 'rgba(89, 87, 93, 1)', 'rgba(89, 196, 213, 1)', 'rgba(207, 37, 125, 1)'],
      colors3: ['#00B0F0', '#FD7403', '#FF0000', '#0070C0', '#FFFF00', '#00FF00', 'FF3399', '#008000', '#000066', '#000000'],
      colorsReservations: ['#7CDAE8', '#00ADC6'],
      colorsWalkin: ['#D1A7F0', '#8A51B4']
    };
  },
  computed: {
  },
  mounted () {
    setTimeout(() => {
      this.loadedFull = true;
    }, 2000);
  },
  beforeDestroy () {
  },
  methods: {
    getTypeText () {
      switch (this.sectionId) {
        case 1:
          return 'reservas';
        case 2:
          return 'precompras';
        case 3:
          return 'walk-In';
        case 4:
          return 'Reservas - Precompras - Walk-In';
        default:
          break;
      }
    },
    getTotalsByType (list, type) {
      const total = _.sumBy(list, (data) => {
        if (typeof data[type] === 'string') {
          return parseInt(data[type]);
        }
        return data[type];
      });
      return total;
    },
    getChartColors (data) {
      const newData = (data.rows) ? data.rows : data;
      if (!Array.isArray(newData)) {
        return [];
      }
      const colors = newData.map(data => {
        return data.color;
      });
      this.colors3 = colors;
      return colors;
    },
    changeFilter (graph, value) {
      this.$emit('changeFilter', graph, value);
    }
  },
  watch: {
    typeNotEffective (value) {
      this.$emit('changeFilter', 'noEffective', value);
    },
    typeByTransaction (value) {
      this.$emit('changeFilter', 'transaction', value);
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/metrics/_styles_metrics_old";
</style>
