<template>
    <div id="download-walkin">
        <div class="header">
            <span class="tittle">Generar reporte de Walk-In</span>
        </div>
        <div class="dates">
            <b-field label="Fecha inicio">
                <datetime-vue v-model="fechaIn" input-id="datetime2" value-zone="America/Bogota" format="yyyy-MM-dd"></datetime-vue>
            </b-field>
            <b-field label="Fecha fin">
                <datetime-vue v-model="fechaOut" input-id="datetime3" value-zone="America/Bogota" format="yyyy-MM-dd"></datetime-vue>
            </b-field>
        </div>
        <div class="btn" @click="generateReport()"> <i v-if="download" class="fa fa-spinner fa-spin" style="margin-right:5px"></i> <span>Generar</span></div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'ModalDownloadWalkin',
  data () {
    return {
      fechaIn: null,
      fechaOut: null,
      urlDescarga: null,
      typeUrlDescarga: null,
      download: false
    };
  },
  methods: {
    generateReport () {
      this.$store.dispatch({ type: 'searchs:generateReportWalkIn', data: { vendorId: this.encrypt(this.vendor.id), dateIn: this.fechaIn, dateOut: this.fechaOut } }).then((response) => {
        if (response.code === 200) {
          this.urlDescarga = response.urlApi;
          this.typeUrlDescarga = response.type;
          this.isDownload();
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'fechas incorrectas',
            position: 'is-top',
            type: 'is-danger'
          });
        }
      }).catch((err) => {
        console.error(err);
        this.$buefy.toast.open({
          duration: 5000,
          message: err,
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    isDownload () {
      this.download = true;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + this.urlDescarga,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        const year = this.$moment().year();
        const hour = this.$moment().hours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = this.$moment().minutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = this.$moment().seconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = this.$moment().month() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = this.$moment().date();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeUrlDescarga === 'xls') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.xlsx';
          link.click();
          this.download = false;
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.pdf';
          link.click();
          this.download = false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
#download-walkin{
    background: #ffffff;
    height: 200px;
    width: 400px;
    border-radius: 8px 8px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header{
        background: #444b57;
        width: 100%;
        height: 50px;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tittle{
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
        }

    }
    .dates{
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 10px;
        .label{
            text-align: center;
        }
        .vdpComponent {
            input {
                padding-right: 0px;
                border: 1px solid lightgray;
                border-radius: 5px;
                width: 140px;
                text-align: center;
            }
        }
    }
    .btn{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #707070;
    color: white;
    background-color: #444b57;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 30px 8px 30px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    }
}
</style>
