<template>
  <div id="onboarding">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <Sidenav @change-full="toggle"></Sidenav>
    <div class="header-onboarding">
      <ConnectionNotificationSearch @openSideBar="openSideBar" :notShow="true" />
    </div>
    <div class="content-wrap general-container-onboarding">
      <div class="container-aux">
        <div class="general-container-onboarding__cards-container">
          <SectionsOnboarding
            @changeSection="changeSectiontypeSection('new')"
            :carousels="carousels"
            :typeSection="typeSection"
            title="Lo nuevo"
            description="Te contamos que tenemos de nuevo para ti"
            icon="icon_new_onboarding"
            :iconActive="`${typeSection === 'new' ? 'icon-active-onboarding' : ''}`"
            :sectionActive="{'general-container-onboarding__cards-container__section--active' : typeSection === 'new'}"
            :countActive="typeSection === 'new'"
            :somethingNew="isNewOnboardingByTypeNew"
          />
          <SectionsOnboarding
            @changeSection="changeSectiontypeSection('best')"
            :carousels="carousels"
            :typeSection="typeSection"
            title="Lo mejorado"
            description="Te contamos lo que hemos mejorado."
            icon="icon_improve_onboarding"
            :iconActive="`${typeSection === 'best' ? 'icon-active-onboarding' : ''}`"
            :sectionActive="{'general-container-onboarding__cards-container__section--active' : typeSection === 'best'}"
            :countActive="typeSection === 'best'"
            :somethingNew="isNewOnboardingByTypeBest"
          />
          <SectionsOnboarding
            @changeSection="changeSectiontypeSection('other')"
            :carousels="carousels"
            :typeSection="typeSection"
            title="Otros servicios/<br>integraciones"
            description="Te contamos las nuevas integraciones."
            icon="icon_other_services_onboarding"
            :iconActive="`${typeSection === 'other' ? 'icon-active-onboarding' : ''}`"
            :sectionActive="{'general-container-onboarding__cards-container__section--active' : typeSection === 'other'}"
            :countActive="typeSection === 'other'"
            :somethingNew="isNewOnboardingByTypeOther"
          />
        </div>
        <div class="general-container-onboarding__slider">
          <div class="ribbon-precompro-new" v-if="isNewOnboarding">
            <div class="ribbon-precompro-new__content">
              <div>New</div>
            </div>
          </div>
          <!-- <i class="icon-mks icon_tag_new_onboarding" v-if="isNewOnboarding">New</i> -->
          <h2>{{titleOnboarding}}</h2>
          <b-carousel :autoplay="false" :arrow="false" v-model="carouselItem" @change="changeWithSlideCarousel" v-if="!reloadCarousel">
            <b-carousel-item v-for="(carousel, i) in carousels" :key="i" >
              <div class="general-container-onboarding__slider__container-imgs">
                <img :src="carousel.urlImage"  alt="" srcset="">
              </div>
            </b-carousel-item>
          </b-carousel>
          <!-- <div class="carousel" style="min-height:0px!important;top:-30px;">
            <div class="carousel-indicator is-inside is-bottom">
              <a v-for="(item, index) in carousels" :key="index"
                :class="{ 'indicator-item': true, 'is-active': index === carouselItem}"
                @click="carouselItem = index">
                <span class="indicator-style is-dots"></span>
              </a>
            </div>
          </div> -->
          <p>{{descriptionOnboarding}}</p>
          <div class="general-container-onboarding__slider__actions">
            <b-button class="general-container-onboarding__slider__actions--previous" v-if="carouselItem > 0" @click="() => nextPreviousCarouselItem('prev')">Anterior</b-button>
            <b-button class="general-container-onboarding__slider__actions--next" v-if="carousels.length > 1 && carouselItem < carousels.length - 1" @click="() => nextPreviousCarouselItem('next')">Siguiente</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidenav from '@/components/_shared/Sidenav';
import ConnectionNotificationSearch from '@/components/_shared/ConnectionNotificationSearch';
import SectionsOnboarding from '@/components/Onboarding/SectionsOnboarding';
import globalTypes from '@/store/types/global';
import serviceAdmin from '@/helpers/apis/serviceAdmin';

export default {
  name: 'OnboardingVue',
  components: {
    Sidenav,
    ConnectionNotificationSearch,
    SectionsOnboarding
  },
  data () {
    return {
      carousels: [],
      typeSection: 'new', // 1 lo nuevo, 2 lo mejorado, 3 otros servicios
      carouselItem: 0,
      isLoading: false,
      titleOnboarding: '',
      descriptionOnboarding: '',
      isNewOnboarding: false,
      isNewOnboardingByTypeNew: false,
      isNewOnboardingByTypeBest: false,
      isNewOnboardingByTypeOther: false,
      reloadCarousel: false
    };
  },
  mounted () {
    const urlParamsType = this.$route.query.typeOnboarding ? this.$route.query.typeOnboarding : null;
    if (urlParamsType) {
      this.typeSection = urlParamsType;
    }
    this.getGeneralDataByTypesForNew();
  },
  methods: {
    changeWithSlideCarousel (index) {
      if (index < 0) {
        this.carouselItem = 0;
      } else {
        this.carouselItem = index;
      }
    },
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    nextPreviousCarouselItem (type) {
      if (type === 'next') {
        if (this.carouselItem + 1 < this.carousels.length) {
          this.carouselItem++;
        }
      } else {
        if (this.carouselItem + 1 <= this.carousels.length) {
          this.carouselItem--;
        }
      }
    },
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    getAllDataOnboarding () {
      this.isLoading = true;
      this.carousels = [];
      this.carouselItem = 0;
      serviceAdmin.get(`/onboarding?type=${this.typeSection}`).then(({ data }) => {
        this.carousels = data.filter((onboarding) => onboarding.isActive);
        this.titleOnboarding = '';
        this.descriptionOnboarding = '';
        this.isNewOnboarding = false;
        if (this.carousels.length > 0) {
          this.titleOnboarding = this.carousels[this.carouselItem].title;
          this.descriptionOnboarding = this.carousels[this.carouselItem].description;
          this.getValidationTagNew(this.carousels[this.carouselItem].created_at);
          const urlParamsId = this.$route.query.onboarding ? parseInt(this.$route.query.onboarding) : null;
          if (urlParamsId) {
            this.reloadCarousel = true;
            const index = this.carousels.findIndex((item) => item.id === urlParamsId);
            this.changeWithSlideCarousel(index);
            setTimeout(() => {
              this.reloadCarousel = false;
            }, 500);
          }
        }
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        console.log(error);
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    getValidationTagNew (date, returnValue = false) {
      const creationDate = this.$moment(date).format('YYYY/MM/DD');
      const actualDate = this.$moment().format('YYYY/MM/DD');
      const days = this.$moment(actualDate).diff(this.$moment(creationDate), 'days');
      this.isNewOnboarding = false;
      if (days <= 30) {
        if (!returnValue) {
          this.isNewOnboarding = true;
        }
        return true;
      } else {
        if (!returnValue) {
          this.isNewOnboarding = false;
        }
        return false;
      }
    },
    getGeneralDataByTypesForNew () {
      this.isLoading = true;
      serviceAdmin.get('/onboarding').then(({ data }) => {
        const generalData = data.filter((onboarding) => onboarding.isActive);
        // Inicializar las variables en un solo objeto
        const onboardingTypes = {
          new: 'isNewOnboardingByTypeNew',
          best: 'isNewOnboardingByTypeBest',
          other: 'isNewOnboardingByTypeOther'
        };

        // Resetear los valores a false
        Object.keys(onboardingTypes).forEach(type => {
          this[onboardingTypes[type]] = false;
        });

        // Verificar y actualizar según el tipo y la validación
        generalData.forEach(onboarding => {
          const typeKey = onboardingTypes[onboarding.type];
          if (typeKey && this.getValidationTagNew(onboarding.created_at, true)) {
            this[typeKey] = true;
          }
        });
        this.isLoading = false;
        // ejecuta de nuevo la petición de onboarding pero solo del tipo por defecto pusto en el state typeSection, en éste caso, trae todos los datos del type New
        this.getAllDataOnboarding();
      }).catch((error) => {
        this.isLoading = false;
        console.log(error);
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    changeSectiontypeSection (type) {
      this.typeSection = type;
      this.carouselItem = 0;
    }
  },
  watch: {
    typeSection () {
      this.getAllDataOnboarding();
    },
    carouselItem (value) {
      if (this.carousels.length > 0 && value > 0) {
        this.titleOnboarding = this.carousels[value].title;
        this.descriptionOnboarding = this.carousels[value].description;
        this.getValidationTagNew(this.carousels[this.carouselItem].created_at);
      }
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/onboarding/_index";
</style>
