<template>
  <div id="infoUser">
    <div class="header_info">
      <div class="name_user">
        <label class="display_name ffr"> {{ dataUser.name }} </label>
        <div class="user_vip" v-if="dataUser.isVip">
          <span class="ffr">VIP</span>
        </div>
      </div>
      <i class="icons icon_close_modal" @click="closeInfoUser()"></i>
    </div>
    <div class="content_detail">
      <div class="left_side">
        <div class="indicator_user_info"></div>
        <div class="title_left">
          <i class="icons icon_total_users"></i>
          <span class="text_title ffr">Datos del usuario</span>
        </div>
        <div class="info_client">
          <div class="info_phone">
            <div class="container_icon">
              <i class="icons icon_phone"></i>
            </div>
            <div class="container_texts">
              <img :src="bandera" class="flag_user">
              <span class="texts_details phone ffr">
                ({{ dataUser.indicativoUser !== null ? dataUser.indicativoUser : '' }}) {{ dataUser.phone !== null && dataUser.phone !== '' ? dataUser.phone : 'N/A' }}
              </span>
            </div>
          </div>
          <div class="info_mcd">
            <div class="container_icon">
              <i class="icons icon_mail"></i>
            </div>
            <div class="container_texts">
              <span class="texts_details ffr">
                {{ dataUser.email !== null &&  dataUser.email !== ''? dataUser.email : 'N/A' }}
              </span>
            </div>
          </div>
          <div class="info_mcd" v-if="params.activeBirthDate">
            <div class="container_icon">
              <i class="icons icon_calendar"></i>
            </div>
            <div class="container_texts">
              <span class="texts_details ffr" v-if="dataUser.birthday !== undefined && dataUser.birthday !== null">
                {{ dataUser.birthday | moment('MMMM DD') }} de {{ dataUser.birthday | moment('YYYY') }}
              </span>
              <span class="texts_details ffr" v-else>
                N/A
              </span>
            </div>
          </div>
          <div class="info_mcd">
            <div class="container_icon">
              <i class="icons icon_document"></i>
            </div>
            <div class="container_texts">
              <span class="texts_details ffr">
                {{ dataUser.identityDocument !== null && dataUser.identityDocument !== undefined ? dataUser.identityDocument : 'N/A' }}
              </span>
            </div>
          </div>
        </div>
        <div class="edit_user" @click="openEdit()">
          <i class="icons icon_edit_reservation"></i>
          <span class="text_edit_user ffr">Editar datos de usuario</span>
        </div>
        <div class="type_client">
          <span class="text_type ffr">
            Cliente:
            <strong>{{ dataUser.visits > 1 ? 'Recurrente' : 'Nuevo' }}</strong>
          </span>
        </div>
        <div class="calification_client">
          <span class="text_calification ffr">Calificación del comensal</span>
          <b-rate class="stars_client" v-model="scoreClient" icon-pack="fa" disabled></b-rate>
        </div>
        <div class="notes_tags_client">
          <div class="title_nt tag_add">
            <i class="icons icon_add_tag" @click="modalTags = true"></i>
            <span class="fs14 ffr" @click="modalTags = true">Tags</span>
          </div>
          <div class="client_tags">
            <div class="client_tag" v-for="(tag, i) in userTags" :key="i">
              <div>
                <i class="icons icon_tag"></i>
              </div>
              <span class="client_texts ffr">{{ tag }}</span>
              <div>
                <i class="icons icon_close_modal_tag" @click="deleteNT('tag', i)"></i>
              </div>
            </div>
          </div>
          <div class="title_nt notes">
            <i class="icons icon_add_notes" style="cursor: default;"></i>
            <span class="fs14 ffr" style="cursor: default;">Notas</span>
          </div>
          <div class="notas-cont" :style="{marginTop: '14px'}">
            <textarea class="notes-input" v-model="note" @change="changeNote()" placeholder="Notas" maxlength="250"></textarea>
          </div>
        </div>
      </div>
      <div class="right_side">
        <div class="indicator_user_historic"></div>
        <div class="header_historic">
          <div class="title_historic">
            <i class="icons icon_historic"></i>
            <span class="tt_historic ffr">Histórico</span>
          </div>
          <div class="subtitle_historic">
            <span class="fs_historic ffr">Información historia de las reservas</span>
            <span class="ss_historic ffr">A continuación se listaran las reservas realizadas por este usuario:</span>
          </div>
        </div>
        <div class="content_historic">
          <div class="section_reservation" :class="{'only_one' : statisticsUser.totalPrecompra == 0 && statisticsUser.totalWalkin == 0}" v-if="statisticsUser.totalReserva > 0">
            <div class="reservation_table">
              <div class="header_table">
                <div class="header_id">
                  <span class="title_table main ffr">Id Reserva</span>
                </div>
                <div class="header_date">
                  <span class="title_table ffr">Fecha</span>
                </div>
                <div class="header_efective">
                  <span class="title_table ffr">Efectivas</span>
                </div>
                <div class="header_duration">
                  <span class="title_table ffr">Duración</span>
                </div>
              </div>
              <div class="content_table" :class="{'only_one' : statisticsUser.totalPrecompra == 0 && statisticsUser.totalWalkin == 0}" v-if="statisticsUser.reservationList">
                <div class="registers_table" v-for="(res, i) in statisticsUser.reservationList" :key="i">
                  <div class="content_id">
                    <div class="border_left"></div>
                    <span class="text_table ffr">{{ res.id }}</span>
                  </div>
                  <div class="content_date">
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('YYYY/MM/DD') }}</span><br>
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('HH:mm:ss') }}</span>
                  </div>
                  <div class="content_efective">
                    <span class="text_table ffr">{{ (res.isFinish !== null &&  res.isFinish > 0) ? 1 : 0 }}</span>
                  </div>
                  <div class="content_duration">
                    <span class="text_table ffr">{{ durationReservation(res.isFinish, res.isSeated) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="reservation_metrics" :class="{'only_one' : statisticsUser.totalPrecompra == 0 && statisticsUser.totalWalkin == 0}">
              <div class="content_title_metrics">
                <span class="title_metrics ffr">Total de reservas: <strong>{{ statisticsUser.totalReserva }}</strong></span>
              </div>
              <div class="container_radials">
                <div class="radial_users show">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalFreeFinished, statisticsUser.totalReserva)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#54d401"
                    stop-color="#54d401"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalFreeFinished, statisticsUser.totalReserva)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">Llegó</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalFreeFinished}}/{{statisticsUser.totalReserva}}</span>
                  </div>
                </div>
                <div class="radial_users no_show">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalFreeNoShow, statisticsUser.totalReserva)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#ff7001"
                    stop-color="#ff7001"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalFreeNoShow, statisticsUser.totalReserva)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">No Llegó</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalFreeNoShow}}/{{statisticsUser.totalReserva}}</span>
                  </div>
                </div>
                <div class="radial_users canceled">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalFreeCancel, statisticsUser.totalReserva)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#ff0101"
                    stop-color="#ff0101"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalFreeCancel, statisticsUser.totalReserva)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">Canceladas</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalFreeCancel}}/{{statisticsUser.totalReserva}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section_precompro" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalWalkin == 0}" v-if="statisticsUser.totalPrecompra > 0">
            <div class="precompro_table">
              <div class="header_table precompro">
                <div class="header_id">
                  <span class="title_table main ffr">Id Reserva</span>
                </div>
                <div class="header_date">
                  <span class="title_table ffr">Fecha</span>
                </div>
                <div class="header_efective">
                  <span class="title_table ffr">Efectivas</span>
                </div>
                <div class="header_duration">
                  <span class="title_table ffr">Duración</span>
                </div>
              </div>
              <div class="content_table" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalWalkin == 0}" v-if="statisticsUser.precomproList">
                <div class="registers_table" v-for="(res, i) in statisticsUser.precomproList" :key="i">
                  <div class="content_id">
                    <div class="border_left precompro"></div>
                    <span class="text_table ffr">{{ res.id }}</span>
                  </div>
                  <div class="content_date">
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('YYYY/MM/DD') }}</span><br>
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('HH:mm:ss') }}</span>
                  </div>
                  <div class="content_efective">
                    <span class="text_table ffr">{{ (res.isFinish !== null &&  res.isFinish > 0) ? 1 : 0 }}</span>
                  </div>
                  <div class="content_duration">
                    <span class="text_table ffr">{{ durationReservation(res.isFinish, res.isSeated) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="precompro_metrics" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalWalkin == 0}">
              <div class="content_title_metrics precompro">
                <div>
                  <span class="title_metrics ffr">Total de precompras: <strong>{{ statisticsUser.totalPrecompra }}</strong></span>
                </div>
                <div>
                  <span class="title_metrics ffr">Total <strong>{{ statisticsUser.totalBalance | currency('$ ', 0, { thousandsSeparator: '.' }) }}</strong></span>
                </div>
              </div>
              <div class="container_radials">
                <div class="radial_users show">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalPayFinished, statisticsUser.totalPrecompra)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#54d401"
                    stop-color="#54d401"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalPayFinished, statisticsUser.totalPrecompra)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">Llegó</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalPayFinished}}/{{statisticsUser.totalPrecompra}}</span>
                  </div>
                </div>
                <div class="radial_users no_show">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalPayNoShow, statisticsUser.totalPrecompra)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#ff7001"
                    stop-color="#ff7001"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalPayNoShow, statisticsUser.totalPrecompra)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">No Llegó</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalPayNoShow}}/{{statisticsUser.totalPrecompra}}</span>
                  </div>
                </div>
                <div class="radial_users canceled">
                  <radial-progress-bar
                    :diameter="51"
                    :completed-steps="percentReservation(statisticsUser.totalPayCancel, statisticsUser.totalPrecompra)"
                    :total-steps="100"
                    :innerStrokeWidth="5"
                    :innerStrokeColor="'#ededed'"
                    :strokeWidth="5"
                    start-color="#ff0101"
                    stop-color="#ff0101"
                    >
                    <p class="text_in_radial ffr">{{percentReservationInt(statisticsUser.totalPayCancel, statisticsUser.totalPrecompra)}}%</p>
                  </radial-progress-bar>
                  <div class="info_circles">
                    <span class="title_circle ffr">Canceladas</span><br>
                    <span class="value_circle ffr">{{statisticsUser.totalPayCancel}}/{{statisticsUser.totalPrecompra}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section_walk" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalPrecompra == 0}" v-if="statisticsUser.totalWalkin > 0">
            <div class="walk_table">
              <div class="header_table walk">
                <div class="header_id walk">
                  <span class="title_table main ffr">Id Reserva</span>
                </div>
                <div class="header_date walk">
                  <span class="title_table ffr">Fecha</span>
                </div>
                <div class="header_duration walk">
                  <span class="title_table ffr">Duración</span>
                </div>
              </div>
              <div class="content_table" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalPrecompra == 0}" v-if="statisticsUser.walkList">
                <div class="registers_table" v-for="(res, i) in statisticsUser.walkList" :key="i">
                  <div class="content_id walk">
                    <div class="border_left walk"></div>
                    <span class="text_table ffr">{{ res.id }}</span>
                  </div>
                  <div class="content_date walk">
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('YYYY/MM/DD') }}</span><br>
                    <span class="text_table ffr">{{ res.fechaCompleta | moment('HH:mm:ss') }}</span>
                  </div>
                  <div class="content_duration walk">
                    <span class="text_table ffr">{{ durationReservation(res.isFinish, res.isSeated) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="walk_metrics" :class="{'only_one' : statisticsUser.totalReserva == 0 && statisticsUser.totalPrecompra == 0}">
              <span class="title_walk ffr">Total Efectivas</span>
              <span class="text_walk ffr">Walk-In {{statisticsUser.totalWalkFinished}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="modalTags" :can-cancel="false">
      <div class="card_nt">
        <div class="card_header">
          <i class="icons icon_close_modal_card" @click="modalTags = false"></i>
          <span class="title_card ffr">Añadir tag</span>
        </div>
        <div class="card_content">
          <div class="input_card">
            <input type="text" class="inputs_card" placeholder="Escribir tag" v-model="tag">
          </div>
          <div class="btn_card">
            <button class="btn_add_card" @click="addNT('tags')">Agregar</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import _ from 'lodash';
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import RadialProgressBar from 'vue-radial-progress';
import editUserModal from './editUserModal';
import globalTypes from '@/store/types/global';

export default {
  name: 'InfoUser',
  components: { RadialProgressBar },
  props: ['dataUser'],
  data () {
    return {
      localDataUser: { ...this.dataUser },
      bandera: null,
      paises: null,
      scoreClient: 0,
      userTags: [],
      modalTags: false,
      note: '',
      tag: '',
      statisticsUser: {
        reservationList: []
      }
    };
  },
  mounted () {
    this.statisticsPerson();
    this.listPaises();
    if (this.localDataUser.indicativoUser === null || this.localDataUser.indicativoUser === undefined || this.localDataUser.indicativoUser === '') {
      this.localDataUser.indicativoUser = this.dataUser.indicative;
    }
    this.findFlag(parseInt(this.localDataUser.indicativoUser));
    this.calculateScore();
    this.userTags = this.localDataUser.tags;
    this.note = this.localDataUser.notes;
  },
  methods: {
    closeInfoUser () {
      this.$emit('close');
      this.$parent.close();
    },
    openEdit () {
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: editUserModal,
        hasModalCard: true,
        canCancel: false,
        props: {
          dataUser: this.localDataUser
        },
        events: {
          updateUser () {
            thes.$emit('loadUsers');
            thes.findFlag(parseInt(thes.dataUser.indicativoUser));
          }
        }
      });
    },
    updateUser () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const dataUpdate = {
        userId: this.localDataUser._id,
        email: this.localDataUser.email,
        phone: this.localDataUser.phone,
        identityDocument: this.localDataUser.identityDocument,
        indicative: parseInt(this.localDataUser.indicative),
        flagLabel: this.localDataUser.flagLabel,
        tags: this.userTags,
        notes: this.note
      };
      this.$store.dispatch({
        type: 'people:update',
        data: dataUpdate
      }).then((data) => {
        if (parseInt(data.code) === 200) {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i>Datos actualizados',
            type: 'is-sucess'
          });
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        }
      });
    },
    listPaises () {
      var data = [];
      var ind = this.localDataUser.indicative != null ? this.localDataUser.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else if (item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    findFlag (indicative) {
      const option = this.paises.find(e => e.value === indicative);
      if (option !== undefined) {
        let aux2 = null;
        const aux3 = option.label;
        _.forEach(BanderaPais, function (item) {
          if (indicative !== null && indicative !== undefined) {
            const data = item.country + ' ( +' + indicative + ' )';
            if (data === aux3) {
              aux2 = item.abbreviation.toLowerCase();
            }
          }
        });
        this.bandera = aux2 != null ? '/dashboard/banderas/' + aux2 + '.png' : null;
      }
    },
    calculateScore () {
      let score = 0;
      if (this.localDataUser.reservations.length > 0) {
        this.localDataUser.reservations.forEach(e => {
          if (e.score !== null) {
            score += e.score;
          }
        });
        score = score / this.localDataUser.finished;
        this.scoreClient = score;
      }
    },
    deleteNT (type, i) {
      if (type === 'tag') {
        this.userTags.splice(i, 1);
        this.updateUser();
        return;
      }
      this.updateUser();
    },
    addNT (type) {
      if (type === 'tags') {
        this.userTags.push(this.tag);
        this.tag = '';
        this.modalTags = false;
        this.updateUser();
        return;
      }
      this.updateUser();
    },
    statisticsPerson () {
      this.$store.dispatch({ type: 'people:getStatisticsOneUser', data: { id: this.localDataUser._id } }).then((data) => {
        this.statisticsUser = data.data;
      });
    },
    durationReservation (finish, seated) {
      if (finish !== null && seated !== null) {
        let difference = parseInt(finish) - parseInt(seated);
        const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;
        const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;
        const minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        let days = '00';
        let hours = '00';
        let minutes = '00';
        if (daysDifference > 0) {
          days = daysDifference;
        }
        if (hoursDifference >= 0) {
          hours =
            hoursDifference <= 9 ? '0' + hoursDifference : hoursDifference;
        }
        if (minutesDifference >= 0) {
          minutes =
            minutesDifference <= 9
              ? '0' + minutesDifference
              : minutesDifference;
        }

        if (days > 0) {
          return days + ' dia(s) con ' + hours + ':' + minutes;
        } else {
          return hours + ':' + minutes;
        }
      }
      return '00:00';
    },
    percentReservationInt (number, total) {
      if (total === 0) {
        return 0;
      }
      const percent = (number * 100) / total;
      return parseInt(percent);
    },
    percentReservation (number, total) {
      if (total === 0) {
        return 0;
      }
      const percent = (number * 100) / total;
      return parseFloat(percent.toFixed(2));
    },
    changeNote () {
      this.updateUser();
    }
  }
};
</script>

<style lang="scss">
@use '../../../assets/styles/users/infoUser.scss';
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
</style>
