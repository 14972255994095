<template>
  <div id="sectionCreateEditCategory">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="section-create-edit-category">
      <div class="section-create-edit-category__actions">
        <div class="section-create-edit-category__actions--back">
          <div @click="$emit('changeStatusSectionCreateEditCategories', false)" style="cursor: pointer;">
            <i class="icon-mks icon_arrow_down_general"></i>
            <div v-if="idMenuMultipleForCategoriesProducts" :style="`background-image: url(${bgImageMenu ? bgImageMenu.img : ''}); background-size: cover;background-position: center;`">{{bgImageMenu ? bgImageMenu.name : ''}}</div>
          </div>
          <div>Categoría/<b>{{dataFormCategory.name}}</b></div>
        </div>
        <b-button v-if="type === 'edit'" @click="executeModalCreateSubCategory">Crear subcategoría</b-button>
      </div>
      <section class="section-create-edit-category__first-info" :class="{'section-create-edit-category__first-info__menu-selected': !idMenuMultipleForCategoriesProducts, 'section-create-edit-category__first-info__menu-selected-with-btn' : dataFormCategory.vendorMenuId}">
        <SelectLanguages type="" @changeTypeLanguage="changeTypeLanguage" :notGlobal="true" />
        <div class="section-create-edit-category__first-info__menu-selector" v-if="!idMenuMultipleForCategoriesProducts">
          <div>Menú</div>
          <div v-if="!dataFormCategory.vendorMenuId" class="section-create-edit-category__first-info__menu-selector__select" @click="executeModalSelectMenuForCategory">Selecciona un menú</div>
          <button v-else class="section-create-edit-category__first-info__menu-selector__button" :style="`background: url(${dataFormCategory.backgroundImage}) lightgray 50% / cover no-repeat;`">{{dataFormCategory.vendorCustomName}}</button>
          <div @click="executeModalSelectMenuForCategory">
            <i v-if="!dataFormCategory.vendorMenuId" class="icon-mks icon_plus_alternative"></i>
            <i v-else class="icon-mks icon_edit"></i>
          </div>
        </div>
        <div>
          <label>Total productos</label>
          <b>{{dataFormCategory.products ? dataFormCategory.products : '--'}}</b>
        </div>
        <div>
          <label>Orden</label>
          <b-input type="number" v-model="dataFormCategory.order" class="general-inputs general-inputs-order-center" />
        </div>
        <div>
          <label for="">Activa</label>
          <b-checkbox v-model="dataFormCategory.active" :true-value="1" :false-value="0" />
        </div>
        <div>
          <label for="">Recomendado</label>
          <b-checkbox v-model="dataFormCategory.recommended" :true-value="1" :false-value="0" />
        </div>
      </section>
      <section class="section-create-edit-category__second-info">
        <div :class="`${type === 'create' ? 'create-section-category-products-aux':'edit-section-category-products-aux'}`">
          <section class="section-create-edit-category__second-info__category">
            <div class="section-create-edit-category__second-info__category__container-img-category">
              <b-upload
                accept="image/*"
                @input="fileUpload(fileImageCategory, 'imageCategory')"
                class="file-label section-create-edit-category__second-info__category__container-img-category__upload"
                v-model="fileImageCategory"
              >
                <div
                  class="section-create-edit-category__second-info__category__container-img-category__img"
                  :class="{'section-create-edit-category__second-info__category__container-img-category__img__flex-end' : dataFormCategory.imageCategory}"
                  :style="`background-image: url('${dataFormCategory.imageCategory}')`"
                >
                  <div class="section-create-edit-category__second-info__category__container-img-category__img--change" v-if="dataFormCategory.imageCategory">Cambiar imagen</div>
                  <p v-if="!dataFormCategory.imageCategory">Subir imagen</p>
                </div>
              </b-upload>
              <div class="section-create-edit-category__second-info__field">
                <label for="">Nombre de la categoría</label>
                <b-input placeholder="Escribe el nombre de la categoría" v-model="dataFormCategory.name" class="general-inputs" v-show="typeLang === 'spanish'" />
                <b-input placeholder="Escribe el nombre de la categoría" v-model="dataFormCategory.nameEnglish" class="general-inputs" v-show="typeLang === 'english'" />
                <b-input placeholder="Escribe el nombre de la categoría" v-model="dataFormCategory.nameFrench" class="general-inputs" v-show="typeLang === 'french'" />
              </div>
            </div>
            <div class="section-create-edit-category__second-info__field" >
              <label for="">Descripción de la categoría</label>
              <!-- <vue-editor :editorOptions="editorSettings" v-model="descriptionEditorEs" v-show="typeLang === 'spanish'" /> -->
              <b-input type="textarea" placeholder="Escribe la descripción de la categoría" v-model="dataFormCategory.description" v-show="typeLang === 'spanish'" />
              <b-input type="textarea" placeholder="Escribe la descripción de la categoría" v-model="dataFormCategory.descriptionEnglish" v-show="typeLang === 'english'" />
              <b-input type="textarea" placeholder="Escribe la descripción de la categoría" v-model="dataFormCategory.descriptionFrench" v-show="typeLang === 'french'" />
            </div>
            <div class="section-create-edit-category__second-info__category__image-container-prices">
              <div>
                <label for="">Dos precios</label>
                <b-checkbox v-model="dataFormCategory.twoPrices" :true-value="1" :false-value="0"></b-checkbox>
              </div>
              <div>
                <div>
                  <div>
                    <label for="" class="fix-aux-label">Icono precio derecha</label>
                    <b-upload
                      accept="image/*"
                      @input="fileUpload(fileImagePriceOne, 'priceOne')"
                      class="file-label upload-image-icon-price"
                      v-model="fileImagePriceOne"
                    >
                      Subir imagen
                    </b-upload>
                  </div>
                  <div :style="{'background-image': `url('${getPreviewImageByLang('priceOne')}')`, backgroundSize: 'cover', backgroundPosition: 'center'}"></div>
                </div>
                <div :class="`${dataFormCategory.twoPrices === 0 ? 'inactive-section-prices' : ''}`">
                  <div>
                    <label for="">Icono precio izquierdo</label>
                    <b-upload
                      accept="image/*"
                      @input="fileUpload(fileImagePriceTwo, 'priceTwo')"
                      class="file-label upload-image-icon-price"
                      v-model="fileImagePriceTwo"
                      :disabled="dataFormCategory.twoPrices === 0"
                    >
                      Subir imagen
                    </b-upload>
                  </div>
                  <div :style="{'background-image': `url('${getPreviewImageByLang('priceTwo')}')`, backgroundSize: 'cover', backgroundPosition: 'center'}"></div>
                </div>
              </div>
            </div>
          </section>
          <section class="section-create-edit-category__second-info__container-products">
            <div class="section-create-edit-category__second-info__container-products__image-banner">
              <div>
                <label>Imagen banner</label>
                <b-upload
                  accept="image/*"
                  @input="fileUpload(fileImageBannerUrl, 'imageBanner')"
                  class="file-label section-create-edit-category__second-info__container-products__image-banner--upload"
                  v-model="fileImageBannerUrl"
                >
                  {{dataFormCategory.imageBanner ? 'Cambiar imagen': 'Subir imagen'}}
                </b-upload>
              </div>
              <div class="section-create-edit-category__second-info__container-products__image-banner__file-preview">
                {{fileNameImageBannerUrl}}
              </div>
            </div>
            <div class="section-create-edit-category__second-info__field section-create-edit-category__second-info__list-products">
              <label for="">Productos de la categoría</label>
              <div class="section-create-edit-category__second-info__list-products__container">
                <div class="section-create-edit-category__second-info__list-products__container__searcher">
                  <div class="section-create-edit-category__second-info__list-products__container__searcher--check-massive">
                    <label for="">Activar</label>
                    <b-checkbox @input="executeAlert('all-products', null, 'categoryProduct')" v-model="checkboxProductsCategoryMassive"></b-checkbox>
                  </div>
                  <div class="section-create-edit-category__second-info__list-products__container__searcher--search">
                    <i class="icon-mks icon_search_alternative"></i>
                    <b-input placeholder="Buscar por nombre" v-model="searchProductCategories"/>
                  </div>
                </div>
                <div class="section-create-edit-category__second-info__list-products__container__list">
                  <div class="section-create-edit-category__second-info__list-products__container__list__product" v-for="(product, index) in productsCategory" :key="index">
                    <b-checkbox v-model="checkboxGroupProductsCategory" :native-value="product._id" @input="(value)=>setProductsWithStructure(value, 'category',product._id)">
                      {{getProductNameByLang(product)}}
                    </b-checkbox>
                    <i class="icon-mks icon_trash_alternative" @click="executeAlert('category', product._id)"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-create-edit-category__second-info__actions">
              <button @click="executeModalCreateProducts('category')">
                <i class="icon-mks icon_plus_alternative"></i>
                <div>Crear productos</div>
              </button>
              <button :disabled="checkboxGroupProductsCategory.length === 0" @click="executeAlert('category')">
                <i class="icon-mks icon_trash_alternative"></i>
                <div>Eliminar producto</div>
              </button>
            </div>
          </section>
        </div>
        <section class="section-create-edit-category__second-info__subcategory" v-if="type === 'edit'">
          <div class="section-create-edit-category__second-info__field">
            <label for="">Subcategoría</label>
            <!-- <b-select v-model="dataFormSubCategory._id" class="select-sub-category" placeholder="Seleccione una subcategoría">
              <option disabled v-if="dataFormCategory.children && dataFormCategory.children.length === 0" >No hay subcategorías creadas</option>
              <option :value="category._id" v-for="(category, index) in dataFormCategory.children" :key="index">{{category.name}} </option>
            </b-select> -->
            <div class="select-sub-category" @mouseleave="() => showSubcategoriesList = false">
              <div class="select-sub-category__selector" @click="() => showSubcategoriesList = !showSubcategoriesList">
                <i class="icon-mks icon_arrow_down_general"></i>
                <div v-if="!dataFormSubCategory._id" class="select-sub-category__selector__placeholder">Seleccione una subcategoría</div>
                <div v-if="dataFormSubCategory._id">{{getSubcategoryNameById(dataFormSubCategory._id)}}</div>
              </div>
              <div class="select-sub-category__options" v-show="showSubcategoriesList">
                <div v-if="dataFormCategory.children && dataFormCategory.children.length === 0">No hay subcategorías creadas</div>
                <div v-for="(category, index) in dataFormCategory.children" :key="index">
                  <div @click="selectSubcategoryCustomSelect(category._id)">{{getCategorySubcategoryByLang(category)}}</div>
                  <b-checkbox :value="category.active" :true-value="1" :false-value="0" @input="changeStatusSubcategory(category._id, category.active)"></b-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="section-create-edit-category__second-info__subcategory__details" :style="`${(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null ? 'opacity: 0.5;': ''}`">
            <div class="section-create-edit-category__second-info__subcategory__details__status-subcategory">
              <label for="">Subcategoría activa</label>
              <b-checkbox :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.active" :true-value="1" :false-value="0" />
            </div>
            <div class="section-create-edit-category__second-info__field">
              <label for="">Nombre de la subcategoría</label>
              <b-input :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.name" v-show="typeLang === 'spanish'" class="general-inputs" type="text" />
              <b-input :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.nameEnglish" v-show="typeLang === 'english'" class="general-inputs" type="text" />
              <b-input :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.nameFrench" v-show="typeLang === 'french'" class="general-inputs" type="text" />
            </div>
            <div class="section-create-edit-category__second-info__field">
              <label for="">Descripción de la categoría</label>
              <textarea :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.description" v-show="typeLang === 'spanish'"></textarea>
              <textarea :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.descriptionEnglish" v-show="typeLang === 'english'"></textarea>
              <textarea :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="dataFormSubCategory.descriptionFrench" v-show="typeLang === 'french'"></textarea>
            </div>
            <div class="section-create-edit-category__second-info__field section-create-edit-category__second-info__list-products">
              <label for="">Productos de la subcategoría</label>
              <div class="section-create-edit-category__second-info__list-products__container">
                <div class="section-create-edit-category__second-info__list-products__container__searcher">
                  <div class="section-create-edit-category__second-info__list-products__container__searcher--check-massive">
                    <label for="">Activar</label>
                    <b-checkbox :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" @input="executeAlert('all-products', null, 'subcategoryProduct')"  v-model="checkboxProductsSubCategoryMassive"></b-checkbox>
                  </div>
                  <div class="section-create-edit-category__second-info__list-products__container__searcher--search">
                    <i class="icon-mks icon_search_alternative"></i>
                    <b-input placeholder="Buscar por nombre" :disabled="(dataFormCategory.children && dataFormCategory.children.length === 0) || dataFormSubCategory._id === null" v-model="searchProductSubCategories"/>
                  </div>
                </div>
                <div class="section-create-edit-category__second-info__list-products__container__list">
                  <div class="section-create-edit-category__second-info__list-products__container__list__product" v-for="(product, index) in productsSubCategory" :key="index">
                    <b-checkbox v-model="checkboxGroupProductsSubCategory" :native-value="product._id" @input="(value)=>setProductsWithStructure(value, 'subcategory',product._id)">
                      {{getProductNameByLang(product)}}
                    </b-checkbox>
                    <i class="icon-mks icon_trash_alternative" @click="executeAlert('subcategory', product._id)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-create-edit-category__second-info__actions" v-if="dataFormSubCategory._id !== null">
            <button @click="executeModalCreateProducts('subcategory')">
              <i class="icon-mks icon_plus_alternative"></i>
              <div>Crear productos</div>
            </button>
            <button :disabled="checkboxGroupProductsSubCategory.length === 0" @click="executeAlert('subcategory')">
              <i class="icon-mks icon_trash_alternative"></i>
              <div>Eliminar producto</div>
            </button>
          </div>
        </section>
      </section>
      <section class="section-create-edit-category__main-actions">
        <b-button class="section-create-edit-category__main-actions__save" :disabled="!formIsValid" @click="createEditCategorySubCategory('category')">Guardar cambios</b-button>
        <b-button class="section-create-edit-category__main-actions__close" @click="$emit('changeStatusSectionCreateEditCategories', false)">Cerrar</b-button>
      </section>
    </div>
  </div>
</template>

<script>
import SelectLanguages from '@/components/schedules/menu-qr/complements/SelectLanguages.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import ModalCreateSubCategory from '@/components/schedules/menu-qr/complements/categories/ModalCreateSubCategory.vue';
import ModalSelectMenuForCategories from '@/components/schedules/menu-qr/complements/categories/ModalSelectMenuForCategories.vue';
import CreateEditProduct from '@/components/schedules/menu-qr/complements/products/CreateEditProduct.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
/* import { VueEditor } from 'vue2-editor'; */
export default {
  components: {
    SelectLanguages
  },
  props: ['restaurant', 'idMenuMultipleForCategoriesProducts', 'type', 'dataCategorySelected'],
  data () {
    return {
      isLoading: false,
      typeLang: 'spanish',
      checkboxGroupProductsCategory: [],
      checkboxGroupProductsSubCategory: [],
      checkboxGroupProductsCategoryStructured: [],
      checkboxGroupProductsSubCategoryStructured: [],
      checkboxProductsCategoryMassive: false,
      checkboxProductsSubCategoryMassive: false,
      searchProductCategories: '',
      searchProductSubCategories: '',
      productsCategory: [],
      productsSubCategory: [],
      subCategoryId: null,
      dataFormCategory: {
        _id: null,
        products: null,
        typeLanguage: 'both',
        vendorId: this.restaurant.vendorId,
        vendorMenuId: this.idMenuMultipleForCategoriesProducts,
        name: null,
        nameEnglish: null,
        nameFrench: null,
        parentId: null,
        description: null,
        descriptionEnglish: null,
        descriptionFrench: null,
        active: 1,
        recommended: 0,
        order: 0,
        twoPrices: 0,
        imagePriceOne: null,
        imagePriceOneEnglish: null,
        imagePriceOneFrench: null,
        imagePriceTwo: null,
        imagePriceTwoEnglish: null,
        imagePriceTwoFrench: null,
        imageCategory: null,
        imageBanner: null,
        isImage: null,
        backgroundImage: null,
        menuId: null
      },
      fileImagePriceOne: null,
      fileImagePriceTwo: null,
      fileImageCategory: null,
      fileImageBannerUrl: null,
      fileNameImageBannerUrl: null,
      imagePreviewPriceOne: {
        es: null,
        en: null,
        fr: null
      },
      imagePreviewPriceTwo: {
        es: null,
        en: null,
        fr: null
      },
      descriptionEditorEs: null,
      descriptionEditorEn: null,
      descriptionEditorFr: null,
      editorSettings: {
        placeholder: 'Escribe la descripción de la categoría',
        modules: {
          toolbar: false
        }
      },
      dataFormSubCategory: {
        _id: null,
        name: null,
        nameEnglish: null,
        nameFrench: null,
        description: null,
        descriptionEnglish: null,
        descriptionFrench: null
      },
      timeoutSearcher: null,
      bgImageMenu: null,
      showSubcategoriesList: false
    };
  },
  computed: {
    getCategorySubcategoryByLang () {
      return (data) => {
        if (this.typeLang === 'spanish') {
          return data.name ? data.name : `(ES) ${data.nameEnglish ? data.nameEnglish : ''}`;
        }
        if (this.typeLang === 'english') {
          return data.nameEnglish ? data.nameEnglish : `(IN) ${data.name}`;
        }
        if (this.typeLang === 'french') {
          return data.nameFrench ? data.nameFrench : `(FR) ${data.name}`;
        }
        return null;
      };
    },
    getPreviewImageByLang () {
      return (type = 'priceOne') => {
        if (type === 'priceOne') {
          if (this.typeLang === 'spanish') {
            return this.imagePreviewPriceOne.es;
          }
          if (this.typeLang === 'english') {
            return this.imagePreviewPriceOne.en;
          }
          if (this.typeLang === 'french') {
            return this.imagePreviewPriceOne.fr;
          }
        } else {
          if (this.typeLang === 'spanish') {
            return this.imagePreviewPriceTwo.es;
          }
          if (this.typeLang === 'english') {
            return this.imagePreviewPriceTwo.en;
          }
          if (this.typeLang === 'french') {
            return this.imagePreviewPriceTwo.fr;
          }
        }
        return null;
      };
    },
    formIsValid () {
      const hasValidNameAndDescription = (this.dataFormCategory.name !== null && this.dataFormCategory.name !== '');
      if (this.type === 'create') {
        return hasValidNameAndDescription;
      } else {
        return hasValidNameAndDescription;
      }
    },
    getSubcategoryNameById () {
      return (id) => {
        const nameCategory = this.dataFormCategory.children.find((subcategory) => subcategory._id === id);
        if (nameCategory) {
          if (this.typeLang === 'spanish') {
            return nameCategory.name ? nameCategory.name : `(ES) ${nameCategory.nameEnglish ? nameCategory.nameEnglish : ''}`;
          }
          if (this.typeLang === 'english') {
            return nameCategory.nameEnglish ? nameCategory.nameEnglish : `(IN) ${nameCategory.name}`;
          }
          if (this.typeLang === 'french') {
            return nameCategory.nameFrench ? nameCategory.nameFrench : `(FR) ${nameCategory.name}`;
          }
        }
        return 'No hay subcategorías creadas';
      };
    },
    getProductNameByLang () {
      return (product) => {
        if (this.typeLang === 'spanish') {
          return product.name ? product.name : `(ES) ${product.nameEnglish ? product.nameEnglish : ''}`;
        }
        if (this.typeLang === 'english') {
          return product.nameEnglish ? product.nameEnglish : `(IN) ${product.name}`;
        }
        if (this.typeLang === 'french') {
          return product.nameFrench ? product.nameFrench : `(FR) ${product.name}`;
        }
        return '';
      };
    }
  },
  mounted () {
    if (this.type === 'edit') {
      this.dataFormCategory = this.dataCategorySelected;
      this.setImagesFromDataDefaultEdit();
    }
    this.bgImageMenu = localStorage.getItem('btnMenuBackground') ? JSON.parse(localStorage.getItem('btnMenuBackground')) : null;
    this.listProductsCategorySubcategory();
  },
  beforeDestroy () {
  },
  methods: {
    setImagesFromDataDefaultEdit () {
      const idiomas = [
        {
          acron: 'es',
          name: ''
        },
        {
          acron: 'en',
          name: 'English'
        },
        {
          acron: 'fr',
          name: 'French'
        }
      ];
      const imagePriceProps = [
        { previewKey: 'imagePreviewPriceOne', dataKey: 'imagePriceOne' },
        { previewKey: 'imagePreviewPriceTwo', dataKey: 'imagePriceTwo' }
      ];
      idiomas.forEach(idioma => {
        imagePriceProps.forEach(prop => {
          this[prop.previewKey][idioma.acron] = this.dataCategorySelected[`${prop.dataKey}${idioma.acron === 'es' ? '' : idioma.name}`];
        });
      });
    },
    changeTypeLanguage (typeLang = 'spanish') {
      this.typeLang = typeLang;
    },
    fileUpload (file, type) {
      this.isLoading = true;
      const name = file.name.replace(/\s/g, '').split('.');
      this.uploadImageGlobalCloud(file, name[0], null, this.$store.getters['global:vendor'].vendor, 'menu', 1)
        .then(({ data, code }) => {
          if (code === 200) {
            if (type === 'priceOne') {
              if (this.typeLang === 'spanish') {
                this.imagePreviewPriceOne.es = data.data;
              }
              if (this.typeLang === 'english') {
                this.imagePreviewPriceOne.en = data.data;
              }
              if (this.typeLang === 'french') {
                this.imagePreviewPriceOne.fr = data.data;
              }
            }
            if (type === 'priceTwo') {
              if (this.typeLang === 'spanish') {
                this.imagePreviewPriceTwo.es = data.data;
              }
              if (this.typeLang === 'english') {
                this.imagePreviewPriceTwo.en = data.data;
              }
              if (this.typeLang === 'french') {
                this.imagePreviewPriceTwo.fr = data.data;
              }
            }
            if (type === 'imageCategory') {
              this.dataFormCategory.imageCategory = data.data;
            }
            if (type === 'imageBanner') {
              this.dataFormCategory.imageBanner = data.data;
              this.fileNameImageBannerUrl = file.name;
            }
            this.fileImagePriceOne = null;
            this.fileImagePriceTwo = null;
            this.fileImageCategory = null;
            this.fileImageBannerUrl = null;
            this.isLoading = false;
            this.$buefy.toast.open({
              message: 'Se ha subido la imagen',
              type: 'is-success'
            });
            return;
          }
          this.isLoading = false;
          this.$buefy.toast.open({
            message: 'Hubo un error con la petición',
            type: 'is-danger'
          });
        });
    },
    editSubcategory () {
      this.dataFormSubCategory.userId = this.user.id;
      serviceQr.put(`/menuCustom/editCategory/${this.dataFormSubCategory._id}`, this.dataFormSubCategory).then((resp) => {
        const data = {
          productIds: this.checkboxGroupProductsSubCategoryStructured,
          categoryId: this.dataFormSubCategory._id,
          menuId: this.idMenuMultipleForCategoriesProducts
        };
        if (this.checkboxGroupProductsSubCategoryStructured.length > 0) {
          serviceQr.post('/menuCustom/products/addCategory', data).then((resp) => {
            if (resp.status === 200) {
              this.$emit('getListCategories');
              this.$emit('changeStatusSectionCreateEditCategories', false);
              this.isLoading = false;
            }
          });
        } else {
          this.isLoading = false;
          this.$emit('changeStatusSectionCreateEditCategories', false);
          this.$emit('getListCategories');
        }
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error con la petición',
          type: 'is-danger'
        });
      });
    },
    createEditCategorySubCategory () {
      this.isLoading = true;
      this.dataFormCategory.userId = this.user.id;
      this.dataFormCategory.imagePriceOne = this.imagePreviewPriceOne.es;
      this.dataFormCategory.imagePriceOneEnglish = this.imagePreviewPriceOne.en;
      this.dataFormCategory.imagePriceOneFrench = this.imagePreviewPriceOne.fr;
      this.dataFormCategory.imagePriceTwo = this.imagePreviewPriceTwo.es;
      this.dataFormCategory.imagePriceTwoEnglish = this.imagePreviewPriceTwo.en;
      this.dataFormCategory.imagePriceTwoFrench = this.imagePreviewPriceTwo.fr;
      this.dataFormCategory.order = parseInt(this.dataFormCategory.order);
      if (this.idMenuMultipleForCategoriesProducts === null) {
        delete this.dataFormCategory.backgroundImage;
      }
      if (this.type === 'create') {
        serviceQr.post('/menuCustom/category', this.dataFormCategory).then((resp) => {
          if (resp.data.code === 200) {
            /* let filteredProductsForCategory = [...this.checkboxGroupProductsCategoryStructured];
            filteredProductsForCategory = filteredProductsForCategory.filter((product) => product.isrelate === 1);
            if (filteredProductsForCategory.length > 0 && this.checkboxGroupProductsCategory.length > 0) { */
            const productsIfExistActive = this.checkboxGroupProductsCategoryStructured.filter((item) => item.isrelate === 1);
            const data = {
              productIds: productsIfExistActive,
              categoryId: resp.data.data._id,
              menuId: this.idMenuMultipleForCategoriesProducts
            };
            if (productsIfExistActive.length > 0) {
              this.isLoading = true;
              serviceQr.post('/menuCustom/products/addCategory', data).then((resp) => {
                if (resp.status === 200) {
                  this.isLoading = false;
                  this.$emit('getListCategories');
                  this.$emit('changeStatusSectionCreateEditCategories', false);
                  this.$buefy.modal.open({
                    parent: this,
                    component: AlertConfirmDelete,
                    hasModalCard: true,
                    canCancel: false,
                    customClass: 'alert-confirm-delete alert-success',
                    props: {
                      idMenu: null,
                      type: 'success',
                      title: '',
                      subtitle: `
                        La categoría: <br>
                        <b>${this.dataFormCategory.name}</b><br>
                        fue creada con éxito
                      `,
                      typeDelete: ''
                    }
                  });
                }
              });
            } else {
              this.$buefy.modal.open({
                parent: this,
                component: AlertConfirmDelete,
                hasModalCard: true,
                canCancel: false,
                customClass: 'alert-confirm-delete alert-success',
                props: {
                  idMenu: null,
                  type: 'success',
                  title: '',
                  subtitle: `
                    La categoría: <br>
                    <b>${this.dataFormCategory.name}</b><br>
                    fue creada con éxito
                  `,
                  typeDelete: ''
                }
              });
              this.isLoading = false;
              this.$emit('changeStatusSectionCreateEditCategories', false);
            }
            /* } else {
              this.$emit('getListCategories');
              this.$emit('changeStatusSectionCreateEditCategories', false);
            } */
          } else {
            this.isLoading = false;
          }
        });
      } else {
        serviceQr.put(`/menuCustom/editCategory/${this.dataFormCategory._id}`, this.dataFormCategory).then((resp) => {
          if (resp.data.code === 200) {
            /* let filteredProductsForCategory = [...this.checkboxGroupProductsCategoryStructured];
            filteredProductsForCategory = filteredProductsForCategory.filter((product) => product.isrelate === 1); */
            /* if (filteredProductsForCategory.length > 0) { */
            const data = {
              productIds: this.checkboxGroupProductsCategoryStructured,
              categoryId: this.dataFormCategory._id,
              menuId: this.idMenuMultipleForCategoriesProducts
            };
            if (this.checkboxGroupProductsCategoryStructured.length > 0) {
              // agrega los los productos a la categoría
              serviceQr.post('/menuCustom/products/addCategory', data).then((resp) => {
                if (resp.status === 200) {
                  // valida si la subcategoría existe para editar los datos de la misma
                  this.$buefy.modal.open({
                    parent: this,
                    component: AlertConfirmDelete,
                    hasModalCard: true,
                    canCancel: false,
                    customClass: 'alert-confirm-delete alert-success',
                    props: {
                      idMenu: null,
                      type: 'success',
                      title: '',
                      subtitle: `
                        La categoría: <br>
                        <b>${this.dataFormCategory.name}</b><br>
                        fue editada con éxito
                      `,
                      typeDelete: ''
                    }
                  });
                  if (this.dataFormSubCategory._id !== null) {
                    this.editSubcategory();
                  } else {
                  //  cambia el estado de la sección actual que muestra para crear o editar categorías
                    this.$emit('getListCategories');
                    this.$emit('changeStatusSectionCreateEditCategories', false);
                  }
                }
              });
            } else {
              if (this.dataFormSubCategory._id !== null) {
                this.editSubcategory();
              } else {
                this.$buefy.modal.open({
                  parent: this,
                  component: AlertConfirmDelete,
                  hasModalCard: true,
                  canCancel: false,
                  customClass: 'alert-confirm-delete alert-success',
                  props: {
                    idMenu: null,
                    type: 'success',
                    title: '',
                    subtitle: `
                      La categoría: <br>
                      <b>${this.dataFormCategory.name}</b><br>
                      fue editada con éxito
                    `,
                    typeDelete: ''
                  }
                });
                this.$emit('getListCategories');
                this.$emit('changeStatusSectionCreateEditCategories', false);
                this.isLoading = false;
              }
              /* this.$emit('changeStatusSectionCreateEditCategories', false); */
            }
            /* } else {
              if (this.dataFormSubCategory._id !== null) {
              }
            } */
          } else {
            this.isLoading = false;
            this.$buefy.toast.open({
              message: 'Error con la petición',
              type: 'is-danger'
            });
          }
        });
      }
    },
    listProductsCategorySubcategory (type = 'category', subcategoryId = null) {
      // esto lista los productos por categoría y subcategoría
      this.isLoading = true;
      if (type === 'category') {
        const data = {
          menuId: this.idMenuMultipleForCategoriesProducts,
          categoryId: this.type === 'edit' ? this.dataCategorySelected._id : null,
          q: this.searchProductCategories
        };
        serviceQr.post('/menuCustom/categories/list/products', data).then((resp) => {
          if (resp.status === 200) {
            this.productsCategory = resp.data.data;
            const arrayChecksProductsCategory = [];
            // si hay productos activos para la categoría es decir que si el category Id existe, activo los checks por defecto en los productos elegidos
            resp.data.data.forEach((product) => {
              if (product.categoryId !== null) {
                arrayChecksProductsCategory.push(product._id);
              }
            });
            this.checkboxGroupProductsCategory = arrayChecksProductsCategory;
            this.setProductsWithStructureDefault(resp.data.data, 'category');
            if (this.type === 'edit') {
              arrayChecksProductsCategory.forEach(product => {
                this.setProductsWithStructure(arrayChecksProductsCategory, 'category', product);
              });
            }
          }
          this.isLoading = false;
        });
      } else {
        const data = {
          menuId: this.idMenuMultipleForCategoriesProducts,
          categoryId: this.type === 'edit' ? subcategoryId : null,
          q: this.searchProductSubCategories
        };
        serviceQr.post('/menuCustom/categories/list/products', data).then((resp) => {
          if (resp.status === 200) {
            this.productsSubCategory = resp.data.data;
            const arrayChecksProductsSubCategory = [];
            // si hay productos activos para la subcategoría es decir que si el category Id existe, activo los checks por defecto en los productos elegidos
            resp.data.data.forEach((product) => {
              if (product.categoryId !== null) {
                arrayChecksProductsSubCategory.push(product._id);
              }
            });
            this.checkboxGroupProductsSubCategory = arrayChecksProductsSubCategory;
            this.setProductsWithStructureDefault(resp.data.data, 'subcategory');
            if (this.type === 'edit') {
              arrayChecksProductsSubCategory.forEach(product => {
                this.setProductsWithStructure(arrayChecksProductsSubCategory, 'subcategory', product);
              });
            }
          }
          this.isLoading = false;
        });
      }
    },
    selectAllProducts (type = 'category', active) {
      if (type === 'category' || type === 'subcategory') {
        const products = type === 'category' ? this.productsCategory : this.productsSubCategory;
        const checkboxGroupProducts = type === 'category' ? this.checkboxGroupProductsCategory : this.checkboxGroupProductsSubCategory;
        const checkboxGroupProductsStructured = type === 'category' ? this.checkboxGroupProductsCategoryStructured : this.checkboxGroupProductsSubCategoryStructured;
        if (active) {
          products.forEach((product) => {
            if (!checkboxGroupProducts.includes(product._id)) {
              checkboxGroupProducts.push(product._id);
            }
            const productStruct = checkboxGroupProductsStructured.find((productS) => productS.id === product._id);
            if (productStruct) {
              productStruct.isrelate = 1;
            } else {
              checkboxGroupProductsStructured.push({
                id: product._id,
                isrelate: 1
              });
            }
          });
        } else {
          checkboxGroupProducts.length = 0;
          checkboxGroupProductsStructured.forEach((product) => {
            product.isrelate = 0;
          });
        }
      }
    },
    deleteProducts (type, id) {
      this.isLoading = true;
      let products = [id];
      if (id === null) {
        products = type === 'category' ? this.checkboxGroupProductsCategory : this.checkboxGroupProductsSubCategory;
      }
      const data = {
        productIds: products,
        vendorId: this.vendor.id,
        userId: this.user.id
      };
      serviceQr.post('/menuCustom/products/delete', data).then((response) => {
        if (response.status === 200) {
          if (type === 'category') {
            this.listProductsCategorySubcategory();
          } else {
            this.listProductsCategorySubcategory('subcategory', this.dataFormSubCategory._id);
          }
        }
        this.isLoading = false;
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error con la petición',
          type: 'is-danger'
        });
        this.isLoading = false;
      });
    },
    executeAlert (type, id = null, typeFor = 'categoryProduct') {
      const instance = this;
      let text = null;
      if (type === 'all-products') {
        text = '¿Estás seguro(a) que deseas activar <br> todos los productos?';
      } else {
        text = id === null
          ? `<div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar los productos seleccionados?</div>
          <div><b>Nota:</b> al eliminar estos productos desaparecerán de la categoría y de la lista de productos.</div>`
          : `<div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar este producto?</div>
          <div><b>Nota:</b> al eliminar este producto desaparecerá de la categoría y de la lista de productos.</div>`;
      }
      if ((type === 'all-products' && (this.checkboxProductsCategoryMassive || this.checkboxProductsSubCategoryMassive)) || type !== 'all-products') {
        this.$buefy.modal.open({
          parent: this,
          component: AlertConfirmDelete,
          hasModalCard: true,
          canCancel: false,
          customClass: 'alert-confirm-delete',
          props: {
            idMenu: null,
            type: type === 'all-products' ? type : 'delete',
            title: type === 'all-products' ? 'ESTAS ACTIVANDO TODOS LOS PRODUCTOS' : 'CONFIRMAR',
            subtitle: text,
            typeDelete: type !== 'all-products' ? 'product' : ''
          },
          events: {
            checkProducts () {
              if (typeFor === 'categoryProduct') {
                instance.checkboxProductsCategoryMassive = true;
                instance.selectAllProducts('category', true);
              } else {
                instance.checkboxProductsSubCategoryMassive = true;
                instance.selectAllProducts('subcategory', true);
              }
            },
            deleteProducts () {
              instance.deleteProducts(type, id);
            },
            inactiveCheckbox () {
              if (typeFor === 'categoryProduct') {
                instance.checkboxProductsCategoryMassive = false;
                /* instance.selectAllProducts('category', false); */
              } else {
                instance.checkboxProductsSubCategoryMassive = false;
                /* instance.selectAllProducts('subcategory', false); */
              }
            }
          }
        });
      }
    },
    setProductsWithStructureDefault (data, type) {
      if (type === 'category') {
        data.forEach(product => {
          this.checkboxGroupProductsCategoryStructured.push({
            id: product._id,
            isrelate: 0
          });
        });
      } else {
        data.forEach(product => {
          this.checkboxGroupProductsSubCategoryStructured.push({
            id: product._id,
            isrelate: 0
          });
        });
      }
    },
    setProductsWithStructure (products, type = 'category', id) {
      const existInChecksProducts = products.includes(id);
      const active = existInChecksProducts ? 1 : 0;
      const changeIsRelate = (structuredProducts) => {
        const productIndex = structuredProducts.findIndex((product) => product.id === id);
        if (productIndex !== -1) {
          structuredProducts[productIndex].isrelate = active;
        } else if (existInChecksProducts) {
          structuredProducts.push({ id, isrelate: active });
        }
      };
      if (type === 'category') {
        changeIsRelate(this.checkboxGroupProductsCategoryStructured);
      } else if (type === 'subcategory') {
        changeIsRelate(this.checkboxGroupProductsSubCategoryStructured);
      }
    },
    getDataSubCategory (subcategoryId) {
      this.isLoading = true;
      serviceQr.get(`/menuCustom/category/${subcategoryId}`).then((resp) => {
        if (resp.status === 200) {
          this.dataFormSubCategory.description = resp.data.data.description;
          this.dataFormSubCategory.descriptionEnglish = resp.data.data.descriptionEnglish;
          this.dataFormSubCategory.descriptionFrench = resp.data.data.descriptionFrench;
          this.dataFormSubCategory.name = resp.data.data.name;
          this.dataFormSubCategory.nameEnglish = resp.data.data.nameEnglish;
          this.dataFormSubCategory.nameFrench = resp.data.data.nameFrench;
          this.dataFormSubCategory._id = resp.data.data._id;
          this.dataFormSubCategory.order = resp.data.data.order;
          this.dataFormSubCategory.typeLanguage = 'both';
          this.dataFormSubCategory.vendorId = resp.data.data.vendorId;
          this.dataFormSubCategory.active = resp.data.data.active;
          /* this.dataFormSubCategory = { ...this.dataFormSubCategory, ...resp.data.data }; */
          this.listProductsCategorySubcategory('subcategory', this.dataFormSubCategory._id);
          this.isLoading = false;
        }
      }).catch((error) => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Hubo un error con la petición',
          type: 'is-danger'
        });
        console.log(error);
      });
    },
    getNameCategorySubcategoryByLang (type) {
      switch (type) {
        case 'category':
          if (this.typeLang === 'spanish') {
            return this.dataFormCategory.name;
          }
          if (this.typeLang === 'english') {
            return this.dataFormCategory.nameEnglish;
          }
          if (this.typeLang === 'french') {
            return this.dataFormCategory.nameFrench;
          }
          break;
        case 'subcategory':
          if (this.typeLang === 'spanish') {
            return this.dataFormSubCategory.name;
          }
          if (this.typeLang === 'english') {
            return this.dataFormSubCategory.nameEnglish;
          }
          if (this.typeLang === 'french') {
            return this.dataFormSubCategory.nameFrench;
          }
          break;
      }
    },
    executeModalCreateProducts (createFor = 'category') {
      let idCategory = null;
      let idSubcategory = null;
      let isTwoPricesFromCategory = null;
      const instance = this;
      if (this.type === 'edit') {
        if (createFor === 'category') {
          idCategory = this.dataFormCategory._id;
          isTwoPricesFromCategory = this.dataFormCategory.twoPrices;
        }
        if (createFor === 'subcategory' && this.dataFormSubCategory._id !== null) {
          idSubcategory = this.dataFormSubCategory._id;
        }
      }
      const props = {
        restaurant: instance.restaurant,
        product: null,
        type: 'create',
        idMenuMultipleForCategoriesProducts: instance.idMenuMultipleForCategoriesProducts,
        createProductGlobal: true,
        idCategoryFromCreateCategory: idCategory,
        idSubcategoryFromCreateCategory: idSubcategory,
        isTwoPricesFromCategory: isTwoPricesFromCategory,
        typeLangFromSectionCategorySubcategory: instance.typeLang,
        nameCategoryFromSectionCategorySubcategory: instance.getNameCategorySubcategoryByLang('category'),
        nameSubCategoryFromSectionCategorySubcategory: createFor === 'subcategory' ? instance.getNameCategorySubcategoryByLang('subcategory') : null
      };
      const validateType = idCategory !== null ? 'category' : (idSubcategory !== null ? 'subcategory' : 'category');
      this.$buefy.modal.open({
        parent: this,
        component: CreateEditProduct,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-create-edit-products modal-create-edit-products-for-titles-category-subcategory',
        props: props,
        events: {
          listProductsCategorySubcategory () {
            instance.listProductsCategorySubcategory(validateType, idSubcategory);
          }
        }
      });
    },
    executeModalCreateSubCategory () {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalCreateSubCategory,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-create-subcategory',
        props: {
          parentId: instance.dataFormCategory._id,
          restaurant: instance.restaurant,
          typeLang: instance.typeLang,
          idMenuMultipleForCategoriesProducts: instance.idMenuMultipleForCategoriesProducts,
          typeLangFromSectionCategorySubcategory: instance.typeLang,
          nameCategoryFromSectionCategorySubcategory: instance.getNameCategorySubcategoryByLang('category'),
          nameSubCategoryFromSectionCategorySubcategory: instance.getNameCategorySubcategoryByLang('subcategory')
        },
        events: {
          executeModalCreateProducts (type) {
            instance.executeModalCreateProducts(type);
          },
          executeAlert (type, id) {
            instance.executeAlert(type, id);
          },
          getListCategories () {
            instance.$emit('getListCategories');
          },
          pushNewSubcategoryToTheList (data) {
            instance.pushNewSubcategoryToTheList(data);
          }
        }
      });
    },
    pushNewSubcategoryToTheList (data) {
      this.dataFormCategory.children.push(data);
    },
    selectSubcategoryCustomSelect (id) {
      this.dataFormSubCategory._id = id;
      this.showSubcategoriesList = false;
    },
    changeStatusSubcategory (id, active) {
      const index = this.dataFormCategory.children.findIndex(subcategory => subcategory._id === id);
      // Si se encuentra el objeto, actualiza su valor
      /* this.isLoading = true; */
      if (index !== -1) {
        this.$set(this.dataFormCategory.children[index], 'active', active === 1 ? 0 : 1);
      }
      const data = {
        categoryIds: [id],
        active: active === 1 ? 0 : 1
      };
      serviceQr.post('/menuCustom/categories/state', data).then((resp) => {
        if (resp.status === 200) {
          this.$emit('getListCategories');
          if (this.dataFormSubCategory._id) {
            this.getDataSubCategory(id);
          }
        }
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Hubo un error con la petición',
          type: 'is-danger'
        });
      });
    },
    executeModalSelectMenuForCategory () {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalSelectMenuForCategories,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-select-menu-for-categories',
        props: {
          restaurant: instance.restaurant,
          typeLang: instance.typeLang,
          idBtnMenuSelected: instance.dataFormCategory.vendorMenuId ? instance.dataFormCategory.menuId : null
        },
        events: {
          saveIdMenuForCategory (menuId, menuButtonId, image) {
            // se pasan al formulario el id del menú elegido y el id del botón menú creado que tiene asociado el menu (tipo interactivo)
            instance.dataFormCategory.vendorMenuId = menuId;
            instance.dataFormCategory.backgroundImage = image;
            instance.dataFormCategory.menuId = menuButtonId;
          }
        }
      });
    }
  },
  watch: {
    descriptionEditorEs (value) {
      this.dataFormCategory.description = value;
    },
    descriptionEditorEn (value) {
      this.dataFormCategory.descriptionEnglish = value;
    },
    descriptionEditorFr (value) {
      this.dataFormCategory.descriptionFrench = value;
    },
    'dataFormCategory.twoPrices': {
      handler () {
        if (this.dataFormCategory.twoPrices === 0) {
          /* this.imagePreviewPriceTwo = {
            es: null,
            en: null,
            fr: null
          }; */
        }
      }
    },
    'dataFormSubCategory._id': {
      handler (value) {
        this.getDataSubCategory(value);
      }
    },
    searchProductCategories () {
      if (this.timeoutSearcher) {
        clearTimeout(this.timeoutSearcher);
      }
      this.timeoutSearcher = setTimeout(() => {
        this.listProductsCategorySubcategory('category');
      }, 1000);
    },
    searchProductSubCategories () {
      if (this.dataFormSubCategory._id !== null) {
        if (this.timeoutSearcher) {
          clearTimeout(this.timeoutSearcher);
        }
        this.timeoutSearcher = setTimeout(() => {
          this.listProductsCategorySubcategory('subcategory', this.dataFormSubCategory._id);
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/categories/_section_create_edit_categories.scss";
</style>
