<template>
    <div id="form-contact-delete">
        <b-loading :active.sync="isLoading" :is-full-page="true" :can-cancel="false"></b-loading>
        <div class="title-contact-delete">
            ¿Deseas eliminar los siguientes contactos de la lista "{{ list }}"?
        </div>
        <div class="info-contact-delete">
            <div class="items-delete" v-for="(item, index) in items" :key="'contact-delete#' + index">
                <div class="items-delete-count">
                    {{ index + 1 }}.
                </div>
                <div class="items-delete-name">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="info-contact-actions">
            <button class="info-contact-actions-accept" @click="deleteContact()">Aceptar</button>
            <button class="info-contact-actions-close" @click="cancelContact()">Cerrar</button>
        </div>
    </div>
</template>
<script>
import serviceMarketing from '@/helpers/apis/serviceMarketing';
export default {
  name: 'MarketingDelete',
  props: {
    items: {
      type: Array
    },
    list: {
      type: String
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  methods: {
    deleteContact () {
      const info = {
        vendorId: this.vendor.id,
        contacts: this.items.map((it) => it._id),
        type: this.type
      };
      this.isLoading = true;
      serviceMarketing.patch('/list/' + this.vendor.id, info).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.data,
          type: 'is-success',
          position: 'is-bottom',
          duration: 5000
        });
        this.isLoading = false;
        this.$emit('updated', true);
        this.$parent.close();
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error al eliminar el contacto',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        });
        this.isLoading = false;
      });
    },
    cancelContact () {
      this.$emit('changeCancel', true);
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
#form-contact-delete {
    width: 380px;
    max-height: 250px;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #444B57;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
    .title-contact-delete {
        width: 295px;
        color: #444B57;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 42px 0px 42px;
        @media screen and (max-width: 414px) {
          width: auto;
        }
    }
    .info-contact-delete {
        max-height: 90px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 3px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: #444B57 !important;
            border-color: #444B57 !important;
            box-shadow: unset !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-track {
            background: unset !important;
        }
    }
    .items-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 172px;
        &-count {
            width: 10%;
            color: #444B57;
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &-name {
            margin-left: 5px;
            width: 90%;
            color: #444B57;
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .info-contact-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        &-accept {
            width: 120px;
            height: 30px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px solid #C9C9C9;
            font-family: 'Source Sans Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            background: #444B57;
            color: #FFF;
            cursor: pointer;
        }
        &-close {
            margin-left: 20px;
            width: 120px;
            height: 30px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px solid #C9C9C9;
            background: #FFF;
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 414px) {
      width: 340px;
    }
}
</style>
