<template>
  <div class="component-box">
    <div class="header-component" :style="{'justify-content': (zoneId.length > 0 && !isLoadingTable && seatingTableOri.length > 0) ? 'space-between' : 'center'}">
      <div class="search-table-list" style="width: 30%;" v-show="zoneId.length > 0 && !isLoadingTable && seatingTableOri.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" @click="searchIs = !searchIs">
          <rect width="20" height="20" rx="4" fill="#ECEDEE"/>
          <path d="M15.8598 15.1533L12.8777 12.1713C13.6904 11.1774 14.0899 9.90919 13.9937 8.62898C13.8975 7.34877 13.313 6.1545 12.3609 5.2932C11.4089 4.4319 10.1622 3.96948 8.87882 4.00157C7.5954 4.03365 6.37341 4.55781 5.46561 5.46561C4.55781 6.37341 4.03365 7.5954 4.00157 8.87882C3.96948 10.1622 4.4319 11.4089 5.2932 12.3609C6.1545 13.313 7.34877 13.8975 8.62898 13.9937C9.90919 14.0899 11.1774 13.6904 12.1713 12.8777L15.1533 15.8598C15.2476 15.9508 15.3738 16.0011 15.5048 16C15.6357 15.9988 15.761 15.9463 15.8537 15.8537C15.9463 15.761 15.9988 15.6357 16 15.5048C16.0011 15.3738 15.9508 15.2476 15.8598 15.1533ZM9.01189 13.0086C8.22141 13.0086 7.44869 12.7742 6.79143 12.335C6.13417 11.8959 5.6219 11.2717 5.3194 10.5414C5.0169 9.81106 4.93775 9.00745 5.09197 8.23217C5.24618 7.45688 5.62683 6.74473 6.18578 6.18578C6.74473 5.62683 7.45688 5.24618 8.23217 5.09197C9.00745 4.93775 9.81106 5.0169 10.5414 5.3194C11.2717 5.6219 11.8959 6.13417 12.335 6.79143C12.7742 7.44869 13.0086 8.22141 13.0086 9.01189C13.0074 10.0715 12.5859 11.0874 11.8367 11.8367C11.0874 12.5859 10.0715 13.0074 9.01189 13.0086Z" fill="#444B57"/>
        </svg>
        <input type="text" class="search-table-list-input" id="searchTable" v-model="findTableSearch" placeholder="Buscar por nombre" v-show="searchIs">
      </div>
      <span style="display: flex; height: 100%; align-items: center; justify-content: center; width: 40%;">
        <i class="icon-mks black icon_table_add_reservation i-table-component" style="margin-top: 0px;"></i>Mesa
      </span>
      <div style="display: flex; align-items: center; justify-content: flex-end; height: 100%; width: 30%;" v-show="zoneId.length > 0 && !isLoadingTable && seatingTableOri.length > 0">
        <button
          :class="{selected: allSelected}"
          @click="selectAll"
          class="selectALL"
          style="height: unset;"
        >Seleccionar todo</button>
      </div>
    </div>
    <div class="select-please" v-if="zoneId.length === 0 && !isLoadingTable">Selecciona una Zona</div>
    <div
      class="select-please"
      v-if="zoneId.length !== 0 && !isLoadingTable && seatingTableOri.length === 0"
    >No hay mesas disponibles</div>
    <loader-custom-section v-if="isLoadingTable" />
    <div
      :class="{one:seatingTableOri.length === 1,two:seatingTableOri.length === 2,three:seatingTableOri.length === 3,four:seatingTableOri.length === 4,five:seatingTableOri.length === 5,six:seatingTableOri.length === 6, seven:seatingTableOri.length === 7,height:seatingTableOri.length >= 8 }"
      class="table-component"
      v-if="zoneId.length > 0 && !isLoadingTable"
    >
      <a
        :class="{selectedTable: isSelect(table.value) }"
        :id="'tableId-' + table.value"
        :key="'Tables' + t"
        @click="setTable(table)"
        class="table-item column"
        href="javascript:void(0)"
        v-for="(table, t) in seatingTables"
        v-html="table.label"
      ></a>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import axios from 'axios';
import LoaderCustomSection from '@/components/_shared/LoaderCustomSection.vue';
export default {
  name: 'FormReservationTable',
  components: { LoaderCustomSection },
  props: ['zoneId', 'subZoneId', 'people', 'hour', 'selectedTablesClient', 'tablesValue', 'dataNotPrecharged'],
  data () {
    return {
      seatingTables: [],
      seatingTableOri: [],
      isLoadingTable: false,
      selectedTable: [],
      all: false,
      noAll: false,
      searchTable: false,
      initializedComponent: 0,
      cancelToken: axios.CancelToken,
      cancelTablesSections: null,
      findTableSearch: '',
      searchIs: false,
      isLoading: false
    };
  },
  beforeMount () {
    this.getSectionTable();
  },
  methods: {
    initValues (seating) {
      if (!this.dataNotPrecharged) {
        const tableS = seating.filter((table) => {
          if (this.tablesValue.find(value => value === parseInt(table.value))) {
            return true;
          }
          return false;
        });
        tableS.map(id => {
          this.setTable(id);
        });
        this.initializedComponent = this.initializedComponent + 1;
      }
    },
    getSectionTable () {
      /* if (typeof this.cancelTablesSections === 'function') {
        this.cancelTablesSections();
      } */
      const source = this.cancelToken.source();
      /* this.cancelTablesSections = source.cancel; */
      const instance = this;
      if (this.zoneId.length > 0) {
        if (this.zoneId && this.hour) {
          this.isLoadingTable = true;
          this.$emit('loading', true);
          this.$store.dispatch({ type: 'reservation:tablesSections', data: { sectionId: this.zoneId, people: this.people, date: this.hour, subzoneId: this.subZoneId, source } })
            .then((data) => {
              const aux = [];
              if (this.zoneId !== undefined) {
                this.zoneId.map(function (zone) {
                  const tables = data.data[zone];
                  let name = '';
                  let status = 0;
                  if (tables !== undefined) {
                    tables.map(function (data) {
                      name = data.displayName;
                      if (data.blocked && data.availability === false) {
                        status = 1;
                      } else {
                        if (data.availability === false) {
                          status = 1;
                        }
                        if (data.blocked) {
                          status = 1;
                        }
                        if (data.isEvent === 1) {
                          status = 1;
                        }
                      }
                      if (data.isShared && data.sharedFull === false) {
                        status = 2;
                      }
                      if (status === 1) {
                        aux.push({ value: data.id, label: '<span style="color: #F83037; font-family: \'Roboto\'; font-size: 13px;"><i class="fa fa-circle" style="color: #F83037; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + ' pax </span>', status, section: data.seatingSectionId, displayName: name });
                      } else if (status === 2) {
                        aux.push({ value: data.id, label: '<span style="font-family: \'Roboto\'; font-size: 13px;"><i class="fa fa-circle" style="color: #006EB3; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + ' pax </span>', section: data.seatingSectionId, displayName: name });
                      } else if (status === 0) {
                        aux.push({ value: data.id, label: '<span style="font-family: \'Roboto\'; font-size: 13px;"><i class="fa fa-circle" style="color: #47CC27; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + ' pax </span>', section: data.seatingSectionId, displayName: name });
                      }
                      status = 0;
                    });
                  }
                });
                this.seatingTables = aux;
                this.seatingTableOri = aux;
                if (this.initializedComponent < 1) {
                  this.initValues(aux);
                }
              }
              this.isLoadingTable = false;
              this.$emit('loading', false);
            }).catch(() => {
              this.seatingTables = [];
              this.seatingTableOri = [];
              this.$emit('loading', false);
              this.isLoadingTable = false;
            });
        }
      }
    },
    setTable (tableId) {
      const is = _.find(this.selectedTable, { idTable: tableId.value });
      if (is === undefined) {
        this.selectedTable.push({ idTable: tableId.value, idSection: tableId.section, state: !tableId.label.includes('#F83037') });
      } else {
        const index = _.findIndex(this.selectedTable, { idTable: tableId.value });
        this.selectedTable.splice(index, 1);
      }
    },
    isSelect (tableId) {
      const is = (this.selectedTable).filter(table => {
        if (table.idTable === tableId) {
          return table;
        }
      });
      if (is.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    shortText (text) {
      let name = text;
      if (text.length > 5) {
        name = text.substr(0, 5) + '...';
      }
      return name;
    },
    selectAll () {
      const allTables = [];
      if (!this.allSelected) {
        this.seatingTables.forEach(table => {
          allTables.push({ idTable: table.value, idSection: table.section, state: !table.label.includes('#F83037') });
        });
      }
      this.selectedTable = allTables;
    }
  },
  computed: {
    allSelected () {
      if (this.selectedTable.length > 0) {
        if (this.seatingTableOri.length === this.selectedTable.length) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    zoneId () {
      this.getSectionTable();
      if (this.initializedComponent >= 1) {
        this.selectedTable = [];
      }
    },
    subZoneId () {
      this.getSectionTable();
    },
    hour () {
      this.getSectionTable();
    },
    people () {
      this.getSectionTable();
    },
    selectedTable () {
      this.$emit('tableSelected', this.selectedTable);
    },
    tablesValue () {
      this.getSectionTable();
    },
    findTableSearch () {
      if (this.findTableSearch !== '') {
        const original = JSON.parse(JSON.stringify(this.seatingTableOri));
        const searchTerm = this.findTableSearch.toLowerCase();
        const result = original.filter(item => item.displayName.toLowerCase().includes(searchTerm));
        this.seatingTables = result;
      } else {
        this.seatingTables = this.seatingTableOri;
      }
    },
    searchIs () {
      if (this.searchIs) {
        setTimeout(() => {
          document.getElementById('searchTable').focus();
        }, 100);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.search-table-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: transparent;
  &-input {
    border: unset;
    outline: unset;
    width: 82px;
    height: 13px;
    color: #444B57;
    font-family: 'Source Sans Pro';
    font-size: 10px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    background: transparent;
    &::placeholder {
      color: #A1A5AB;
      font-family: 'Source Sans Pro';
      font-size: 10px;
      font-style: italic;
      font-weight: 300;
    }
  }
}
.header-component {
  padding-bottom: unset!important;
  padding-top: unset!important;
  button {
    outline: unset;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    color: #444b57;
    border: 1px solid #cacdd1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 11px;
    &.selected {
      color: white;
      background-color: #444b57;
    }
  }
}
.table-component {
  display: grid;
  overflow-y: scroll;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  width: 100%;
  max-width: 100%;
  grid-template-columns: repeat(6, minmax(50px, 1fr));
  background-color: #f0f1f2;
  gap: 1px;
  &.one {
    grid-template-columns: repeat(1, minmax(50px, 1fr));
  }
  &.two {
    grid-template-columns: repeat(2, minmax(50px, 1fr));
  }
  &.three {
    grid-template-columns: repeat(3, minmax(50px, 1fr));
  }
  &.four {
    grid-template-columns: repeat(4, minmax(50px, 1fr));
  }
  &.five {
    grid-template-columns: repeat(5, minmax(50px, 1fr));
  }
  @media screen and (min-width: 600px) {
    &.six {
      grid-template-columns: repeat(6, minmax(50px, 1fr));
    }
    &.seven {
      grid-template-columns: repeat(7, minmax(50px, 1fr));
    }
    &.height {
      grid-template-columns: repeat(8, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width: 1000px) {
    &.six {
      grid-template-columns: repeat(6, minmax(50px, 1fr));
    }
    &.seven {
      grid-template-columns: repeat(7, minmax(50px, 1fr));
    }
    &.height {
      grid-template-columns: repeat(7, minmax(50px, 1fr));
    }
  }
  .table-item {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    flex-direction: row;
    font-size: 11px;
    color: #000;
    margin-bottom: -1px;
    flex-direction: column;
    overflow: hidden;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      i {
        margin-right: 5px;
      }
    }
    &:last-child {
      font-size: 13px;
      color: #000;
    }
  }
  &::-webkit-scrollbar {
    width: 1px !important;
  }
}
.i-table-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}
.selectedTable {
  background: #444b57 !important;
  color: #fff !important;
  font-weight: 700 !important;
}
@media only screen and (min-width: 1001px) and (max-width: 1280px) {
  .table-component {
    width: 100%;
    max-width: 541px;
  }
}
.select-search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
</style>
