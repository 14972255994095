<template>
  <transition name="slide-fade">
    <div class="container_detail_poll" v-if="openDetails">
      <div class="container_detail_poll__header">
        <div class="columns is-mobile flex-flow-wrap">
          <div class="column is-12 name_user">{{ selectedPoll.client.name }}</div>
          <div @click="$emit('close')" class="close_container">
            <img alt src="@/assets/icons/polls/close.svg" />
          </div>
          <div class="columns column is-12 container_client is-mobile">
            <div class="column is-6 email_user">
              <img alt src="@/assets/icons/polls/mail_white.svg" srcset />
              {{ selectedPoll.client.email }}
            </div>
            <div class="column is-4 phone_user">
              <img alt src="@/assets/icons/polls/phone_white.svg" srcset />
              {{ selectedPoll.client.phone }}
            </div>
            <div
              class="column is-2 type"
              v-if="selectedPoll && selectedPoll.reservation && selectedPoll.reservation.referrer && selectedPoll.reservation.referrer != 'walking' && selectedPoll.reservation.referrer != 'dashboard' && selectedPoll.reservation.referrer != 'yate' && selectedPoll.qr === false"
            >
              <img alt src="@/assets/icons/polls/world.svg" srcset />
            </div>
            <div
              class="column is-2 type"
              v-else-if="selectedPoll && selectedPoll.reservation && selectedPoll.reservation.referrer && selectedPoll.reservation.referrer === 'walking'"
            >
              <img alt src="@/assets/icons/polls/walking.svg" srcset />
            </div>
            <div class="column is-2 type" v-else-if="selectedPoll && selectedPoll.qr">
              <img alt src="@/assets/icons/polls/qr.svg" srcset />
            </div>
            <div class="column is-2 type" style="opacity: 0.6;" v-else>
              <img alt src="@/assets/icons/polls/phone_white.svg" srcset />
            </div>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-4">
            <div class="container_dates_pool">
              <div>{{ selectedPoll.created_at | moment('DD/MM/YYYY') }}</div>
              <div>{{ selectedPoll.created_at | moment('hh:mm a') }}</div>
              <label for>Fecha de reserva</label>
            </div>
          </div>
          <div class="column is-4" v-if="selectedPoll.dateSend">
            <div class="container_dates_pool">
              <div>{{ selectedPoll.dateSend | moment('DD/MM/YYYY') }}</div>
              <div>{{ selectedPoll.dateSend | moment('hh:mm a') }}</div>
              <label for>Fecha de envío</label>
            </div>
          </div>
          <div class="column is-4" v-if="selectedPoll.updated_at">
            <div class="container_dates_pool">
              <div>{{ selectedPoll.updated_at | moment('DD/MM/YYYY') }}</div>
              <div>{{ selectedPoll.updated_at | moment('hh:mm a') }}</div>
              <label for>Fecha de respuesta</label>
            </div>
          </div>
        </div>
      </div>
      <div class="container_detail_poll__body">
        <!-- <div>Sucursal <b>Oviedo</b></div> -->
        <div class="d-flex rating_card_detail">
          <div
            :key="index"
            class="d-flex"
            v-for="(qualification, index) in selectedPoll.response.stars"
          >
            <div>{{qualification.question}}</div>
            <div>
              <span>{{qualification.qualify}}</span>
              <img alt src="@/assets/icons/polls/star.svg" />
            </div>
          </div>
          <div class="no_rating_section" v-if="selectedPoll.response.length == 0">
            <div
              :key="index"
              class="d-flex"
              v-for="(qualification, index) in selectedPoll.questions"
            >
              <div>{{qualification.question}}</div>
              <div>
                <span>-</span>
                <img alt src="@/assets/icons/polls/star_disabled.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="avg_rating_pool">
          <img alt src="@/assets/icons/polls/progress_static.svg" />
          <div>
            <label for>Promedio</label>
            <span>{{ promedio() }}</span>
          </div>
        </div>
        <div class="columns container_questions_rating is-mobile">
          <div class="column is-6">
            <div
              :key="index"
              class="questions_rating"
              v-for="(checkbox, index) in selectedPoll.response.checkbox"
            >
              <h3>{{ checkbox.question }}</h3>
              <div class="media_types">
                <div>{{checkbox.qualify}}</div>
              </div>
            </div>
            <div
              :key="index"
              class="questions_rating"
              v-for="(radioButtonQuestion, index) in selectedPoll.response.radioButtonQuestion"
            >
              <h3>{{ radioButtonQuestion.question }}</h3>
              <div class="media_types">
                <div>{{radioButtonQuestion.qualify}}</div>
              </div>
            </div>
            <div class="d-flex rating_card_detail">
              <div
                :key="index"
                class="d-flex"
                v-for="(multiRadio, index) in selectedPoll.response.multiRadio"
              >
                <div>{{ multiRadio.sub_question ? multiRadio.sub_question : multiRadio.question }}</div>
                <div>
                  <span>{{multiRadio.qualify}}</span>
                  <img alt src="@/assets/icons/polls/star.svg" />
                </div>
              </div>
              <div
                class="questions_rating"
                style="background-color: unset;"
                v-if="!selectedPoll.response.checkbox && !selectedPoll.response.multiRadio && !selectedPoll.response.radioButtonQuestion"
              >
                <span>No hay preguntas disponibles</span>
                <div class="h-50x"></div>
              </div>
            </div>
          </div>
          <div class="divisor"></div>
          <div class="column is-6">
            <div
              :key="index"
              class="questions_rating"
              v-for="(radioQuestion, index) in selectedPoll.response.radioQuestion"
            >
              <h3>{{ radioQuestion.question }}</h3>
              <div class="media_types">
                <div>{{radioQuestion.qualify}}</div>
              </div>
            </div>
            <div
              :key="index"
              class="questions_rating"
              v-for="(yesOrNoQuestion, index) in selectedPoll.response.yesOrNoQuestion"
            >
              <h3>{{ yesOrNoQuestion.question }}</h3>
              <div class="media_types">
                <div>{{yesOrNoQuestion.qualify}}</div>
                <div
                  style="margin-top: .5rem;"
                  v-if="yesOrNoQuestion.extraQualify"
                >Razon: {{yesOrNoQuestion.extraQualify}}</div>
              </div>
            </div>
            <div
              :key="index"
              class="questions_rating"
              v-for="(calendarQuestion, index) in selectedPoll.response.calendarQuestion"
            >
              <h3>{{ calendarQuestion.question }}</h3>
              <div>{{calendarQuestion.qualify}}</div>
            </div>
            <div
              :key="index"
              class="questions_rating"
              v-for="(openQuestion, index) in selectedPoll.response.openQuestion"
            >
              <h3>{{openQuestion.question}}</h3>
              <div>{{openQuestion.qualify}}</div>
            </div>
            <div
              :key="index"
              class="questions_rating"
              v-for="(openQuestionInput, index) in selectedPoll.response.openQuestionInput"
            >
              <h3>{{openQuestionInput.question}}</h3>
              <div>{{openQuestionInput.qualify}}</div>
            </div>
            <div
              class="questions_rating"
              v-if="!selectedPoll.response.openQuestion && !selectedPoll.response.openQuestionInput && !selectedPoll.response.calendarQuestion && !selectedPoll.response.radioQuestion"
            >
              <h3>No hay preguntas disponibles</h3>
              <div class="h-50x"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns container_comments_user">
        <div class="column is-12">
          <div class="d-flex comment_user__img">
            <img alt src="@/assets/icons/polls/comment.svg" />
            <div class="comment_user__text">
              <label for>Comentario del cliente:</label>
              <p>{{ selectedPoll.comments ? selectedPoll.comments : 'No hay comentarios' }}</p>
              <div class="h-50x"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['openDetails', 'selectedPoll'],
  methods: {
    promedio () {
      if (this.selectedPoll.length < 0) {
        return 'N/A';
      }
      if (this.selectedPoll.response.stars && this.selectedPoll.response.stars.length > 0) {
        return this.avg(this.selectedPoll.response.stars);
      }
      if (this.selectedPoll.response.multiRadio && this.selectedPoll.response.multiRadio.length > 0) {
        return this.mostRepeated(this.selectedPoll.response.multiRadio);
      }
      return 'N/A';
    },
    mostRepeated (data) {
      // Creamos un objeto para almacenar la cantidad de repeticiones de cada elemento
      const counts = {};
      // Iteramos sobre el array y contamos las repeticiones de cada elemento
      for (let i = 0; i < data.length; i++) {
        const element = data[i].qualify;
        counts[element] = (counts[element] || 0) + 1;
      }
      let mostRepeatedElement;
      let maxCount = 0;
      // Iteramos sobre el objeto de conteos para encontrar el elemento más repetido
      for (const element in counts) {
        if (counts[element] > maxCount) {
          mostRepeatedElement = element;
          maxCount = counts[element];
        }
      }
      return mostRepeatedElement;
    },
    avg (array) {
      let sum = 0;
      array.forEach((el) => {
        sum += parseInt(el.qualify);
      });
      const avg = sum / array.length;
      return avg.toFixed(2);
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/polls/_card_poll_detail";
</style>
