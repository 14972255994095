<template>
  <div class="position-relative">
    <div class="toolbar-poll-v2 d-flex">
      <div class="toolbar-poll-v2__container_logo d-flex">
        <div @click="openSideBar" class="client">
          <img :src="vendor.thumbnailURL" alt srcset />
        </div>
        <h2>Encuestas</h2>
      </div>
      <div @click="openSearcher" class="toolbar-poll-v2__container_search_responsive">
        <img alt src="@/assets/icons/polls/search_x2.svg" />
      </div>
      <div class="toolbar-poll-v2__container_filters d-flex">
        <button
          @click="openModal"
          class="toolbar-poll-v2__container_filters__button_dates js-modal-trigger"
          type="button"
        >Buscar por fecha</button>
        <div
          @mouseleave="mouseOut('dateBy')"
          class="toolbar-poll-v2__container_filters__select_date_by"
        >
          <div
            :data-list="selectDateByConfig.options"
            :data-value="selectDateByConfig.value"
            class="aselect"
          >
            <div @click="toggle('dateby')" class="selector">
              <div class="label date_by">
                <span>{{selectDateByConfig.value}}</span>
              </div>
              <div :class="selectDateByConfig.visible ? 'expanded' : ''" class="arrow_single"></div>
              <div :class="selectDateByConfig.visible ? '': 'hidden'">
                <ul class="date_by">
                  <li
                    :class="{ current_dateby : item.name === selectDateByConfig.value }"
                    :key="index"
                    @click="selectOrdeBy(item.value, item.name, 'dateby')"
                    class="date_by"
                    v-for="(item, index) in selectDateByConfig.options"
                  >{{item.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          @mouseleave="mouseOut('orderBy')"
          class="toolbar-poll-v2__container_filters__select_order_by"
        >
          <div
            :data-list="selectOrdeByConfig.options"
            :data-value="selectOrdeByConfig.value"
            @focusout="selectOrdeByConfig.visible = !selectOrdeByConfig.visible"
            class="aselect"
          >
            <div @click="toggle('orderBy')" class="selector">
              <div
                :style="!selectOrdeByConfig.visible ? {'background' : '#767c86', 'color': '#fff'}  : ''"
                class="label"
              >
                <span>{{selectOrdeByConfig.value}}</span>
              </div>
              <div :class="!selectOrdeByConfig.visible ? 'expanded' : ''" class="arrow"></div>
              <div :class="selectOrdeByConfig.visible ? '': 'hidden'">
                <ul>
                  <li
                    :class="{ current : item.name === selectOrdeByConfig.value }"
                    :key="index"
                    @click="selectOrdeBy(item.value, item.name, 'orderBy')"
                    v-for="(item, index) in selectOrdeByConfig.options"
                  >{{item.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="toolbar-poll-v2__container_filters__search d-flex">
          <img alt src="@/assets/icons/polls/search.svg" srcset />
          <input @keyup.enter="filterPollBy(false)" type="text" v-model="query" />
        </div>
      </div>
    </div>
    <div class="bar-container_filters d-flex" v-if="searcherResponsive">
      <div class="bar-container_filters__search_responsive">
        <img alt src="@/assets/icons/polls/search_black.svg" srcset />
        <input @keyup.enter="filterPollBy(false)" placeholder="Buscar" type="text" v-model="query" />
      </div>
      <div
        @click="searcherResponsive = !searcherResponsive"
        class="bar-container_filters__close_searcher"
      >
        <img alt src="@/assets/icons/polls/close_black.svg" srcset />
      </div>
    </div>
    <div class="columns position-relative" id="containerPollsId">
      <div class="column is-4">
        <PollQualification
          @update-select-date-by-config="(e) => selectDateByConfig = e"
          @update-select-order-by-config="(e) => selectOrdeByConfig = e"
          @update-group="(e) => group = e"
          @update-day-poll-filter="(e) => DayPollFilter = e"
          :DayPollFilter="DayPollFilter"
          :group="group"
          :selectDateByConfig="selectDateByConfig"
          :selectOrdeByConfig="selectOrdeByConfig"
          @filterGroup="filterGroup"
          @filterPollBy="filterPollBy"
          @mouseOut="mouseOut"
          @openModalDates="openModal"
          @poll-exist="pollExist"
          @selectOrdeBy="selectOrdeBy"
          @toggle="toggle"
        />
      </div>
      <div class="column is-8">
        <div class="container-cards-polls">
          <div class="polls-title is-12 has-text-centered columns">
            <div
              :class="dateRange.start && dateRange.end ? 'is-6': 'is-5'"
              class="column text-left"
            >
              <div v-if="dateRange.start && dateRange.end">
                <span class="polls-title__orderby__text">Filtro activo:</span>
                <span class="text_capitalized">
                  {{ dateRange.start | moment('MMMM DD') }}
                  <span>a</span>
                  {{ dateRange.end | moment('MMMM DD') }}
                </span>
                <span @click="cleanFilter('range_dates')" class="polls-title__orderby__cancel">
                  <img alt src="@/assets/icons/polls/close.svg" />
                </span>
              </div>
            </div>
            <div
              class="column is-2"
              v-if="!dateRange.start && !dateRange.end"
            >{{ !dateRange.start && !dateRange.end ? getTitle() : ''}}</div>
            <div
              :class="dateRange.start && !dateRange.end ? 'is-6': 'is-5'"
              class="polls-title__orderby column text-right d-flex"
              v-if="groupTxt != ''"
            >
              <div>
                <span class="polls-title__orderby__text">Ordenador por:</span>
                {{groupTxt}}
                <span
                  @click="cleanFilter('group')"
                  class="polls-title__orderby__cancel"
                >
                  <img alt src="@/assets/icons/polls/close.svg" />
                </span>
              </div>
            </div>
          </div>
          <div class="columns is-12 is-multiline" v-if="dataResult.length > 0">
            <CardPoll
              :key="index +'-'+ pollDetails.id"
              :pollDetails="pollDetails"
              @showDetails="showDetails(pollDetails)"
              v-for="(pollDetails, index) in filterBy(dataResult)"
            />
          </div>
          <div v-if="dataResult.length == 0">
            <center style="padding-top:20px">
              <b>
                <span>No se encontro ninguna encuesta!</span>
              </b>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pollTypes from '@/store/types/poll';
import PollQualification from '@/components/polls_v2/pollQualification';
import ModalDates from '@/components/polls_v2/atoms/modalDates';
import CardPollDetail from '@/components/polls_v2/molecules/cardPollDetail';
import CardPoll from '@/components/polls_v2/molecules/cardPoll';
import globalTypes from '@/store/types/global';
export default {
  props: ['pollLenght', 'paginatorData', 'pollId', 'isLoading'],
  components: { PollQualification, CardPoll },
  mounted () {
    this.total = this.paginatorData.total;
    this.current = this.paginatorData.current_page;
    this.perPage = this.paginatorData.per_page;
    this.dataResult = this.pollInformation;
    const notification = document.getElementsByClassName('navbar-item')[0];
    if (notification) {
      notification.addEventListener('click', (e) => {
        if (this.openDetails) {
          this.openDetails = false;
        }
      });
    }
  },
  data () {
    return {
      dataResult: [],
      idPoll: 0,
      DayPollFilter: 7,
      query: '',
      group: '',
      groupTxt: '',
      polls: [],
      pollIdEncrypt: null,
      selectBg: false,
      dateBar_day: '00',
      dateBar_day2: '00',
      qualify: 0,
      comments: 0,
      users: 0,
      pullStars: null,
      pullStarsLength: null,
      generalVendorQualify: null,
      dataFilteredHorizontal: null,
      filterDate: null,
      filterDateEnd: null,
      dateRange: {
        start: null,
        end: null
      },
      searcherResponsive: false,
      openDetails: false,
      selectedPoll: [],
      selectOrdeByConfig: {
        value: 'Ordenado por',
        visible: false,
        options: [
          {
            name: 'Fecha ascendente',
            value: 'monthAsc'
          },
          {
            name: 'Fecha descendente',
            value: 'monthDesc'
          },
          {
            name: 'Encuestas contestadas',
            value: 'answered'
          },
          {
            name: 'Encuestas no contestadas',
            value: 'notAnswered'
          },
          {
            name: 'Calificaciones debajo de 3',
            value: '3'
          },
          {
            name: 'Calificaciones debajo de 2',
            value: '2'
          },
          {
            name: 'Calificaciones debajo de 1',
            value: '1'
          }
        ]
      },
      selectDateByConfig: {
        value: 'Últimos 7 Días',
        visible: false,
        options: [
          {
            name: 'Últimos 7 Días',
            value: 7
          },
          {
            name: 'Últimos 15 Días',
            value: 15
          },
          {
            name: 'Últimos 30 Días',
            value: 30
          }
        ]
      },
      openMenu: false,
      firstFilterPollBy: true
    };
  },
  methods: {
    getTitle (title = null) {
      if (title != null) {
        switch (this.DayPollFilter) {
          case 7:
            return 'No hay respuestas de encuestas en los últimos 7 días';
          case 15:
            return 'No hay respuestas de encuestas en los últimos 15 días';
          case 30:
            return 'No hay respuestas de encuestas en los últimos 30 días';
          default:
            return '';
        }
      }
      switch (this.DayPollFilter) {
        case 7:
          return 'Últimos 7 días';
        case 15:
          return 'Últimos 15 días';
        case 30:
          return 'Últimos 30 días';
        default:
          return '';
      }
    },
    filterPollBy (changed = false, dayPollFilter = null, group = null) {
      if (changed) this.filtersChange(dayPollFilter, group);
      const id = this.decryptMx(this.pollId);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      let orderDate = null;
      let answerNull = null;
      let dayFilter = null;
      let small = null;
      if (this.group === 'monthAsc') {
        orderDate = 'asc';
      }
      if (this.group === 'monthDesc') {
        orderDate = 'desc';
      }
      if (this.group === 'answered') {
        answerNull = 'desc';
      }
      if (this.group === 'notAnswered') {
        answerNull = 'asc';
      }
      if (this.group === '3') {
        small = 3;
      }
      if (this.group === '2') {
        small = 2;
      }
      if (this.group === '1') {
        small = 3;
      }
      if (this.dateRange != null && this.dateRange.start && this.dateRange.end) {
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, pollId: this.pollId, smaller: small, orderDate: orderDate, answerNull: answerNull, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });

            this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, pollId: id, smaller: small, orderDate: orderDate, answerNull: answerNull, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
              if (data.code === 200) {
                this.dataFilteredHorizontal = null;
                const dataNew = {};
                dataNew.generalVendorQualify = data.data.generalVendorQualify;
                dataNew.checkQuestions = data.data.checkbox;
                dataNew.qualify = data.data.qualify;
                dataNew.comments = data.data.comments;
                dataNew.users = data.data.users;
                dataNew.allPolls = data.data;
                let index = 0;
                const chunkSize = 5;
                const arrayLength = Object.keys(data.data.stars).length;
                const tempArray = [];
                for (index = 0; index < arrayLength; index += chunkSize) {
                  const myChunk = Object.entries(data.data.stars).slice(
                    index,
                    index + chunkSize
                  );
                  tempArray.push(myChunk);
                }
                dataNew.pullStars = tempArray;
                dataNew.pullStarsLength = Object.keys(data.data.stars).length;
                this.dataFilteredHorizontal = dataNew;
                this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
              } else {
                this.error = data.message;
              }
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            });
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } else if (this.DayPollFilter !== 'Rango de fechas') {
        this.filterDate = null;
        this.filterDateEnd = null;
        dayFilter = this.DayPollFilter;
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, pollId: this.pollId, smaller: small, orderDate: orderDate, answerNull: answerNull, dayRangeFilter: dayFilter } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
            this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, pollId: id, smaller: small, orderDate: orderDate, answerNull: answerNull, dayRangeFilter: dayFilter } }).then(({ data }) => {
              if (data.code === 200) {
                this.dataFilteredHorizontal = null;
                const dataNew = {};
                dataNew.generalVendorQualify = data.data.generalVendorQualify;
                dataNew.checkQuestions = data.data.checkbox;
                dataNew.qualify = data.data.qualify;
                dataNew.comments = data.data.comments;
                dataNew.users = data.data.users;
                dataNew.allPolls = data.data;
                let index = 0;
                const chunkSize = 5;
                const arrayLength = Object.keys(data.data.stars).length;
                const tempArray = [];
                for (index = 0; index < arrayLength; index += chunkSize) {
                  const myChunk = Object.entries(data.data.stars).slice(
                    index,
                    index + chunkSize
                  );
                  tempArray.push(myChunk);
                }
                dataNew.pullStars = tempArray;
                dataNew.pullStarsLength = Object.keys(data.data.stars).length;
                this.dataFilteredHorizontal = dataNew;
                this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
              } else {
                this.error = data.message;
              }
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            });
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.dateRange = {
          start: null,
          end: null
        };
      } else {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    },
    filterGroup (changed = false, dayPollFilter = null, group = null) {
      if (changed) {
        this.filtersChange(dayPollFilter, group);
      }
      if (this.group === 'monthAsc') {
        this.orderByMonth('asc');
        this.groupTxt = 'Fecha ascendente';
      }
      if (this.group === 'monthDesc') {
        this.orderByMonth('desc');
        this.groupTxt = 'Fecha descendente';
      }
      if (this.group === 'answered') {
        this.orderByAnswer('desc');
        this.groupTxt = 'Encuestas contestadas';
      }
      if (this.group === 'notAnswered') {
        this.orderByAnswer('asc');
        this.groupTxt = 'Encuestas no contestadas';
      }
      if (this.group === '3') {
        this.smallerThan(3);
        this.groupTxt = 'Calificaciones debajo de 3';
      }
      if (this.group === '2') {
        this.smallerThan(2);
        this.groupTxt = 'Calificaciones debajo de 2';
      }
      if (this.group === '1') {
        this.smallerThan(1);
        this.groupTxt = 'Calificaciones debajo de 1';
      }
    },
    orderByMonth (mod) {
      const id = this.decryptMx(this.pollId);
      let dayFilter = null;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (this.dateRange != null && this.dateRange.start !== null && this.dateRange.end !== null) {
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, orderDate: mod, pollId: this.pollId, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, orderDate: mod, pollId: id, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
            this.dataFilteredHorizontal = null;
            const dataNew = {};
            dataNew.generalVendorQualify = data.data.generalVendorQualify;
            dataNew.checkQuestions = data.data.checkbox;
            dataNew.qualify = data.data.qualify;
            dataNew.comments = data.data.comments;
            dataNew.users = data.data.users;
            dataNew.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            dataNew.pullStars = tempArray;
            dataNew.pullStarsLength = Object.keys(data.data.stars).length;
            this.dataFilteredHorizontal = dataNew;
            this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } else if (this.DayPollFilter !== 'Rango de fechas') {
        dayFilter = this.DayPollFilter;
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, orderDate: mod, pollId: this.pollId, dayRangeFilter: dayFilter } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, orderDate: mod, pollId: id, dayRangeFilter: dayFilter } }).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
          if (data.code === 200) {
            this.dataFilteredHorizontal = null;
            const dataNew = {};
            dataNew.generalVendorQualify = data.data.generalVendorQualify;
            dataNew.checkQuestions = data.data.checkbox;
            dataNew.qualify = data.data.qualify;
            dataNew.comments = data.data.comments;
            dataNew.users = data.data.users;
            dataNew.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            dataNew.pullStars = tempArray;
            dataNew.pullStarsLength = Object.keys(data.data.stars).length;
            this.dataFilteredHorizontal = dataNew;
            this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      }
    },
    orderByAnswer (mod) {
      const id = this.decryptMx(this.pollId);
      let dayFilter = null;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (this.dateRange != null && this.dateRange.start !== null && this.dateRange.end !== null) {
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, answerNull: mod, pollId: this.pollId, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, answerNull: mod, pollId: id, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
          if (data.code === 200) {
            this.dataFilteredHorizontal = null;
            const dataNew = {};
            dataNew.generalVendorQualify = data.data.generalVendorQualify;
            dataNew.checkQuestions = data.data.checkbox;
            dataNew.qualify = data.data.qualify;
            dataNew.comments = data.data.comments;
            dataNew.users = data.data.users;
            dataNew.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            dataNew.pullStars = tempArray;
            dataNew.pullStarsLength = Object.keys(data.data.stars).length;
            this.dataFilteredHorizontal = dataNew;
            this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } else if (this.DayPollFilter !== 'Rango de fechas') {
        dayFilter = this.DayPollFilter;
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, answerNull: mod, pollId: this.pollId, dayRangeFilter: dayFilter } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
            this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, answerNull: mod, pollId: id, dayRangeFilter: dayFilter } }).then(({ data }) => {
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
              if (data.code === 200) {
                this.dataFilteredHorizontal = null;
                const dataNew = {};
                dataNew.generalVendorQualify = data.data.generalVendorQualify;
                dataNew.checkQuestions = data.data.checkbox;
                dataNew.qualify = data.data.qualify;
                dataNew.comments = data.data.comments;
                dataNew.users = data.data.users;
                dataNew.allPolls = data.data;
                let index = 0;
                const chunkSize = 5;
                const arrayLength = Object.keys(data.data.stars).length;
                const tempArray = [];
                for (index = 0; index < arrayLength; index += chunkSize) {
                  const myChunk = Object.entries(data.data.stars).slice(
                    index,
                    index + chunkSize
                  );
                  tempArray.push(myChunk);
                }
                dataNew.pullStars = tempArray;
                dataNew.pullStarsLength = Object.keys(data.data.stars).length;
                this.dataFilteredHorizontal = dataNew;
                this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
              } else {
                this.error = data.message;
              }
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            });
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      }
    },
    smallerThan (mod) {
      const id = this.decryptMx(this.pollId);
      let dayFilter = null;
      if (this.dateRange != null && this.dateRange.start !== null && this.dateRange.end !== null) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, smaller: mod, answerNull: 'desc', pollId: this.pollId, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, smaller: mod, answerNull: 'desc', pollId: id, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
          if (data.code === 200) {
            this.dataFilteredHorizontal = null;
            const dataNew = {};
            dataNew.generalVendorQualify = data.data.generalVendorQualify;
            dataNew.checkQuestions = data.data.checkbox;
            dataNew.qualify = data.data.qualify;
            dataNew.comments = data.data.comments;
            dataNew.users = data.data.users;
            dataNew.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            dataNew.pullStars = tempArray;
            dataNew.pullStarsLength = Object.keys(data.data.stars).length;
            this.dataFilteredHorizontal = dataNew;
            this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } else if (this.DayPollFilter !== 'Rango Fecha') {
        dayFilter = this.DayPollFilter;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, smaller: mod, answerNull: 'desc', pollId: this.pollId, dayRangeFilter: dayFilter } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
        this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, smaller: mod, answerNull: 'desc', pollId: id, dayRangeFilter: dayFilter } }).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
          if (data.code === 200) {
            this.dataFilteredHorizontal = null;
            const dataNew = {};
            dataNew.generalVendorQualify = data.data.generalVendorQualify;
            dataNew.checkQuestions = data.data.checkbox;
            dataNew.qualify = data.data.qualify;
            dataNew.comments = data.data.comments;
            dataNew.users = data.data.users;
            dataNew.allPolls = data.data;
            let index = 0;
            const chunkSize = 5;
            const arrayLength = Object.keys(data.data.stars).length;
            const tempArray = [];
            for (index = 0; index < arrayLength; index += chunkSize) {
              const myChunk = Object.entries(data.data.stars).slice(
                index,
                index + chunkSize
              );
              tempArray.push(myChunk);
            }
            dataNew.pullStars = tempArray;
            dataNew.pullStarsLength = Object.keys(data.data.stars).length;
            this.dataFilteredHorizontal = dataNew;
            this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      }
    },
    cleanFilter (type) {
      if (type === 'group') {
        this.group = '';
        this.groupTxt = '';
        this.filterPollBy();
      }
      if (type === 'range_dates') {
        this.dateRange = null;
        this.DayPollFilter = 7;
        this.filterPollBy();
      }
    },
    pollExist (val, paginatorData, pollId) {
      this.$emit(
        'poll-exist2',
        val,
        paginatorData,
        pollId
      );
    },
    filtersChange (dayPollFilter, group) {
      this.DayPollFilter = dayPollFilter;
      this.group = group;
    },
    openModal () {
      this.showModal = true;
      const instance = this;
      this.$buefy.modal.open({
        events: {
          filterDateRange (dateRange) {
            instance.dateRange = dateRange;
            instance.$emit('close');
          }
        },
        parent: this,
        component: ModalDates,
        hasModalCard: true,
        customClass: 'modal_dates_poll',
        trapFocus: true
      });
    },
    capitalize (text) {
      let lower = text.toLowerCase();
      const first = lower.charAt(0).toUpperCase();
      lower = lower.slice(1, -1);
      const combined = first + lower;
      return combined;
    },
    openSearcher () {
      this.searcherResponsive = !this.searcherResponsive;
    },
    showDetails (pollDetails) {
      this.selectedPoll = pollDetails;
      const notification = document.getElementsByClassName('navbar-item');
      const openNotification = document.getElementsByClassName('enter');
      if (openNotification.length > 0) {
        notification[0].click();
      }
      this.openDetails = true;
      const instance = this;
      this.$buefy.modal.open({
        events: {
          filterDateRange (dateRange) {
            instance.dateRange = dateRange;
            instance.$emit('close');
          }
        },
        props: {
          openDetails: instance.openDetails,
          selectedPoll: instance.selectedPoll
        },
        parent: this,
        component: CardPollDetail,
        hasModalCard: true,
        customClass: 'modal_details',
        trapFocus: true,
        animation: 'slide-prev'
      });
    },
    selectOrdeBy (option, text, type) {
      if (type === 'orderBy') {
        this.group = option;
        this.selectOrdeByConfig.value = text;
        this.filterGroup(false);
      } else {
        this.DayPollFilter = option;
        this.dateRange = null;
        this.selectDateByConfig.value = text;
        this.filterPollBy(false);
      }
    },
    toggle (type) {
      if (type === 'orderBy') {
        this.selectOrdeByConfig.visible = !this.selectOrdeByConfig.visible;
      } else {
        this.selectDateByConfig.visible = !this.selectDateByConfig.visible;
      }
    },
    mouseOut (type) {
      if (type === 'orderBy') {
        this.selectOrdeByConfig.visible = false;
      } else {
        this.selectDateByConfig.visible = false;
      }
    },
    openSideBar () {
      if (this.openMenu === false) {
        this.$store.commit(globalTypes.mutations.setShowSideBar, true);
        this.openMenu = true;
        return;
      }
      this.$store.commit(globalTypes.mutations.setShowSideBar, false);
      this.openMenu = false;
    }
  },
  watch: {
    pollInformation () {
      this.dataResult = this.pollInformation;
      this.$emit('filters-exist', (this.dateRange != null) ? this.dateRange.start : null, (this.dateRange != null) ? this.dateRange.end : null, this.DayPollFilter, this.query, this.group);
    },
    dateRange () {
      const id = this.decryptMx(this.pollId);
      let orderDate = null;
      let answerNull = null;
      if (this.group === 'monthAsc') {
        orderDate = 'asc';
      }
      if (this.group === 'monthDesc') {
        orderDate = 'desc';
      }
      if (this.group === 'answered') {
        answerNull = 'desc';
      }
      if (this.group === 'notAnswered') {
        answerNull = 'asc';
      }
      if (this.dateRange != null && this.dateRange.end && this.dateRange.start) {
        const format = 'YYYY-MM-DD';
        this.dateRange.end = this.$moment(this.dateRange.end).format(format);
        this.dateRange.start = this.$moment(this.dateRange.start).format(format);
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
        this.$store.dispatch({ type: 'poll:result', data: { filterPollBy: this.query, pollId: this.pollId, orderDate: orderDate, answerNull: answerNull, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
          if (data.code === 200) {
            this.$store.commit(pollTypes.mutations.setPollInformation, data.data.reservation.data);
            this.$emit('pagi', data.data.reservation);
            this.$store.dispatch({ type: 'poll:filteredPollData', data: { filterPollBy: this.query, pollId: id, orderDate: orderDate, answerNull: answerNull, filterDate: this.dateRange.start, filterDateEnd: this.dateRange.end } }).then(({ data }) => {
              if (data.code === 200) {
                this.dataFilteredHorizontal = null;
                const dataNew = {};
                dataNew.generalVendorQualify = data.data.generalVendorQualify;
                dataNew.checkQuestions = data.data.checkbox;
                dataNew.qualify = data.data.qualify;
                dataNew.comments = data.data.comments;
                dataNew.users = data.data.users;
                dataNew.allPolls = data.data;
                let index = 0;
                const chunkSize = 5;
                const arrayLength = Object.keys(data.data.stars).length;
                const tempArray = [];
                for (index = 0; index < arrayLength; index += chunkSize) {
                  const myChunk = Object.entries(data.data.stars).slice(
                    index,
                    index + chunkSize
                  );
                  tempArray.push(myChunk);
                }
                dataNew.pullStars = tempArray;
                dataNew.pullStarsLength = Object.keys(data.data.stars).length;
                this.dataFilteredHorizontal = dataNew;
                this.$store.commit(pollTypes.mutations.setPollData, this.dataFilteredHorizontal);
              } else {
                this.error = data.message;
              }
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            });
          } else {
            this.error = data.message;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });

        this.DayPollFilter = 7;
      }
    },
    pollId () {
      if (this.firstFilterPollBy && typeof this.pollId === 'string') {
        this.filterPollBy();
        this.firstFilterPollBy = false;
      }
    }
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/polls/_poll_detail";
</style>
