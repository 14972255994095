<template>
  <div @click="$emit('changeValue', 1)" :class="`check-template ${all ? 'check-template-fix' : ''}`">
    <div class="descheck" v-if="type === 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="9.5" fill="white" stroke="#C9C9C9"/>
      </svg>
    </div>
    <div class="descheck" v-if="(type === 1 || type === 2) && !all">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.62067 17.0863C5.55087 18.9843 8.12116 20.0279 10.8602 20.0279C13.5942 20.0279 16.1595 18.9891 18.0798 17.1009C20.0199 15.2078 21.0867 12.6983 21.0867 10.0188V9.1645C21.0867 8.69851 20.7047 8.31989 20.2233 8.31989C19.747 8.31989 19.36 8.69365 19.36 9.1645V10.0188C19.36 12.242 18.4767 14.3292 16.8691 15.902C15.2564 17.4698 13.1278 18.3338 10.8552 18.3338H10.8502C6.16119 18.329 2.34545 14.5962 2.35041 10.0091C2.35041 7.78594 3.2386 5.69869 4.84627 4.12111C6.45891 2.55324 8.58758 1.68922 10.8602 1.68922H10.8651C12.0609 1.68922 13.227 1.93678 14.3186 2.41248C14.7553 2.60664 15.2664 2.41248 15.4599 1.98532C15.6584 1.55816 15.4599 1.05819 15.0232 0.86888C13.7033 0.296099 12.3041 0 10.8651 0H10.8602C8.12612 0 5.56079 1.03877 3.6306 2.92701C1.69048 4.81524 0.623657 7.32966 0.623657 10.0043C0.623657 12.6788 1.68551 15.1933 3.62067 17.0863ZM10.2498 12.4507C10.4186 12.6157 10.6418 12.6983 10.8602 12.6983C11.0785 12.6983 11.3018 12.6157 11.4705 12.4507L21.7864 2.36878C22.1238 2.0387 22.1238 1.4999 21.7864 1.16982C21.449 0.839745 20.8982 0.839745 20.5608 1.16982L10.8602 10.6547L8.66202 8.49948C8.32461 8.1694 7.77383 8.1694 7.43642 8.49948C7.09901 8.82956 7.09901 9.36836 7.43642 9.69844L10.2498 12.4507Z" :fill="(type === 1) ? '#C9C9C9' : '#3DAF41'"/>
      </svg>
    </div>
    <div class="descheck" v-if="(type === 1 || type === 2) && all" style="position: relative;">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style="position: relative;">
        <circle cx="10" cy="10" r="9.5" fill="white" stroke="#C9C9C9"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" style="position: absolute; top: 3px; left: 3px;">
        <circle cx="7" cy="7" r="7" fill="#444B57"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmailCheck',
  props: {
    type: {
      type: Number
    },
    all: {
      type: Boolean,
      default: false
    },
    checkDesign: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss">
.check-template {
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 20px;
  cursor: pointer;
  &-fix {
    padding-top: 0px!important;
    @media screen and (max-width: 500px) {
      padding-top: 10px!important;
    }
  }
  @media screen and (max-width: 500px) {
    align-items: flex-start;
    padding-bottom: unset;
  }
  .descheck {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
  }
}
</style>
