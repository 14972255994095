<template>
  <div
    v-show="map"
    id="Reservation-Component"
    class="animated fadeIn"
    :class="{
      'component-lateral': toggle,
      'active-toogle': toggle,
      'with-turn': validateButtons(),
      'button-filters': showFilterButtons,
    }"
  >
    <div class="header-box" v-if="toggle == true && turns">
      <a
        class="card-title"
        :class="{ 'tittle-lateral': toggle }"
        :style="{top: '10px', left: '-2px'}"
      >
        <i class="icon-mks icon_reservation" :class="{'black': !toggle, 'white': toggle}"></i>
        <span :style="{color: (toggle) ? 'white': 'black', marginBottom: '15px'}">Reservas</span>
        <i class="icon_arrow_left_map icon-mks" :class="{'gray-blue': !toggle, 'white': toggle}" style="widows: 20px; height: 20px; margin-bottom: 15px;" @click="expand()"></i>
        <div
          class="alertContainer lateralposition"
          v-show="(dateFilterSelect.length > 0 || selectedDates.length > 0)"
        >
          <i class="icon_alert_danger"></i>
        </div>
      </a>
    </div>
    <div class="date-title">
      <i
        class="icon_arrow_left_map icon-mks black btn-dateter"
        :class="{ 'not-active-reservation': loadingReservation }"
        @click="prevDate()"
      ></i
      >{{ selectedDate | moment("dddd DD MMMM YYYY") }}
      <i
        class="icon_arrow_right_map icon-mks black btn-dateter"
        :class="{ 'not-active-reservation': loadingReservation }"
        @click="nextDate()"
      ></i>
    </div>
    <div class="serch">
      <div class="left">
        <input
          type="text"
          v-model="query"
          class="campo"
          placeholder="Nombre, teléfono, comentarios, vip o requerimientos"
          title="Nombre, teléfono, comentarios, vip o requerimientos"
          @keydown.esc="query = ''"
        />
        <!-- Input para capturar el autocompletado de chrome. No quitar -->
        <input
          type="text"
          class="campo"
          placeholder="autocomplete"
          style="position: absolute; left: -500px"
        />
        <span class="material-icons-outlined" v-show="!query">
        search
        </span>
        <span class="material-icons-outlined" v-show="query" @click.prevent.stop="query = ''">
        close
        </span>
      </div>
      <div class="right">
        <div class="flex" v-if="!validatePermission('ReadOnly')">
          <i
            class="icon-mks black icon_dashboard_printer"
            style="margin-right: unset;"
            @click="generateReport($event)"
          ></i>
            <ReporteReservation
              v-if="downloadReportModalActive"
              v-model="apiReportDownload"
              @close-emit="CloseMenuM"
              :event="eventForModal"
            ></ReporteReservation>
        </div>
      </div>
    </div>
    <div class="bg-list-container">
      <div class="list-container">
        <div class="columns tab-list is-mobile">
          <div
            class="column tab-item"
            @click.prevent.stop="currentFilter = 'active'"
            :class="{ active: currentFilter === 'active' }"
            :style="{
              color:
                currentFilter === 'active' ? vendorParams.toolbarColor : '',
            }"
          >
            PRÓXIMAS ({{ amounts["active"] }})
          </div>
          <div
            class="column tab-item partial-border-right"
            @click.prevent.stop="currentFilter = 'partialSeated'"
            :class="{ active: currentFilter === 'partialSeated' }"
            :style="{
              color:
                currentFilter === 'partialSeated'
                  ? vendorParams.toolbarColor
                  : '',
            }"
            v-if="params.activatePartialSeated"
            v-tooltip="{ content: 'Parcialmente Sentado', placement: 'bottom' }"
          >
            PS ({{ amounts["partialSeated"] }})
          </div>
          <div
            class="column tab-item"
            @click.prevent.stop="currentFilter = 'seated'"
            :class="{ active: currentFilter === 'seated' }"
            :style="{
              color:
                currentFilter === 'seated' ? vendorParams.toolbarColor : '',
            }"
          >
            SENTADAS ({{ amounts["seated"] }})
          </div>
          <div
            class="column tab-item"
            @click.prevent.stop="currentFilter = 'finish'"
            :class="{ active: currentFilter === 'finish' }"
            :style="{
              color:
                currentFilter === 'finish' ? vendorParams.toolbarColor : '',
            }"
          >
            FINALIZADAS ({{ amounts["finish"] }})
          </div>
        </div>
        <ReservationList
          :filter="currentFilter"
          :query="query"
          :cfilter="currentFilter"
        ></ReservationList>
      </div>
      <div
        class="btn-box"
        v-if="!validatePermission('ReadOnly') && !validatePermission('MixSFCN')"
        :class="{ noPrecompra: !params.prePurchaseDashboard }"
      >
        <a
          href="javascript:void(0)"
          @click.prevent.stop="setAddReservation()"
          class="buton-action reservation"
          :class="{
            'btn-reserva-lateral': toggle,
            'buttons-change-size':
              params.prePurchaseDashboard && selectedDate == today,
          }"
        >
          <div>Reserva</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <a
          href="javascript:void(0)"
          @click.prevent.stop="setAddPrecompra()"
          class="buton-action precompra"
          :class="{
            'btn-reserva-lateral': toggle,
            'buttons-change-size':
              params.prePurchaseDashboard && selectedDate == today,
          }"
          v-if="params.prePurchaseDashboard"
        >
          <div>Precompra</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <a
          href="javascript:void(0)"
          @click.prevent.stop="setAddWalkin()"
          class="buton-action walking"
          :class="{
            'btn-walkin-lateral': toggle,
            'buttons-change-size': params.prePurchaseDashboard,
          }"
          v-if="selectedDate == today"
        >
          <div>Walk-In</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <button
          class="buttonList"
          @click="toggleMenuL"
          :peopleAwaiting="listLength"
          :class="{ peopleWaiting: listLength > 0 }"
        >
          <img src="@/assets/icons/addReservation/clock.svg" alt="icon" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReservationList from './ReservationList';
import reservationTypes from '@/store/types/reservation';
import ReporteReservation from '@/components/_shared/ReporteReservation';
import _ from 'lodash';

export default {
  name: 'ReservationsRVue',
  components: { ReservationList, ReporteReservation },
  props: ['lateral', 'showFilterButtons'],
  data () {
    return {
      query: '',
      proximas: true,
      sentadas: false,
      terminadas: false,
      generate: false,
      todayReservations: [],
      currentFilter: 'active',
      toggle: this.lateral,
      listLength: 0,
      downloadReportModalActive: false,
      eventForModal: {}
    };
  },
  created () {
    window.setInterval(this.getListLength, 600);
  },
  computed: {
    amounts () {
      return _.mapValues(
        { active: {}, partialSeated: {}, seated: {}, finish: {} },
        (item, key) => this.getAmount(key)
      );
    },
    ...mapGetters({
      turns: reservationTypes.getters.theTurns,
      loadingReservation: reservationTypes.getters.loadingReservation
    })
  },
  methods: {
    validateButtons () {
      let status = false;
      let tipo = 'almuerzo';
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      if (this.turns) {
        if (!status) {
          if (this.turns[tipo]) {
            if (this.turns[tipo][this.selectedDate] !== undefined) {
              status = true;
            } else if (this.turns[tipo][dayOfWeek] !== undefined) {
              status = true;
            }
          }
        }
        if (!status) {
          tipo = 'cena';
          if (this.turns[tipo]) {
            if (this.turns[tipo][this.selectedDate] !== undefined) {
              status = true;
            } else if (this.turns[tipo][dayOfWeek] !== undefined) {
              status = true;
            }
          }
        }
        if (!status) {
          tipo = 'desayuno';
          if (this.turns[tipo]) {
            if (this.turns[tipo][this.selectedDate] !== undefined) {
              status = true;
            } else if (this.turns[tipo][dayOfWeek] !== undefined) {
              status = true;
            }
          }
        }
      }
      return status;
    },
    getListLength () {
      this.listLength = window.localStorage.getItem('listLength');
    },
    toggleMenuL () {
      this.$store.commit(
        reservationTypes.mutations.setSideLateral,
        !this.sidelateral
      );
    },
    generateReport (e) {
      const posicion = e.target.getBoundingClientRect();
      this.eventForModal = { pageX: posicion.x + 8, pageY: posicion.y + 9 };
      if (this.downloadReportModalActive) {
        this.downloadReportModalActive = false;
        return;
      }
      this.OpenMenuM();
    },
    getAmount (filter) {
      const date = this.selectedDate;
      const amount = _(this.reservations[date])
        .filter((reservation) => {
          let returnable = true;
          switch (filter) {
            case 'finish':
              returnable =
                reservation.codeStatus === 4 ||
                reservation.codeStatus === 5 ||
                reservation.codeStatus === 3;
              break;
            case 'seated':
              returnable = reservation.codeStatus === 2;
              break;
            case 'partialSeated':
              returnable = reservation.codeStatus === 6;
              break;
            case 'active':
              returnable =
                reservation.codeStatus === 1 ||
                reservation.codeStatus === 0 ||
                reservation.codeStatus === 7;
              break;
          }
          return (
            returnable &&
            (!_.size(this.selectedDates) ||
              _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')))
          );
        })
        .size();
      return amount;
    },
    prevDate () {
      const prev = this.$moment(this.dateComplete)
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, prev);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment(
        new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      ).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(
        reservationTypes.mutations.setLoadingReservation,
        true
      );
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store
        .dispatch({
          type: 'reservation:reservations',
          data: {
            vendorId: this.$store.state.vendorId,
            date: this.selectedDate
          }
        })
        .finally(() => {
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        });
    },
    nextDate () {
      const next = this.$moment(this.dateComplete)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, next);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment(
        new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      ).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(
        reservationTypes.mutations.setLoadingReservation,
        true
      );
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store
        .dispatch({
          type: 'reservation:reservations',
          data: {
            vendorId: this.$store.state.vendorId,
            date: this.selectedDate
          }
        })
        .finally(() => {
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        });
    },
    contraer () {
      this.toggle = true;
      this.$emit('toggle', this.toggle);
    },
    expand () {
      this.toggle = false;
      this.$emit('toggle', this.toggle);
    },
    CloseMenuM () {
      this.downloadReportModalActive = false;
    },
    OpenMenuM () {
      this.downloadReportModalActive = true;
    }
  },
  watch: {
    selectedDate () {
      this.currentFilter = 'active';
      this.getAmount(this.currentFilter);
    },
    selectedDates () {
      this.currentFilter = 'active';
      this.getAmount(this.currentFilter);
    },
    map () {
      this.downloadReportModalActive = false;
      this.toggle = false;
    },
    lateral () {
      this.toggle = this.lateral;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.not-active-reservation {
  pointer-events: none !important;
  opacity: 0.7;
}
.component-lateral {
  padding-left: 30px;
  transition: width 0.4s ease-in-out;
}
#Reservation-Component {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  background: #ededed;
  z-index: 1;
  &.component-lateral {
    .btn-box {
      visibility: hidden;
    }
  }
  .precompra {
    background: #bdc30e;
  }
  &.with-turn {
    border-top: 6px solid #ededed;
    height: calc(100% - 85px);
  }
  .btn-dateter {
    font-size: 1rem;
    margin: 0px;
    width: 7px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buton-seple {
    border: none;
    background: transparent;
    transform: rotate(-90deg);
    padding-bottom: 10px;
  }
  .btn-reserva-lateral {
    position: absolute;
    left: -89px;
    bottom: 15px;
    transform: rotate(180deg);
  }
  .btn-walkin-lateral {
    position: absolute;
    left: -84px;
    bottom: 60px;
    transform: rotate(180deg);
  }
  .alertContainer {
    background-color: red;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 1000px) {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      &.lateralposition {
        display: none;
      }
    }
    @media screen and (min-width: 1000px) {
      &:not(.lateralposition) {
        display: none;
      }
    }
    .icon_alert_danger {
      width: 100%;
      height: 100%;
      align-self: center;
      mask-repeat: no-repeat;
      mask-size: 60%;
      mask-position: center;
      background-color: white;
      @media screen and (min-width: 1000px) {
        transform: rotate(180deg);
      }
    }
  }
  .tittle-lateral {
    position: absolute;
    left: 0px;
    top: 10px;
    right: 0px;
    margin: auto;
    font-weight: bold;
    color: rgba(68, 75, 87, 1);
    cursor: pointer !important;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    .icon_reservation, .buton-seple {
      height: 25px;
      width: 30px;
      margin-bottom: 10px;
      transform: rotate(90deg);
      margin-right: unset;
      padding: unset;
    }
    .buton-seple {
      transform: rotate(0deg);
      margin-top: 10px;
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
  }
  .serch {
    opacity: 1;
    transition-delay: 0s;
    transition: all 0.2s ease;
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    .left {
      width: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .right {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      margin-top: 5px;
    }
    .campo {
      padding: 10px 30px 10px 15px;
      border: 1px solid #ccc;
      width: calc(100% - 10px);
      margin-left: 5px;
      border-radius: 50px;
      font-size: 10px;
      color: #000;
      outline: none;
    }
    span {
      position: absolute;
      right: 13px;
      font-size: 22px;
      color: #ccc;
      cursor: pointer;
    }
  }
  .date-title {
    padding: 10px 5px 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 1;
    transition-delay: 0s;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-height: 40px;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .columns {
    margin: 0;
    padding: 0;
    &:first-child {
      margin: 0 !important;
    }
    &:last-child {
      margin: 0 !important;
    }
    .column {
      margin: 0;
      padding: 0;
    }
  }
  .card-title {
    display: flex;
    align-items: center;
    i {
      height: 25px;
      width: 30px;
      margin-top: 0px;
    }
  }
  .header-box {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px 10px 0 0;
  }
  .bg-list-container {
    background: #fff;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 145px);
  }
  .list-container {
    max-height: 100%;
    border: 2px solid #e5e6e7;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    height: 100%;
    opacity: 1;
    transition-delay: 0s;
    transition: all 0.2s ease;
    flex: 2;
    overflow: auto;
    background: #fff;
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background: #fff;
    padding: 10px 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    &.noPrecompra {
      justify-content: center;
      gap: 16px;
    }
  }
  .btn-box, .btn-box-2 {
    .buton-action {
      color: #fff;
      font-weight: 600;
      display: flex;
      padding: 8px 17px 8px 17px !important;
      border-radius: 50px;
      line-height: 22px;
      font-family: "Roboto";
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      min-width: 93px;
      justify-content: center;
      &.buttons-change-size {
        padding: 5px 8px 5px 10px;
      }
      &.reservation {
        background: #00adc6;
      }
      &.walking {
        background: #8a51b4;
      }
      &.precompra {
        background: #bdc30e;
      }
      i {
        height: 14px;
        width: 16px;
        font-size: 18px;
        margin-left: 2px;
        mask-repeat: no-repeat;
        background-color: white;
        mask-position: top;
      }
    }
  }
  .partial-border-right {
    border-right: 2px solid #e5e6e7;
  }
  .tab-list {
    background: #ebeced;
    margin-bottom: 10px !important;
    .tab-item {
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 9px;
      padding: 10px 0px;
      margin: 0;
      height: auto;
      font-weight: 500;
      cursor: pointer;
      &:first-child {
        border-right: 2px solid #e5e6e7;
      }
      &:last-child {
        border-left: 2px solid #e5e6e7;
      }
      &.active {
        background: #fff;
      }
    }
  }
  /* Menu */
  .menu-wrap-messager {
    position: absolute;
    z-index: 999;
    background: transparent;
    -webkit-transition: width 0.3s, height 0.3s;
    transition: width 0.3s, height 0.3s;
    width: 0;
    height: 0;
    font-size: 1.5em;
    left: 10%;
    top: 0;
    right: -20%;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    border-radius: 10px;
  }
  .menu-messager {
    opacity: 0;
    font-size: 0.65em;
    color: #000;
    text-align: right;
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    position: relative;
    background: #fff;
  }
  .not-show-menu {
    display: none;
    z-index: 9999;
    position: relative;
    max-width: 338px;
  }
  .show-menu-messager {
    display: flex;
    .circle-absolute {
      background: #fff;
      height: 30px;
      width: 30px;
      position: absolute;
      right: 25px;
      top: 0px;
      border-radius: 0;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
  /* Shown menu */
  .show-menu-messager .table-detail {
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  .show-menu-messager .menu-wrap-messager {
    width: 100%;
    -webkit-animation: anim-jelly 0.8s linear forwards;
    animation: anim-jelly 0.8s linear forwards;
    top: 0;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  .show-menu-messager .menu-messager {
    opacity: 1;
    -webkit-transition: opacity 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    -webkit-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 15px -4px rgba(0, 0, 0, 0.75);
    z-index: 2;
    .table-detail {
      opacity: 1;
    }
  }
}
.desplegar {
  width: 30px;
  #Reservation-Component.with-turn {
    background: #fff !important;
    border-top: 0;
    border-radius: 10px;
    overflow: hidden;
  }
  #Reservation-Component.active-toogle {
    background: #444B57 !important;
    height: 100%;
    @media screen and (max-width:1000px) {
      background: #fff !important;
    }
    .header-box {
      background: #444B57 !important;
      @media screen and (max-width:1000px) {
        background: #fff !important;
      }
    }
    .bg-list-container {
      background: #444B57 !important;
      @media screen and (max-width:1000px) {
        background: #fff !important;
      }
    }
  }
}
</style>
