import { render, staticRenderFns } from "./ValidationAction.vue?vue&type=template&id=15d3a5a2"
import script from "./ValidationAction.vue?vue&type=script&lang=js"
export * from "./ValidationAction.vue?vue&type=script&lang=js"
import style0 from "./ValidationAction.vue?vue&type=style&index=0&id=15d3a5a2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports