<template>
    <div class="cont-reservation-messager">
      <div v-for="(reservation, keyHourSeats) in info" :key="keyHourSeats+'.5'" >
        <div class="bar-reserv"
            :class="{
            'notification-reserva': ((reservation.balancePaid == null || parseInt(reservation.balancePaid) == 0) && reservation.referrer != 'Walk-In' && reservation.codeStatus !== 4)?true:false,
            'notification-precompra': (parseInt(reservation.balancePaid) > 0 && reservation.referrer != 'Walk-In' && reservation.codeStatus !== 4 && reservation.codeStatus !== 7)?true:false,
            'notification-precompra-pending': (parseInt(reservation.balancePaid) > 0 && reservation.referrer != 'Walk-In' && reservation.codeStatus !== 4 && reservation.codeStatus === 7)?true:false,
            'notification-walkin': (reservation.referrer == 'Walk-In' && reservation.codeStatus !== 4)?true:false,
            'seated': (reservation.codeStatus === 2),
            'seated partial': (reservation.codeStatus === 6)}"
            v-if="reservation.codeStatus !== 3 && reservation.codeStatus !== 4"
          >
          <div @click.prevent.stop="detailViewReservation(reservation)" class="col hour"><i class="icon-mks black icon_dashboard_clock"></i>
            <div v-if="reservation.codeStatus == 2" class="timer-box">{{ statusDisplay(reservation) }}</div>
            <div v-if="(reservation.codeStatus == 1 || reservation.codeStatus == 4 || reservation.codeStatus == 0 || reservation.codeStatus == 6 || reservation.codeStatus == 7)" >{{dateHour(reservation)}}</div>
          </div>
          <div class="col name" @click.prevent.stop="detailViewReservation(reservation)" >{{reservation.displayName}}</div>
          <div class="col peoples" @click.prevent.stop="detailViewReservation(reservation)">{{reservation.people}}</div>
          <button class="col btn-action" v-if="reservation.codeStatus == 2 && reservation.referrer == 'Walk-In' && !validatePermission('ReadOnly')"></button>
          <button class="col btn-action" v-if="reservation.codeStatus == 2 && reservation.referrer == 'Walk-In' && !validatePermission('ReadOnly')" @click="oldStatus('walk-in', reservation.id)">
            <i class="icon-mks black icon_reload"></i>
          </button>
          <button class="col btn-action" v-if="reservation.codeStatus == 2 && reservation.referrer !== 'Walk-In' && !validatePermission('ReadOnly')" @click="oldStatus('isSeated',reservation.id)">
            <i class="icon-mks black icon_reload"></i>
          </button>
          <button class="col btn-action" v-if="reservation.codeStatus == 6 && reservation.referrer !== 'Walk-In' && !validatePermission('ReadOnly')" @click="oldStatus('isPartialSeated',reservation.id)">
            <i class="icon-mks black icon_reload"></i>
          </button>
          <button class="col btn-action" v-if="(reservation.codeStatus == 1 || reservation.codeStatus == 0 ) && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && params.activatePartialSeated && !validatePermission('ReadOnly') && validatePermission('Seated') && validateSittingOnlyHour(reservation)" @click.prevent.stop="setReservationPartialSeated(reservation.id)" >
              <img src="../../../../assets/icons/sentado-parcial.svg" v-if="parseInt(reservation.balancePaid) <= 0">
              <img src="../../../../assets/icons/icono-sentado-verde.svg" v-if="parseInt(reservation.balancePaid) > 0">
          </button>
          <button class="col btn-action" v-if="(reservation.codeStatus == 1 || reservation.codeStatus == 0 ) && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && !validatePermission('ReadOnly') && validatePermission('Seated') && validateSittingOnlyHour(reservation)" @click.prevent.stop="setReservationSeated(reservation.id)" >
            <i class="icon-mks black icon_dashboard_seat"></i>
          </button>
          <button class="col btn-action" v-if="(reservation.isSeated || reservation.isPartialSeated) && reservation.codeStatus != 4 && reservation.codeStatus != 3 && !validatePermission('ReadOnly') && validatePermission('Finish')" @click="finish(reservation.id)" title="Finalizar Reserva">
            <i class="icon-mks black icon_estadofinalizar"></i>
          </button>
        </div>
      </div>
      <div v-for="(reservation, keyHourCancels) in info" :key="keyHourCancels+'can.5'" :class="{'display_none' : reservation.codeStatus !== 4}" >
        <div class="bar-reserv notification-cancel" >
          <div @click.prevent.stop="detailViewReservation(reservation)" class="col hour"><i class="icon-mks black icon_dashboard_clock"></i>
            <div>{{ statusDisplay(reservation) }}</div>
            <div>{{ dateHour(reservation) }}</div>
          </div>
          <div class="col name" @click.prevent.stop="detailViewReservation(reservation)" >{{reservation.displayName}}</div>
          <div class="col peoples" @click.prevent.stop="detailViewReservation(reservation)">{{reservation.people}}</div>
        </div>
      </div>
      <div v-for="(infoHours, keyHourBloked) in hourBlocked" :key="keyHourBloked" >
        <div class="bar-reserv blockead">
          <div class="col btn-action">
            <i class="icon-mks black icon_block"></i>
          </div>
          <div class="col name"><div class="er">{{ infoHours.fechaCompleta | moment('hh:mm a') }}</div> Bloqueada</div>
        </div>
      </div>
    </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'TableDetailHours',
  props: ['date'],
  data () {
    return {
      info: [],
      isLoading: false,
      isLoadingAction: false,
      reservas: [],
      hourInstant: null,
      now: null,
      clock: null,
      hourBlocked: [],
      yesterday: null
    };
  },
  computed: {
    ...mapGetters({
      additionalQuestions: [reservationTypes.getters.additionalQuestions],
      systemDate: [globalTypes.getters.serverTime]
    })
  },
  mounted () {
    this.setHourDetail();
    this.yesterday = this.$moment(this.systemDate).subtract(1, 'days').format('YYYY-MM-DD');
    setInterval(() => {
      this.now = this.$moment().valueOf();
    }, 1000);
    this.interval = setInterval(() => {
      this.clock = this.$moment();
    }, 1000);
  },
  methods: {
    statusDisplay (data) {
      if (data.codeStatus === 2 && data.referrer !== 'Walk-In') {
        const seatedTime = this.$moment(parseInt(data.isSeated)); // Convert isSeated to Moment object
        const duration = this.$moment.duration(this.clock.diff(seatedTime)); // Calculate duration
        return this.$moment.utc(duration.asMilliseconds()).format('HH:mm:ss'); // Format duration
      }
      if (data.codeStatus === 2 && data.referrer === 'Walk-In') {
        const seatedTimeWalkIn = this.$moment(parseInt(data.isSeatedWalkIn));
        const duration = this.$moment.duration(this.clock.diff(seatedTimeWalkIn));
        return this.$moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      }
    },
    setHourDetail () {
      const hour = this.$moment().format('HH');
      let minute = this.$moment().format('mm');
      if (minute <= 30) {
        minute = '00';
      } else {
        minute = '30';
      }
      const complete = hour + minute;
      this.hourInstant = complete;
    },
    dateMonth (data) {
      return this.$moment(parseInt(data.date)).format('MMM/DD');
    },
    dateHour (data) {
      return this.$moment(parseInt(data.date)).format('hh:mm a');
    },
    reservationType (type) {
      if (type.referrer === 'Walk-In') {
        return 'Walk-In';
      } else if (type.balancePaid > 0) {
        return 'Precompra';
      } else if ((type.balancePaid === 0 || type.balancePaid === null || type.balancePaid === '') && type.referrer === 'whitelabel') {
        return 'Reservas';
      } else if (type.referrer === 'phone') {
        return 'Teléfono';
      }
    },
    convertHour (hour) {
      const hor = hour.split(':');
      const hora = (hor[0] >= 9) ? '0' + hor[0] : hor[0];
      const minuto = hor[1];
      const hoy = this.$moment(this.dateComplete).hours(parseInt(hora)).minutes(parseInt(minuto)).format('YYYY-MM-DD HH:mm:ss');
      return hoy;
    },
    finish (reservationId) {
      this.accionLoadingF = true;
      if (this.accionLoadingF) {
        const userId = this.user.id;
        const score = this.reservationScore;
        const comments = this.reservationComments;
        const valueConsumed = this.valueConsumed;
        this.$store
          .dispatch({
            type: 'reservation:reservationIsFinish',
            data: {
              reservationId: reservationId,
              userId: userId,
              comments: comments,
              score: score,
              valueConsumed: valueConsumed
            }
          })
          .then(() => {
            this.accionLoadingF = false;
            this.isOpen = false;
          }).catch(err => {
            console.error(err);
          });
      }
    },
    getHourTableIsBlocked (blocked, timestamp) {
      if (blocked !== undefined) {
        const hay = blocked.length;
        if (hay > 0) {
          for (var i = 0; i < hay; i++) {
            if (blocked[i].fechaCompleta === timestamp) {
              return blocked[i].isBlocked;
            }
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    oldStatus (type, reservationId) {
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: type, userId: userId }
        })
        .then(() => {
          this.reverseLoading = false;
          this.isOpen = false;
        });
    },
    oldWalkin (type, reservationId) {
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: type, userId: userId }
        })
        .then(() => {
          this.reverseLoading = false;
          this.isOpen = false;
        });
    },
    getReservationTableId () {
      const reservas = _(this.reservas)
        .sortBy(reservation => (parseInt(reservation.date)))
        .filter(reservation => {
          return reservation && (!_.size(this.selectedDates) || _.includes(this.selectedDates, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')));
        }).value();
      this.info = [];
      if (reservas) {
        const reservationList = _(reservas).sortBy(reservation => (parseInt(reservation.date))).value();
        reservas.sort((a, b) => a.date - b.date);
        const reser = reservas;
        const ti = this.tableInfo.id;
        const data = [];
        if (reser !== undefined) {
          Object.keys(reservationList).forEach(function (key) {
            if (reser[key].tableId != null) {
              const tabl = reser[key].tableId.split(', ');
              const tables = tabl.map(Number);
              if (tables.includes(ti)) {
                data.push(reser[key]);
              }
            }
          });
        }
        const total = data.length;
        for (var w = 0; w < total; w++) {
          if (data[w].codeStatus === 2 || data[w].codeStatus === 6) {
            this.info.unshift(data[w]);
          } else {
            this.info.push(data[w]);
          }
        }
      }
    },
    detailViewReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.$store.dispatch({ type: 'people:findByReservationId', data: { id: infoReserva.id } }).then(response => {
        let type = '';
        if (infoReserva.referrer !== 'Walk-In') {
          type = (infoReserva.balancePaid === null || parseInt(infoReserva.balancePaid) === 0) ? 'Reserva' : 'Precompra';
        } else {
          type = 'Walk-In';
        }
        infoReserva.edit = true;
        if (response.code === 200 && response.data != null) {
          infoReserva.subscribed = response.data.subscribed;
          infoReserva.peopleId = response.data._id;
          infoReserva.peopleMetrics = response.data.peopleMetrics;
        }
        this.$store.commit(reservationTypes.mutations.setType, type);
        this.$store.commit(reservationTypes.mutations.setInfoReservation, infoReserva);
        this.$store.commit(reservationTypes.mutations.setViewReservation, true);
      });
    },
    setReservationConfirmed (id) {
      this.isLoadingAction = true;
      this.$store.dispatch({ type: 'reservation:reservationIsConfirmed', data: { reservationId: id, userId: this.user.id } }).then(() => {
        this.isLoadingAction = false;
      }).catch(() => {
        this.isLoadingAction = false;
      }).finally(() => {
        this.isLoadingAction = false;
      });
    },
    setReservationSeated (id) {
      this.isLoadingAction = true;
      this.$store.dispatch({ type: 'reservation:reservationIsSeated', data: { reservationId: id, userId: this.user.id } }).then(() => {
        this.isLoadingAction = false;
      }).catch(() => {
        this.isLoadingAction = false;
      }).finally(() => {
        this.isLoadingAction = false;
      });
    },
    setReservationPartialSeated (id) {
      this.isLoadingAction = true;
      this.$store.dispatch({ type: 'reservation:reservationIsSeatedPartial', data: { reservationId: id, userId: this.user.id } }).then(() => {
        this.isLoadingAction = false;
      }).catch(() => {
        this.isLoadingAction = false;
      }).finally(() => {
        this.isLoadingAction = false;
      });
    },
    isReservationNotConfirmed (id) {
      this.isLoadingAction = true;
      this.$store.dispatch({ type: 'reservation:undoReservationStatus', data: { reservationId: id, item: 'isConfirmed', userId: this.user.id } }).then(() => {
        this.isLoadingAction = false;
      }).catch(() => {
        this.isLoadingAction = false;
      }).finally(() => {
        this.isLoadingAction = false;
      });
    },
    validateSittingOnlyHour (reservation) {
      if (reservation.isSeated) {
        return true;
      }
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      } else {
        return true;
      }
      if (dif != null && dif <= 60) {
        return true;
      }
      return false;
    }
  },
  watch: {
    tableInfo () {
      if (this.tableInfo !== '') {
        this.reservas = this.reservations[this.selectedDate];
        this.getReservationTableId();
        this.hourBlocked = [];
        const thes = this;
        _.forEach(this.tableInfo.blocked, function (item) {
          if (item.fecha === thes.selectedDate) {
            thes.hourBlocked.push(item);
          }
        });
      }
    },
    selectedDate () {
      this.reservas = this.reservations[this.selectedDate];
      this.getReservationTableId();
      this.hourBlocked = [];
      const thes = this;
      _.forEach(this.tableInfo.blocked, function (item) {
        if (item.fecha === thes.selectedDate) {
          thes.hourBlocked.push(item);
        }
      });
    },
    selectedDates () {
      this.reservas = this.reservations[this.selectedDate];
      this.getReservationTableId();
    },
    reservations () {
      this.reservas = this.reservations[this.selectedDate];
      this.getReservationTableId();
    }
  }
};
</script>
<style lang="scss" scoped>
  .cont-reservation-messager{
    max-height: 137px;
    overflow-y: auto;
    margin-bottom: 10px;
     &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      background:#EBECED;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      outline: 1px solid #fff;
      border-radius: 47px;
      border: 1px solid #fff;
      -webkit-box-shadow: 0px -1px 0px #d1d1d1;
      -moz-box-shadow: 0px -1px 0px #d1d1d1;
      box-shadow: inset 0px 0px 4px #fff;
      width: 100%;
    }
  }

  .bar-reserv{
    display:flex;
    align-items: stretch;
    justify-content: flex-start;
    max-height: 35px;
    overflow:hidden;
    position: relative;
    cursor:pointer;
    .col{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 2px;
      flex:1;
      font-size: 12px;
      max-width:130px;
      text-align: left;
      i{
        width:20px;
        margin-top: 1px;
        height:15px;
      }
      &.btn-action{
        width:30px;
        max-width: 30px;
        border:none;
        background:transparent;
        justify-content: center;
        cursor:pointer;
        i{
          width:17px !important;
          height:17px !important;
          margin-right:0;
          margin-top: 0;
        }
        img{
          width: 17px !important;
          height: 17px !important;
          margin-right:0;
          margin-top: 0;
        }
      }
      &.hour{
        max-width: 93px;
        line-height: 2;
      }
      &.peoples{
        max-width: 30px;
        justify-content: center;
        font-weight:600;
      }
      &.name{
        font-weight:600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display:block;
        min-width:130px;
        padding-top: 8px;
        max-width: 200px;
      }
    }
    &.blockead{
      color:#000 !important;
      margin-left: 10px;
      i{
        width:20px !important;
        height:20px !important;
        margin-right:0;
        margin-top: 0;
        background: red !important;
      }
      .col{
        &.btn-action{
          width: 20px;
          max-width: 20px;
        }
         &.name{
          display: flex;
          margin-left: 3px;
          font-size: 12px;
          margin-right: 5px;
          .er{
            margin-right: 10px;
          }
        }
      }
    }
  }
</style>
