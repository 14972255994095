<template>
  <div id="module-email">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <a @click="resetModule" class="return">
      <img src="@/assets/icons/marketing/return.svg" alt="precompro.com" class="return-icon">
      <span class="return-text">Volver</span>
    </a>
    <div :class="`module-payment ${!stepViewEmail ? 'fix-container-payment': ''} ${openTab ? 'open-tab':''}`" v-if="!isPayment && !isLoading">
      <div class="module-payment-swipe" v-if="!stepViewEmail">
        <div class="module-payment-swipe-pc">
          <Payment :stepFor="pageId" :totalFull="totalMail" :type="provider" :items="taxes" @paymentShow="isPayment = $event" @paymentData="infoPayment = $event" @changePage="pageId = $event" @setTotalPayment="setTotalPayment"></Payment>
          <div class="divider"></div>
          <Campaign @changeStatusViewEmail="changeStatusViewEmail"/>
        </div>
      </div>
      <EmailSteps @changeStatusViewEmail="changeStatusViewEmail" :stepFor="pageId" :totalMail="totalMail" @showContact="showContact = $event" @changeViewInt="$emit('changeModuleEmail', $event)" @setCharge="showPayment = $event.pay, totalMail = $event.total, pad = 1" @changePage="pageId = $event" v-if="totalMail > 0 && !showPayment && stepViewEmail" />
    </div>
    <div class="module-payment module-payment-email" :style="{height: (isPayment) ? 'auto' : '100%', flexDirection: 'column' }" v-if="isPayment && !isLoading">
      <Method :lockInfo="lock" @creditMethodEvent="creditMethod = $event" :totalPayment="totalPayment"></Method>
      <CardComponent
        @selectTypeMethod="cardType = $event"
        @statusOfTransaction="setStatus($event)"
        v-if="creditMethod"
      />
      <DebitComponent v-else />
      <ModalInfo
        :cardNumber="cardItem"
        :isPse="pse"
        :status="state"
        :vendorInfo="vendor"
        :mercadopago="isMercadoPago"
        @close="openModal = $event"
        v-if="openModal"
      />
    </div>
    <Taxes :type="provider" :items="taxes" :open="openTab" @setOpen="openTab = $event"></Taxes>
  </div>
</template>
<script>
import Taxes from '@/components/Marketing/taxes';
import Payment from '@/components/Marketing/payment';
import Campaign from '@/components/Marketing/email/Campaign';
import EmailSteps from '@/components/Marketing/email/EmailSteps';
import Method from '@/components/Marketing/payment/Method';
import CardComponent from '@/components/Marketing/payment/Card';
import DebitComponent from '@/components/Marketing/payment/PsePay';
import serviceMarketing from '@/helpers/apis/serviceMarketing';
import ModalInfo from '@/components/Marketing/payment/TransactionStatusModal';

export default {
  name: 'MarketingEmail',
  props: {
    provider: {
      type: String
    }
  },
  components: {
    Taxes,
    Campaign,
    EmailSteps,
    Payment,
    Method,
    CardComponent,
    DebitComponent,
    ModalInfo
  },
  data () {
    return {
      pad: 0,
      showPayment: false,
      taxes: [],
      totalMail: 0,
      lock: false,
      showContact: false,
      isPayment: false,
      infoPayment: null,
      openTab: false,
      creditMethod: true,
      cardItem: null,
      pse: false,
      isMercadoPago: false,
      cardType: null,
      state: '',
      isLoading: false,
      pageId: 1,
      totalPayment: null,
      minTab: 0,
      stepViewEmail: false,
      openModal: false
    };
  },
  mounted () {
    this.getTaxes();
    this.configMarketing();
  },
  methods: {
    handleResize () {
      this.minTab = 0;
      if (window.innerWidth <= 500) {
        this.swipeActive = true;
      } else {
        this.swipeActive = false;
      }
      if (window.innerWidth <= 414 && this.totalMessage > 0) {
        this.minTab = 1;
      }
    },
    setStatus (data) {
      this.state = data.state;
      this.pse = data.pse;
      this.cardItem = data.cardItem;
      this.isMercadoPago = data.isMercadoPago || false;
      this.openModal = true;
    },
    changeMinTab (type) {
      if (type === 'left') {
        this.minTab = 0;
      } else {
        this.minTab = 1;
      }
    },
    configMarketing () {
      this.isLoading = true;
      serviceMarketing.get('configuration/' + this.vendor.id + '?type=email').then(({ data }) => {
        this.totalMail = data.data.total || 0;
        this.isLoading = false;
      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this.totalMail = 0;
      });
    },
    getTaxes () {
      serviceMarketing.get('taxes?type=email').then(({ data }) => {
        this.taxes = data.data;
      }).catch(() => {
        this.taxes = [];
      });
    },
    resetModule () {
      if (!this.stepViewEmail) {
        if (this.pageId === 1) {
          window.localStorage.removeItem('marketing_package');
          this.$emit('resetModule', {
            module: 'reset'
          });
        } else if (this.pageId === 4) {
          this.showPayment = false;
          this.totalMail = 0;
          this.pageId = 1;
          this.pad = 0;
          this.configMarketing();
        } else if (this.pageId === 5) {
          this.showPayment = false;
          this.isPayment = false;
          /* this.totalMail = 0; */
          this.pad = 0;
          this.pageId = 1;
        } else {
          this.pageId = 1;
        }
      } else {
        if (this.pageId === 1) {
          this.stepViewEmail = false;
        }
        if (this.pageId === 2) {
          this.pageId = 1;
        }
      }
    },
    setTotalPayment (value) {
      this.totalPayment = value;
    },
    changeStatusViewEmail (status) {
      this.stepViewEmail = status;
    }
  },
  watch: {
    pageId () {
      if (this.pageId === 3) {
        this.pageId = 1;
        window.localStorage.removeItem('marketing_package');
        this.$emit('resetModule', {
          module: 'reset'
        });
      }
    },
    totalMessage () {
      if (this.totalMail > 0) {
        this.minTab = 1;
      } else {
        this.minTab = 0;
      }
    }
  },
  destroyed () {
    this.taxes = [];
    this.totalMail = 0;
    this.isMail = false;
    this.showContact = false;
  }
};
</script>
<style lang="scss">
#module-email {
  background: #FFF;
  height: 100%;
  padding-bottom: 40px;
  .divider {
    margin: 0px 70px 0px 70px;
    width: 2px;
    height: 403px;
    flex-shrink: 0;
    background: #444B57;
  }
  .module-payment {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: baseline;
    padding-top: 10px;
    @media screen and (min-width: 1024px) {
      flex-wrap: wrap;
    }
    &-swipe{
      height: 100%;
      .carousel-indicator{
        display: none;
      }
      &-pc{
        display: flex;
        height: 100%;
        @media screen and (max-width: 768px) {
          display: grid;
        }
      }
      &-mobile{
        display: none;
        height: 100%;
        @media screen and (max-width: 500px) {
          display: block;
        }
        .carousel{
          .carousel-items{
            height: 83vh;
          }
        }
      }
    }
    &.module-payment-email{
      @media screen and (min-width: 415px) and (max-width: 500px) {
        margin-top: -20px;
        padding-top: 0px;
      }
      @media screen and (max-width: 500px) {
        margin-top: -19px;
        padding-top: 0px;
      }
    }
    &:not(.open-tab){
      flex-wrap: nowrap;
      height: calc(100% - 10px);
    }
    &.open-tab{
      @media screen and (max-width: 1024px) {
        height: calc(100% - 75px);
      }
      @media screen and (min-width: 1024px) {
        flex-wrap: nowrap;
        height: calc(100% - 75px);
      }
      @media screen and (max-width: 765px) {
        flex-wrap: nowrap;
        height: calc(100% - 75px);
      }
      @media screen and (max-width: 500px) {
        flex-wrap: nowrap;
        height: calc(100% - 120px);
      }
      @media screen and (max-width: 500px) {
        flex-wrap: nowrap;
        height: calc(100% - 120px);
      }
    }
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #444B57 !important;
      box-shadow: unset !important;
      border-color: #444B57 !important;
      border-radius: 6px !important;
    }
  }
  .divider {
    margin: 0px 70px 0px 70px;
    width: 2px;
    height: 435px;
    flex-shrink: 0;
    background: #444B57;
    @media screen and (max-width: 1024px) {
      margin: 0px 30px;
    }
    @media screen and (max-width: 768px) {
      height: 2px;
      width: 500px;
      margin: 20px auto;
    }
    @media screen and (max-width: 500px) {
      max-width: 380px;
    }
  }
  .divider-v-tab {
    display: none;
    width: 356px;
    height: 4px;
    border-radius: 6px;
    flex-shrink: 0;
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 500px) {
      display: flex;
    }
    &-item {
      width: 50%;
      background: #C9C9C9;
      &-active {
        background: #444B57;
      }
    }
  }
  .divider-v {
    display: none;
    width: 500px;
    height: 2px;
    margin: 20px 0px 20px 0px;
    flex-shrink: 0;
    background: #444B57;
    @media screen and (max-width: 1000px) {
      display: inline;
    }
    @media screen and (max-width: 500px) {
      display: none;
    }
    &-tab{
      &-item{
        cursor: pointer;
      }
    }
  }
  .return {
    color: #444B57;
    text-decoration: none;
    display: flex;
    width: 80px;
    align-content: center;
    padding: 15px 0px 0px 15px;
    position: absolute;
    @media screen and (max-width: 500px) {
      width: 25px;
      position: relative;
      padding-bottom: unset;
    }
    &-text {
      color: #444B57;
      font-size: 14px;
      font-family: 'Source Sans Pro';
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    &-icon {
      width: 8px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 12px;
    }
  }
}
</style>
