<template>
  <div
    class="conts"
  >
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Precompras', placement: 'bottom' }"
      v-if="vendor && isPurchase"
    >
      <i class="icon-mks white icon_prepurchase"></i
      ><span>{{ prepurchaseAmount }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Walk-In', placement: 'bottom' }"
    >
      <i class="icon-mks white icon_walkin"></i><span>{{ walkinAmount }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Canceladas', placement: 'bottom' }"
    >
      <i class="icon-mks white icon-w30 icon_cancelda"></i
      ><span>{{ reservationCancel }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'No Llegó', placement: 'bottom' }"
    >
      <i class="icon-mks white icon-w30 icon_nollego"></i
      ><span>{{ reservationIsNoShow }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Reservas', placement: 'bottom' }"
    >
      <i class="icon-mks white icon-w30 icon_reservation"></i
      ><span>{{ reservationAmount }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Personas', placement: 'bottom' }"
    >
      <i class="icon-mks white icon_person"></i><span>{{ totalPeoples }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Proximas', placement: 'bottom' }"
    >
      <i class="icon-mks white icon_nextPeople"></i
      ><span>{{ nextPeople }}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Personas Efectivas', placement: 'bottom' }"
      v-if="filterActivate || dateFilterSelect.length > 0"
    >
      <i class="icon-mks white icon_efectivePeople"></i
      ><span>{{ efectivePeople }} / {{totalPeoples}}</span>
    </a>
    <a
      class="navbar-item"
      v-tooltip="{ content: 'Mesas ocupadas / Total de mesas', placement: 'bottom' }"
      v-if="filterActivate || dateFilterSelect.length > 0"
    >
      <i class="icon-mks white icon_ThinTable"></i
      ><span>{{ blockingTables }}/{{ totalTables }} </span>
    </a>
    <a
      class="navbar-item"
      style="background-color: white; display: flex; justify-content: center; padding: unset;"
      v-tooltip="{ content: 'Informe de turno', placement: 'bottom' }"
      id="btnTurnDetail"
      @click="showTurnInfo()"
    >
      <i class="icon-mks icon_plus_filter" style="background-color: #444B57;" v-if="!showTurnDetail"></i>
      <i class="icon-mks icon_minus_filter" style="background-color: #444B57;" v-if="showTurnDetail"></i>
    </a>
  </div>
</template>
<script>
import serviceActionReservation from '@/helpers/apis/serviceActionReservation';
import globalTypes from '@/store/types/global';
export default {
  name: 'SummaryVue',
  props: ['filterActivate', 'fecha'],
  data: () => ({
    reservationAmount: 0,
    walkinAmount: 0,
    prepurchaseAmount: 0,
    peopleAmount: 0,
    reservationCancel: 0,
    reservationIsNoShow: 0,
    totalTables: 0,
    blockingTables: 0,
    totalPeoples: 0,
    nextPeople: 0,
    efectivePeople: 0,
    showBtnTurn: false
  }),
  mounted () {
    this.counts();
  },
  computed: {
    isPurchase () {
      return !!this.vendorOpParams.purchaseCost;
    }
  },
  methods: {
    reconnectSocket () {
      if (!this.socket) {
        window.location.reload();
      }
    },
    counts () {
      serviceActionReservation
        .post('/counts', {
          vendorId: this.vendor.id,
          dates: this.dateFilterSelect,
          fecha: this.fecha
        })
        .then(({ data }) => {
          this.prepurchaseAmount = data.data.isPrePurchase;
          this.walkinAmount = data.data.isWalkIn;
          this.reservationCancel = data.data.isCancelled;
          this.reservationIsNoShow = data.data.isNoShow;
          this.reservationAmount = data.data.allReservations;
          this.totalPeoples = data.data.allPeople;
          this.nextPeople = data.data.isNextPeople;
          this.efectivePeople = data.data.isEfective;
          this.blockingTables = data.data.seatedTables;
          this.totalTables = data.data.totalTables;
        }).catch((e) => { console.error(e); });
    },
    updateCountInfo () {
      this.counts();
    },
    showTurnInfo () {
      this.$store.commit(globalTypes.mutations.setShowTurnDetail, !this.showTurnDetail);
    }
  },
  watch: {
    fecha () {
      this.updateCountInfo();
    },
    selectedDate () {
      this.updateCountInfo();
    },
    reservations () {
      this.updateCountInfo();
    },
    dateFilterSelect () {
      this.updateCountInfo();
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getShowButtonTurn);
  }
};
</script>
<style lang="scss" scoped>
.icon {
  height: 2.5rem;
  max-width: 4rem;
}
.separator-v {
  height: 2.5rem;
  width: 1px;
  background: #fff;
}
@media (max-width: 1200px) {
  .spinner {
    display: none !important;
  }
}
@media (max-width: 820px) {
  .icon {
    height: 2rem;
    max-width: 2rem;
  }
}
</style>
