<template>
  <div id="campaign-email">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    <Steper :step="stepFor"></Steper>
    <div v-if="stepFor === 1" class="campaign-email-step-one">
      <div class="campaign-one">
        <div class="campaign-center">
          <Check :type="checkOne" style="pointer-events: none;"></Check>
          <div class="campaign-center-item">
            <div class="campaign-info">
              <span class="campaign-info-for">Para:</span>
              <span class="campaign-info-subfor overflow-fix-tags" v-if="checkOne === 2">
                <b-tag closable rounded type="is-dark" v-for="(list, index) in selectedFor" :key="'listFor#' + index" @close.stop="setCloseList(index, list._id)">
                  <span  @click="showContact(list)" style="cursor: pointer;">
                  {{ list.name }}
                  </span>
                </b-tag>
              </span>
              <span class="campaign-info-subfor ellipsis-fix" v-else>Selecciona el grupos de contactos a quien va dirigido el correo.</span>
            </div>
            <div class="campaign-action">
              <button class="campaign-action-button" :class="{edit: checkOne == 1, selectEdit: checkOne == 2}" @click="setRadio('for', 1)">
                <span class="campaign-action-button-text" v-if="checkOne >= 1">Editar contactos</span>
                <span class="campaign-action-button-text" v-else>Seleccionar contactos</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="campaign-two" :class="{showMax: checkTwo >= 1  && save === true}" :style="{ height: (checkTwo === 1) ? '169px' : '80px', opacity: (checkOne != 2) ? '0.5' : 'unset', pointerEvents: (checkOne != 2) ? 'none' : 'all', userSelect: 'none' }">
        <div class="campaign-center" :style="{ height: (checkTwo === 1) ? '80px' : '100%' }">
          <Check :type="checkTwo" style="pointer-events: none;"></Check>
          <div class="campaign-center-item">
            <div class="campaign-info">
              <span class="campaign-info-for">Asunto:</span>
              <span class="campaign-info-subfor ellipsis-fix" v-if="checkTwo === 2">{{ subject }}</span>
              <span class="campaign-info-subfor ellipsis-fix" v-else>Escribe el título del correo que aparece en la bandeja de entrada.</span>
            </div>
            <div class="campaign-action" :class="{notShow: checkTwo >= 1 && save === true}">
              <button class="campaign-action-button" :class="{edit: checkTwo == 1, selectEdit: checkTwo == 2}" @click="setRadio('subject', 1)">
                <span class="campaign-action-button-text" v-if="checkTwo >= 1">Editar Asunto</span>
                <span class="campaign-action-button-text" v-else>Agregar Asunto</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="checkTwo === 1" class="subject">
          <div class="subject-top" style="margin-top: -12px;">
            <input type="text" id="subject" v-model="subjectPlace" placeholder="Escribe el asunto" maxlength="150"/>
            <span class="subject-top-message">{{ subjectPlace.length }} / 150 Caracteres</span>
          </div>
          <div class="subject-bottom">
            <button class="subject-bottom-all" :class="{active: (subjectPlace.length > 4), inactive: (subjectPlace.length <= 3)}" @click="setSubject(true)">Guardar</button>
            <button class="subject-bottom-all cancel" style="margin-left: 20px;" :class="{}" @click="setSubject(false)">Cancelar</button>
          </div>
        </div>
      </div>
      <div class="campaign-three" :style="{ opacity: (checkTwo != 2) ? '0.5' : 'unset', pointerEvents: (checkTwo != 2) ? 'none' : 'all', userSelect: 'none' }">
        <div class="campaign-footer">
          <div class="campaign-footer-item fix-item">
            <Check :type="checkThree" style="pointer-events: none;"></Check>
            <div class="campaign-info">
              <span class="campaign-info-for">Diseñar contenido:</span>
              <span class="campaign-info-subfor">Diseña el contenido del correo</span>
            </div>
          </div>
          <div class="campaign-footer-info">
            <div class="campaign-footer-info-item" @click="setRadio('content', 1)">
              <img src="@/assets/icons/marketing/texto-active.svg" alt="precompro.com" v-if="checkFour === 1">
              <img src="@/assets/icons/marketing/texto.svg" alt="precompro.com" v-else>
              <Check :type="checkFour" :all="true" @changeValue="setRadio('content', 1)" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="campaign-footer-info-item-text">Contenido solo texto</span>
            </div>
            <div class="campaign-footer-info-item"  @click="setRadio('contentWithImage', 1)">
              <img src="@/assets/icons/marketing/texto-imagen-active.svg" alt="precompro.com" v-if="checkFive === 1">
              <img src="@/assets/icons/marketing/texto-imagen.svg" alt="precompro.com" v-else>
              <Check :type="checkFive" :all="true" @changeValue="setRadio('contentWithImage', 1)" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="campaign-footer-info-item-text">Contenido texto e imagen</span>
            </div>
            <div class="campaign-footer-info-item" @click="setRadio('image', 1)">
              <img src="@/assets/icons/marketing/imagen-active.svg" alt="precompro.com" v-if="checkSix === 1">
              <img src="@/assets/icons/marketing/imagen.svg" alt="precompro.com" v-else>
              <Check :type="checkSix" :all="true" @changeValue="setRadio('image', 1)" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="campaign-footer-info-item-text">Contenido solo imagen</span>
            </div>
          </div>
        </div>
      </div>
      <div class="campaign-button" :style="{paddingBottom: (checkTwo === 1) ? '40px' : 'unset'}">
        <button class="campaign-button-item" :class="{'campaign-button-item-active': (this.checkOne === 2 && this.checkTwo === 2 && this.checkThree === 2)}" @click="setDesignPage()" style="user-select: none; opacity: 0.5; pointer-events: none;">
          <span class="campaign-button-item-text">
            Continuar
          </span>
        </button>
        <button id="chargeMail" class="campaign-button-item campaign-button-item-active-two" @click="setRecharge()" style="margin-left: 5px;">
          <span class="campaign-button-item-text">
            Recargar
          </span>
        </button>
      </div>
    </div>
    <div v-if="stepFor === 2" class="design">
      <div class="view" @click="showView = !showView">
        <span>Vista previa</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" v-if="showView">
          <path d="M9.79231 4.91179C9.72592 4.97873 9.64693 5.03186 9.55989 5.06812C9.47286 5.10438 9.37951 5.12305 9.28523 5.12305C9.19094 5.12305 9.09759 5.10438 9.01056 5.06812C8.92353 5.03186 8.84454 4.97873 8.77814 4.91179L5.50708 1.64074C5.44069 1.57379 5.3617 1.52066 5.27467 1.4844C5.18763 1.44814 5.09428 1.42948 5 1.42948C4.90572 1.42948 4.81237 1.44814 4.72533 1.4844C4.6383 1.52066 4.55931 1.57379 4.49291 1.64074L1.22186 4.91179C1.15546 4.97873 1.07647 5.03186 0.989437 5.06812C0.902405 5.10438 0.809054 5.12305 0.714771 5.12305C0.620487 5.12305 0.527137 5.10438 0.440104 5.06812C0.353072 5.03186 0.27408 4.97873 0.207685 4.91179C0.0746641 4.77797 0 4.59696 0 4.40827C0 4.21959 0.0746641 4.03858 0.207685 3.90476L3.48589 0.626567C3.88763 0.225326 4.4322 -4.673e-05 5 -4.673e-05C5.56779 -4.673e-05 6.11237 0.225326 6.51411 0.626567L9.79231 3.90476C9.92533 4.03858 10 4.21959 10 4.40827C10 4.59696 9.92533 4.77797 9.79231 4.91179Z" fill="#444B57"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none" v-else>
          <path d="M9.79231 0.21126C9.72592 0.144319 9.64693 0.0911862 9.55989 0.054927C9.47286 0.0186678 9.37951 0 9.28523 0C9.19094 0 9.09759 0.0186678 9.01056 0.054927C8.92353 0.0911862 8.84454 0.144319 8.77814 0.21126L5.50708 3.48231C5.44069 3.54925 5.3617 3.60238 5.27467 3.63864C5.18763 3.6749 5.09428 3.69357 5 3.69357C4.90572 3.69357 4.81237 3.6749 4.72533 3.63864C4.6383 3.60238 4.55931 3.54925 4.49291 3.48231L1.22186 0.21126C1.15546 0.144319 1.07647 0.0911862 0.989437 0.054927C0.902405 0.0186678 0.809054 0 0.714771 0C0.620487 0 0.527137 0.0186678 0.440104 0.054927C0.353072 0.0911862 0.27408 0.144319 0.207685 0.21126C0.0746641 0.345075 0 0.526091 0 0.714773C0 0.903456 0.0746641 1.08447 0.207685 1.21829L3.48589 4.49648C3.88763 4.89772 4.4322 5.12309 5 5.12309C5.56779 5.12309 6.11237 4.89772 6.51411 4.49648L9.79231 1.21829C9.92533 1.08447 10 0.903456 10 0.714773C10 0.526091 9.92533 0.345075 9.79231 0.21126Z" fill="#444B57"/>
        </svg>
      </div>
      <div class="design-left" id="design-left">
        <div class="design-left-header">
          <div class="design-left-header-subject ellipsis-fix">
            <span>{{ subject || 'Descuento del 50% en todos los combos de hamburguesas' }}</span>
          </div>
          <div class="design-left-header-tag">
            <span>Recibidos</span>
            <div class="design-left-header-tag-i">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                <path d="M0 4H0.496042L1.36148 2.90833C1.56201 2.65833 1.74142 2.40833 1.94195 2.175H1.98417C2.19525 2.40833 2.39578 2.65833 2.58575 2.90833L3.48285 4H4L2.27968 1.94167L3.85224 0H3.36675L2.5752 1.01667C2.39578 1.23333 2.23747 1.45833 2.05805 1.675H2.01583C1.82586 1.45833 1.64644 1.23333 1.47757 1.01667L0.654354 0H0.137203L1.72032 1.90833L0 4Z" fill="black"/>
              </svg>
            </div>
          </div>
          <div class="design-left-header-view">
            <span>Vista previa</span>
          </div>
        </div>
        <div class="design-left-subject">
          <div class="design-left-subject-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
              <circle cx="15.5" cy="15.5" r="15.5" fill="#BDC30E"/>
            </svg>
            <div>P</div>
          </div>
          <div class="design-left-subject-for">
            <div class="design-left-subject-for-subject">
              <span class="design-left-subject-for-subject-title">{{ vendor.displayName }}</span>
              <span class="design-left-subject-for-subject-subtitle">{{ vendor.email }}</span>
            </div>
            <div class="design-left-subject-from">
              <span>para Clientes recurrentes</span>
              <div class="design-left-subject-from-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="3" viewBox="0 0 6 3" fill="none">
                  <path d="M3 3L2.62268e-07 -4.76995e-08L6 4.76837e-07L3 3Z" fill="black"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="design-left-subject-body" v-if="htmlDesign === '' || htmlDesign.length === 0">
          <div class="design-left-subject-body-title" v-if="typeDesign === 'content' || typeDesign === 'contentWithImage'">
            <span>
              Aenean sed tortor id risus accumsan fermentum quis vel dui.
            </span>
          </div>
          <div class="design-left-subject-body-subtitle" v-if="typeDesign === 'content' || typeDesign === 'contentWithImage'">
            <span>
              Fusce sit amet est ac ligula interdum volutpat quis eu eros. Vivamus sit amet aliquam purus,
            </span>
          </div>
          <div class="design-left-subject-body-content" v-if="typeDesign === 'content'">
            <span class="design-left-subject-body-content-one">
              Duis accumsan faucibus dolor eu scelerisque.
              Aliquam porttitor molestie tempor.
              In hac habitasse platea dictumst.
              Integer convallis, orci sit amet efficitur gravida, mi lectus faucibus ex,
              et dignissim lectus eros non turpis. Aenean eget vestibulum diam.
              Sed vel odio sapien. Pellentesque euismod, eros at sodales vestibulum,
              metus ipsum venenatis turpis, a tempus sapien turpis sit amet tellus.
              Maecenas tempus enim enim, eu hendrerit mi fermentum a.
              Etiam vitae neque eu mauris dignissim gravida. Donec fringilla,
              nisi et auctor fermentum, quam nunc placerat nisi, nec laoreet libero augue ac dolor.
            </span>
          </div>
          <div class="design-left-subject-body-content-two" v-if="typeDesign === 'content'">
            <span>
              Fusce sit amet est ac ligula interdum volutpat quis eu eros. Vivamus sit amet aliquam purus,
            </span>
          </div>
          <div class="design-left-subject-body-image" v-if="typeDesign === 'contentWithImage'"></div>
          <div class="design-left-subject-body-image-all" v-if="typeDesign === 'image'"></div>
          <div class="design-left-subject-body-content" style="margin-top: 70px; margin-bottom: 30px;" v-if="typeDesign === 'content' && checkIsSubscription">
            <span class="textIsSubscriptionSambox">Si no quiere recibir este tipo de correos, puedes cancelar la suscripción <strong style="text-decoration-line: underline;">aquí</strong></span>
          </div>
        </div>
        <div class="design-left-subject-body" v-else>
          <div id="contentHtml" v-html="htmlForEditor"></div>
          <div class="design-left-subject-body-content" style="margin-top: 70px; margin-bottom: 30px;" v-if="checkIsSubscription">
            <span class="textIsSubscriptionSambox">Si no quiere recibir este tipo de correos, puedes cancelar la suscripción <strong style="text-decoration-line: underline;">aquí</strong></span>
          </div>
        </div>
      </div>
      <div class="design-right">
        <div class="design-right-tabs">
          <div class="design-right-tabs-item" :class="{'design-right-tabs-item-active': stepByPass === 1, 'design-right-tabs-item-inactive': stepByPass === 2}" @click="stepByPass = 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
              <path d="M9.744 6C9.744 6.66 9.204 7.2 8.544 7.2H1.2C0.54 7.2 0 6.66 0 6C0 5.34 0.54 4.8 1.2 4.8H8.544C9.204 4.8 9.744 5.34 9.744 6ZM0 1.2C0 1.86 0.54 2.4 1.2 2.4H12C12.66 2.4 13.2 1.86 13.2 1.2C13.2 0.54 12.66 0 12 0H1.2C0.54 0 0 0.54 0 1.2ZM7.248 10.8C7.248 10.14 6.708 9.6 6.048 9.6H1.2C0.54 9.6 0 10.14 0 10.8C0 11.46 0.54 12 1.2 12H6.048C6.708 12 7.248 11.46 7.248 10.8ZM15.42 2.196L16.272 1.344C16.383 1.23276 16.5149 1.1445 16.6601 1.08428C16.8052 1.02406 16.9608 0.993066 17.118 0.993066C17.2752 0.993066 17.4308 1.02406 17.5759 1.08428C17.7211 1.1445 17.853 1.23276 17.964 1.344L18.816 2.196C19.284 2.664 19.284 3.42 18.816 3.888L17.964 4.74L15.42 2.196ZM14.568 3.048L8.376 9.24C8.268 9.348 8.208 9.492 8.208 9.66V11.352C8.208 11.688 8.472 11.952 8.808 11.952H10.5C10.656 11.952 10.812 11.892 10.92 11.772L17.112 5.58L14.568 3.048Z" :fill="(stepByPass === 1) ? 'white' : '#444B57'"/>
            </svg>
            <span>Contenido</span>
          </div>
          <div class="design-right-tabs-item ml-10" :class="{'design-right-tabs-item-active': stepByPass === 2, 'design-right-tabs-item-inactive': stepByPass === 1}" @click="stepByPass = 2">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
              <path d="M6.56704 0.145342L6.6693 0.199437L12.4448 3.53401C12.7677 3.72049 13.0085 4.02186 13.119 4.37801C13.2296 4.73415 13.2018 5.11888 13.0413 5.45546L12.9872 5.55846L8.54111 13.2591C8.35463 13.582 8.05325 13.8228 7.69711 13.9334C7.34097 14.0439 6.95624 14.0162 6.61966 13.8556L6.51665 13.8015L0.741175 10.467C0.418059 10.2805 0.177167 9.97896 0.0665922 9.62264C-0.043983 9.26633 -0.0161062 8.88142 0.144657 8.54476L0.198751 8.4425L3.53332 2.66702L2.24988 1.926C2.08493 1.83209 1.96231 1.67845 1.9073 1.49678C1.8523 1.31511 1.86911 1.11926 1.95426 0.949617C2.03941 0.779974 2.18642 0.649477 2.36496 0.585039C2.5435 0.5206 2.73997 0.527133 2.91383 0.60329L2.9909 0.642564L4.27434 1.38358L4.64485 0.74186C4.82087 0.436524 5.09994 0.20391 5.43198 0.08576C5.76402 -0.0323905 6.1273 -0.028343 6.45663 0.0971763L6.56704 0.145342ZM13.3147 6.42916L13.4607 6.25947L13.517 6.32393L13.6638 6.49807L13.7949 6.6611L13.9409 6.85228L14.0921 7.06273C14.3425 7.42508 14.5722 7.83338 14.5722 8.11201C14.5722 8.4068 14.4551 8.68952 14.2467 8.89797C14.0382 9.10642 13.7555 9.22353 13.4607 9.22353C13.1659 9.22353 12.8832 9.10642 12.6748 8.89797C12.4663 8.68952 12.3492 8.4068 12.3492 8.11201C12.3492 7.77707 12.6797 7.25613 12.9805 6.85228L13.1265 6.6611L13.3147 6.42916ZM5.92829 1.48288L5.55778 2.1246L6.1995 2.4951C6.36831 2.59407 6.49112 2.75578 6.54114 2.94496C6.59115 3.13414 6.56431 3.33542 6.46647 3.50488C6.36863 3.67434 6.20773 3.79823 6.01889 3.8495C5.83005 3.90076 5.62859 3.87526 5.45848 3.77854L4.81676 3.40804L3.59853 5.51845H11.2992L11.7038 4.81745L5.92829 1.48288Z" :fill="(stepByPass === 2) ? 'white' : '#444B57'"/>
            </svg>
            <span>Diseño</span>
          </div>
        </div>
        <div class="design-right-body">
          <div class="design-right-body-design" v-if="stepByPass === 2">
            <div class="design-right-body-design-item" style="margin-left: unset;" @click="setRadio('content', 1, 1)">
              <img src="@/assets/icons/marketing/texto-active.svg" alt="precompro.com" v-if="checkFour === 1">
              <img src="@/assets/icons/marketing/texto.svg" alt="precompro.com" v-else>
              <Check :type="checkFour" @changeValue="setRadio('content', 1, 1)" :all="true" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="design-right-body-design-item-text">Contenido solo texto</span>
            </div>
            <div class="design-right-body-design-item" @click="setRadio('contentWithImage', 1, 1)">
              <img src="@/assets/icons/marketing/texto-imagen-active.svg" alt="precompro.com" v-if="checkFive === 1">
              <img src="@/assets/icons/marketing/texto-imagen.svg" alt="precompro.com" v-else>
              <Check :type="checkFive" @changeValue="setRadio('contentWithImage', 1, 1)" :all="true" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="design-right-body-design-item-text">Contenido texto e imagen</span>
            </div>
            <div class="design-right-body-design-item" @click="setRadio('image', 1, 1)">
              <img src="@/assets/icons/marketing/imagen-active.svg" alt="precompro.com" v-if="checkSix === 1">
              <img src="@/assets/icons/marketing/imagen.svg" alt="precompro.com" v-else>
              <Check :type="checkSix" @changeValue="setRadio('image', 1, 1)" :all="true" style="padding-bottom: unset; height: 30px;"></Check>
              <span class="design-right-body-design-item-text">Contenido solo imagen</span>
            </div>
          </div>
          <div style="height: 100%;" v-show="stepByPass === 1">
            <div id="editorContainer" v-show="stepByPass === 1"></div>
            <div style="display: flex; align-items: center; gap: 12px; margin: 12px 0;">
              <input type="checkbox" class="checkbox-isSubscription" id="checkboxIsSubscription" @change="confirmChange" :checked="checkIsSubscription" />
              <label for="checkboxIsSubscription" class="textCheckIsSubscription"></label>
              <span class="textCheckIsSubscription" id="checkboxIsSubscription">La opción de cancelar la suscripción para este correo esta activada.</span>
            </div>
            <div class="design-right-body-action" v-show="stepByPass === 1">
              <button class="design-right-body-action-save design-right-body-action-inactive" @click="setHtml()">Guardar</button>
              <button class="design-right-body-action-close design-right-body-action-inactive" @click="setCancel()">Cerrar</button>
              <button class="design-right-body-action-close design-right-body-action-inactive" @click="setCampaignProg()" :class="{'destination-action-disabled': (message === '' || selectedFor.length === 0)}">
                Programar envió
              </button>
              <button class="design-right-body-action-send design-right-body-action-active" :class="{'design-right-body-action-notComplete': (htmlDesign == null || htmlDesign === '')}" @click="setCampaign()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Check from '@/components/Marketing/email/Check.vue';
import Steper from '@/components/Marketing/email/Steper.vue';
import CancelUnSubscription from '@/components/Marketing/email/CancelUnSubscription.vue';
import Contact from '@/components/Marketing/Contact.vue';
import Program from '@/components/Marketing/Program.vue';
import serviceAdminPhotos from '@/helpers/apis/serviceAdminPhotos';
import serviceMarketing from '@/helpers/apis/serviceMarketing.js';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import MyUploadAdapter from '@/helpers/MyUploadAdapter.js';

function MyCustomUploadAdapterPlugin (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}

export default {
  props: {
    totalMail: {
      type: Number
    },
    stepFor: {
      type: Number,
      default: 1
    }
  },
  components: {
    Check,
    Steper
  },
  data () {
    return {
      showView: true,
      loading: false,
      count: 0,
      checkOne: 0,
      checkTwo: 0,
      checkThree: 0,
      checkFour: 0,
      checkFive: 0,
      checkSix: 0,
      selectedFor: [],
      subjectPlace: '',
      subject: '',
      typeDesign: 'content',
      isProgramming: 0,
      programmingDate: null,
      programmingHour: null,
      programmingMinute: null,
      contacts: [],
      htmlForEditor: '<p>Esto es una prueba con imagen desde el servidor de devs</p><p>&nbsp;</p><p style="text-align:center;"><span class="text-big" style="background-color:hsl(240, 75%, 60%);color:hsl(0, 0%, 100%);">Imagen</span></p><figure class="image"><img style="aspect-ratio:433/241;" src="https://servicemail.precompro.co/uploads/1708979792_Captura de pantalla 2023-07-06 232514.png" width="433" height="241"></figure><p style="text-align:center;"><span style="color:hsl(210, 75%, 60%);">FIN IMAGEN</span></p>',
      editor: null,
      htmlDesign: '',
      stepByPass: 1,
      isLoading: false,
      save: false,
      message: '',
      checkIsSubscription: 1
    };
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.destroy().then(() => {
        this.editor = null;
      });
    }
  },
  methods: {
    confirmChange (event) {
      const isChecked = event.target.checked;
      const thes = this;

      if (!isChecked) {
        thes.$buefy.modal.open({
          parent: thes,
          component: CancelUnSubscription,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {},
          events: {
            onCancelUnSubscription () {
              event.preventDefault();
              event.target.checked = thes.checkIsSubscription;
            },
            onConfirmSubscription () {
              thes.checkIsSubscription = isChecked;
            }
          }
        });
      } else {
        thes.checkIsSubscription = isChecked;
      }
    },
    setCloseList (index) {
      this.selectedFor.splice(index, 1);
      if (this.selectedFor.length === 0) {
        this.checkOne = 0;
      }
    },
    showContact (listDefault = null) {
      const thes = this;
      this.$buefy.modal.open({
        parent: thes,
        component: Contact,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: 'email',
          selectedContact: thes.contacts,
          selectedFor: thes.selectedFor,
          limit: this.totalMail,
          listDefault
        },
        customClass: 'campaign-drop-background',
        canCancel: false,
        events: {
          changeValue (value) {
            thes.checkOne = value;
          },
          setContacts (items) {
            thes.contacts = items;
          },
          setList (info) {
            thes.selectedFor = info;
          }
        }
      });
    },
    setSubject (status) {
      if (status) {
        this.subject = this.subjectPlace;
        this.checkTwo = 2;
        this.save = false;
      } else {
        this.subject = '';
        this.subjectPlace = '';
        this.checkTwo = 0;
      }
    },
    setRadio (type, value, edit = 0) {
      switch (type) {
        case 'for':
          /* this.checkOne = value; */
          this.showContact();
          break;
        case 'subject':
          this.checkTwo = value;
          this.save = true;
          setTimeout(() => {
            document.getElementById('subject').focus();
          }, 300);
          break;
        case 'content':
          if (this.checkFive === 1 || this.checkSix === 1) {
            this.checkFive = 0;
            this.checkSix = 0;
            this.checkThree = 0;
          }
          this.checkFour = value;
          this.checkThree = 2;
          this.typeDesign = 'content';
          break;
        case 'contentWithImage':
          if (this.checkFour === 1 || this.checkSix === 1) {
            this.checkFour = 0;
            this.checkSix = 0;
            this.checkThree = 0;
          }
          this.checkFive = value;
          this.checkThree = 2;
          this.typeDesign = 'contentWithImage';
          break;
        case 'image':
          if (this.checkFive === 1 || this.checkFour === 1) {
            this.checkFive = 0;
            this.checkFour = 0;
            this.checkThree = 0;
          }
          this.checkSix = value;
          this.checkThree = 2;
          this.typeDesign = 'image';
          break;
        default:
          this.checkOne = 0;
          this.checkTwo = 0;
          this.checkThree = 0;
          this.checkFour = 0;
          this.checkFive = 0;
          this.checkSix = 0;
          this.typeDesign = null;
          this.htmlForEditor = '';
          break;
      }
    },
    setRecharge () {
      this.$emit('changePage', 4);
      this.$emit('changeStatusViewEmail', false);
      this.$emit('setCharge', { pay: true, total: this.totalMail });
    },
    setDesignPage () {
      if (this.checkOne === 2 && this.checkTwo === 2 && this.checkThree === 2) {
        this.$emit('changePage', 2);
      } else {
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Debes seleccionar contactos, Ingresar un asunto y seleccionar un diseño',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    },
    setHtml () {
      if (this.htmlForEditor != null && this.htmlForEditor !== '') {
        this.htmlDesign = this.htmlForEditor;
      } else {
        this.htmlDesign = this.htmlForEditor;
      }
    },
    setCancel () {
      this.htmlDesign = '';
      this.htmlForEditor = '';
      this.$emit('changePage', 3);
    },
    setCampaign () {
      this.isLoading = true;
      if (this.htmlDesign == null && this.htmlDesign === '') {
        this.$buefy.toast.open({
          duration: 1000,
          message: 'Debes escribir al menos un texto',
          position: 'is-bottom',
          type: 'is-danger'
        });
        this.isLoading = false;
      }
      const info = {
        typeDesign: this.typeDesign,
        subject: this.subject,
        contacts: this.selectedFor,
        vendorId: this.vendor.id,
        userId: this.user.id,
        isProgramming: this.isProgramming,
        programmingDate: this.programmingDate,
        programmingHour: this.programmingHour,
        programmingFullTime: parseInt(this.$moment(this.programmingDate + ' ' + this.programmingHour).format('x')),
        type: 'email',
        date: this.$moment().format('YYYY-MM-DD'),
        hour: this.$moment().format('HH:mm:ss'),
        dateTime: parseInt(this.$moment().format('x')),
        content: this.htmlDesign,
        total: 0,
        send: 0,
        active: 1,
        isUnSubscription: this.checkIsSubscription
      };
      let totalItem = 0;
      this.selectedFor.forEach((i) => {
        totalItem = totalItem + i.contacts.length;
      });
      info.total = totalItem;
      serviceMarketing.post('marketing-campaign', info).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.data,
          type: 'is-success',
          duration: 5000
        });
        this.loading = false;
        this.$emit('changeViewInt', {
          module: 'history',
          item: 'email',
          show: true
        });
      }).catch((err) => {
        this.loading = false;
        this.$buefy.toast.open({
          message: err.response.data,
          type: 'is-danger',
          duration: 5000
        });
      });
    },
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      const extention = file.name.split('.');
      let path = '';
      if (this.vendor !== null) {
        path = (this.vendor.sede == null) ? this.vendor.url : this.vendor.url + '/' + this.vendor.sede;
      }
      var formData = new FormData();
      formData.append('vendorId', this.vendor.id);
      formData.append('fileName', `marketing/email/${path}/${extention[0]}.${extention[1]}`);
      formData.append('content', file);
      formData.append('optimize', 0);
      serviceAdminPhotos.post('digitalOcean/upload/image', formData)
        .then(result => {
          const url = result.data.data;
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(err => {
          console.error(err);
        });
    },
    setCampaignProg () {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: Program,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: 'email'
        },
        canCancel: false,
        events: {
          close () {
            thes.isProgramming = 0;
            thes.programmingDate = null;
            thes.programmingHour = null;
          },
          accept (acceptData) {
            thes.isProgramming = 1;
            thes.programmingDate = acceptData.date;
            thes.programmingHour = acceptData.hour;
            thes.setCampaign();
          }
        }
      });
    },
    createEditor () {
      const el = document.querySelector('#editorContainer');
      if (el) {
        setTimeout(() => {
          DecoupledEditor
            .create(document.querySelector('#editorContainer'), {
              extraPlugins: [MyCustomUploadAdapterPlugin]
            })
            .then(editor => {
              this.editor = editor;

              editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
              editor.model.document.on('change:data', () => {
                const contenido = editor.getData();
                this.htmlForEditor = contenido;
              });
            })
            .catch(error => {
              console.error(error);
            });
        }, 800);
      }
    }
  },
  watch: {
    showView () {
      const designLeft = document.getElementById('design-left');
      if (this.showView) {
        designLeft.style.display = '';
      } else {
        designLeft.style.display = 'none';
      }
    },
    stepFor () {
      if (this.stepFor === 2) {
        setTimeout(() => {
          DecoupledEditor
            .create(document.querySelector('#editorContainer'), {
              extraPlugins: [MyCustomUploadAdapterPlugin]
            })
            .then(editor => {
              this.editor = editor;

              editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              );
              editor.model.document.on('change:data', () => {
                const contenido = editor.getData();
                this.htmlForEditor = contenido;
              });
            })
            .catch(error => {
              console.error(error);
            });
        }, 800);
      }
    },
    stepByPass () {
      if (this.stepByPass === 1) {
      }
      if (this.stepByPass === 2) {
        document.querySelector('#editorContainer').innerHTML = '';
      }
    }
  },
  mounted () {
    this.createEditor();
  }
};
</script>
<style lang="scss">
#editorContainer {
  height: calc(100% - 90px);
  border: 1px solid #E9E9E9;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  &::-webkit-scrollbar {
    width: 3px !important;
    height: 3px!important;
  }
  &::-webkit-scrollbar-thumb {
    background: #444B57 !important;
    border-color: #444B57 !important;
    box-shadow: unset !important;
    border-radius: 6px !important;
  }
}
.textIsSubscriptionSambox {
  color: #000;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.textCheckIsSubscription {
  color: #444B57;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkbox-isSubscription {
  display: none;
}
.checkbox-isSubscription + label {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  background-color: white;
  border: 1px solid #444B57;
}
.checkbox-isSubscription:checked + label {
  background-color: #444B57;
}
.checkbox-isSubscription + label:after {
  content: '✔'; /* símbolo de check */
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 11px;
  color: white;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}
.checkbox-isSubscription:checked + label:after {
  transform: translate(-50%, -50%) scale(1);
}
/* Estilos para las listas con viñetas */
.ck-editor__editable ul {
  list-style-type: disc; /* Tipo de viñeta */
  margin-left: 1.5em;    /* Espaciado izquierdo */
}
/* Estilos para los elementos de lista */
.ck-editor__editable li {
  margin-bottom: 0.5em;  /* Espaciado entre elementos de lista */
}
/* Estilos para las listas ordenadas */
.ck-editor__editable ol {
  list-style-type: decimal; /* Tipo de numeración (1, 2, 3, etc.) */
  margin-left: 1.5em;       /* Espaciado izquierdo */
}
#contentHtml {
  /* Estilos para las listas con viñetas */
  ul {
    list-style-type: disc; /* Tipo de viñeta */
    margin-left: 1.5em;    /* Espaciado izquierdo */
  }

  /* Estilos para los elementos de lista */
  li {
    margin-bottom: 0.5em;  /* Espaciado entre elementos de lista */
  }
  /* Estilos para las listas ordenadas */
  ol {
    list-style-type: decimal; /* Tipo de numeración (1, 2, 3, etc.) */
    margin-left: 1.5em;       /* Espaciado izquierdo */
  }
}
.module-payment.fix-container-payment{
  #campaign-email{
  }
}
#campaign-email {
  height: calc(100% - 5px);
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    margin-bottom: 5px;
  }
  .campaign-header{
    margin-top: 5px!important;
    @media screen and (max-width: 375px) {
      margin-top: 35px!important;
    }
  }
  .campaign-email-step-one {
    width: 743px;
    margin: 16px auto;
    @media screen and (max-width: 768px) {
      width: 668px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      padding: 0 5px;
    }
  }
  .notShow {
    display: flex;
    @media screen and (max-width: 500px) {
      display: none!important;
    }
  }
  .edit {
    background: #E9E9E9 !important;
    border-color: #E9E9E9 !important;
    span {
      color: #444B57 !important;
      font-weight: 400 !important;
    }
  }
  .selectEdit {
    background: #ffffff !important;
    border-color: #444B57 !important;
    span {
      color: #444B57 !important;
      font-weight: 400 !important;
    }
  }
  .campaign-one {
    height: 80px;
    flex-shrink: 0;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #C9C9C9;
    background: #FFF;
    @media screen and (max-width: 500px) {
      height: 130px!important;
    }
  }
  .campaign-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
    padding-left: 12px;
    margin-top: -5px;
    @media screen and (max-width: 500px) {
      width: 100%;
      padding-bottom: 17px;
    }
    &-for {
      color: #444B57;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1px;
    }
    &-subfor {
      color: #444B57;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: auto;
      gap: 3px;
      display: flex;
      margin-right: 2px;
      flex-flow: wrap;
      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
      &.ellipsis-fix{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        max-width: 400px;
      }
      &.overflow-fix-tags{
        overflow: auto;
        height: 24px;
      }
      @media screen and (max-width: 768px) {
        &.ellipsis-fix{
          max-width: 326px;
        }
      }
      @media screen and (max-width: 500px) {
        &.ellipsis-fix{
          max-width: 326px;
        }
      }
      @media screen and (max-width: 400px) {
        &.ellipsis-fix{
          max-width: 250px;
        }
      }
      &::-webkit-scrollbar {
        width: 3px !important;
      }
      &::-webkit-scrollbar-thumb {
        background: #444B57 !important;
        border-color: #444B57 !important;
        box-shadow: unset !important;
        border-radius: 6px !important;
      }
    }
  }
  .campaign-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    &-button {
      cursor: pointer;
      width: 200px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #444B57;
      background: #444B57;
      @media screen and (max-width: 500px) {
        margin-left: -20px;
      }
      &-text {
        color: #FFF;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .campaign-footer {
    height: 380px;
    flex-shrink: 0;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #C9C9C9;
    background: #FFF;
    padding: 20px 12px;
    @media screen and (max-width: 500px) {
      height: 305px!important;
    }
    .check-template{
      margin-top: -3px;
    }
    &-item {
      display: flex;
      justify-content: stretch;
      margin-bottom: 3px;
      padding-left: 8px;
    }
    &-info {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 22px;
      gap: 30px;
      @media screen and (max-width: 500px) {
        margin-top: 0px;
        height: 214px!important;
        gap: 16px;
      }
      &-item {
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          height: 136px!important;
          width: 110.726px;
          margin-top: 10px;
        }
        &-text {
          color: #444B57;
          text-align: center;
          font-family: 'Source Sans Pro';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-top: 8px;
          @media screen and (max-width: 500px) {
            font-size: 12px;
            margin-top: 12px;
            padding: 0 10px;
          }
        }
        &-imgs{
          height: 83%;
        }
        @media screen and (max-width: 500px) {
          &-imgs{
            height: 100%;
          }
        }
        img {
          margin-bottom: 8px;
          @media screen and (max-width: 500px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .campaign-center {
    justify-content: stretch;
    padding: 20px;
    height: 100%;
    align-items: flex-start;
    display: flex;
    &-item {
      width: 100%;
      display: flex;
      justify-content: stretch;
      align-items: center;
      @media screen and (max-width: 500px) {
        display: grid;
        justify-items: center;
      }
    }
    .check-template{
      margin-top: -3px;
    }
  }
  .campaign-two {
    height: 80px;
    flex-shrink: 0;
    border-left: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-right: 1px solid #C9C9C9;
    background: #FFF;
    @media screen and (max-width: 500px) {
      height: 130px!important;
    }
    .subject {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 50px;
      padding-right: 20px;
      &-top {
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
        input {
          border: unset;
          border-bottom: 2px solid #D9D9D9;
          width: 100%;
          &::placeholder {
            color: #D9D9D9;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &-message {
          width: 92px;
          color: #444B57;
          text-align: center;
          font-family: 'Source Sans Pro';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &-bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-all {
          width: 78px;
          height: 25px;
          flex-shrink: 0;
          border-radius: 4px;
          border: 1px solid #444B57;
          background: #444B57;
          color: #fff;
          cursor: pointer;
          font-size: 12px;
        }
        .inactive {
          opacity: 0.5;
          pointer-events: none;
        }
        .cancel {
          background: #fff !important;
          color: #444B57 !important;
        }
      }
    }
  }
  .showMax {
    @media screen and (max-width: 500px) {
      height: 169px!important;
    }
  }
  .campaign-three {
    height: 380px;
    flex-shrink: 0;
    border-radius: 0px 0px 10px 10px;
    border-left: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-right: 1px solid #C9C9C9;
    background: #FFF;
    @media screen and (max-width: 500px) {
      height: 305px;
    }
  }
  .campaign-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    @media screen and (min-width: 1280px) {
      padding-bottom: 40px !important;
    }
    @media screen and (max-width: 500px) {
      padding-bottom: 60px;
      flex-direction: column;
      #chargeMail {
        margin-top: 10px;
      }
    }
    &-item {
      width: 200px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #444B57;
      background: #444B57;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        width: 100%;
        margin-left: 0px!important;
      }
      &-text {
        color: #FFF;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &-active {
        pointer-events: all !important;
        opacity: unset !important;
      }
      &-active-two {
        background: #fff;
        pointer-events: all !important;
        opacity: unset !important;
        span {
          color: #444B57;
        }
      }
    }
  }
  .design {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .view {
      display: none;
      height: 27px;
      flex-shrink: 0;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #C9C9C9;
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      outline: unset;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        display: flex;
        width: 390px;
        margin: 0 auto;
      }
      @media screen and (min-width: 501px) {
        display: none;
      }
      svg {
        width: 10px;
        height: 5.123px;
        flex-shrink: 0;
      }
      span {
        color: #444B57;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 10px;
      }
    }
    .design-left {
      width: 580px;
      height: 552px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #C9C9C9;
      background: #FFF;
      padding: 14px 16px;
      position: relative;
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 428px;
      }
      @media screen and (max-width: 500px) {
        width: 390px;
        border-radius: unset;
        border-top: unset;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0 auto;
        height: 100%;
        padding: 10px 8px 12px 8px;
      }
      @media screen and (max-width: 400px) {
        width: 360px;
        border-radius: unset;
        border-top: unset;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &-header {
        padding: 0 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-subject {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          user-select: none;
          &.ellipsis-fix{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            width: 65%;
          }
          span {
            color: #000;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        &-tag {
          width: 52px;
          height: 12px;
          flex-shrink: 0;
          border-radius: 2px;
          background: #D9D9D9;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-left: 6px;
          user-select: none;
          span {
            color: #000;
            font-family: 'Source Sans Pro';
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
          &-i {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
            height: 100%;
            svg {
              width: 4px;
              height: 4px;
              flex-shrink: 0;
            }
          }
        }
        &-view {
          width: 87px;
          height: 27px;
          flex-shrink: 0;
          border-radius: 0px 10px;
          border: 1px solid #C9C9C9;
          background: #FFF;
          position: absolute;
          top: -1px;
          right: -1px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          @media screen and (max-width: 500px) {
            display: none;
          }
          span {
            color: #7C8189;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      &-subject {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-logo {
          margin-right: 6px;
          position: relative;
          width: 31px;
          height: 31px;
          flex-shrink: 0;
          div {
            position: absolute;
            color: #FFF;
            font-family: 'Source Sans Pro';
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
          }
        }
        &-for {
          display: flex;
          justify-content: center;
          align-content: flex-start;
          flex-direction: column;
          &-subject {
            display: flex;
            justify-content: center;
            align-content: center;
            height: 15px;
            &-title {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #000;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            &-subtitle {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;
              color: #000;
              font-family: 'Source Sans Pro';
              font-size: 10px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }
        }
        &-from {
          display: flex;
          justify-content: flex-start;
          align-content: center;
          margin-top: 2px;
          height: 15px;
          span {
            color: #000;
            font-family: 'Source Sans Pro';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-left: 3px;
            width: 6px;
            flex-shrink: 0;
          }
        }
        &-body {
          margin-top: 10px;
          height: calc(100% - 70px);
          overflow-y: auto;
          padding: 0 37px;
          overflow-x: hidden;
          @media screen and (max-width: 500px) {
            padding: 0 4px;
            height: 373px;
          }
          &::-webkit-scrollbar {
            width: 3px !important;
          }
          &::-webkit-scrollbar-thumb {
            background: #444B57 !important;
            border-color: #444B57 !important;
            box-shadow: unset !important;
            border-radius: 6px !important;
          }
          &-title {
            display: flex;
            width: 271px;
            height: 43px;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            flex-wrap: wrap;
            margin: 0 auto;
            @media screen and (max-width: 400px) {
              width: 240px;
              height: 63px;
            }
            span {
              color: #000;
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
          &-subtitle {
            display: flex;
            width: 271px;
            height: 31px;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            flex-wrap: wrap;
            margin: 0 auto;
            margin-top: 15px;
            @media screen and (max-width: 400px) {
              width: 240px;
              height: 53px;
            }
            span {
              color: #000;
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-image {
            width: 392px;
            height: 260px;
            flex-shrink: 0;
            border-radius: 10px;
            border: 1px solid #C9C9C9;
            background: url('../../../assets/icons/marketing/test.png'), linear-gradient(lightgray, lightgray);
            background-color: #FFF;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            margin: 25px auto auto auto;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 342px;
            }
            @media screen and (max-width: 500px) {
              width: 100%;
            }
            @media screen and (max-width: 400px) {
              width: 336px;
            }
          }
          &-content {
            width: 407px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            flex-wrap: wrap;
            margin: 30px auto auto auto;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 342px;
            }
            @media screen and (max-width: 500px) {
              width: 366px;
            }
            @media screen and (max-width: 400px) {
              width: 340px;
            }
            &-one {
              color: #000;
              text-align: justify;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-content-two {
            width: 271px;
            height: 31px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 30px auto auto auto;
            span {
              color: #000;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-image-all {
            margin: 0px auto;
            width: 474px;
            height: 409px;
            flex-shrink: 0;
            border-radius: 10px;
            border: 1px solid #C9C9C9;
            background: url('../../../assets/icons/marketing/test.png'), linear-gradient(lightgray, lightgray);
            background-color: #FFF;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 342px;
            }
            @media screen and (max-width: 500px) {
              width: 366px;
            }
          }
        }
      }
    }
    .design-right {
      display: flex;
      align-items: center;
      width: 580px;
      flex-shrink: 0;
      height: 523px;
      margin-left: 20px;
      flex-direction: column;
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 494px;
      }
      @media screen and (max-width: 768px) {
        margin-left: unset;
        margin-top: 30px;
        padding-bottom: 60px;
      }
      @media screen and (max-width: 500px) {
        padding-top: 20px;
        margin: 0 auto;
        padding-bottom: 0px;
        height: 443px;
      }
      @media screen and (max-width: 500px) {
        width: 390px;
      }
      @media screen and (max-width: 400px) {
        width: 360px;
      }
      .ml-10 {
        margin-left: 10px;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          margin-left: 14px;
        }
        @media screen and (max-width: 500px) {
          margin-left: 4px;
        }
      }
      &-tabs {
        width: 580px;
        height: 40px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: 494px;
        }
        @media screen and (max-width: 500px) {
          width: 390px;
        }
        @media screen and (max-width: 400px) {
          width: 360px;
        }
        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 285px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 10px 10px 0px 0px;
          border: 1px solid #C9C9C9;
          cursor: pointer;
          @media screen and (min-width: 769px) and (max-width: 1024px) {
            width: 240px;
          }
          @media screen and (max-width: 500px) {
            width: 193px;
          }
          @media screen and (max-width: 400px) {
            width: 180px;
          }
          svg {
            margin-right: 9px;
          }
          span {
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          &-active {
            background: #444B57;
            span {
              color: #FFF;
            }
          }
          &-inactive {
            background: #FFF;
            span {
              color: #444B57;
            }
          }
        }
      }
      &-body {
        width: 580px;
        height: 483px;
        flex-shrink: 0;
        background: #FFF;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: 494px;
        }
        @media screen and (max-width: 500px) {
          width: 390px;
          height: 258px;
        }
        @media screen and (max-width: 400px) {
          width: 360px;
        }
        &-action {
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 500px) {
            flex-direction: column;
            padding-bottom: 10px;
          }
          &-save {
            width: 142px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid #444B57;
            flex-shrink: 0;
            cursor: pointer;
            font-size: 14px;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 120px;
            }
            @media screen and (max-width: 500px) {
              width: 388px;
              margin-top: 10px;
            }
            @media screen and (max-width: 400px) {
              width: 360px;
            }
            span {
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-close {
            width: 142px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid #444B57;
            flex-shrink: 0;
            margin-left: 4px;
            margin-right: 4px;
            flex-shrink: 0;
            font-size: 14px;
            cursor: pointer;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 120px;
            }
            @media screen and (max-width: 500px) {
              width: 388px;
              margin-top: 10px;
            }
            @media screen and (max-width: 400px) {
              width: 360px;
            }
            span {
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-send {
            width: 142px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid #444B57;
            flex-shrink: 0;
            cursor: pointer;
            font-size: 14px;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              width: 120px;
            }
            @media screen and (max-width: 500px) {
              width: 388px;
              margin-top: 10px;
            }
            @media screen and (max-width: 400px) {
              width: 360px;
            }
            span {
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              line-height: normal;
            }
          }
          &-active {
            background: #444B57;
            color: #FFF;
            font-weight: 600;
          }
          &-inactive {
            background: #FFF;
            color: #444B57;
            font-weight: 400;
          }
          &-notComplete {
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
          }
        }
        &-design {
          width: 580px;
          height: 320px;
          flex-shrink: 0;
          display: flex;
          border-radius: 0px 0px 10px 10px;
          border: 1px solid #C9C9C9;
          background: #FFF;
          align-items: center;
          justify-content: center;
          padding: 20px 23px 30px 23px;
          @media screen and (min-width: 769px) and (max-width: 1024px) {
            width: 494px;
            padding: 30px 23px;
          }
          @media screen and (max-width: 768px) {
            height: 325px;
            padding: 30px 23px;
          }
          @media screen and (max-width: 500px) {
            max-width: 390px;
            width: 100%;
            height: 260px;
            padding: 30px 23px;
          }
          &-item {
            margin-left: 27px;
            height: 280px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media screen and (min-width: 769px) and (max-width: 1024px) {
              margin-left: 22px;
              width: 137px;
            }
            @media screen and (max-width: 768px) {
              height: 190px;
            }
            @media screen and (max-width: 500px) {
              margin-left: 16px;
              height: 200px;
              width: 160px;
            }
            &-text {
              color: #444B57;
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-top: 14px;
              @media screen and (min-width: 769px) and (max-width: 1024px) {
                font-size: 12px;
              }
              @media screen and (max-width: 500px) {
                margin-top: 12px;
              }
            }
            .check-template{
              height: 38px!important;
              padding-top: 20.14px!important;
              @media screen and (max-width: 500px) {
                padding-top: 8px!important;
                height: 27px!important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
