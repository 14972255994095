<template>
  <svg class="box-template" xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 8" fill="none" @click="setChange()">
    <path d="M11.1466 0.195198C11.0216 0.0702129 10.852 0 10.6753 0C10.4985 0 10.3289 0.0702129 10.2039 0.195198L4.00834 6.39075L1.14623 3.52865C1.08473 3.46497 1.01117 3.41418 0.929829 3.37924C0.84849 3.3443 0.761007 3.32591 0.672484 3.32514C0.583962 3.32437 0.496172 3.34124 0.414238 3.37476C0.332304 3.40828 0.257867 3.45779 0.195269 3.52039C0.132672 3.58298 0.0831679 3.65742 0.049646 3.73936C0.0161242 3.82129 -0.000744067 3.90908 2.51723e-05 3.9976C0.000794412 4.08612 0.0191859 4.17361 0.0541266 4.25495C0.0890672 4.33628 0.139857 4.40985 0.203533 4.47135L3.53699 7.8048C3.66201 7.92979 3.83156 8 4.00834 8C4.18512 8 4.35467 7.92979 4.47969 7.8048L11.1466 1.1379C11.2716 1.01288 11.3418 0.84333 11.3418 0.666548C11.3418 0.489766 11.2716 0.320221 11.1466 0.195198Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  name: 'MarketingBox',
  props: {
    valueFaul: {
      type: Boolean
    }
  },
  methods: {
    setChange () {
      this.$emit('changeOpt', !this.valueFaul);
    }
  }
};
</script>
<style lang="scss">
.box-template {
  border-radius: 4px;
  border: 1px solid #444B57;
  background: #444B57;
  width: 15.75px;
  height: 18px;
  flex-shrink: 0;
  cursor: pointer;
}
</style>
