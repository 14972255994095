<template>
  <div class="profile" v-if="user">
    <div class="avatar" :style="{backgroundColor: vendorParams.colorDashboard}">
      <div class="BigInitials">{{getInitials(user.displayName)}}</div>
    </div>
    <div class="infoContainer" v-show="isOpen">
      <h3>{{user.displayName}}</h3>
      <div class="change_pass" @click="changePassword()">Cambiar Contraseña</div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import authTypes from '@/store/types/auth';

export default {
  name: 'UserVue',
  props: ['isOpen'],
  methods: {
    getRole (user) {
      let isAdminVendor = false;
      const vendor = this.idVendor;
      let role = 'Usuario';
      _.forEach(user.vendors, function (value) {
        if (parseInt(value) === parseInt(vendor)) {
          isAdminVendor = true;
        }
      });
      if (user.isAdmin && !isAdminVendor) {
        role = 'Super Administrador';
      }
      if (isAdminVendor) {
        role = 'Administrador';
      }
      return role;
    },
    changePassword () {
      this.$store.commit(authTypes.mutations.setShowChangedPassword, true);
    }
  }
};
</script>
