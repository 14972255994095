<template>
  <div id="list-search">
    <div class="header">
      <div class="columns">
        <div class="column hidden-tablet">
          <!-- <i class="fa fa-file" style="margin-right:5px;cursor:pointer" @click="openDowloadWalkIn()"></i> -->
          <a class="white icon_dashboard_printer" v-if="!generate && filterStatus === 'Recibidas'" @click="generateReport()"></a>
          <span class="icon-report" v-if="generate && filterStatus === 'Recibidas'"><i class="fa fa-spinner fa-spin"></i> Generando, por favor espere ...</span>
        </div>
        <div class="column">
          <div class="list-search-fill">
            <input type="text" placeholder="Buscar" v-model="search" @input="searchs(search)" @keydown.esc="clearSearchs()"/>
            <i class="lup fa fa-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-hor">
      <b-table
        :data="desserts"
        :striped="isStriped"
        :loading="loading"
        icon-pack="fa"
      >
        <template slot-scope="props">
          <b-table-column field="date" label="Fecha" centered>{{parseInt(props.row.date) | moment('timezone', timezone, 'DD[/]MM[/]YYYY hh:mm A')}}</b-table-column>
          <b-table-column field="people" label="Personas" centered>{{ props.row.people }}</b-table-column>
          <b-table-column field="displayName" label="Nombre" centered>{{ props.row.displayName }}</b-table-column>
          <b-table-column field="email" label="Email" centered>{{ (props.row.email == null) ? 'N/A' : props.row.email }}</b-table-column>
          <b-table-column field="phone" label="Teléfono" centered>{{ (props.row.phone == null) ? 'N/A' : props.row.phone }}</b-table-column>
          <b-table-column field="created_at" label="Fecha de Creación" centered>{{ props.row.created_at }}</b-table-column>
          <b-table-column field="id" label="Id" centered>{{ props.row.id }}</b-table-column>
          <b-table-column field="status" label="Estado" centered v-if="props.row.status != undefined">{{ props.row.status }}</b-table-column>
          <b-table-column field="persona" label="List" centered v-if="props.row.persona">{{ (props.row.persona == null) ? 'N/A' : props.row.persona }}</b-table-column>
          <b-table-column field="empresa" label="Empresa" centered v-if="props.row.empresa">{{ (props.row.empresa == null) ? 'N/A' : props.row.empresa }}</b-table-column>
        </template>

        <template slot="empty" v-if="!loading">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>Su búsqueda no encontró resultados.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div class="pagination" style="padding: 10px 10px; align-content: right; float: right;">
        <b-pagination
          @change="changePag"
          :total="total"
          :current.sync="current"
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :order="order"
          :per-page="perPage"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          icon-pack="fa"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import searchsTypes from '@/store/types/searchs';
import reservationTypes from '@/store/types/reservation';
import modalWalkIn from '@/components/_shared/ModalDownloadWalkIn';
export default {
  name: 'InformationVue',
  mounted () {
    this.getReservationInProcess();
  },
  data () {
    return {
      search: '',
      loading: true,
      error: null,
      generate: false,
      desserts: [],
      isStriped: true,
      total: 0,
      current: 0,
      rangeBefore: 1,
      rangeAfter: 1,
      order: 'is-centered',
      perPage: 10,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    };
  },
  methods: {
    generateReport () {
      this.generate = true;
      const dateIn = this.filterDateIn != null ? this.filterDateIn : this.$moment().format('YYYY-MM-DD');
      const dateOut = this.filterDateOut != null ? this.filterDateOut : this.$moment().format('YYYY-MM-DD');
      let status = 0;
      if (this.filterStatus === 'Recibidas') {
        status = 1;
      }
      this.$store.dispatch({ type: 'searchs:generateReportSearch', data: { dateIn, dateOut, search: this.search, vendorId: this.vendorId, status: status, displayName: this.filterName, email: this.filterEmail, phone: this.filterPhone, reservationId: this.filterReservationId } }).then(({ data }) => {
        if (data.code === 200) {
          this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
          this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
          this.isDownload();
        } else {
          this.generate = false;
        }
      })
        .catch(() => {
          this.generate = false;
        });
    },
    changePag (e) {
      this.loading = true;
      if (this.filterStatus === 'En proceso') {
        this.$store.dispatch({ type: 'searchs:getReservationInProcess', data: { vendorId: this.vendorId, page: e, search: this.search } }).then(data => {
          this.desserts = [];
          this.desserts = data.data;
          this.total = data.total;
          this.current = data.current_page;
          this.perPage = data.per_page;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      } else {
        let status = 0;
        if (this.filterStatus === 'Recibidas') {
          status = 1;
        }
        this.$store.dispatch({
          type: 'searchs:getReservationInFilter',
          data: {
            vendorId: this.vendorId,
            status: status,
            displayName: this.filterName,
            email: this.filterEmail,
            phone: this.filterPhone,
            reservationId: this.filterReservationId,
            dateIn: this.filterDateIn,
            dateOut: this.filterDateOut,
            page: e
          }
        })
          .then(data => {
            this.desserts = [];
            this.desserts = data.data;
            this.total = data.total;
            this.current = data.current_page;
            this.perPage = data.per_page;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
            this.$store.commit(searchsTypes.mutations.setLoadingFilter, false);
            this.$store.commit(searchsTypes.mutations.setNameFilter, null);
            this.$store.commit(searchsTypes.mutations.setEmailFilter, null);
            this.$store.commit(searchsTypes.mutations.setPhoneFilter, null);
            this.$store.commit(searchsTypes.mutations.setReservationIdFilter, null);
            this.$store.commit(
              searchsTypes.mutations.setReservationIdFilter,
              null
            );
          });
      }
    },
    getReservationInProcess () {
      this.loading = true;
      this.$store.dispatch({ type: 'searchs:getReservationInProcess', data: { vendorId: this.vendorId, search: this.search } }).then(data => {
        this.desserts = data.data;
        this.total = data.total;
        this.current = data.current_page;
        this.perPage = data.per_page;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    isDownload () {
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + this.apiReportDownload,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeApiReportDownload === 'xlsx') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.xlsx';
          link.click();
          this.generate = false;
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.pdf';
          link.click();
          this.generate = false;
        }
      });
    },
    getReservationInFilter () {
      this.desserts = [];
      this.loading = true;
      this.$store.commit(searchsTypes.mutations.setExecuteFilter, false);
      let status = 0;
      if (this.filterStatus === 'Recibidas') {
        status = 1;
      }
      this.$store.dispatch({ type: 'searchs:getReservationInFilter', data: { vendorId: this.vendorId, status: status, displayName: this.filterName, email: this.filterEmail, phone: this.filterPhone, reservationId: this.filterReservationId, dateIn: this.filterDateIn, dateOut: this.filterDateOut, search: this.search } }).then(data => {
        this.desserts = data.data;
        this.total = data.total;
        this.current = data.current_page;
        this.perPage = data.per_page;
        this.loading = false;
        this.$store.commit(searchsTypes.mutations.setLoadingFilter, false);
        this.$store.commit(searchsTypes.mutations.setNameFilter, null);
        this.$store.commit(searchsTypes.mutations.setEmailFilter, null);
        this.$store.commit(searchsTypes.mutations.setPhoneFilter, null);
        this.$store.commit(searchsTypes.mutations.setReservationIdFilter, null);
        this.$store.commit(
          searchsTypes.mutations.setReservationIdFilter,
          null
        );
      }).catch(() => {
        this.loading = false;
        this.$store.commit(searchsTypes.mutations.setLoadingFilter, false);
        this.$store.commit(searchsTypes.mutations.setNameFilter, null);
        this.$store.commit(searchsTypes.mutations.setEmailFilter, null);
        this.$store.commit(searchsTypes.mutations.setPhoneFilter, null);
        this.$store.commit(
          searchsTypes.mutations.setReservationIdFilter,
          null
        );
      });
    },
    searchs (string) {
      clearTimeout(this.timeout);
      var thes = this;
      if (this.filterStatus !== 'Recibidas') {
        this.timeout = setTimeout(function () {
          if (string !== '' && string.length >= 3) {
            thes.getReservationInProcess();
          }
        }, 500);
      } else {
        this.timeout = setTimeout(function () {
          if (string !== '' && string.length >= 3) {
            thes.getReservationInFilter();
          }
        }, 500);
      }
    },
    clearSearchs () {
      this.search = '';
      if (this.filterStatus !== 'Recibidas') {
        this.getReservationInProcess();
      } else {
        this.getReservationInFilter();
      }
    },
    openDowloadWalkIn () {
      this.$buefy.modal.open({
        parent: this,
        component: modalWalkIn,
        hasModalCard: true
      });
    }
  },
  watch: {
    filterExecute () {
      if (this.filterExecute) {
        this.getReservationInFilter();
      }
    },
    search () {
      if (this.search === '' && this.desserts.length === 0) {
        if (this.filterStatus !== 'Recibidas') {
          this.getReservationInProcess();
        } else {
          this.getReservationInFilter();
        }
      }
    }
  }
};
</script>
<style lang="scss">
.icon-report {
  color: #000;
}
#list-search {
  .hidden-tablet{
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
  .icon_dashboard_printer{
    display: block;
    width: 25px;
    height: 25px;
    mask-size: contain;
    background-color: grey;
  }
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px 5px 0 0;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  z-index: 2;
  .icon_filter{
    display: none;
  }
  .header {
    position: relative;
    background: #fafafa;
    border-radius: 5px 5px 0 0;
    padding: 5px 25px;
    border-bottom: 1px solid rgba(250, 250, 250, 1);
  }
  .icon-mks {
    height: 50px;
    width: 50px;
    position: absolute;

    right: 5px;
    top: 0;
  }
  input {
    background: inherit;
    border: none;
    outline: none;
    border-radius: 0px;
    box-shadow: none;
    border-bottom: 2px solid rgba(112, 112, 112, 1);
    padding-right: 25px;
  }
  th {
    border: 1px solid rgba(227, 227, 227, 1);
  }
  .table td,
  .table th {
    padding: 1em 0.75em;
  }
  .b-table .table {
    border: 1px solid transparent;
  }
  .th-wrap {
    justify-content: center;
  }
  .pagination-link.is-current {
    background-color: rgba(68, 75, 87, 1);
    border-color: rgba(68, 75, 87, 1);
  }
  .pagination .pagination-previous {
    order: 1;
  }
  .b-table .table {
    border-collapse: collapse;
  }
}
.list-search-fill {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  height: 35px;
  input {
    border: 0;
    background-color: #fafafa;
    width: 500px;
    font-size: 18px;
    outline: none;
    &::placeholder {
      font-weight: bold;
      color: #dbdbdb;
    }
  }
  i {
    font-weight: bold;
    font-size: 25px;
  }
}
</style>
