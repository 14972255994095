<template>
  <div id="toolbar_gift_card_container">
    <div class="toolbar_gift_cards">
      <div>
        <div class="toolbar_gift_cards__title">
          <div class="client" @click="openSideBar">
            <img :src="vendor.thumbnailURL" alt="" srcset="">
          </div>
          <h2>Bonos de regalo</h2>
        </div>
        <div class="d-flex" v-if="!validatePermission('ReadOnly')">
          <i v-show="!generate" class="icon-mks white icon_dashboard_printer" @click="generateReport"></i>
          <span v-show="generate" class="text-white"><i class="fa fa-spinner fa-spin"></i>&nbsp;Generando</span>
        </div>
        <div class="toolbar_gift_cards__search d-flex">
          <img src="@/assets/icons/polls/search.svg" alt="" srcset=""  @click="$emit('closeSearch')">
          <input type="text" @keyup.enter="$emit('search',$event.target.value)" :value="query">
        </div>
      </div>
    </div>
    <div class="bar_search" v-if="searcherResponsive">
      <div class="toolbar_gift_cards__search_responsive d-flex">
        <img src="@/assets/icons/polls/search_black.svg" alt="" srcset="">
        <input type="text" placeholder="Buscar" @keyup.enter="$emit('search',$event.target.value)" :value="query">
      </div>
      <div class="bar_search__close" @click="$emit('closeSearch')">
        <img src="@/assets/icons/polls/close_black.svg" alt="" srcset="">
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from '@/store/types/global';
import serviceReport from '@/helpers/apis/serviceReport.js';
import reservationTypes from '@/store/types/reservation';
import axios from 'axios';
export default {
  props: ['searcherResponsive', 'query'],
  data () {
    return {
      searcher: false,
      generate: false,
      clickEvent: {},
      generateModal: false
    };
  },
  methods: {
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    generateReport (e) {
      this.generate = true;
      serviceReport.post('/generateReportGiftcards', { vendorId: this.vendor.id })
        .then(({ data }) => {
          this.generate = false;
          this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
          this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
          this.isDownload();
        })
        .catch(err => {
          console.error(err);
          this.generate = false;
          this.generateModal = false;
        });
    },
    isDownload () {
      this.download = true;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + this.apiReportDownload,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        this.download = false;
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeApiReportDownload === 'xlsx') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'reporte_gifCard' + filename + '.xlsx';
          link.click();
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'reporte_gifCard' + filename + '.pdf';
          link.click();
        }
      }).catch((e) => {
        console.error(e);
        this.download = false;
        this.$buefy.notification.open({
          message: 'Archivo no encontrado',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>

<style lang="scss">
@use "@/assets/styles/giftCards/_toolbar";
</style>
