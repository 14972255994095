<template>
  <div
    class="comments-client-restaurant-prec"
    :class="{'comments-client-restaurant-prec__two-columns': (hasClientComment && typeComments !== 'viewReservation') || (typeComments === 'viewReservation' && (hasClientComment || hasRestaurantComment)), 'comments-client-restaurant-prec__create-edit-reservation' : typeComments !== 'viewReservation'}"
    :style="`${typeComments !== 'viewReservation' ? 'margin: 16px 14px 14px': ''}`"
  >
    <div
      v-if="hasClientComment || (typeComments === 'viewReservation')"
      class="comments-client-restaurant-prec__client"
      :class="{'comments-client-restaurant-prec__client--with-separator' : (typeComments === 'viewReservation' && (hasClientComment || hasRestaurantComment)) }"
    >
      <textarea
        ref="textareaCommentsClientPrec"
        v-model="contentClient"
        @input="preventLabelModification('client')"
        @click="ensureCursorPosition('client')"
        @scroll="handleScroll('client')"
        spellcheck="false" autocomplete="off"
        :disabled="true"
        :class="{'text-area-for-client-restaurant-comments-aux': typeComments === 'viewReservation'}"
      ></textarea>
      <span
        class="hidden-text-area-commentary"
        :style="{width: (labelClientWidth - 2) + 'px', background: `${typeComments === 'viewReservation' ? '#EEEFF0' : ''}`}"
        ref="spanLabelClient"
      >
      </span>
      <label ref="labelClient">{{ labelTextClient }}</label>
    </div>
    <div
      v-if="(typeComments === 'viewReservation' && (hasClientComment || hasRestaurantComment)) || typeComments !== 'viewReservation'"
      class="comments-client-restaurant-prec__restaurant"
      :class="typeComments !== 'viewReservation' ? 'view-reservation-aux' : ''"
      :style="{background: `${typeComments !== 'viewReservation' ? '#FFF' : ''}`}"
    >
      <textarea
        maxlength="526"
        ref="textareaCommentsRestauranttPrec"
        v-model="contentRestaurant"
        @input="preventLabelModification('restaurant')"
        @click="ensureCursorPosition('restaurant')"
        @scroll="handleScroll('restaurant')"
        spellcheck="false" autocomplete="off"
        :disabled="typeComments === 'viewReservation'"
        :class="{'text-area-for-client-restaurant-comments-aux': typeComments === 'viewReservation'}"
      ></textarea>
      <span
        class="hidden-text-area-commentary"
        :style="{width: (labelRestaurantWidth - 2) + 'px', background: `${typeComments === 'viewReservation' ? '#EEEFF0' : ''}`}"
        ref="spanLabelRestaurant"
      ></span>
      <label ref="labelRestaurant" :style="`${typeComments === 'viewReservation' ? 'color: #79808D;' : ''}`">{{ labelTextRestaurant }}</label>
      <span v-if="typeComments !== 'viewReservation'" class="comments-client-restaurant-prec__restaurant__counter-characters">{{contentRestaurant.length - 26}}/500</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['infoReserva', 'typeComments'],
  data () {
    return {
      labelTextClient: '',
      contentClient: '',
      labelTextRestaurant: '',
      contentRestaurant: '',
      labelClientWidth: 0,
      labelRestaurantWidth: 0,
      scrollPositions: {
        client: 0,
        restaurant: 0
      }
    };
  },
  computed: {
    hasClientComment () {
      return this.infoReserva.comments && this.infoReserva.comments !== null && this.infoReserva.comments !== '';
    },
    hasRestaurantComment () {
      return this.infoReserva.commentRestaurant && this.infoReserva.commentRestaurant !== null && this.infoReserva.commentRestaurant !== '';
    }
  },
  mounted () {
    this.initializeLabelsAndContents();
    this.calculateLabelWidths();
  },
  methods: {
    calculateLabelWidths () {
      this.$nextTick(() => {
        if (this.$refs.labelClient) {
          this.labelClientWidth = this.$refs.labelClient.offsetWidth + 2;
        }
        if (this.$refs.labelRestaurant) {
          this.labelRestaurantWidth = this.$refs.labelRestaurant.offsetWidth + 2;
        }
      });
    },
    initializeLabelsAndContents () {
      const hasClientComment = this.infoReserva.comments && this.infoReserva.comments !== null && this.infoReserva.comments !== '';
      const hasRestaurantComment = this.infoReserva.commentRestaurant && this.infoReserva.commentRestaurant !== null && this.infoReserva.commentRestaurant !== '';
      this.labelTextClient = (hasClientComment && this.typeComments !== 'viewReservation') || (this.typeComments === 'viewReservation' && (hasClientComment || hasRestaurantComment)) ? 'Comentario cliente: ' : 'Comentarios: ';
      this.contentClient = `${this.labelTextClient} ${this.infoReserva.comments || ''}`;
      this.labelTextRestaurant = 'Comentario restaurante:   ';
      this.contentRestaurant = `${this.labelTextRestaurant}${this.infoReserva.commentRestaurant || ''}`;
    },
    preventLabelModification (type) {
      const textarea = type === 'client'
        ? this.$refs.textareaCommentsClientPrec
        : this.$refs.textareaCommentsRestauranttPrec;

      const labelText = type === 'client'
        ? this.labelTextClient
        : this.labelTextRestaurant;

      if (!textarea.value.startsWith(labelText)) {
        const currentCursor = textarea.selectionStart;
        textarea.value = labelText + textarea.value.slice(labelText.length);
        this.$nextTick(() => {
          textarea.setSelectionRange(
            Math.max(currentCursor, labelText.length),
            Math.max(currentCursor, labelText.length)
          );
        });
      }

      if (type === 'client') {
        this.contentClient = textarea.value;
      } else {
        this.contentRestaurant = textarea.value;
      }
    },
    ensureCursorPosition (type) {
      const textarea = type === 'client'
        ? this.$refs.textareaCommentsClientPrec
        : this.$refs.textareaCommentsRestauranttPrec;

      const labelText = type === 'client'
        ? this.labelTextClient
        : this.labelTextRestaurant;

      if (textarea.selectionStart < labelText.length) {
        textarea.setSelectionRange(labelText.length, labelText.length);
      }
    },
    handleScroll (type) {
      const textareaRef = type === 'client' ? 'textareaCommentsClientPrec' : 'textareaCommentsRestauranttPrec';
      const textarea = this.$refs[textareaRef];
      const scrollPosition = textarea.scrollTop;

      // Guarda la posición del scroll
      this.scrollPositions[type] = scrollPosition;

      // Calcula el nuevo top para el label y el span
      const newTop = scrollPosition <= 20 ? 8 - scrollPosition : -scrollPosition;

      // Asigna el nuevo top dinámicamente
      if (type === 'client' && this.$refs.labelClient && this.$refs.spanLabelClient) {
        this.$refs.labelClient.style.top = `${newTop - 1.2}px`;
        this.$refs.spanLabelClient.style.top = `${newTop - 1.2}px`;
      }
      if (type === 'restaurant' && this.$refs.labelRestaurant && this.$refs.spanLabelRestaurant) {
        this.$refs.labelRestaurant.style.top = `${newTop - 1.2}px`;
        this.$refs.spanLabelRestaurant.style.top = `${newTop - 1.2}px`;
      }
    },
    debounce (func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    handleContentRestaurant (value) {
      const stringFormatted = value.replace('Comentario restaurante:   ', '');
      this.$emit('changeCommentsRestaurant', stringFormatted);
    }
  },
  watch: {
    contentRestaurant (newValue) {
      if (this.typeComments !== 'viewReservation') {
        this.debounceContentRestaurant(newValue);
      }
    }
  },
  created () {
    this.debounceContentRestaurant = this.debounce(this.handleContentRestaurant, 500);
  }
};
</script>

<style lang="scss">
  .comments-client-restaurant-prec{
    font-family: "Source Sans Pro";
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden;
    border: 1px solid #A8ADB5;
    border-radius: 8px;
    &__client, &__restaurant{
      height: 74px;
      max-height: 74px;
      position: relative;
      display: inline-block;
      width: 100%;
      padding-bottom: 10px;
      background: #EEEFF0;
      &--with-separator{
        border-right: 1px solid #A8ADB5;
      }
      textarea {
        font-family: "Source Sans Pro"!important;
        line-height: normal;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        resize: none; /* Evita redimensionar */
        padding: 7px 12px; /* Espacio interior */
        background: #FFF;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        border: 0;
        &.text-area-for-client-restaurant-comments-aux{
          background: #EEEFF0;
          color: #79808D!important;
          -webkit-text-fill-color: #79808D!important;
          opacity: 1;
        }
        &:focus-visible{
          outline: none;
        }
        &::-webkit-scrollbar-track {
          background: transparent!important;
          width: 1px !important;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 1px;
          width: 1px !important;
          background: #444B57!important;
          border: 0!important;
          box-shadow: none !important;
          outline: none !important;
         /*  border: 1px solid #444B57!important; */
        }
        &::-webkit-scrollbar {
          width: 2px!important;
          border: 0 !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
      label {
        line-height: normal;
        z-index: 10;
        color: #79808D;
        font-size: 12px;
        font-weight: 600;
      }
      .hidden-text-area-commentary{
        width: 77.101px;
        height: 18px;
        background: white;
      }
      label, .hidden-text-area-commentary{
        position: absolute;
        top: 8px;
        left: 12px;
      }
      .comments-client-restaurant-prec__client textarea:focus +s label,
      .comments-client-restaurant-prec__client textarea:not(:empty) + label {
        visibility: hidden;
      }
    }
    &__restaurant{
      position: relative;
      &__counter-characters{
        position: absolute;
        right: 4px;
        bottom: 1px;
        font-size: 10px;
      }
      label{
        color: #444B57;
      }
      textarea{
        color: #444B57;
      }
    }
    &.comments-client-restaurant-prec__two-columns{
      grid-template-columns: 1fr 1fr;
      border-radius: 8px;
      border: 1px solid #A8ADB5;
      background: #FFF;
      overflow: hidden;
      .comments-client-restaurant-prec__client, .comments-client-restaurant-prec__restaurant{
        .view-reservation-aux{
          padding-bottom: 0px;
        }
        overflow: hidden;
        height: 100px;
        max-height: 100px;
        textarea {
          font-size: 12px;
          border: 0;
          border-radius: 0;
          padding: 8px 12px;
        }
        label{
          font-weight: 600;
          font-size: 12px;
        }
        .hidden-text-area-commentary{
          width: 103px;
        }
        label, .hidden-text-area-commentary{
        }
      }
      .comments-client-restaurant-prec__client{
        textarea {
          opacity: 1;
          background: #EEEFF0;
          color: #79808D!important;
          -webkit-text-fill-color: #79808D!important;
        }
        label{
          color: #79808D;
        }
        .hidden-text-area-commentary{
          background: #EEEFF0;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .comments-client-restaurant-prec{
      &__client, &__restaurant{
        &--with-separator{
        }
        textarea {
        }
        label {
        }
        .hidden-text-area-commentary{
        }
        label, .hidden-text-area-commentary{
        }
      }
      &.comments-client-restaurant-prec__two-columns{
        .comments-client-restaurant-prec__client, .comments-client-restaurant-prec__restaurant{
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
          label, .hidden-text-area-commentary{
          }
        }
        .comments-client-restaurant-prec__client{
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .comments-client-restaurant-prec{
      &__client, &__restaurant{
        &--with-separator{
        }
        textarea {
        }
        label {
        }
        .hidden-text-area-commentary{
        }
        label, .hidden-text-area-commentary{
        }
      }
      &.comments-client-restaurant-prec__two-columns{
        .comments-client-restaurant-prec__client, .comments-client-restaurant-prec__restaurant{
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
          label, .hidden-text-area-commentary{
          }
        }
        .comments-client-restaurant-prec__client{
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .comments-client-restaurant-prec{
      &__client, &__restaurant{
        &--with-separator{
          border-right: 0;
          border-bottom: 1px solid #A8ADB5;
        }
        textarea {
        }
        label {
        }
        .hidden-text-area-commentary{
        }
        label, .hidden-text-area-commentary{
        }
      }
      &.comments-client-restaurant-prec__two-columns{
        grid-template-columns: 1fr;
        .comments-client-restaurant-prec__client, .comments-client-restaurant-prec__restaurant{
          max-height: 50px;
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
          label, .hidden-text-area-commentary{
          }
        }
        .comments-client-restaurant-prec__client{
          textarea {
          }
          label{
          }
          .hidden-text-area-commentary{
          }
        }
      }
    }
  }
</style>
