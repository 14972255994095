<template>
  <div
    id="selectLanguages"
    :class="`${type === 'menu' ? 'select-languages-container-menu': (type === 'multimenu' ? 'select-languages-container-multimenu' : 'select-languages-container-general')}`"
    @mouseleave="hideOptions"
    v-if="params.languageMenuActivate"
  >
    <div class="select-languages-container" @click="toggleOptions">
      <div class="select-languages-container--selected" >
        <div>{{languageSelected}}</div>
        <div>
          <i class="icon-mks icon_arrow_down_general" :style="`${showOptions ? 'transform: rotate(180deg);': ''}`"></i>
        </div>
      </div>
    </div>
    <div class="select-languages-options" v-show="showOptions">
      <div class="select-languages-options--option" v-if="languageSelected !== 'ES'" @click="selectOption('ES')">
        ES
      </div>
      <div class="select-languages-options--option" v-if="languageSelected !== 'IN'" @click="selectOption('IN')">
        IN
      </div>
      <div class="select-languages-options--option" v-if="languageSelected !== 'FR'" @click="selectOption('FR')">
        FR
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import menuQrTypes from '@/store/types/menuQr';
export default {
  props: ['type', 'notGlobal'],
  data () {
    return {
      showOptions: false,
      languageSelected: 'ES'
    };
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      typeLanguage: menuQrTypes.getters.typeLanguage
    })
  },
  methods: {
    toggleOptions () {
      this.showOptions = !this.showOptions;
    },
    hideOptions () {
      this.showOptions = false;
    },
    selectOption (language) {
      this.languageSelected = language;
      switch (language) {
        case 'ES':
          this.$emit('changeTypeLanguage', 'spanish');
          if (!this.notGlobal) {
            this.$store.commit(menuQrTypes.mutations.setTypeLanguage, 'spanish');
          }
          break;
        case 'IN':
          this.$emit('changeTypeLanguage', 'english');
          if (!this.notGlobal) {
            this.$store.commit(menuQrTypes.mutations.setTypeLanguage, 'english');
          }
          break;
        case 'FR':
          this.$emit('changeTypeLanguage', 'french');
          if (!this.notGlobal) {
            this.$store.commit(menuQrTypes.mutations.setTypeLanguage, 'french');
          }
          break;
        default:
          break;
      }
      this.hideOptions();
    }
  },
  watch: {
    typeLanguage () {
      switch (this.typeLanguage) {
        case 'spanish':
          this.selectOption('ES');
          break;
        case 'english':
          this.selectOption('IN');
          break;
        case 'french':
          this.selectOption('FR');
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/_select_languages.scss";
</style>
