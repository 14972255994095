<template>
  <div class="block-pres-drag">
    <div v-hammer:press="(event) => onPress(event, reservation)" class="onPress" @click.prevent.stop="showReservation(reservation)" ></div>
    <drag :transfer-data="{ reservation: {id:reservation.id, name: reservation.displayName} }" :id="'ReservationItem'+reservation.id" class="ReservationItem"
    :class="{
        opacity5: reservation.codeStatus === 3 || reservation.codeStatus === 4 || reservation.codeStatus === 5,
        'bordered-item border-sobre-hora':
          (now > parseInt(reservation.date) &&
            now < parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1))
          && reservation.referrer != 'Walk-In'
          && (reservation.codeStatus == 0 || reservation.codeStatus == 1),
        'bordered-item border-retrasada':
          now > parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1)
          && reservation.referrer != 'Walk-In'
          && (reservation.codeStatus == 0 || reservation.codeStatus == 1)
      }">
      <div slot="image" class="drag-image">
        <div class="column is-2 bg-box" style="width:60px; height: 60px;border-radius:10px;" @click.prevent.stop="showReservation(reservation)"
          :class="{
            'precompra-pending': (reservation.referrer != 'Walk-In' && reservation.balancePaid > 0  && reservation.codeStatus === 7),
            'precompra': (reservation.referrer != 'Walk-In' && reservation.balancePaid > 0 && reservation.codeStatus !== 7),
            'reservation': (reservation.referrer != 'Walk-In' && (reservation.balancePaid == null || reservation.balancePaid == 0)),
            'bg-cancel': reservation.codeStatus == 4,
            'bg-walkin': (reservation.referrer == 'Walk-In'),
            'sobre-hora':
            (now > parseInt(reservation.date) &&
             now < parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1))
              && reservation.referrer != 'Walk-In'
              && (reservation.codeStatus == 0 || reservation.codeStatus == 1),
            'retrasada':
              now > parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1)
              && reservation.referrer != 'Walk-In'
              && (reservation.codeStatus == 0 || reservation.codeStatus == 1)
          }">
          <div class="people roll">
            {{reservation.people}}
            <div class="number-people">
              <i class="icon-mks white icon_person"></i>
            </div>
          </div>
          <div class="roll">
            {{getTableInfo(reservation)}}
          </div>
        </div>
      </div>
      <div class="column check-box" v-if="(reservation.codeStatus == 0 || reservation.codeStatus == 1) && !validatePermission('ReadOnly')">
        <button class="action" v-if="reservation.codeStatus == 0"  @click.prevent.stop="isReservationConfirmed(reservation.id)" title="El restaurante no ha confirmado la reserva">
          <i class="icon-mks gray icon_gray" alt="Reserva No Confirmada" title="El restaurante no ha confirmado la reserva"></i>
        </button>
          <button class="action" v-if="reservation.codeStatus == 1"  @click.prevent.stop="isReservationNotConfirmed(reservation.id)" title="Reserva confirmada por restaurante">
          <img src="@/assets/icons/icono-check.svg" title="Reserva confirmada por restaurante">
        </button>
        <button class="action" v-if="reservation.isUserConfirmed == null" title="El usuario no ha confirmado la reservaa">
          <i class="icon-mks gray icon_gray" alt="Reserva No Confirmada" title="El usuario no ha confirmado la reserva"></i>
        </button>
        <button class="action" v-if="reservation.isUserConfirmed == 'email' || reservation.isUserConfirmed == 'sms' || reservation.isUserConfirmed == 'whatsapp' || reservation.isUserConfirmed == 'integration'" title="Reserva confirmada por usuario">
          <img src="@/assets/icons/icoon-check-2.svg" alt="Reserva Confirmada" title="Reserva confirmada por usuario">
        </button>
      </div>
      <div class="column is-2 bg-box" @click.prevent.stop="showReservation(reservation)"
        :class="{
          'precompra-pending': (reservation.referrer != 'Walk-In' && reservation.balancePaid > 0  && reservation.codeStatus === 7),
          'precompra': (reservation.referrer != 'Walk-In' && reservation.balancePaid > 0  && reservation.codeStatus !== 7),
          'reservation': (reservation.referrer != 'Walk-In' && (reservation.balancePaid == null || reservation.balancePaid == 0)),
          'bg-cancel': reservation.codeStatus == 4, 'bg-walkin': (reservation.referrer == 'Walk-In'),
          'sobre-hora':
            (now > parseInt(reservation.date) &&
             now < parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1))
              && reservation.referrer != 'Walk-In'
              && (reservation.codeStatus == 0 || reservation.codeStatus == 1),
          'retrasada':
              now > parseInt(reservation.date) + 1000 * 60 * (parseInt(params.anticipationUserTime) + 1)
              && reservation.referrer != 'Walk-In'
              && (reservation.codeStatus == 0 || reservation.codeStatus == 1)
        }">
        <div class="people roll">
          {{reservation.people}}
          <div class="number-people">
            <i class="icon-mks white icon_person"></i>
          </div>
        </div>
        <div class="roll">
          {{getTableInfo(reservation)}}
        </div>
      </div>
      <div  class="column center-box" @click.prevent.stop="showReservation(reservation)">
        <div class="roll name">
          <div class="name-com">
            <i class="fa fa-user-circle" style="margin-right: 5px;" :style="colorIconCompanion(reservation)" v-if="params.activeCompanion && (reservation.codeStatus == 1 || reservation.codeStatus == 0)" v-tooltip="{content: messageIconCompanion(reservation), placement: 'bottom'}"></i>
            {{ reservation.displayName | capitalize }}
            <span v-if="reservation.eventId"><i class="fa fa-star"></i></span>
          </div>
          <div class="client-icon visit icon_star_fill_visit icon-mks black-pre"
            v-tooltip="{ content: `Asistencias: ${showUser}`, placement: 'bottom' }" v-if="showUser && showUser > 1 && reservation.referrer != 'Walk-In' ">
            <span class="visit-number">{{ showUser }}</span>
          </div>
          <div class="client-icon no-show icon_rombo_no_show icon-mks red"
            v-tooltip="{ content: `No-show: ${notShowUser}`, placement: 'bottom' }" v-if="notShowUser && notShowUser > 1">
            <span class="visit-number" :class="{'two-digits-visit-number' : notShowUser > 9}">{{ notShowUser }}</span>
          </div>
          <GeneralTags :infoReserva="reservation" :existTag="reservation.isExistsTag === 1" :customClass="'container-tags-reservation-global-in-items'"/>
          <a v-if="reservation.isYate === 1" class="vipItem yate-img-icon"><img src="@/assets/icons/addReservation/bote.svg" alt="icon tag"/></a>
          <div class="icon-box" style="padding: 5px !important" v-if="reservation.hasDuplicatedReservations">
            <img class="icon-mks" src="@/assets/icons/alert_yellow.svg" v-tooltip="{content: 'Usuario con otras Reservas', placement: 'bottom'}">
          </div>
          <div v-if="reservation.isYate == 1 && reservation.referrer == 'Walk-In'" class="icon-box">
            <img style="height: 15px; width: 18px;" src="@/assets/icons/yate.jpeg" alt="" v-tooltip="{content: 'Reserva Yate', placement: 'bottom'}">
          </div>
          <div class="icon-box" v-if="reservation.isHotel">
            <i
              class="icon-mks black icon_hotel_habitation"
              v-tooltip="{content: 'Huésped', placement: 'bottom'}"
            ></i>
          </div>
          <div class="icon-box referrers-fix-height">
            <img :if="getUrlImageReferrer(reservation.referrer) !== null" :src="getUrlImageReferrer(reservation.referrer)" style="height: 100%;" height="30" width="30" v-tooltip="{ classes: ['custom-class-fix-popover-global-referer'], content: `Reserva ${reservation.referrer === 'dashboard' ? 'teléfono': reservation.referrer}`, placement: 'bottom'}">
          </div>
        </div>
        <div class="roll">
          <div class="status-box">
            <div v-if="parseInt(reservation.balancePaid) <= 0 && reservation.codeStatus !== 7" class="status">{{reservation.status}}</div>
            <div v-if="parseInt(reservation.balancePaid) > 0 && reservation.codeStatus !== 7" class="status">{{reservation.balancePaid | currency('$', 0)}}</div>
            <div v-if="parseInt(reservation.balancePaid) > 0 && reservation.codeStatus === 7" class="status">{{reservation.status}}</div>
          </div>
          <div class="big-icon-box">
            <div class="icon-box" v-if="reservation.alergies != null">
              <i class="icon-mks black icon_alergy" v-tooltip="{classes: reservation.celebrationName === null && !reservation.giftCardId && !reservation.comments ? ['custom-class-fix-popover-global-referer'] : [], content: 'Alergia(s): ' + (reservation.alergies.length > 15 ? reservation.alergies.substring(0, 15) + '...'  : reservation.alergies), placement: 'bottom'}"></i>
            </div>
            <div class="icon-box" v-if="reservation.comments || reservation.commentRestaurant">
              <img class="icon_glob" src="@/assets/icons/globo_2.svg">
            </div>
            <div class="icon-box" v-if="reservation.giftCardId">
              <i class="icon-mks black icon_bono" v-tooltip="{classes: reservation.celebrationName === null ? ['custom-class-fix-popover-global-referer'] : [], content: 'Tiene Bono', placement: 'bottom'}"></i>
            </div>
            <div style="display:flex" v-if="reservation.services != null && reservation.services.length > 0">
              <div class="icon-box 2" style="text-align: center;" v-for="(serv, llave) in reservation.services" :key="llave" :class="{'display_none': llave > 2 || !reservation.services || serv.requirements === undefined}" >
                <img v-if="llave != 2" style="height: 100%;"  :src="serv.requirements.iconURL" v-tooltip="{content: serv.requirements.displayName , placement: 'bottom'}" />
                <i v-if="llave == 2" class="fa fa-ellipsis-h" v-tooltip="{content: 'Más' , placement: 'bottom'}"></i>
              </div>
            </div>
            <div v-if="reservation.decoration" class="icon-box">
              <i v-if="vendor.id !== 172 && vendor.id !== 173" class="icon-mks black icon_decoration" v-tooltip="{classes: ['custom-class-fix-popover-global-referer'], content: 'Tiene Decoración', placement: 'bottom'}"></i>
              <i v-if="vendor.id == 172 || vendor.id == 173" class="icon-mks black icon_heart" v-tooltip="{classes: ['custom-class-fix-popover-global-referer'], content: 'Tiene Decoración', placement: 'bottom'}"></i>
            </div>
            <div v-if="reservation.celebrationName != null" class="icon-box">
              <img :src="reservation.celebrationIcon" width="30" height="30" v-tooltip="{classes: ['custom-class-fix-popover-global-referer'], content: 'Tiene Celebración', placement: 'bottom'}">
            </div>
          </div>
        </div>
      </div>
      <div class="column final-box" v-if="!isLoading && (reservation.codeStatus < 3 || reservation.codeStatus == 6) && selectedDate == systemDate.substr(0,10) && this.validateSittingOnlyHour(reservation) && !validatePermission('ReadOnly')">
        <button class="action" v-if="(reservation.codeStatus == 1 || reservation.codeStatus == 0) && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && params.activatePartialSeated && (validatePermission('Seated') || validatePermission('MixSFCN'))" @click.prevent.stop="toggleMenuR(reservation.id)" >
          <i class="icon-mks black icon_dashboard_seat"></i>
        </button>
        <div class="not-show-menu" :id="'Reserv#'+reservation.id" v-if="params.activatePartialSeated" ><!-- show-menu-messager -->
          <div class="menu-wrap-messager">
            <div class="menu-messager">
              <div class="circle-absolute"></div>
              <div class="contenet-seats">
                <div class="item-seats" @click.prevent.stop="isReservationSeatedPartial(reservation.id)" v-if="validatePermission('Seated') || validatePermission('MixSFCN')">
                  <button class="action">
                    <img src="@/assets/icons/sentado-parcial.svg" v-if="parseInt(reservation.balancePaid) <= 0">
                    <img  src="@/assets/icons/icono-sentado-verde.svg" v-if="parseInt(reservation.balancePaid) > 0">
                  </button>
                  Sentar Parcial
                </div>
                <div class="item-seats" @click.prevent.stop="isReservationSeated(reservation.id)" v-if="validatePermission('Seated') || validatePermission('MixSFCN')">
                  <button class="action">
                    <i class="icon-mks black icon_dashboard_seat"></i>
                  </button>
                    Sentar Total
                </div>
              </div>
            </div>
          </div>
        </div>
        <b class="action" v-if="reservation.codeStatus == 6 && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && params.activatePartialSeated && (validatePermission('Seated') || validatePermission('MixSFCN') )" @click.prevent.stop="toggleMenuRS(reservation.id)"  v-tooltip="{content: 'Opciones de Sentado', placement: 'top'}">
          <i class="icon-mks black icon_estado_fin_parcial"></i>
        </b>
        <div class="not-show-menu" :id="'Reservs#'+reservation.id" v-if="params.activatePartialSeated" ><!-- show-menu-messager -->
          <div class="menu-wrap-messager">
            <div class="menu-messager">
              <div class="circle-absolute"></div>
              <div class="contenet-seats">
                <div class="item-seats" @click.prevent.stop="isReservationSeated(reservation.id) && (validatePermission('Seated') || validatePermission('MixSFCN'))">
                  <button class="action">
                    <i class="icon-mks black icon_dashboard_seat"></i>
                  </button>
                  Sentar Total
                </div>
                <div class="item-seats" @click.prevent.stop="showReservation(reservation)" v-if="reservation.codeStatus == 6 && reservation.codeStatus != 4 && reservation.codeStatus != 3 || reservation.isSeated  && reservation.codeStatus != 4 && reservation.codeStatus != 3">
                  <button class="action">
                    <i class="icon-mks black icon_estadofinalizar"></i>
                  </button>
                  Finalizar
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="action" v-if="validateSitting(reservation) && (validatePermission('Seated') || validatePermission('MixSFCN'))"  @click.prevent.stop="isReservationSeated(reservation.id)" title="Sentar Reserva">
          <i class="icon-mks black icon_dashboard_seat"></i>
        </button>
        <button class="action" v-if="reservation.isSeated && reservation.codeStatus != 4 && reservation.codeStatus != 3 && (validatePermission('Finish') || validatePermission('MixSFCN'))"   @click.prevent.stop="showReservation(reservation)" v-tooltip="{content: 'Finalizar Reserva', placement: 'bottom'}">
          <i class="icon-mks black icon_estadofinalizar"></i>
        </button>
      </div>
      <div v-if="isLoading" class="column final-box">
        <div class="action">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
    </drag>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import GeneralTags from '@/components/Extends/GeneralTags';

export default {
  props: ['reservation', 'query'],
  components: {
    GeneralTags
  },
  data: () => ({
    isLoading: false,
    yesterday: null,
    isChefCooking: null,
    vendorChefOn: 79,
    now: null
  }),
  computed: {
    ...mapGetters({
      additionalQuestions: [reservationTypes.getters.additionalQuestions],
      systemDate: [globalTypes.getters.serverTime]
    }),
    showUser () {
      if (this.reservation.peopleMetrics) {
        return this.reservation.peopleMetrics.show;
      } else {
        return 0;
      }
    },
    notShowUser () {
      if (this.reservation.peopleMetrics) {
        return this.reservation.peopleMetrics.noShow;
      } else {
        return 0;
      }
    }
  },
  mounted () {
    this.yesterday = this.$moment(this.systemDate).subtract(1, 'days').format('YYYY-MM-DD');
    this.isChefCooking = this.reservation.isChefCooking;
    this.now = this.$moment().valueOf();
    this.interval = setInterval(() => {
      this.now = this.$moment().valueOf();
    }, 1000);
  },
  destroyed () {
    clearInterval(this.interval);
  },
  methods: {
    validateSitting (reservation) {
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      }
      if (dif != null) {
        if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
          if ((reservation.codeStatus === 1 || reservation.codeStatus === 0) && (this.selectedDate === this.systemDate.substr(0, 10) || this.selectedDate === this.yesterday) && !this.params.activatePartialSeated && dif <= 60) {
            return true;
          }
        } else {
          if ((reservation.codeStatus === 1 || reservation.codeStatus === 0) && (this.selectedDate === this.systemDate.substr(0, 10) || this.selectedDate === this.yesterday) && !this.params.activatePartialSeated && dif <= 180) {
            return true;
          }
        }
        return false;
      }
      if ((reservation.codeStatus === 1 || reservation.codeStatus === 0) && (this.selectedDate === this.systemDate.substr(0, 10) || this.selectedDate === this.yesterday) && !this.params.activatePartialSeated) {
        return true;
      }
      return false;
    },
    validateSittingOnlyHour (reservation) {
      if (reservation.isSeated) {
        return true;
      }
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      } else {
        return true;
      }
      if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
        if (dif != null && dif <= 60) {
          return true;
        }
      } else {
        if (dif != null && dif <= 180) {
          return true;
        }
      }
      return false;
    },
    colorIconCompanion (info) {
      let color = 'color: red;';
      const maxColor = info.people - 1;
      if (info.companions != null) {
        if (info.companions.indexOf(',') > 0) {
          const totalColor = info.companions.split(', ');
          if (totalColor.length >= maxColor) {
            color = 'color: green;';
          } else {
            color = 'color: orange;';
          }
        } else {
          const totalColor2 = 1;
          if (totalColor2 >= maxColor) {
            color = 'color: green;';
          } else {
            color = 'color: orange;';
          }
        }
      }
      return color;
    },
    messageIconCompanion (info) {
      let message = 'Aún no tiene acompañantes';
      const maxMessage = info.people - 1;
      if (info.companions != null) {
        if (info.companions.indexOf(',') > 0) {
          const totalMessage = info.companions.split(', ');
          if (totalMessage.length >= maxMessage) {
            message = 'Ya tienes todos los acompañantes';
          } else {
            message = 'Aún le faltan acompañantes';
          }
        } else {
          const totalMessage2 = 1;
          if (totalMessage2 >= maxMessage) {
            message = 'Ya tienes todos los acompañantes';
          } else {
            message = 'Aún le faltan acompañantes';
          }
        }
      }
      return message;
    },
    toggleMenuR (reservation) {
      const els = document.getElementsByClassName('show-menu-messager');
      if (els.length > 0) {
        for (let i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(document.getElementById('Reserv#' + reservation), 'show-menu-messager')
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(document.getElementById('Reserv#' + reservation), 'show-menu-messager')
        // eslint-disable-next-line
        classie.add(document.body, 'show-mobile-map')
      }
    },
    toggleMenuRS (reservation) {
      const els = document.getElementsByClassName('show-menu-messager');
      if (els.length > 0) {
        for (let i = 0; i < els.length; i++) {
          els[i].classList.remove('show-menu-messager');
          if (els.length === i) {
            // eslint-disable-next-line
            classie.add(document.getElementById('Reservs#' + reservation), 'show-menu-messager')
          }
        }
      } else {
        // eslint-disable-next-line
        classie.add(document.getElementById('Reservs#' + reservation), 'show-menu-messager')
        // eslint-disable-next-line
        classie.add(document.body, 'show-mobile-map')
      }
    },
    getTableInfo (reservation) {
      if (reservation.tableName !== null) {
        if (reservation.tableName.length <= 5) {
          return reservation.tableName;
        } else {
          return reservation.tableName.substr(0, 5) + '...';
        }
      }
    },
    showReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
      this.isLoading = true;
      this.$store.dispatch({ type: 'people:findByReservationId', data: { id: infoReserva.id } }).then(response => {
        let type = '';
        if (infoReserva.referrer !== 'Walk-In') {
          type = (infoReserva.balancePaid === null || parseInt(infoReserva.balancePaid) === 0) ? 'Reserva' : 'Precompra';
        } else {
          type = 'Walk-In';
        }
        infoReserva.edit = true;
        if (response.code === 200 && response.data != null) {
          infoReserva.subscribed = response.data.subscribed;
          infoReserva.peopleId = response.data._id;
          infoReserva.peopleMetrics = response.data.peopleMetrics;
          infoReserva.lastReservation = response.data.lastReservation;
        }
        this.isLoading = false;
        this.$store.commit(reservationTypes.mutations.setType, type);
        this.$store.commit(reservationTypes.mutations.setInfoReservation, infoReserva);
        this.$store.commit(reservationTypes.mutations.setViewReservation, true);
      }).catch(() => {
        this.isLoading = false;
      });
    },
    isReservationConfirmed (id) {
      this.isLoading = true;
      this.$store.dispatch({ type: 'reservation:reservationIsConfirmed', data: { reservationId: id, userId: this.user.id } }).then(({ data }) => {
        if (data.code === 200) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    isReservationNotConfirmed (id) {
      this.isLoading = true;
      this.$store.dispatch({ type: 'reservation:undoReservationStatus', data: { reservationId: id, item: 'isConfirmed', userId: this.user.id } }).then(({ data }) => {
        if (data.code === 200) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    isReservationSeated (id) {
      this.isLoading = true;
      this.$store.dispatch({ type: 'reservation:reservationIsSeated', data: { reservationId: id, userId: this.user.id } }).then(({ data }) => {
        if (data.code === 200) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    isReservationSeatedPartial (id) {
      this.isLoading = true;
      this.$store.dispatch({ type: 'reservation:reservationIsSeatedPartial', data: { reservationId: id, userId: this.user.id } }).then(({ data }) => {
        if (data.code === 200) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setChefCooking (cooking, id) {
      this.$store.dispatch({ type: 'reservation:reservationIsChefCooking', data: { reservationId: id, cooking: cooking } }).then(({ data }) => {
        if (data.code === 200) {
          this.isChefCooking = cooking;
        }
      });
    },
    onPress (event, reserv) {
      if (this.isResponsive()) {
        this.$store.commit(reservationTypes.mutations.setSideLateralMove, reserv);
      }
    },
    isResponsive () {
      if (window.innerWidth <= 1000) { return true; } else { return false; }
    }
  }
};
</script>
<style lang="scss">
  .block-pres-drag{
    position:relative;
    height:65px;
    width:100%;
    .onPress{
      position: absolute;
      height: 60px;
      width: calc(100% - 82px);
      top: 0;
      left: 36px;
      z-index: 99999;
      display: none;
    }
  }
  .display_none{
    display: none !important;
  }
  .ReservationItem{
    display:flex;
    align-items:stretch;
    margin:0 5px 5px 5px !important;
    min-height: 60px;
    max-height: 60px;
    border-right: 1px solid #ccc;
    border-top:1px solid #ccc;
    border-radius: 5px;
    cursor:pointer;

    &.bordered-item{
      .check-box .action{
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-left: none;
          border-bottom: none;
        }
      }
      .bg-box{
        border-left: none;
        border-bottom: none;
      }
      .center-box .roll{
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-left: none;
          border-bottom: none;
        }
      }
      .final-box{
        border-bottom: none;
      }
    }

    &.border-sobre-hora{
      border: 2px solid #FFD43B;
    }

    &.border-retrasada{
      border: 2px solid #FF8A00;
    }

    .not-show-menu {
      z-index: 9999999;
      max-width: 338px;
      min-width: 140px;
      left: -114px;
      top: 0;
      right: 0;
    }
    .menu-messager {
      padding: 5px;
    }

    .show-menu-messager .circle-absolute {
      right: 5px !important;
    }
    .show-menu-messager .contenet-seats{
      z-index: 2;
      width:100%;
      position:relative;
      font-size: 13px;
      .action{
        width: 20px;
        flex: 1;
        max-width: 35px;
      }
      .item-seats{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:first-child{
          border-bottom: 1px solid #eee;
          padding-bottom: 3px;
          margin-bottom: 3px;
        }
      }
      .final-box img,
      .check-box i,
      .check-box img {
        height: 20px;
        width: 20px;
      }
    }

    i{
      margin-right:0;
      max-width:100%;
      max-height:100%;
      margin-top: 0px;
    }
     & > div:first-child{
       border-radius:5px 0 0 5px;
     }
    .bg-box{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #ccc;
      border-bottom:1px solid #ccc;
      max-width: 60px;
      &.reservation{
        background:#00ADC6;
      }
      &.walking{
        background:#8A51B4;
      }
      &.precompra{
        background:#CAD300;
      }
      &.precompra-pending{
        background:#D9D9D9;
      }
      &.sobre-hora{
        background: #FFD43B !important;
      }
      &.retrasada{
        background: #FF8A00 !important;
      }
      .roll{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding:0 5px;
        width:100%;
        justify-content: center;
        font-size: 12px;
        color: #fff;

      }
      .people{
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;
        padding-top: 2px;
        margin-bottom: 2px;
        font-weight:600;
        &:after{
          content:"";
          bottom:0px;
          position: absolute;

          height:1px;
          width:70%;
          left:15%;
          background: #fff;
        }
        i{
          height: 13px;
        }
        .number-people{
          position:relative;
          font-size:10px;
          font-weight:700;
          margin-top:-2px;
          color:#fff;
        }
      }
    }
    .center-box{
      min-height: 100%;
      display: flex;
      flex-direction: column;
      width: 0;
      .roll{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:0 0 0 5px;
        border-left: 1px solid #ccc;
        border-bottom:1px solid #ccc;
        max-height:50%;
        &.name{
          width:100%;
          .icon-box{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px !important;
            i{
              margin-top: 0 !important;
            }
          }
        }
        .client-icon{
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;

          &.visit{
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
          }
          &.no-show{
            .visit-number{
              padding-right: 1px;
            }
          }
          .visit-number{
            color: white;
            font-size: 10px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
          }
        }
        .name-com{
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 40px);

        }
        &:first-child{
          font-size: 11px;
          font-weight: 700;
          text-transform: capitalize;
          white-space: nowrap;
        }
        .status{
          font-size: 11px;
        }

        .big-icon-box{
          display:flex;
        }
        .icon-box{
          align-items: center;
          justify-content: center;
          border-left: 1px solid #ccc;
          padding: 5px;
          max-height:30px;
          max-width: 30px;
          min-width: 30px;
          min-height:30px;
          display: flex;
          &.referrers-fix-height{
            height: 100%;
          }
          i{
            margin-top: 0;
            &.fa-ellipsis-h {
              margin-top: 0;
              bottom: -5px;
              position: relative;
              left: 0px;
            }
          }
          img{
            margin-top: 0;
            max-width: 100%;
          }
        }
      }
    }

    .final-box, .check-box{
      min-height: 100%;
      display: flex;
      flex-direction: column;
      max-width:40px;
      border-left: 1px solid #ccc;
      border-bottom:1px solid #ccc;
      border-radius: 0 0 5px 0;
      cursor:pointer;
      background:transparent;
      i, img {
        height: 19px;
        width: 18px;
      }
      .action{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border:none;
        border-radius:  0 5px 5px 0;
        align-items:center;
        cursor:pointer;
        background:transparent;
      }
    }
    .check-box{
      .action{
        padding: 0;
      }
    }

    .check-box{
      max-width:30px;
      border:none;
      .action{
        border-left: 1px solid #ccc;
        border-bottom:1px solid #ccc;
        &:first-child{
          border-radius:  5px 0 0 0;
        }
        &:last-child{
          border-radius:  0 0 0 5px;
        }
      }
    }
    .icon-mks.icon_reserva_telephone {
      margin-top: 3px !important;
      height: 15px;
      width: 18px;
    }
  }
  @media(max-width: 1000px) {
    .block-pres-drag{
      .onPress{
        display:block;
      }
    }

    .show-mobile-map{
    .ReservationItem{
      .not-show-menu{
        left:0;
        .menu-wrap-messager{
          left:1% !important;
          right: 0 !important;
        }
      }
    }
  }
  }
  .vipItem {
    padding: 1px 1px;
    border-radius: 50px;
    font-weight: bold;
    width: 30px;
    text-align: center;
    margin-right: 5px;
    user-select: none;
    font-size: 7px;
    display: flex;
    &.yate-img-icon{
      max-width: 19px;
      max-height: 19px;
      img{
        min-width: 19px;
        min-height: 19px;
      }
    }
  }

  .icon_glob {
    width: 15px!important;
    height: 15px!important;
    margin-right: 5px;
  }
</style>
