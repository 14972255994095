<template>
  <div @click="showUl = false" class="user-component">
    <!--DISPLAYNAME WITH SEARCH-->
    <InputComponent
      :field="'displayName'"
      :formInfo="formInfo"
      :iconLeft="'user'"
      :iconRight="true"
      :initInfo="initInfo"
      :placeholder="'Nombre'"
      :required="true"
      :rules="'min:3'"
      :type="'text'"
      @changeValue="searchUser($event) ;"
      @keypress="keypress"
      v-if="!walk || typeof walk === 'undefined'"
    />
    <InputComponent
      :field="'displayName'"
      :formInfo="formInfo"
      :iconLeft="'user'"
      :iconRight="true"
      :initInfo="initInfo"
      :placeholder="'Nombre'"
      :type="'text'"
      @changeValue="searchUser($event);"
      @keypress="keypress"
      v-if="walk"
    />
    <div class="space__coincidence-list" v-if="showUl"></div>
    <ul class="concidence-list" tabindex="0" v-click-outside="()=>showUl=false" v-if="showUl">
      <li class="loading" v-if="isLoading">Cargando resultados...</li>
      <li class="loading" v-if="!isLoading && dataUser.length == 0">No hay coincidencias..</li>
      <li
        :key="ind"
        @click="setData(data)"
        class="concidence-list-item"
        v-for="(data, ind) in dataUser"
      >
        <div
          style="
            display: flex;
            width: 35%;
            justify-content: flex-start;
            align-items: center;
            max-width: 35%;
          "
        >
          <img
            :src="require(data.isVip ? '@/assets/icons/vipActive.svg' : '@/assets/icons/addReservation/usuario.svg')"
            alt="icon VIP"
            class="personIcon"
            width="15px"
          />
          <span
            style="
              font-size: 11px;
              font-family: 'Roboto';
              color: #606773;
              margin-left: 5px;
            "
          >{{ data.name ? data.name : "No hay info" }}</span>
        </div>
        <div
          style="
            display: flex;
            width: 28%;
            justify-content: flex-start;
            align-items: center;
            max-width: 28%;
          "
        >
          <img
            alt="phone"
            src="@/assets/icons/addReservation/phone.svg"
            style="width: 12px; height: 12px"
          />
          <span
            style="
              font-size: 11px;
              font-family: 'Roboto';
              color: #606773;
              margin-left: 5px;
            "
          >{{ data.phone ? data.phone : "No hay info" }}</span>
        </div>
        <div
          style="
            display: flex;
            width: 32%;
            justify-content: flex-start;
            align-items: center;
            max-width: 32%;
          "
        >
          <img
            alt="email"
            src="@/assets/icons/addReservation/mail.svg"
            style="width: 12px; height: 12px"
          />
          <span
            style="
              font-size: 11px;
              font-family: 'Roboto';
              color: #606773;
              margin-left: 5px;
            "
          >{{ data.email ? data.email : "No hay info" }}</span>
        </div>
      </li>
    </ul>
    <!--FIN DISPLAYNAME WITH SEARCH-->

    <!--PHONE AND EMAIL-->
    <div :class="{noDocument:!params.enableIdentityDocument}" class="user-component-item">
      <div :class="{toList:toList}" class="phoneContainer">
        <SelectInput
          :field="'phone'"
          :fieldSelector="'bandera'"
          :formInfo="formInfo"
          :iconLeft="'phone'"
          :iconRight="false"
          :initInfo="initInfo"
          :placeholder="'Telefono'"
          :required="true"
          :selector="true"
          :type="'number'"
          :typeData="'countries'"
          @changeValue="changeSelectorValue($event,'phone')"
          @changeFromSelectorUser="changeFromSelectorUser"
          :fromSelectorUser="fromSelectorUser"
        />
      </div>
      <div :class="{toList:toList}" class="emailContainer">
        <InputComponent
          :field="'email'"
          :formInfo="formInfo"
          :iconLeft="'email'"
          :iconRight="false"
          :initInfo="initInfo"
          :placeholder="'Email'"
          :required="true"
          :rules="'email'"
          :type="'email'"
          @changeValue="changeInputValue"
        />
      </div>
      <div class="doucmentContainer" v-if="!toList && params.enableIdentityDocument">
        <SelectInput
          :field="'identityDocument'"
          :fieldSelector="'typeDocument'"
          :formInfo="formInfo"
          :iconRight="false"
          :initInfo="initInfo"
          :placeholder="'Documento Identidad'"
          :required="true"
          :rules="'regex:^[a-zA-Z0-9]*$'"
          :selector="true"
          :type="'text'"
          :typeData="'document'"
          @changeValue="changeSelectorValue($event,'document')"
        />
      </div>
    </div>

    <!--CAMPOS PARAMETRIZADOS-->
    <InputComponent
      :field="'address'"
      :iconLeft="'address'"
      :iconRight="false"
      :initInfo="initInfo"
      :placeholder="'Dirección'"
      :required="true"
      :rules="'min:3'"
      :type="'text'"
      @changeValue="changeInputValue"
      v-if="params.enableAddress"
    />
    <InputComponent
      :field="'birthday'"
      :iconLeft="'birthday'"
      :iconRight="false"
      :initInfo="initInfo"
      :placeholder="'Seleccione la fecha de nacimiento'"
      :type="'text'"
      @changeValue="changeInputValue"
      v-if="params.activeBirthDate"
    />
    <!-- <Input
      v-if="params.activeCompanion"
      :iconLeft="'temperature'"
      :placeholder="'Temperatura'"
      :field="'temperature'"
      :type="'text'"
      :rules="'numeric'"
      :required="true"
      :iconRight="false"
      :initInfo="initInfo"
    />-->
    <div class="input-habitation" v-if="params.isActiveHotel && formInfo.isHotel">
      <InputComponent
        :field="'habitation'"
        :iconLeft="'habitation'"
        :iconRight="false"
        :initInfo="initInfo"
        :placeholder="'Número de la habitación'"
        :required="true"
        :rules="'min:1'"
        :type="'text'"
        @changeValue="changeInputValue"
      />
    </div>
    <!--FIN CAMPOS PARAMETRIZADOS-->

    <div class="people-legal" v-if="params.legalPersonSelector && !walk && !toList">
      <div class="people-legal-left">
        <div :key="this.noLegalPerson" class="checboxClientType">
          <span class="f10 fw-400 itsClient">¿El cliente es persona jurídica ?</span>
          <div class="checkBoxFamily">
            <b-checkbox
              @change.native="onlyOneSelected"
              name="subscribed"
              native-value="YES"
              size="is-small"
              v-model="legalPerson"
            ></b-checkbox>
          </div>
        </div>
        <div class="legalInputContainer" v-if="formInfo.isLegalPerson">
          <InputComponent
            :field="'legalPersonName'"
            :iconLeft="'company'"
            :iconRight="false"
            :initInfo="initInfo"
            :placeholder="'Nombre de la empresa'"
            :required="true"
            :rules="'min:3'"
            :type="'text'"
            @changeValue="changeInputValue"
          />
        </div>
      </div>
      <div class="people-legal-right" v-if="false">
        <b-checkbox
          name="subscribed"
          size="is-small"
          v-if="params.enableSuscribed"
          v-model="formInfo.subscribed"
        >Suscribir cliente acepta el envío de información</b-checkbox>
      </div>
    </div>

    <p class="sub-text" v-if="!walk">
      *No olvides poner un correo o teléfono para poder comunicarte con el
      cliente.
    </p>
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import validator from 'validator';
import _ from 'lodash';
import InputComponent from '@/components/Extends/Input';
import SelectInput from '@/components/Extends/SelectInput';
export default {
  name: 'FormReservationUser',
  props: ['email', 'indicativo', 'flag', 'walk', 'initInfo', 'toList', 'creating'],
  components: {
    InputComponent,
    SelectInput
  },
  data () {
    return {
      formInfo: {
        isLegalPerson: false,
        subscribed: false,
        displayName: '',
        birthday: '',
        phone: '',
        indicative: 57,
        indicativo: 57,
        pais: 'Colombia',
        bandera: '',
        email: ''
      },
      isLoading: false,
      showUl: false,
      canShowUl: true,
      dataUser: [],
      timeout: null,
      noLegalPerson: false,
      legalPerson: ['NO'],
      returnObject: {},
      fromSelectorUser: false
    };
  },
  mounted () {
    this.listPaises();
    if (this.flag !== undefined) {
      this.value.bandera = this.flag;
      this.bandera = this.flag;
    }
  },
  computed: {
    sampleFormat () {
      const dtf = new Intl.DateTimeFormat('es-ES');
      return dtf.format(new Date(2000, 11, 25, 12));
    }
  },
  watch: {
    formInfo: {
      handler: function () {
        if (!this.formInfo.phone) {
          this.formInfo.phone = '';
        }
        this.$emit('formInfo', { ...this.formInfo });
      },
      deep: true
    },
    initInfo: {
      handler: function () {
        if (this.initInfo.subscribed) {
          this.formInfo.subscribed = this.initInfo.subscribed;
        }
        if (this.initInfo.isLegalPerson) {
          this.formInfo.isLegalPerson = this.legalPerson = ['YES'];
        }
        if (this.initInfo.legalPersonName) {
          this.formInfo.legalPersonName = this.initInfo.legalPersonName;
        }
        if (this.initInfo.typeDocument) {
          this.formInfo.typeDocument = this.initInfo.typeDocument;
        }
      },
      deep: true
    },
    legalPerson () {
      if (this.legalPerson.includes('YES')) {
        this.formInfo.isLegalPerson = true;
      } else {
        this.formInfo.isLegalPerson = false;
      }
    }
  },
  methods: {
    changeFromSelectorUser (status) {
      this.fromSelectorUser = status;
    },
    keypress () {
      this.canShowUl = true;
    },
    changeInputValue (data) {
      this.formInfo = { ...this.formInfo, ...data };
      // this.returnObject = { ...this.returnObject, ...data };
      // this.$emit('formInfo', { ...this.formInfo });
    },
    changeSelectorValue (data, field) {
      this.formInfo = { ...this.formInfo, ...data };
      // this.returnObject = { ...data };
      // this.$emit('formInfo', { ...this.formInfo });
    },
    onlyOneSelected () {
      // ? se asegura que deba tener una opcion seleccionada y solo una
      if (this.legalPerson.length > 1) {
        this.legalPerson.shift();
      } else if (this.legalPerson.length === 0) {
        this.legalPerson = ['NO'];
      }
    },
    listPaises () {
      var data = [];
      var ind = this.value?.indicativo != null ? this.value.indicativo : 57;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else if (item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      if (this.value) {
        this.value.paises = data;
      }
    },
    isValidEmail () {
      if (
        this.value.email !== null &&
        this.value.email !== '' &&
        this.value.email !== undefined
      ) {
        if (validator.isEmail(this.value.email)) {
          this.value.errorMail = null;
          return true;
        } else {
          this.value.errorMail = 'Por favor ingrese un Email válido';
          return false;
        }
      } else {
        this.value.errorMail = null;
      }
      // this.$forceUpdate();
      // this.vm.$forceUpdate();
    },
    getUserList () {
      if (this.formInfo.displayName.length > 2) {
        this.showUl = true;
      } else {
        this.showUl = false;
      }
    },
    setData (data) {
      this.changeFromSelectorUser(true);
      if (data.notes || data.tags) {
        this.$emit('getRequeriments', { notes: data.notes ? data.notes : '', tags: data.tags ? data.tags : [] });
      }
      this.formInfo.displayName = data.name;
      this.formInfo.birthday = data.birthday;
      if (data.phone) {
        this.formInfo.phone = data.phone;
      } else {
        this.formInfo.phone = null;
      }
      if (data.email) {
        this.formInfo.email = data.email;
      } else {
        this.formInfo.email = null;
      }
      this.formInfo.subscribed = data.subscribed;
      this.formInfo.address = data.address;
      this.formInfo.temperature = data.temperature;
      this.formInfo.isVip = !!(data.isVip === true || data.isVip === 1);
      this.formInfo.isRecommended = !!(data.isRecommended === true || data.isRecommended === 1);
      this.formInfo.isHabitual = !!(data.isHabitual === true || data.isHabitual === 1);
      this.formInfo.isYate = !!(data.isYate === true || data.isYate === 1);
      this.formInfo.isHotel = 0;
      this.formInfo.habitation = null;
      if (this.formInfo.isYate) {
        this.formInfo.referrer = 'yate';
      }

      this.formInfo.identityDocument = data.identityDocument;
      this.formInfo.typeDocument = data.typeDocument ? data.typeDocument : 'CC';
      this.formInfo.indicative = data.indicativoUser ? parseInt(data.indicativoUser) : (data.indicative ? data.indicative : 57);
      this.formInfo.indicativo = data.indicativoUser ? parseInt(data.indicativoUser) : (data.indicative ? data.indicative : 57);
      this.showUl = false;
    },
    searchUser (object) {
      this.formInfo = { ...this.formInfo, ...object };
      this.$emit('formInfo', {
        ...this.formInfo,
        ...object
      });
      if (this.canShowUl) {
        if (object.inputPressed) {
          const string = object.displayName;
          clearTimeout(this.timeout);
          var thes = this;
          this.isLoading = true;
          const stringNew = string ? string.trim() : '';
          this.timeout = setTimeout(function () {
            if (string !== '' && string.length >= 2 && stringNew.length > 2) {
              thes.$store
                .dispatch({
                  type: 'people:search',
                  data: {
                    search: string,
                    vendorId: thes.vendor.id
                  }
                })
                .then((response) => {
                  thes.dataUser = response;
                  thes.showUl = true;
                  thes.isLoading = false;
                  thes.canShowUl = false;
                });
            }
          }, 500);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.personIcon {
  min-width: 23px;
  object-fit: fill;
  max-height: 18px;
}
.phoneMaxRange {
  @media (max-width: 565px) {
    max-width: 245px;
  }
}
.input-habitation {
  width: 45%;
  @media (min-width: 1280px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 36.2%;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
}
.legalInputContainer {
  min-width: 40%;
  height: fit-content;
  position: relative;
  top: 0.4rem;
  flex-grow: 1;
  @media (max-width: 565px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) and (max-width: 965px) {
    width: 59.5%;
  }
  @media (min-width: 1000px) {
    min-width: 43.5%;
    position: relative;
    right: 20px;
  }
  @media (min-width: 1200px) {
    min-width: 45%;
    position: relative;
    right: 25px;
  }
  @media (min-width: 1400px) {
    min-width: 57%;
    position: relative;
    right: 0px;
  }
  @media (min-width: 1800px) {
    min-width: 67%;
    position: relative;
    right: 0px;
  }
}
.checboxClientType {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
.user-component {
  position: relative;
  &-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.noDocument {
      @media screen and (max-width: 900px) and (min-width: 500px) {
        justify-content: flex-start;
        gap: 22px;
        .phoneContainer {
          flex: 0.6;
        }
        .emailContainer {
          flex: 1;
        }
      }
    }

    .phoneContainer {
      width: 40%;
      @media (max-width: 1600px) {
        width: 49%;
      }
      @media (max-width: 1000px) {
        width: 26%;
      }
      @media (max-width: 565px) {
        width: 100%;
      }
    }
    .phoneContainer.toList {
      @media (max-width: 1000px) {
        width: 36%;
      }
      @media (max-width: 565px) {
        width: 100%;
      }
    }
    .emailContainer {
      width: 58%;
      @media (max-width: 1600px) {
        width: 49%;
      }
      @media (max-width: 1000px) {
        width: 40%;
      }
      @media (max-width: 565px) {
        width: 100%;
      }
    }
    .emailContainer.toList {
      width: 58%;
      @media (max-width: 1000px) {
        width: 60%;
      }
      @media (max-width: 565px) {
        width: 100%;
      }
    }
    .doucmentContainer {
      width: 40%;
      height: fit-content;
      #select-input-personalizado {
        margin: unset;
      }
      @media (min-width: 1000px) and (max-width: 1279px) {
        width: 48%;
      }
      @media (max-width: 1000px) {
        width: 32%;
      }
      @media (max-width: 565px) {
        width: 100%;
      }
    }
    @media (max-width: 565px) {
      flex-direction: column;
    }
    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
    .left-50 {
      width: 50%;
      @media (max-width: 565px) {
        width: 65%;
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-left: 15px;
      @media (max-width: 565px) {
        margin: unset;
      }
      #input-personalizado {
        width: 100%;
      }
    }
  }
  .people-legal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.7rem;
    margin-top: 4px;
    gap: 9px;
    min-height: 45px;
    max-width: 100%;
    @media screen and (max-width: 600px) {
      gap: 4px; //? puede que diga que hay poco espaciado ya sabemos como son
    }
    @media screen and (max-width: 900px) {
      margin-top: 0px;
    }
    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      overflow: hidden;
      @media (max-width: 565px) {
        align-items: flex-start;
        flex-direction: column;
      }

      span {
        margin-right: 15px;
      }
    }
    &-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      #input-personalizado {
        width: 100%;
        margin-bottom: unset;
      }
    }
  }
  .space__coincidence-list {
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 1000;
    border-left: 1px solid rgba(68, 75, 87, 0.5);
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    height: 2rem;
    width: 100%;
    background-color: transparent;
    pointer-events: none;
  }
  .concidence-list::-webkit-scrollbar-track {
    border: none;
    background-color: transparent !important;
  } /* webkit browsers(safari, chrome) */
  .concidence-list::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 47px !important;
  }
  .concidence-list {
    position: absolute;
    left: 0;
    top: 40px;
    background: #fff;
    z-index: 999;
    width: 100%;
    overflow-y: auto;
    max-height: 175px;
    padding: 2px 10px;
    border-left: 1px solid rgba(68, 75, 87, 0.5);
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    border-bottom: 1px solid rgba(68, 75, 87, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .loading {
      margin-bottom: 3px;
      font-size: 11px;
      font-family: "Roboto";
      margin-top: 3px;
      margin-left: 15px;
    }
    .close-right {
      float: right;
      cursor: pointer;
      position: sticky;
      right: 5px;
      top: 5px;
      width: 22px;
      height: 22px;
      background: #444b57;
      color: white;
      border-radius: 99%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      display: flex;
      cursor: pointer;
      width: 100%;
      padding: 4px 0;
    }
    li:hover {
      background-color: #ccc;
      border-radius: 5px;
      span {
        color: white;
      }
    }
  }
  .bandera_list {
    display: flex;
    margin-top: 0px;
    width: 25px;
    height: 17px;
    margin-right: 0;
  }
  .phone_field {
    .help.is-danger {
      color: #ff3860;
      margin-top: -8px;
      margin-left: 140px;
    }
  }
  @media screen and (max-width: 1450px) {
    .phone_field {
      .help.is-danger {
        margin-left: 110px;
      }
    }
  }
  @media screen and (max-width: 1170px) {
    .phone_field {
      .help.is-danger {
        margin-left: 80px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .phone_field {
      .help.is-danger {
        margin-left: 155px;
      }
    }
  }
  @media screen and (max-width: 870px) {
    .phone_field {
      .help.is-danger {
        margin-left: 120px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .phone_field {
      .help.is-danger {
        margin-left: 0px;
      }
    }
  }
  .indicativo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .field.is-horizontal {
      width: 100%;
    }
    .phone-indi {
      position: relative;
    }
    .left-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 5px 0px 5px 5px;
      border-radius: 50px 0 0 50px;
      border-right: 0;
      height: 27px;
      padding-left: 16px;
      margin-right: 0 !important;
      position: absolute;
      z-index: 5;
      margin-top: 1px;
      margin-left: 13px;
    }
    .field-body {
      .field {
        &:first-child {
          position: absolute;
          .help.is-danger {
            color: #ff3860;
            display: none;
          }
        }
        &:last-child {
          .input {
            padding-left: 100px;
          }
        }
      }
    }
    .input-phone {
      width: 100%;
      padding: 5px 8px;
      border: 1px solid #d2d6de;
      border-radius: 0 5px 5px 0;
      outline: none;
      height: 30px;
    }
  }
  .multiselect {
    box-sizing: content-box !important;
    display: block !important;
    position: relative !important;
    width: 100% !important;
    text-align: left !important;
    color: #35495e !important;
    min-height: auto !important;
    height: 100% !important;
    &__tags {
      margin-left: -32px !important;
      min-height: 30px !important;
      display: block !important;
      padding: 0px 30px 0 30px !important;
      border-radius: 5px !important;
      border: none !important;
      background: transparent !important;
      font-size: 14px !important;
    }
    &__select {
      width: 33px !important;
      height: 21px !important;
      &::before {
        height: 30px !important;
      }
    }
    &__option--highlight:after {
      content: "" !important;
      background: #41b883 !important;
      color: #fff !important;
    }
    &__option:after {
      display: none !important;
    }
    &__option {
      display: flex !important;
      align-items: center !important;
      padding: 5px !important;
      height: 20px !important;
      line-height: 12px !important;
      text-decoration: none !important;
      text-transform: none !important;
      position: relative !important;
      cursor: pointer !important;
      white-space: nowrap !important;
      font-size: 12px !important;
      min-height: 30px !important;
    }
    &__single {
      margin-top: -1px;
    }
    &__input,
    &__single {
      padding-left: 5px !important;
      margin-bottom: 0px !important;
      font-size: 11px !important;
      font-weight: 600 !important;
      line-height: 21px !important;
      background: transparent !important;
    }
    &__content-wrapper {
      position: absolute !important;
      display: block;
      background: #fff !important;
      width: 200px !important;
      max-height: 239px !important;
      overflow: auto !important;
      border: 1px solid #e8e8e8;
      border-top: none !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      z-index: 50 !important;
      -webkit-overflow-scrolling: touch !important;
      -webkit-transform: translateZ(0px) !important;
    }
  }
  .field .input,
  .field .taginput .taginput-container.is-focusable,
  .field .textarea,
  .field .select select {
    border-radius: 50px !important;
    box-shadow: none !important;
    padding-left: 15px;
  }
  .field-label.is-normal {
    padding-top: 0;
    display: flex;
    margin-right: 10px;
    .label {
      display: flex;
      align-items: center;
      max-height: 30px;
      font-size: 14px;
      padding-left: 0px;
      white-space: nowrap;
    }
  }
  .control .help.counter {
    float: right;
    margin-left: 0.5em;
    display: none;
  }
}
.control.has-icons-left .icon.is-left {
  margin-top: -3px !important;
}
.noVip {
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;
  user-select: none;
  font-size: 12px;
  width: 55px;
  height: 28px;
  display: block;
  &:hover {
    color: #b5b5b5 !important;
  }
}
.vip {
  border-radius: 4px;
  color: #dfae04;
  font-weight: bold;
  width: 95px;
  text-align: center;
  margin-left: 10px;
  user-select: none;
  font-size: 12px;
  width: 55px;
  height: 28px;
  display: block;
  &:hover {
    color: #ebeced !important;
  }
}
.field-label {
  margin-right: 0.5rem !important;
  text-align: left !important;
}
.field-label.is-normal {
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.field-label.is-normal {
  padding-top: 0 !important;
}
.field.is-horizontal {
  display: flex;
  margin-bottom: 10px;
}
.the_calendar_day {
  position: absolute;
  left: 8.5px;
  font-size: 11px;
  top: 21px;
}
.birth_calendar {
  .vdpComponent:hover {
    background: none !important;
  }
  .vdpTimeUnit > input:hover,
  .vdpTimeUnit > input:focus {
    border-bottom-color: none;
  }
  .vdpComponent input {
    text-shadow: none;
    color: black;
  }
  .vdpOuterWrap.vdpFloating {
    z-index: 5;
  }
  .vdpComponent {
    width: 100%;
    .toggle_birthday {
      padding-left: 15px;
      width: 100%;
      border-radius: 50px;
      border-style: double;
      border: 1px solid transparent;
      height: 2em;
      border-color: #b5b5b5;
      color: #303030;
      text-align: left;
    }
  }
}
.birth_calendar::placeholder {
  color: gray;
}
.user-bx {
  position: relative;
  .field-body {
    position: relative !important;
    .Concidence {
      position: absolute;
      left: 0;
      top: 100%;
      background: #fff;
      z-index: 999;
      border-radius: 10px;
      border: 1px solid #ccc;
      width: 100%;
      overflow-y: auto;
      max-height: 100px;
      li {
        padding: 5px 10px;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          background: #2cbe37;
          color: #fff;
        }
      }
    }
    .field {
      &:first-child {
        width: 100%;
        margin-right: 0;
      }
      &:last-child {
        width: 0;
      }
    }
  }
}
.text-danger {
  width: 100%;
  position: relative;
  font-size: 12px;
  color: #ff3860;
  bottom: 17px;
  left: 96px;
}
.user-bx .field-body .field:last-child {
  width: 65px;
}
.vip-search {
  padding-right: 30px !important;
}
.imgYate {
  cursor: pointer;
}
.contYate {
  margin: -2px 0 0 10px;
  position: relative;
  .icon-mks {
    width: 12px;
    height: 12px;
    position: absolute;
    right: -10px;
    top: -2px;
  }
  // &.active {
  //   border: 1px solid;
  //   border-radius: 5px;
  //   padding: 5px 5px 0px 5px;
  // }
}
.sub-text {
  font-size: 12px;
  font-family: "Roboto";
  color: #444b57;
  margin-bottom: 10px;
}
.mb-1-rem {
  margin-bottom: 1rem;
}
.f10 {
  font-family: "Roboto";
  font-size: 14px;
}
@media only screen and (min-width: 1001px) and (max-width: 1279px) {
  .f10 {
    font-size: 14px;
  }
}
.checkBoxFamily {
  position: relative;
  top: 1px;
}
@media only screen and (min-width: 1000px) and (max-width: 1250px) {
  .checkBoxFamily {
    position: relative;
    top: 2px;
  }
}
@media only screen and (min-width: 1000px) {
  .itsClient {
    white-space: nowrap;
  }
}
.control-label {
  font-size: 13px;
}
</style>
