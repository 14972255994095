<template>
  <div class="container-menu-qr__cards" v-if="menu">
    <div class="container-menu-qr__cards__background-img" :style="{'background-image': `url('${changeImagesByLanguage}')`, backgroundSize: 'cover', opacity: `${localMenu.isActive === 1 ? '': '0.5'}`}">
      <h2>{{changeTitleByLanguage}}</h2>
      <div class="container-menu-qr__cards__background-img__dark-section"></div>
    </div>
    <div class="container-menu-qr__cards__type">
      <span class="container-menu-qr__cards__type__title">Tipo</span>
      <div class="container-menu-qr__cards__type__options">
        <div :class="{'container-menu-qr__cards__type__options--selected' : localMenu.type === 'image'}">Imagen</div>
        <div :class="{'container-menu-qr__cards__type__options--selected' : localMenu.type === 'custom'}">Interactivo</div>
        <div :class="{'container-menu-qr__cards__type__options--selected' : localMenu.type === 'url'}">Link</div>
      </div>
    </div>
    <div class="container-menu-qr__cards__order">
      <div class="container-menu-qr__cards__order__number">
        <div>Orden</div><div>{{localMenu.order}}</div>
      </div>
      <div class="container-menu-qr__cards__order--active">
        <div>Botón activo</div>
        <b-checkbox v-model="localMenu.isActive" :true-value="1" :false-value="0" @input="deactivateMenu(menu)"></b-checkbox>
      </div>
    </div>
    <div class="container-menu-qr__cards__actions" :class="{'container-menu-qr__cards__actions-two-items' : !localMenu.vendorCustomId}">
      <button @click="deleteMenu(localMenu.id)">
        <img src="@/assets/icons/menuQr/trash.svg" alt="delete">
        <label for="" v-if="!localMenu.vendorCustomId">Eliminar</label>
      </button>
      <button v-if="localMenu.vendorCustomId" @click="setImgMenuLocalStorage">
        <img src="@/assets/icons/menuQr/eye.svg" alt="view">
      </button>
      <button @click="updateButton">
        <img src="@/assets/icons/menuQr/edit.svg" alt="edit" >
        <label for="" v-if="!localMenu.vendorCustomId">Editar</label>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import CreateNewButton from '@/components/schedules/menu-qr/CreateNewButton.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
import menuQrTypes from '@/store/types/menuQr';
export default {
  props: ['menu', 'restaurantData', 'restaurant'],
  data () {
    return {
      urlLanguage: '',
      localMenu: { ...this.menu }
    };
  },
  computed: {
    ...mapGetters({
      typeLanguage: menuQrTypes.getters.typeLanguage
    }),
    changeImagesByLanguage () {
      switch (this.typeLanguage) {
        case 'spanish':
          return this.localMenu.image;
        case 'english':
          return this.localMenu.imageEnglish;
        case 'french':
          return this.localMenu.imageFrench;
        default:
          return null;
      }
    },
    changeTitleByLanguage () {
      switch (this.typeLanguage) {
        case 'spanish':
          return this.localMenu.title;
        case 'english':
          return this.localMenu.titleEnglish;
        case 'french':
          return this.localMenu.titleFrench;
        default:
          return null;
      }
    }
  },
  methods: {
    deleteMenu (id) {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete',
        props: {
          idMenu: id,
          type: 'delete',
          title: 'Confirmar',
          subtitle: '¿Estás seguro(a) que quieres eliminar este menú?',
          typeDelete: 'multimenu'
        },
        events: {
          changeStatusLoader (status) {
            instance.$emit('changeStatusLoader', status);
          },
          getMultiMenu () {
            instance.$emit('getMultiMenu');
          }
        }
      });
    },
    updateButton () {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: CreateNewButton,
        hasModalCard: true,
        canCancel: false,
        customClass: 'modal-create-new-button',
        props: {
          id: instance.localMenu.id,
          info: instance.menu,
          restaurantData: instance.restaurantData,
          restaurant: instance.restaurant
        },
        events: {
          getMultiMenu () {
            instance.$emit('getMultiMenu');
          }
        }
      });
    },
    deactivateMenu () {
      const updatedMenu = {
        ...this.localMenu,
        userId: this.user.id
      };
      this.$emit('updateMenu', updatedMenu);
      this.$emit('changeStatusLoader', true);
      serviceQr.post('/menudigital/multiple/state', updatedMenu).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('getMultiMenu');
        this.$emit('changeStatusLoader', false);
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    setImgMenuLocalStorage () {
      const menu = {
        img: this.localMenu.image,
        name: this.localMenu.title
      };
      localStorage.setItem('btnMenuBackground', JSON.stringify(menu));
      this.$emit('changeToCategoriesProducts', true, this.localMenu.vendorCustomId);
    }
  }
};
</script>
