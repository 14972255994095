<template>
  <div id="Requirement">
    <div class="columns requi-buttons is-mobile">
      <button v-if="activeTab === 0" class="column tab-requirements mr-1" :class="{active: activeTab == 0}" @click="activeTab = 0"> <img class="icon_glob" src="@/assets/icons/addReservation/requerimientos_white.svg" alt="requerimientos"/>Comentarios de reserva</button>
      <button v-else-if="activeTab !== 0" class="column tab-requirements mr-1" :class="{active: activeTab == 0}" @click="activeTab = 0"> <img class="icon_glob" src="@/assets/icons/addReservation/requerimientos_black.svg" alt="requerimientos"/>Comentarios de reserva</button>

      <button v-if="activeTab === 1" class="column tab-requirements" :class="{active: activeTab == 1}" @click="activeTab = 1"> <img class="icon_glob" :src="require(notes || tags.length>0 ? '@/assets/icons/viewReservation/icon_characteristics_active_0.svg':'@/assets/icons/addReservation/caracteristicas_white.svg')" alt="caracteristicas"/>Características del cliente</button>
      <button v-else-if="activeTab !== 1" class="column tab-requirements" :class="{active: activeTab == 1}" @click="activeTab = 1"> <img class="icon_glob" :src="require(notes || tags.length>0 ? '@/assets/icons/viewReservation/icon_characteristics_active_1.svg':'@/assets/icons/addReservation/caracteristicas_black.svg')" alt="caracteristicas"/>Características del cliente</button>
    </div>
    <b-tabs :animated="false" v-model="activeTab">
      <b-tab-item>
        <div class="tab-requirement-component" v-if="requirements.length > 0 && !queueType">
          <div class="requirements-component columns is-mobile" v-if="agentNav().parsedResult.os.name !== 'iOS'">
            <div v-for="(req, r) in requirements" :key="'requirements#' + r"
              :class="{ selectRequirement: validateAmenities(req.id) }"
              class="requirements-item"
              @click="toggleItem(req)"
              v-tooltip="{content: req.displayName, placement: 'bottom'}">
              <img class="icon" :title="req.displayName" :alt="req.displayName" :src="req.requirements.iconURL" style="height: 22px;"/>
            </div>
          </div>
          <div class="requirements-component columns is-mobile" v-else>
            <div v-for="(req, r) in requirements" :key="'requirements#' + r"
              :class="{ selectRequirement: validateAmenities(req.id) }"
              class="requirements-item"
              @click="toggleItem(req)">
              <img class="icon" :title="req.displayName" :alt="req.displayName" :src="req.requirements.iconURL" style="height: 22px;"/>
            </div>
          </div>
          <CommentsClientRestaurant
            :infoReserva="{
              comments: textBoxComment,
              commentRestaurant: textBoxCommentRestaurant
            }"
            typeComments="createEditReservation"
            @changeCommentsRestaurant="changeCommentsRestaurant"
          />
          <!-- <b-field>
            <b-input class="notes-input-div" type="textarea" v-model="comments" :class="{queueType:queueType}" @input="evitarEspacioEnBlanco" minlength="0" maxlength="500" placeholder="Comentarios">
            </b-input>
          </b-field> -->
        </div>
      </b-tab-item>
      <b-tab-item>
        <div class="tab-feature">
          <div class="tags-cont">
            <div class="user-tags">
              <label for="Tabs" class="user-label">Tags</label>
              <input type="text" v-model="tag" v-validate="'required'" class="input-tags" placeholder="Escribe el tag" @keyup.enter="addTags"/>
              <button class="button-tags" @click="addTags"><img src="@/assets/icons/addReservation/addTags.svg" alt="add tags"></button>
            </div>
            <div class="user-tags-show" v-if="Object.keys(tags).length > 0">
              <b-tag v-for="(itemTag, iTag) in tags"
                :key="'tags#s-' + iTag"
                attached
                closable
                type="is-success"
                closeType='is-success'
                aria-close-label="Close tag"
                style="font-family: 'Roboto'; font-size: 8px;"
                @close="delTag(iTag)">
                <img src="@/assets/icons/addReservation/tagLabel.svg" alt="tag label" style="margin-right: 5px;">{{ itemTag }}
              </b-tag>
            </div>
          </div>
        </div>
        <b-field>
          <b-input class="notes-input-div" type="textarea" v-model="notes" minlength="0" maxlength="500" placeholder="Notas">
          </b-input>
        </b-field>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import _ from 'lodash';
import Bowser from 'bowser';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import CommentsClientRestaurant from '@/components/_shared/CommentsClientRestaurant';
export default {
  name: 'FormReservationRequeriments',
  components: {
    CommentsClientRestaurant
  },
  props: ['textBoxComment', 'textBoxCommentRestaurant', 'ammenitisValue', 'notesValue', 'tagsValue', 'queueType'],
  data () {
    return {
      activeTab: 0,
      tag: null,
      tags: [],
      comments: '',
      commentRestaurant: '',
      amenities: [],
      notes: ''
    };
  },
  created () {
    this.initValues();
    this.$store.dispatch({ type: 'reservation:getRequirements', data: { vendorId: this.vendorId } });
  },
  methods: {
    evitarEspacioEnBlanco (event) {
      if (this.comments === ' ') {
        this.comments = '';
      }
    },
    initValues () {
      if (this.ammenitisValue) {
        this.amenities = this.ammenitisValue;
      }
      if (this.textBoxComment) {
        this.comments = this.textBoxComment;
      }
      if (this.textBoxCommentRestaurant) {
        this.commentRestaurant = this.textBoxCommentRestaurant;
      }
      if (this.notesValue) {
        this.notes = this.notesValue;
      }
      if (this.tagsValue) {
        this.tags = this.tagsValue;
      }
    },
    deleteUserNote (ind) {
      this.notes.splice(ind, 1);
    },
    validateAmenities (id) {
      return _.find(this.amenities, { id: id });
    },
    toggleItem (item) {
      if (this.amenities.find(element => element.id === item.id)) {
        const index = _.findIndex(this.amenities, function (o) { return o.id === item.id; });
        this.amenities.splice(index, 1);
      } else {
        this.amenities.push(item);
      }
      this.changeEmit();
    },
    agentNav () {
      return Bowser.getParser(window.navigator.userAgent);
    },
    promptNotes () {
      this.$buefy.dialog.prompt({
        type: 'is-dark',
        message: 'Añadir nota al usuario.',
        cancelText: 'Cancelar',
        confirmText: 'Agregar',
        inputAttrs: {
          placeholder: 'Ingrese la nota '
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.notes.push(value);
        }
      });
    },
    delTag (ind) {
      this.tags.splice(ind, 1);
      this.changeEmit();
    },
    addTags () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.tags.push(this.tag);
          this.changeEmit();
          this.tag = null;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Debe escribir el tag',
            position: 'is-bottom',
            type: 'is-danger'
          });
        }
      });
    },
    changeEmit () {
      this.$emit('change', { amenities: this.amenities, comments: this.comments, commentRestaurant: this.commentRestaurant, notes: this.notes, tags: this.tags });
    },
    changeCommentsRestaurant (comment) {
      this.commentRestaurant = comment;
      this.changeEmit();
    }
  },
  watch: {
    comments () {
      this.changeEmit();
    },
    notes () {
      this.changeEmit();
    },
    tags () {
      this.changeEmit();
    },
    tagsValue (value) {
      this.tags = this.tagsValue;
    },
    notesValue () {
      this.notes = this.notesValue;
    },
    textBoxComment () {
      if (!this.comments) {
        this.comments = this.textBoxComment;
      }
    },
    textBoxCommentRestaurant () {
      if (!this.commentRestaurant) {
        this.commentRestaurant = this.textBoxCommentRestaurant;
      }
    }
  },
  computed: {
    ...mapGetters({
      requirements: [reservationTypes.getters.requirements]
    })
  }
};
</script>
<style lang="scss">
#Requirement {
  button:disabled,
  button[disabled]{
    background-color: #fff;
  }
  .selectRequirement {
    color: #00ADC6!important;
    background-color: #444B57!important;
    border: unset !important;
    img{
      filter: invert(100%)
    }
  }
  .tab-requirements {
    display: flex !important;
    padding: 10px 5px !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background:#DCDEE1;
    color:#444B57;
    i {
      margin-left: 5px;
      height: 14px;
      margin-top: -1px;
      background:#A4A8AC !important;
    }
    &.active{
      background:#444B57;
      color:#ffffff;
      i {
        margin-left: 5px;
        height: 14px;
        margin-top: -1px;
        background:#000 !important;
      }
    }
  }
  .mr-1{
    margin-right: 1px!important;
  }
  .requirements-component{
    margin-top:15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .requirements-item{
      padding: 10px 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40px;
      cursor:pointer;
      border: 1px solid #A8ADB5;
      margin: 5px;
      border-radius: 6px;
      box-shadow: 1px 2px 7px #A8ADB5;
    }
  }
  .tab-requirement-component {
    margin-top: 12px;
  }
  .b-tabs .tab-content {
    padding: 0 !important;
  }
  .b-tabs .tab-content .tab-item {
    border: 1px solid #A8ADB5;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .comment-input{
    width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    padding: 15px 15px;
    border: none;
    outline:none;
    border-radius: 8px;
    border: 1px solid #A8ADB5;
    height: 70px;
    margin: 15px;
    max-height: 70px;
    min-height: 70px;
    &.queueType{
          max-height: 90px;
    min-height: 90px;
    }
  }
  .textarea {
    border: 1px solid #a8adb5;
    padding: 15px 15px;
    border-radius: 8px;
    height: 70px!important;
    min-height: 70px!important;
    color: #1e1414 !important;
  }
  .textarea::placeholder {
    color: #757575;
    opacity: 1;
  }
  .textarea:focus,.textarea:active {
    border: 1px solid #a8adb5 !important;
    color: #1e1414 !important;
  }
  .notes-input-div{
    padding: 1em 1em 0.8em 1em;
    padding-bottom: 0;
    outline: none;
    font-palette: initial;
  }
  .notes-input{
    width: 100%;
    min-width: 100%;
    padding: 15px 15px;
    border: none;
    outline:none;
    border-radius: 8px;
    border: 1px solid #A8ADB5;
    height: 70px;
    max-height: 70px;
    min-height: 70px;
  }
  .tab-feature {
    margin: 15px 15px 0px;
  }
  .tags-cont {
    display: flex;
    flex-direction: column;
    label {
      color: #444B57;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .taginput-container {
      overflow-y: auto;
      overflow-x: hidden;
      height: 60px!important;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      display: flex;
      align-items: flex-start;
    }
    .user-tags-show {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      flex-wrap: wrap;
    }
    .user-tags {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      .user-label {
        font-size: 16px;
        font-family: 'Roboto';
        margin-right: 10px;
      }
      .input-tags {
        width: 160px;
        height: 25px;
        border: 1px solid #A8ADB5;
        border-radius: 4px;
        margin-right: 1px;
      }
      .button-tags {
        width: 25px;
        height: 25px;
        background-color: #444B57;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
      }
      .taginput-container .tag {
        background-color: #16A796;
        color: #fff;
        margin-right: 5px;
        font-size: 14px!important;
      }
      .control {
        padding-left: 8px!important;
      }
    }
  }
  .notas-cont {
    display: flex;
    flex-direction: column;
    label {
      color: #444B57;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .notes {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100px;
      .note {
        border-radius: 20px;
        color: #454C58;
        margin: 0 5px 10px 0;
        padding: 10px;
        font-size: 12px;
      }
    }
    .add-note {
      font-size: 20px;
      margin: 10px;
      color: #807C7C;
    }
  }
  .icon_glob {
    margin-left: 10px;
    max-width: 100%;
    width: 18px;
    height: 18px;
  }
  .tags {
    margin-bottom: unset!important;
    margin-right: 5px;
    .is-success {
      background-color: #16A796!important;
    }
  }
}
</style>
