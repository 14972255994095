<template>
  <div id="notifications">
    <a
      :class="{ 'new-notifications': newNotifications }"
      @click="reviewNotch()"
      class=" notificationItem"
    >
      <div v-show="newNotifications">
        <div class="noti-cont">{{ newNotifications }}</div>
      </div>
      <i class="icon-mks white icon_notification"></i>
    </a>
    <div :class="{ enter: sideNot }" class="listNotifications" id="lgMenu">
      <div class="cont-header">
        <span @click="closeMenu()" class="exit">&times;</span>
        <div class="tle">Notificaciones</div>
        <i class="icon_delete_outlined icon-mks black-pre" @click="openDeleteAll()"></i>
      </div>
      <div class="search-notification">
        <b-field>
          <b-input placeholder="Buscar..."
            type="search"
            v-model="search"
            icon="magnify">
          </b-input>
        </b-field>
      </div>
      <div class="cont-notifications">
        <div class="notification-empty" v-if="!(filteredNotifications || []).length">
          <div>No hay notificaciones</div>
        </div>
        <div
          v-for="(notification, index) in filteredNotifications"
          :class="{ notificationSeen: notification.isSeen, 'delete-notification-cont': showDeleteIcon(notification.id), 'first-not': index === 0 }"
          :key="index"
          class="cont-notification"
        >
          <div style="display: flex; flex-direction: column; justify-content: center;">
            <div
              :class="{
                'borde-precompra':
                  notification.referrer != 'Walk-In' &&
                  notification.balancePaid > 0 &&
                  notification.type != 'vendorCancelReservation' && notification.type != 'clientCancelReservation',
                'borde-reserva':
                  notification.referrer != 'Walk-In' &&
                  (notification.balancePaid == null ||
                    notification.balancePaid == 0) &&
                  notification.type != 'vendorCancelReservation' && notification.type != 'clientCancelReservation',
                'borde-cancel': notification.type == 'vendorCancelReservation' || notification.type == 'clientCancelReservation',
                'borde-walkin': notification.referrer == 'Walk-In',
                'bg-disabled': notification.type == 'newWaitlist',
                'delete-notification': showDeleteIcon(notification.id),
              }"
              @click="ViewNotificationReservation(notification)"
              class="mini-avatar"
            >
              <div>
                {{
                  showDeleteIcon(notification.id)
                    ? '&times;'
                    :getInitials(notification.displayName ? notification.displayName : notification.userName)
                }}
              </div>
            </div>
            <div v-if="notification.isSeen && !showDeleteIcon(notification.id)"
              class="has-text-centered is-size-7"
            >
              Leída
          </div>
          </div>
          <div
            :class="{'bg-disabled': notification.type == 'newWaitlist'}"
            @click="ViewNotificationReservation(notification)"
            class="notification-body"
          >
            <div class="notification-type">{{ getNotificationType(notification.type) }}</div>
            <div
              :class="{'text-small': notification.type == 'newWaitlist'}"
              class="notification-text"
            >{{ notification.text }}</div>
            <div
              class="notification-time"
            >{{ timeAgo(parseInt(notification.timestamp)) }} por {{(notification.referrer === 'dashboard') ? notification.userName : 'Cliente'}}</div>
          </div>
          <div class="notification-remove">
            <i class="icon_delete_outlined icon-mks black-pre" @click="openSingleDelete(notification.id)"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal confirmar eliminar todas las notificaciones -->
    <b-modal :active.sync="confirmDelete" id="confirm-delete-notifications">
      <section class="modal-card-body">
        <i class="icon_delete_outlined icon-mks red"></i>
        <p class="main-text-confirm">Confirmar</p>
        <p class="secondary-text-confirm">{{ deleteText }}</p>

        <div class="buttons-cancel">
          <button class="btn" @click="validateRemove">Si</button>
          <button class="btn btn-inverse" @click="confirmDelete = false; deleteSingleNotificationId = null">No</button>
        </div>
      </section>
    </b-modal>
  </div>
</template>
<script>
import _ from 'lodash';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
export default {
  name: 'NotificationsVue',
  data () {
    return {
      notify: false,
      notificaciones: [],
      newNotifications: 0,
      reviewedCount: 0,
      isOpen: false,
      sideNot: false,
      confirmDelete: false,
      search: '',
      deleteSingleNotificationId: null
    };
  },
  computed: {
    filteredNotifications () {
      if (this.search && this.search.length > 0) {
        const searchLowercase = this.search.toLowerCase();
        return this.notificaciones.filter(item => {
          return item.displayName?.toLowerCase().includes(searchLowercase) ||
                item.text?.toLowerCase().includes(searchLowercase) ||
                item.userName?.toLowerCase().includes(searchLowercase);
        });
      }

      return this.notificaciones;
    },
    deleteText () {
      if (this.deleteSingleNotificationId) return '¿Estás seguro(a) que quieres eliminar esta notificación?';
      return '¿Estás seguro(a) que quieres eliminar todas las notificaciones?';
    }
  },
  mounted () {
    this.getNotificationList();
    this.newNotifications = this.countNotch(this.getNewNotification());
    var bodyEl = document.body;
    // eslint-disable-next-line
    classie.add(bodyEl, 'show-right-bar')
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      const altura = window.innerHeight;
      const lista = document.querySelector('.listNotifications');
      if (lista) {
        lista.style.height = altura + 'px';
      }
    },
    openSingleDelete (id) {
      this.deleteSingleNotificationId = id;
      this.confirmDelete = true;
    },
    openDeleteAll () {
      this.deleteSingleNotificationId = null;
      this.confirmDelete = true;
    },
    validateRemove () {
      if (this.deleteSingleNotificationId) {
        this.removeNotification(this.deleteSingleNotificationId);
      } else {
        this.removeAll();
      }
      this.confirmDelete = false;
    },
    showDeleteIcon (id) {
      return this.confirmDelete && (this.deleteSingleNotificationId === null || this.deleteSingleNotificationId === id);
    },
    notifica () {
      this.notify = !this.notify;
    },
    getNotificationType (type) {
      switch (type) {
        case 'vendorReservation':
          return 'Nueva reserva';
        case 'clientReservation':
          return 'Nueva reserva';
        case 'vendorUpdateReservation':
          return 'Reserva actualizada';
        case 'clientUpdateReservation':
          return 'Reserva actualizada';
        case 'clientCancelReservation':
          return 'Reserva cancelada';
        case 'vendorCancelReservation':
          return 'Reserva cancelada';
        case 'newWaitlist':
          return 'Nueva Lista de Espera';
        default:
          return 'Nueva reserva';
      }
    },
    timeAgo (timestamp) {
      return _.upperFirst(this.$moment(timestamp).fromNow());
    },
    removeNotification (notificationId) {
      this.$store
        .dispatch({
          type: 'reservation:notificationIsArch',
          data: { notificationId: [notificationId], userId: this.user.id }
        })
        .finally(() => {
          const that = this;
          var index = that.notificaciones.findIndex((i) => i.id === notificationId);
          if (index !== -1) {
            const notificationsOld = that.notificaciones;
            notificationsOld.splice(index, 1);
            _.sortBy(notificationsOld, [
              function (o) {
                return that.$moment(o.created_at, 'YYYY-MM-DD HH:mm:ss').toDate();
              }
            ]);
            that.$store.commit(reservationTypes.mutations.setNotifications, []);
            that.$store.commit(
              reservationTypes.mutations.setNotifications,
              notificationsOld
            );
          }
        });
    },
    removeAll () {
      const ids = [];
      this.notificaciones.forEach((e) => {
        if (e.isArch === 0 || e.isArch === null) {
          ids.push(e.id);
        }
      });
      if (ids.length > 0) {
        this.$store
          .dispatch({
            type: 'reservation:notificationIsArch',
            data: { notificationId: ids, userId: this.user.id }
          }).finally(() => {
            this.$store.dispatch({
              type: 'reservation:notifications',
              data: {
                vendorId: this.$store.state.vendorId,
                userId: this.encrypt(this.user.id)
              }
            });
          });
      }
    },
    getInitials (name) {
      return _.upperCase(
        _((name || '').split(' '))
          .filter(text => text)
          .map(text => text[0])
          .take(2)
          .value()
          .join('')
      );
    },
    ViewNotificationReservation (notification) {
      const infoNotification = JSON.parse(JSON.stringify(notification));
      if (infoNotification.type === 'newWaitlist') {
        return;
      }
      if (infoNotification.isSeen === 0 || infoNotification.isSeen === null) {
        this.$store.dispatch({
          type: 'reservation:notificationIsSeen',
          data: { notificationId: [infoNotification.id], userId: this.user.id, vendorId: this.vendor.id }
        }).finally(() => {
          this.$store.dispatch({
            type: 'reservation:notifications',
            data: {
              vendorId: this.$store.state.vendorId,
              userId: this.encrypt(this.user.id)
            }
          });
        });
      }
      let type = '';
      if (infoNotification.referrer !== 'Walk-In') {
        type =
          infoNotification.balancePaid === null || infoNotification.balancePaid === 0
            ? 'Reserva'
            : 'Precompra';
      } else {
        type = 'Walk-In';
      }
      const diaReservaMoment = this.$moment(parseInt(infoNotification.date / 1000), 'X').format('YYYY-MM-DD');
      const dataReserva = this.getReservationShow(
        diaReservaMoment,
        infoNotification.reservationId
      );
      if (dataReserva.length > 0) {
        this.$store.commit(reservationTypes.mutations.setType, type);
        const infoReser = JSON.parse(JSON.stringify(dataReserva[0]));
        this.$store.commit(
          reservationTypes.mutations.setInfoReservation,
          infoReser
        );
        this.$store.commit(reservationTypes.mutations.setViewReservation, true);
      } else {
        this.$store
          .dispatch({
            type: 'reservation:reservationNew',
            data: { reservationId: this.encrypt(infoNotification.reservationId) }
          })
          .then(data => {
            if (data.data.length > 0) {
              let type = '';
              if (data.data[0].referrer !== 'Walk-In') {
                type =
                  data.data[0].balancePaid === null ||
                    data.data[0].balancePaid === 0
                    ? 'Reserva'
                    : 'Precompra';
              } else {
                type = 'Walk-In';
              }
              this.$store.commit(reservationTypes.mutations.setType, type);
              const infoReser = JSON.parse(JSON.stringify(data.data[0]));
              this.$store.commit(
                reservationTypes.mutations.setInfoReservation,
                infoReser
              );
              this.$store.commit(
                reservationTypes.mutations.setViewReservation,
                true
              );
            }
          });
      }
    },
    getNewNotification () {
      const newNotificationShow = _(this.notificaciones)
        .filter(notification => {
          let show = false;
          if (
            (notification.isSeen === 0 || notification.isSeen === null) &&
            (notification.isArch === 0 || notification.isArch === null) &&
            (notification.codeStatus === 0 ||
              notification.codeStatus === 1 ||
              notification.codeStatus === 4)
          ) {
            show = notification;
          }
          if (
            notification.isReviewed === 1 &&
            (notification.isSeen === 0 || notification.isSeen === null) &&
            (notification.isArch === 0 || notification.isArch === null)
          ) {
            this.reviewedCount++;
          }
          return show;
        })
        .value();
      return newNotificationShow;
    },
    getNotificationList () {
      const newNotificationsData = _(this.notifications)
        .filter(notification => {
          let info = false;
          if (notification.isArch === 0 || notification.isArch === null) {
            info = notification;
          }
          return info;
        })
        .value();

      if (this.timezone !== 'America/Bogota') {
        newNotificationsData.forEach(element => {
          const horaNueva = this.$moment(parseInt(element.date)).format('hh:mm a');
          const text = element.text.split('-');
          element.text = text[0] + ' - ' + horaNueva;
        });
      }
      this.notificaciones = newNotificationsData;
    },
    getReservationShow (date, id) {
      const reservationDate = _(this.reservations[date])
        .filter(reservation => {
          let reserva = false;
          if (reservation.id === id) {
            reserva = reservation;
          }
          return reserva;
        })
        .value();
      return reservationDate;
    },
    countNotch (data) {
      const size = _.size(data) - this.reviewedCount;
      return size;
    },
    closeMenu () {
      this.sideNot = !this.sideNot;
      this.$store.commit(globalTypes.mutations.setSideBarState, this.sideNot);
      this.confirmDelete = false;
      this.deleteSingleNotificationId = null;
      this.search = '';
    },
    reviewNotch () {
      this.sideNot = !this.sideNot;
      this.$store.commit(globalTypes.mutations.setSideBarState, this.sideNot);
      const ids = [];
      this.notificaciones.forEach((e) => {
        if (e.isReviewed === 0 || e.isReviewed === null) {
          ids.push(e.id);
        }
      });
      if (ids.length > 0) {
        this.$store.dispatch({
          type: 'reservation:notificationIsReviewed',
          data: { notificationId: ids, userId: this.user.id, vendorId: this.vendor.id }
        }).finally(() => {
          this.$store.dispatch({
            type: 'reservation:notifications',
            data: {
              vendorId: this.$store.state.vendorId,
              userId: this.encrypt(this.user.id)
            }
          });
        });
        this.newNotifications = 0;
      } else {
        this.newNotifications = 0;
      }
    }
  },
  watch: {
    notifications () {
      this.reviewedCount = 0;
      this.newNotifications = this.countNotch(this.getNewNotification());
      this.getNotificationList();
    },
    reservations () {
      this.reviewedCount = 0;
      this.newNotifications = this.countNotch(this.getNewNotification());
      this.getNotificationList();
    }
  }
};
</script>
<style lang="scss">
.borde-reserva{
  border: 2px solid #00ADC6;
}
.borde-precompra{
  border: 2px solid #CAD300;
}
.borde-cancel{
  border: 2px solid #f44336;
}
.borde-walkin{
  border: 2px solid #8A51B4;
}
#notifications {
  .text-small {
    font-size: 9.5px !important;
  }
  .notificationItem {
    position: relative;
  }
  .bg-disabled {
    pointer-events: none;
  }
  .noti-cont {
    align-items: center;
    background: white;
    border-radius: 100%;
    color: #444b57;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    height: 15px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -35%;
    width: 15px;
    z-index: 1;
  }
  .navbar-item {
    height: 100%;
    padding: unset;
    padding-left: 0.3rem;
  }
  .listNotifications {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    color: #444b57;
    display: flex;
    flex-direction: column;
    padding: 0;
    top: -8px;
    z-index: 101;

    .cont-header {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 50px;
      justify-content: flex-start;
      padding: 16px;
      .exit {
        color: #444b57;
        cursor: pointer;
        font-size: 33px;
        font-weight: 100;
      }
      .tle {
        flex: 1;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
      .icon_delete_outlined{
        cursor: pointer;
        height: 16px;
        margin-top: 1px;
        width: 16px;
      }
    }
    .search-notification{
      padding: 5px 16px;
      .icon.is-left{
        font-size: unset;
        i{
          color: #444b57;
        }
      }
    }
    .first-not {
      padding-top: 0px !important;
    }
    .cont-notifications {
      box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 15px;
      overflow: auto;
      padding: 5px;
      /* &::-webkit-scrollbar {
        width: 0 !important;
      } */
      .notificationSeen {
        opacity: 0.5;
      }
      .delete-notification-cont{
        opacity: 1;
      }
      .cont-notification {
        border-bottom: 2px solid #D9DBDE;
        display: flex;
        flex-direction: row;
        min-height: fit-content;
        padding: 5px 0px;
        box-sizing: content-box;
        .mini-avatar {
          box-sizing:border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          color: #444b57;
          cursor: pointer;
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0 12px;
          width: 50px;
          height: 50px;
          text-transform: uppercase;
        }
        .delete-notification{
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); /* Box shadow with a light color */
            font-size: 4rem;
            font-weight: lighter;
            padding-bottom: 11px;
        }
        .notification-body {
          display: flex;
          flex: 2;
          flex-direction: column;
          .notification-type {
            color: #444b57;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }
          .notification-text {
            color: #444b57;
            font-size: 12px;
            max-width: 95%;
            cursor: pointer;
          }
          .notification-time {
            color: #9ba0a5;
            font-size: 10px;
            cursor: pointer;
          }
        }
        .notification-remove {
          min-width: 13%;
          max-width: 13%;
          display: flex;
          flex: 1;
          align-items: center;
          justify-items: center;
          padding-left: 5px;
          .icon_delete_outlined {
            cursor: pointer;
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .listNotifications {
      max-width: 98%;
    }
  }
}
.notification-empty {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.notificationItem {
  @media screen and (max-width: 550px) {
    right: 8px;
  }
}
#notifications{
  width: fit-content;
}

#confirm-delete-notifications{

  &.is-active{
    z-index: 1 !important;
  }
  .modal-background {
    background-color: rgba(10, 10, 10, 0.5);
  }
  .modal-content{
    align-items: center;
    display: flex;
    justify-content: center;

    .modal-card-body{
      border-radius: 8px;
      color: #444b57;
      flex-grow: unset;
      max-width: 98%;
      width: 338px;
      padding: 40px 10px;
      text-align: center;

      .icon_delete_outlined{
        height: 40px;
        margin: 0 auto;
        width: 40px;
      }

      .main-text-confirm{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 20px 0;
      }

      .secondary-text-confirm{
        font-size: 14px;
      }

      .buttons-cancel{
        display: flex;
        margin-top: 30px;
        justify-content: center;
        gap: 14px;

        .btn{
          align-items: center;
          background-color: #444b57;
          border-radius: 10px;
          border: 1px solid #444b57;
          color: #fff;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          justify-content: center;
          min-width: 130px;
          padding: 4px 15px;
          transition: all .3s ease;

          &:hover{
            background: rgba(0, 0, 0, 0.3);
            border-color: rgba(0, 0, 0, 0.3);
            color: #444b57;
          }

          &.btn-inverse{
            background-color: #fff;
            color: #444b57;
            &:hover{
              background-color: #444b57;
              color: #fff;
            }
          }
        }
      }
    }

    .modal-close{
      display: none;
    }
  }
}
@media screen and (max-width: 1200px) {
  #confirm-delete-notifications{

    &.is-active{
      z-index: 101 !important;
    }
  }
}
</style>
