<template>
  <div style="background-color: white; height: 100%; padding-top: 20px;">
    <div class="column">
      <b-field label="Zona a Editar">
        <b-select placeholder="Seleccione una zona" expanded @input="getInfoSectionForMap($event)" v-model="editMapConfig.sectionId">
          <option
            :key="indexSection"
            :value="section.id"
            v-for="(section, indexSection) in sections"
          >{{ section.displayName }}</option>
        </b-select>
      </b-field>
    </div>
    <RestaurantArea :style="editMapConfig.canvas" ref="workspace">
      <transition-group name="table">
        <div
          :key="'subZone#'+subIndex"
          @click="editSubSection(subSection)"
          class="control"
          v-for="(subSection, subIndex) in editMapConfig.section.subSections"
        >
          <b-taglist
            :style="{top: subSection.y+'px', left: subSection.x+'px', position: 'absolute', zIndex: 1}"
            style="cursor: pointer;"
            attached
          >
            <b-tag type="is-dark">{{ subSection.title }} #{{subSection.id}}</b-tag>
            <b-tag type="is-success">
              <b-icon icon="pencil" size="is-small"></b-icon>
            </b-tag>
          </b-taglist>
        </div>
      </transition-group>
      <transition-group name="table">
        <FreeTransform
          :angle="table.angle"
          :disable-scale="table.disableScale === true"
          :height="table.height"
          :key="table.id"
          :offset-x="editMapConfig.offsetX"
          :offset-y="editMapConfig.offsetY"
          :scale-x="table.scaleX"
          :scale-y="table.scaleY"
          :subSection="editMapConfig.sections"
          :width="table.width"
          :x="table.x"
          :y="table.y"
          @dblclick="editTable(table)"
          @update="updateTable(table, $event)"
          v-for="table in editMapConfig.tables"
        >
          <RestaurantTable
            :chairs="table.maxSize"
            :id="table.id"
            :size="table.minSize"
            :size-max="table.maxSize"
            :is-private="!!table.preventReservations"
            :isActive="parseInt(table.isActive)"
            :name="table.displayName"
            :rotation-table="table.angle"
            :rounded="table.isRounded"
          ></RestaurantTable>
        </FreeTransform>
      </transition-group>
    </RestaurantArea>
    <div class="wait-button" style="bottom: 1%!important; right: 5%; background-color: transparent; max-width: 60px; display: flex; justify-content: center; align-items: center;" v-tooltip="{content: 'Guardar', placement: 'top'}" @click="setEditMapSave" v-if="validatePermission('EditMap') && editMapConfig.sectionId != 0 && !frontModal">
      <div class="settings-icon" style="">
        <i class="fa fa-save icon-mks" style="color: white;"></i>
      </div>
    </div>
  </div>
</template>
<script>
import RestaurantArea from '@/components/reservations/EditMap/RestaurantArea';
import FreeTransform from '@/components/reservations/EditMap/FreeTransform';
import RestaurantTable from '@/components/reservations/EditMap/RestaurantTable';
import RestaurantTableEdit from '@/components/reservations/EditMap/Crud/Table';
import RestaurantSubZone from '@/components/reservations/EditMap/Crud/SubZone';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import serviceAdmin from '@/helpers/apis/serviceAdmin';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  name: 'EditMapCrudVue',
  components: {
    RestaurantTable,
    FreeTransform,
    RestaurantArea
  },
  data () {
    return {
      editMapConfig: {
        canvas: {
          width: '95%',
          height: '300px'
        },
        section: {},
        offsetY: 0,
        offsetX: 0,
        sectionId: 0,
        tables: []
      }
    };
  },
  methods: {
    getInfoSectionForMap (id) {
      const values = this.sections.find(item => item.id === id);
      if (values) {
        this.editMapConfig.section = values;
        this.editMapConfig.sectionId = values.id;
        const data = this.tables[0][values.displayName];
        this.editMapConfig.tables = data.map(table => {
          table.width = table.width || 90;
          table.height = table.height || 90;
          table.isRounded = !!parseInt(table.isRounded);
          table.isActive = parseInt(table.isActive);
          table.classPrefix = 'tr';
          table.scaleX = 1;
          table.scaleY = 1;
          table.angle = table.angle || 0;
          table.styles = {};
          table.disableScale = true;
          table.selected = false;
          return table;
        });
      }
      this.setCanvasTopLeft();
    },
    setCanvasTopLeft () {
      const workspace = this.cumulativeOffset(this.$refs.workspace.$el);
      this.editMapConfig.offsetY = workspace.top;
      this.editMapConfig.offsetX = workspace.left;
    },
    setEditMapSave () {
      const instance = this;
      instance.$buefy.dialog.confirm({
        message: 'Estás seguro que quieres <b>guardar</b> el mapa? <br> El mapa quedará asi por siempre',
        cancelText: 'Cerrar',
        confirmText: 'Si, Guardar',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: () => {
          instance.setUpdateSubSection();
          instance.setUpdateTable();
          instance.$buefy.toast.open({
            message: 'Actualizado con exitó',
            type: 'is-success'
          });
          instance.$store.commit(globalTypes.mutations.setEditMap, 0);
        }
      });
    },
    cumulativeOffset (element) {
      var top = 0;
      var left = 0;
      do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
      } while (element);

      return {
        top: top,
        left: left
      };
    },
    editSubSection (subsection) {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          title: 'Editar Sub Zona',
          seatingSectionId: instance.editMapConfig.sectionId,
          subzone: {
            id: subsection.id,
            title: subsection.title,
            copy: subsection.copy,
            x: subsection.x,
            y: subsection.y
          }
        },
        events: {
          update (payload) {
            instance.updateZone(subsection, payload);
          }
        },
        canCancel: false,
        component: RestaurantSubZone,
        hasModalCard: true
      });
    },
    editTable (table) {
      var instance = this;
      instance.$buefy.modal.open({
        parent: instance,
        props: {
          title: 'Editar Mesa',
          table: table,
          seatingSectionId: instance.editMapConfig.sectionId
        },
        events: {
          update (payload) {
            instance.updateTable(table, payload);
          }
        },
        canCancel: false,
        component: RestaurantTableEdit,
        hasModalCard: true
      });
    },
    updateDataTable (table, action) {
      var payload = {};
      switch (action) {
        case 'scale':
          payload.width = table.width * table.scaleX;
          payload.height = table.height * table.scaleY;
          break;
        case 'translate':
          payload.x = table.x;
          payload.y = table.y;
          break;
        case 'rotate':
          payload.angle = table.angle;
          break;
      }
    },
    setUpdateSubSection () {
      const instance = this;
      const totalrequest = instance.editMapConfig.section.subSections.length;
      let requestSend = 0;
      for (let index = 0; index < instance.editMapConfig.section.subSections.length; index++) {
        const info = {
          sectionId: instance.editMapConfig.sectionId,
          section: instance.editMapConfig.section.subSections[index],
          vendorId: instance.vendor.id
        };
        serviceVendor.put('/admin/subsection/' + info.section.id, info).then(() => {
          requestSend++;
        }).catch(() => {
          instance.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición subZone',
            type: 'is-danger'
          });
        });
      }
      if (totalrequest === requestSend) {
        instance.$store.dispatch({
          type: 'section:sections',
          data: { vendorId: this.idVendor }
        });
      }
    },
    setUpdateTable () {
      const instance = this;
      const totalrequest = instance.editMapConfig.tables.length;
      let requestSend = 0;
      for (let index = 0; index < instance.editMapConfig.tables.length; index++) {
        const info = {
          sectionId: instance.editMapConfig.sectionId,
          table: instance.editMapConfig.tables[index],
          vendorId: instance.vendor.id,
          return: 0
        };
        serviceVendor.put('/admin/table/' + info.table.id + '/edit', info).then(() => {
          requestSend++;
        }).catch(() => {
          instance.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición Table',
            type: 'is-danger'
          });
        });
      }
      const data = {
        type: 'syncvendor',
        id: instance.vendor.id
      };
      serviceAdmin.put('sync/database', data).then(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Se han actualizado las mesas',
          type: 'is-success'
        });
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
      if (totalrequest === requestSend) {
        instance.$store.dispatch({
          type: 'reservation:tables',
          data: { vendorId: instance.vendorId }
        });
      }
    },
    updateTable (table, payload) {
      var instance = this;
      const index = instance.editMapConfig.tables.findIndex(item => item.id === table.id);
      if (index >= 0) {
        if (payload.table) {
          instance.editMapConfig.tables[index].displayName = payload.table.displayName;
          instance.editMapConfig.tables[index].isPrioritary = payload.table.isPrioritary;
          instance.editMapConfig.tables[index].minSize = payload.table.minSize;
          instance.editMapConfig.tables[index].maxSize = payload.table.maxSize;
          instance.editMapConfig.tables[index].eventId = payload.table.eventId;
          instance.editMapConfig.tables[index].seatingSubSectionId = payload.table.seatingSubSectionId;
          instance.editMapConfig.tables[index].seatingSectionId = payload.table.seatingSectionId;
          instance.editMapConfig.tables[index].preventReservations = payload.table.preventReservations;
          instance.editMapConfig.tables[index].isShared = payload.table.isShared;
          instance.editMapConfig.tables[index].isActive = payload.table.isActive;
          instance.editMapConfig.tables[index].isRounded = payload.table.isRounded;
          instance.editMapConfig.tables[index].isEvent = payload.table.isEvent;
        }
        if (payload.x) {
          instance.editMapConfig.tables[index].x = payload.x;
        }
        if (payload.y) {
          instance.editMapConfig.tables[index].y = payload.y;
        }
        if (payload.angle) {
          instance.editMapConfig.tables[index].angle = payload.angle;
        }
        if (payload.width) {
          instance.editMapConfig.tables[index].width = payload.width;
        }
        if (payload.height) {
          instance.editMapConfig.tables[index].height = payload.height;
        }
      }
    },
    updateZone (zone, payload) {
      var instance = this;
      const index = instance.editMapConfig.section.subSections.findIndex(item => item.id === zone.id);
      if (index >= 0) {
        instance.editMapConfig.section.subSections[index].title = payload.section.title;
        instance.editMapConfig.section.subSections[index].copy = payload.section.copy;
        instance.editMapConfig.section.subSections[index].x = payload.section.x;
        instance.editMapConfig.section.subSections[index].y = payload.section.y;
      }
      instance.editMapConfig.section = {};
      instance.editMapConfig.tables = [];
      instance.editMapConfig.canvas.width = '95%';
      instance.editMapConfig.canvas.height = '300px';
      setTimeout(() => {
        instance.getInfoSectionForMap(instance.editMapConfig.sectionId);
      }, 1000);
    }
  },
  watch: {
    getEditMap () {
      if (this.getEditMap === 1) {
        setTimeout(() => {
          this.setCanvasTopLeft();
        }, 200);
      }
    },
    'editMapConfig.tables': {
      handler (arr) {
        if (arr.length) {
          var width = Math.max.apply(Math, arr.map(o => o.x)) + 300;
          var height = Math.max.apply(Math, arr.map(o => o.y)) + 300;
          this.editMapConfig.canvas.width = (width > 500 ? width : 500) + 'px';
          this.editMapConfig.canvas.height = (height > 400 ? height : 400) + 'px';
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      frontModal: [reservationTypes.getters.frontModal]
    })
  }
};
</script>

<style lang="scss" scoped>
  .table-enter-active,
  .table-leave-active {
    transition: all 1s;
  }
  .table-enter, .table-leave-to /* .table-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
  .ml-5 {
    margin-left: 5px;
  }
</style>
