<template>
  <div id="app">
    <b-modal :active.sync="showMsgPay" :can-cancel="false" id="modalMsgPay">
      <div class="content-msgPay">
        <i @click="showMsgPay = false" class="fa fa-times times-right"></i>
        <div class="content-msgPay-title f25 mb-15">¡Hola!</div>
        <div class="content-msgPay-title content-msgPay-title-center">
          <span class="f25-l">Registramos una o varias facturas</span>
        </div>
        <div class="content-msgPay-title content-msgPay-title-center">
          <span class="f25">pendientes por pagar.</span>
        </div>
        <div class="content-msgPay-title content-msgPay-title-center">
          <span class="f25-l" span>Evita estos mensajes, realizando</span>
        </div>
        <div class="content-msgPay-title content-msgPay-title-center mb-15">
          <span class="f25" span>el pago oportunamente</span>
        </div>
        <div class="content-msgPay-title f20">¡GRACIAS!</div>
      </div>
    </b-modal>
    <ChooseVendor v-if="chooseVendor" :loginChoose="loginChooseVendor" :active="chooseVendor"></ChooseVendor>
    <router-view />
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import LocalStorage from 'store';
import moment from 'moment-timezone';
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import serviceBlocking from '@/helpers/apis/serviceBlocking';
import sectionTypes from '@/store/types/section';
import ChooseVendor from '@/components/_shared/ChooseVendor.vue';

export default {
  components: {
    ChooseVendor
  },
  data () {
    return {
      intervalPing: null,
      intervalVersion: null,
      showMsgPay: false,
      intervalMsg: null,
      loginChooseVendor: false
    };
  },
  beforeMount () {
    this.obtenerTamanoPantalla();
    window.addEventListener('resize', this.obtenerTamanoPantalla);
  },
  computed: {
    chooseVendor () {
      return this.$store.getters['global:chooseVendor'];
    }
  },
  mounted () {
    const deviceId = window.localStorage.getItem('deviceId_dashboard');
    if (deviceId === undefined || deviceId == null) {
      window.localStorage.setItem('deviceId_dashboard', uuidv4());
    }
    window.addEventListener('load', () => {
      navigator.onLine ? this.showStatus(true) : this.showStatus(false);
      window.addEventListener('online', () => {
        this.showStatus(true);
        this.$socket.connect();
      });
      window.addEventListener('offline', () => {
        this.showStatus(false);
        this.$socket.disconnect();
      });
    });
    this.intervalPing = setInterval(() => {
      if (this.$socket && this.$socket.connected) {
        // Envía un ping si el WebSocket está conectado
        this.$socket.emit('statusConnected', {
          vendorId: LocalStorage.get('_id'),
          id: LocalStorage.get('keyWs')
        });
      } else {
        // Si el socket no está conectado, intenta re conectar
        this.$store.commit(globalTypes.mutations.setSocket, false);
      }
      const deviceId = window.localStorage.getItem('deviceId_dashboard');
      if (deviceId === undefined || deviceId == null) {
        window.localStorage.setItem('deviceId_dashboard', uuidv4());
      }
    }, 5000);
    if (this.vendor && this.vendor.timezone) {
      moment.tz.setDefault(this.vendor.timezone);
    } else {
      moment.tz.setDefault('America/Bogota');
    }
  },
  methods: {
    obtenerTamanoPantalla () {
      document.body.style.setProperty('--GlobalScreenHeight', window.innerHeight + 'px');
    },
    reloadList () {
      this.$store
        .dispatch({
          type: 'waitingList:getWaitingList',
          data: { vendorId: this.vendorId, selectedDate: this.selectedDate }
        })
        .then(response => {
          if (parseInt(response.code) === 200) {
            this.waitingList = response.data;
            let countList = 0;
            _.forEach(this.waitingList, function (value) {
              _.forEach(value, function () {
                countList++;
              });
            });
            this.listLength = window.localStorage.setItem('listLength', countList);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  sockets: {
    statusConnectedResponse: function (data) {
      this.$store.commit(globalTypes.mutations.setSocket, true);
    },
    disconnect: function () {
      this.$store.commit(globalTypes.mutations.setSocket, false);
    },
    connect: function () {
      this.$store.commit(globalTypes.mutations.setSocket, true);
    },
    reconnect: function () {
      this.$store.commit(globalTypes.mutations.setSocket, true);
    },
    reconnect_attempt: function () {
      console.log('intentando re conectar...');
    },
    connect_error: function (error) {
      console.error('Error de conexión:', error);
      this.$store.commit(globalTypes.mutations.setSocket, false);
    },
    reconnect_failed: function () {
      console.error('Falló la reconexión');
      this.$store.commit(globalTypes.mutations.setSocket, false);
    },
    closeSession: function (data) {
      if (parseInt(this.user.id) === parseInt(data.userId)) {
        this.logout({
          id: parseInt(this.user.id)
        });
      }
    },
    newUpdateReloadApp: function () {
      window.location.reload(true);
    },
    newUpdateReload: function (version) {
      const current = LocalStorage.get('version');
      if (current === undefined) {
        this.$store.commit(globalTypes.mutations.setShowMessage, true);
        LocalStorage.set('version', parseInt(version));
      } else if (parseInt(current) < parseInt(version)) {
        this.$store.commit(globalTypes.mutations.setShowMessage, true);
        LocalStorage.set('version', parseInt(version));
      }
    },
    waitingReload: function (data) {
      const idV = data.vendorId;
      if (idV === this.vendor.id) {
        this.reloadList();
      }
    },
    reservationNew: function (data) {
      if (parseInt(data.vendorId) === parseInt(this.idVendor)) {
        const fecha = Object.keys(data.reservations)[0];
        if (this.$moment(this.dateComplete).format('YYYY-MM-DD') === fecha) {
          this.$store.commit(reservationTypes.mutations.setReservations, data.reservations);
        }
        this.$store.dispatch({ type: 'global:getReferrers' });
        this.$store.dispatch({
          type: 'reservation:reservationsDay',
          data: {
            vendorId: this.$store.state.vendorId,
            month: this.selectedMonth
          }
        });
        this.$store.dispatch({
          type: 'reservation:notifications',
          data: {
            vendorId: this.$store.state.vendorId,
            userId: this.encrypt(this.user.id)
          }
        });
      }
    },
    notificationNew: function (vendorId) {
      if (vendorId === parseInt(this.$store.state.idVendor)) {
        this.$store.dispatch({
          type: 'reservation:notifications',
          data: {
            vendorId: this.$store.state.vendorId,
            userId: this.encrypt(this.user.id)
          }
        });
      }
    },
    reservationDelete: function (data) {
      if (parseInt(data.vendorId) === parseInt(this.idVendor)) {
        this.$store.dispatch({
          type: 'reservation:reservationsDay',
          data: {
            vendorId: this.$store.state.vendorId,
            month: this.selectedMonth
          }
        });
        const fecha = Object.keys(data.reservations)[0];
        if (this.$moment(this.dateComplete).format('YYYY-MM-DD') === fecha) {
          this.$store.commit(reservationTypes.mutations.setReservations, data.reservations);
        }
        this.$store.dispatch({
          type: 'reservation:notifications',
          data: {
            vendorId: this.$store.state.vendorId,
            userId: this.encrypt(this.user.id)
          }
        });
      }
    },
    newBlockedUnBlockedSection: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        this.$store.commit(sectionTypes.mutations.setSections, data.sections);
      }
    },
    newBlockedUnBlockedTable: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        this.$store.commit(reservationTypes.mutations.setTables, data.tables);
      }
    },
    vendorUpdate: function () {
      const _this = this;
      this.$store.dispatch({ type: 'global:vendor', data: { vendorId: this.idVendor } }).then(resp => {
        if (resp.data.params.msgPay) {
          this.showPayMsg();
        } else {
          this.showMsgPay = false;
          clearInterval(this.intervalMsg);
          this.intervalMsg = null;
        }
        if (resp.data.params.activateGlobalPopUp) {
          _this.showGlobalMsg();
        } else {
          this.showMsgGlobal = false;
          clearInterval(this.intervalGlobal);
          this.intervintervalGlobalalMsg = null;
        }
      });
    },
    newVendorParams: function () {
      this.$store.dispatch({ type: 'global:vendor', data: { vendorId: this.idVendor } }).then(resp => {
        if (resp.data.params.msgPay) {
          this.showPayMsg();
        } else {
          this.showMsgPay = false;
          clearInterval(this.intervalMsg);
          this.intervalMsg = null;
        }
        if (resp.data.params.activateGlobalPopUp) {
          this.showGlobalMsg();
        } else {
          this.showMsgGlobal = false;
          clearInterval(this.intervalGlobal);
          this.intervalGlobal = null;
        }
      });
    },
    newUpdateSMS: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        this.$store.state.vendor.vendor = data.vendor;
      }
    },
    newBlockedDay: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        serviceBlocking.post('/blocked/day', { vendorId: this.idVendor }).then(({ data }) => {
          this.$store.commit(reservationTypes.mutations.setBlockingDay, data.data);
        });
        this.$store.commit(reservationTypes.mutations.setBlocked, data.blocked);
      }
    },
    newUnBlockedDay: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        serviceBlocking.post('/blocked/day', { vendorId: this.idVendor }).then(({ data }) => {
          this.$store.commit(reservationTypes.mutations.setBlockingDay, data.data);
        });
        this.$store.commit(reservationTypes.mutations.setBlocked, data.blocked);
      }
    },
    newBlockedHour: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        this.$store.commit(reservationTypes.mutations.setBlocked, data.blocked);
      }
    },
    newUnBlockedHour: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        this.$store.commit(reservationTypes.mutations.setBlocked, data.blocked);
      }
    },
    newBlockedRange: function (data) {
      if ((parseInt(data.vendorId) === parseInt(this.idVendor))) {
        serviceBlocking.post('/blocked/day', { vendorId: this.idVendor }).then(({ data }) => {
          this.$store.commit(reservationTypes.mutations.setBlockingDay, data.data);
        });
        this.$store.commit(reservationTypes.mutations.setBlocked, data.blocked);
      }
    },
    setPermissionUpdate: function (data) {
      if ((parseInt(data.userId) === parseInt(this.user.id))) {
        this.$store.dispatch({
          type: 'global:getPermissions',
          data: { userId: this.user.id }
        });
      }
    }
  },
  watch: {
    params (params) {
      if (params.msgPay) {
        this.intervalMsg = setInterval(() => {
          this.showMsgPay = true;
        }, this.params.timeNotificationNotPay);
      } else {
        clearInterval(this.intervalMsg);
        this.showMsgPay = false;
      }
    }
  },
  destroyed () {
    clearInterval(this.intervalPing);
    clearInterval(this.intervalVersion);
    this.intervalPing = null;
    this.intervalVersion = null;
    window.removeEventListener('resize', this.obtenerTamanoPantalla);
  }
};
</script>
<style lang="scss">
@use "./assets/styles/index.scss";
body {
  height: 100%;
  max-height: 100% !important;
}
#app {
  height: 100%;
  overflow: auto;
}
</style>
