<template>
  <div ref="FreeTransform" :class="{[`${classPrefix}-transform`]: true, [`${classPrefix}-transform--active`]:isSelected}" :style="styles" @mouseup="click" @mousedown="mousedown" @dblclick="dblClick">
    <div :class="`${classPrefix}-transform__content`" :style="computedStyles.element">
      <slot></slot>
    </div>
    <div v-if="isSelected" :class="`${classPrefix}-transform__controls`" :style="computedStyles.controls">
        <div :class="`${classPrefix}-transform__rotator`" @mousedown="handleRotation"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--tl`]" @mousedown="handleScale('tl',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--ml`]" @mousedown="handleScale('ml',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--tr`]" @mousedown="handleScale('tr',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--tm`]" @mousedown="handleScale('tm',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--bl`]" @mousedown="handleScale('bl',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--bm`]" @mousedown="handleScale('bm',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--br`]" @mousedown="handleScale('br',$event)"></div>
        <div :class="[`${classPrefix}-transform__scale-point ${classPrefix}-transform__scale-point--mr`]" @mousedown="handleScale('mr',$event)"></div>
    </div>
  </div>
</template>

<script>
import { styler, scale, translate, rotate } from 'free-transform';

export default {
  name: 'FreeTransform',
  props: {
    classPrefix: {
      type: String,
      default: 'tr'
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    scaleX: {
      type: Number,
      required: true
    },
    scaleY: {
      type: Number,
      required: true
    },
    scaleLimit: {
      type: Number,
      default: 0.1
    },
    angle: {
      type: Number,
      required: true
    },
    disableScale: {
      type: Boolean,
      default: false
    },
    offsetX: {
      type: Number,
      required: true
    },
    offsetY: {
      type: Number,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    selectOn: {
      validator: function (value) {
        return ['dblclick', 'mousedown', 'click'].indexOf(value) !== -1;
      },
      default: 'mousedown'
    },
    aspectRatio: {
      type: Boolean,
      default: true
    },
    scaleFromCenter: {
      type: Boolean,
      default: true
    },
    subSections: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isSelected: this.selected || false,
      isDragging: false,
      hasMouseDown: false
    };
  },
  computed: {
    computedStyles () {
      const { element, controls } = styler({
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
        angle: this.angle,
        disableScale: this.disableScale
      });

      return {
        element: {
          ...element,
          width: element.width ? `${element.width}px` : null,
          height: element.height ? `${element.height}px` : null,
          'box-shadow': this.isSelected ? 'rgba(0, 0, 0, 0.15) 0px 0px 9px 0px' : null,
          'user-select': 'none'
        },
        controls: {
          ...controls,
          width: `${controls.width}px`,
          height: `${controls.height}px`
        }
      };
    }
  },
  methods: {
    handleScale (scaleType, event) {
      if (event.stopPropagation) event.stopPropagation();
      if (event.preventDefault) event.preventDefault();
      const drag = scale(scaleType, {
        startX: event.pageX,
        startY: event.pageY,
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
        angle: this.angle,
        scaleLimit: this.scaleLimit,
        scaleFromCenter: this.scaleFromCenter && event.altKey,
        enableScaleFromCenter: this.scaleFromCenter,
        aspectRatio: this.aspectRatio && event.shiftKey,
        enableAspectRatio: this.aspectRatio
      }, (payload) => {
        this.lastAction = 'scale';
        this.$emit('update', payload);
      });

      this.onDrag(drag, 'scale');
    },
    handleTranslation (event) {
      // if (event.stopPropagation) event.stopPropagation()
      const drag = translate({
        x: this.x,
        y: this.y,
        startX: event.pageX,
        startY: event.pageY
      }, (payload) => {
        this.lastAction = 'translate';
        this.$emit('update', payload);
      });

      this.onDrag(drag, 'translate');
    },

    handleRotation (event) {
      // if (event.stopPropagation) event.stopPropagation()
      const drag = rotate({
        startX: event.pageX,
        startY: event.pageY,
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
        angle: this.angle,
        offsetX: this.offsetX,
        offsetY: this.offsetY
      }, (payload) => {
        this.lastAction = 'rotate';
        this.$emit('update', payload);
      });

      this.onDrag(drag, 'rotate');
    },

    onDrag (drag, action) {
      const up = () => {
        document.removeEventListener('mousemove', drag, { pasive: false });
        document.removeEventListener('mouseup', up, { pasive: false });
      };

      document.addEventListener('mousemove', drag, { pasive: false });
      document.addEventListener('mouseup', up, { pasive: false });
    },

    mousedown (event) {
      this.$emit('mousedown', event);
      this.hasMouseDown = true;
      if (this.selectOn === 'mousedown' && this.isSelected) {
        this.$emit('onSelect');
        this.handleTranslation(event);
      }
    },

    mousemove (event) {
      this.$emit('mousemove', event);
      if (this.hasMouseDown) {
        this.isDragging = true;
        this.handleTranslation(event);
      }
    },

    click (event) {
      this.$emit('click', event);
      this.isDragging = false;
      this.hasMouseDown = false;
      if (this.selectOn === 'click') {
        this.$emit('onSelect');
      }
    },

    dblClick (event) {
      this.$emit('dblclick', event);
      this.isDragging = false;
      if (this.selectOn === 'dblclick') {
        this.$emit('onSelect');
      }
    },

    toggle (event) {
      const flyoutElement = this.$refs.FreeTransform;
      // const flyoutElement = document.getElementById("flyout-example")
      let targetElement = event.target; // clicked element

      do {
        if (targetElement === flyoutElement) {
          // Do nothing, just return.
          // document.getElementById("flyout-debug").textContent = "Clicked inside!";
          this.isSelected = true;
          return;
        }
        // Go up the DOM.
        targetElement = targetElement.parentNode;
      } while (targetElement);

      // Do something useful here.
      // document.getElementById("flyout-debug").textContent = "Clicked outside!";
      this.isSelected = false;
    },

    touchHandler (event) {
      // if (event.cancelBubble) event.cancelBubble()
      // if (event.preventDefault) event.preventDefault()
      var touches = event.changedTouches;
      var element = touches[0];
      var type = '';
      switch (event.type) {
        case 'touchstart':
          type = 'mousedown';
          break;
        case 'touchmove':
          type = 'mousemove';
          break;
        case 'touchend':
          type = 'mouseup';
          break;
        default:
          return;
      }

      var simulatedEvent = document.createEvent('MouseEvent');
      simulatedEvent.initMouseEvent(type, true, true, window, 1, element.screenX, element.screenY, element.clientX, element.clientY, false, false, false, false, 0/* left */, null);
      element.target.dispatchEvent(simulatedEvent);
    }
  },
  mounted () {
    document.addEventListener('touchstart', this.touchHandler, { pasive: false }, false);
    document.addEventListener('touchmove', this.touchHandler, { pasive: false }, false);
    document.addEventListener('touchend', this.touchHandler, { pasive: false }, false);
    document.addEventListener('touchcancel', this.touchHandler, { pasive: false }, false);
    document.addEventListener('click', this.toggle);
  },
  beforeDestroy () {
    document.removeEventListener('touchstart', this.touchHandler);
    document.removeEventListener('touchmove', this.touchHandler);
    document.removeEventListener('touchend', this.touchHandler);
    document.removeEventListener('touchcancel', this.touchHandler);
    document.removeEventListener('click', this.toggle);
  }
};
</script>
