<template>
    <div id="campaign-sms">
        <b-loading :active.sync="loading" :is-full-page="true" :can-cancel="false"></b-loading>
        <div class="campaign">
          <div class="image">
            <img src="@/assets/icons/marketing/sms-campaign.svg" alt="precompro.com">
          </div>
          <div class="info">
            <div>
              <span v-if="totalMessage > 0" class="info-title">{{ title }}<span class="info-number">{{ totalMessage }}</span></span>
              <span v-else class="info-title">{{ notitle }}</span>
            </div>
            <div class="info-subtitle">{{ subtitle }}</div>
          </div>
        </div>
        <div class="destination">
          <div class="destination-form">
            <span class="destination-form-for">Para:</span>
            <div class="destination-form-input" :style="{pointerEvents: (totalMessage === 0) ? 'none' : 'all'}" @click="() => {
                if (listFor.length === 0){
                  this.setContact();
                }
              }">
              <b-tag closable rounded type="is-dark" v-for="(list, index) in listFor" :key="'listFor#' + index" @close.stop="setCloseList(index, list._id)" style="margin: 1px;">
                <span @click="setContact(list)" style="cursor: pointer;">
                  {{ list.name }}
                </span>
              </b-tag>
            </div>
            <div class="destination-form-action" @click="setContact()">
              <img src="@/assets/icons/marketing/sms-campaign-add-active.svg" alt="precompro.com"  v-if="isClick">
              <img src="@/assets/icons/marketing/sms-campaign-add-inactive.svg" alt="precompro.com"  v-else>
            </div>
          </div>
          <textarea :autofocus="false" @change.stop @mousedown.stop @click.stop  name="message" id="message" style="resize: none;" :style="{pointerEvents: (totalMessage === 0) ? 'none' : 'all'}" v-validate="'required'" class="destination-text" :class="{'border-alert': errors.has('message') || limitChar}" placeholder="Escriba el mensaje" v-model="message" maxlength="160"></textarea>
          <div class="destination-detail">
            <span class="destination-detail-characters" :class="{'destination-detail-characters-alert': (count >= characters)}">{{ count }}/{{ maxLength }} caracteres</span>
          </div>
          <div class="destination-all">
            <button class="destination-action" @click="setCampaign()" :class="{'destination-action-disabled': (message === '' || listFor.length === 0)}">
              Enviar
            </button>
            <button class="destination-action destination-action-normal" @click="setCampaignProg()" :class="{'destination-action-disabled': (message === '' || listFor.length === 0)}">
              Programar envió
            </button>
            <button class="destination-action" v-if="false" @click="$emit('chargueTab', 0)">
              Comprar
            </button>
          </div>
        </div>
    </div>
</template>
<script>
import Contact from '@/components/Marketing/Contact.vue';
import Program from '@/components/Marketing/Program.vue';
import serviceMarketing from '@/helpers/apis/serviceMarketing.js';

export default {
  name: 'SmsCampaign',
  props: {
    total: {
      type: Boolean
    },
    totalMessage: {
      type: Number
    },
    minT: {
      type: Number
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data () {
    return {
      isShow: false,
      title: 'Tienes',
      notitle: 'No Tienes',
      subtitle: 'mensajes disponibles',
      loading: false,
      count: 0,
      characters: 160,
      maxLength: 160,
      sms: 1,
      listFor: [],
      message: '',
      isClick: false,
      limitChar: false,
      contacts: [],
      isProgramming: 0,
      programmingDate: null,
      programmingHour: null,
      programmingMinute: null
    };
  },
  methods: {
    handleResize () {
      if (window.innerWidth <= 414) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    setCampaignProg () {
      const thes = this;
      thes.$buefy.modal.open({
        parent: thes,
        component: Program,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: 'sms'
        },
        canCancel: false,
        events: {
          close () {
            thes.isProgramming = 0;
            thes.programmingDate = null;
            thes.programmingHour = null;
          },
          accept (acceptData) {
            thes.isProgramming = 1;
            thes.programmingDate = acceptData.date;
            thes.programmingHour = acceptData.hour;
            thes.setCampaign();
          }
        }
      });
    },
    setCloseList (index) {
      this.listFor.splice(index, 1);
    },
    setContact (listDefault = null) {
      const thes = this;
      thes.isClick = true;
      thes.$buefy.modal.open({
        parent: thes,
        component: Contact,
        hasModalCard: true,
        trapFocus: true,
        props: {
          type: 'sms',
          limit: this.totalMessage,
          selectedFor: this.listFor,
          listDefault
        },
        customClass: 'campaign-drop-background',
        canCancel: false,
        events: {
          changeClick (value) {
            thes.isClick = value;
          },
          setList (label) {
            thes.listFor = label;
          },
          setContacts (data) {
            thes.contacts.push(data);
          }
        }
      });
    },
    setCampaign () {
      if (this.listFor.length > 0 && this.message) {
        this.loading = true;
        const info = {
          typeDesign: null,
          subject: 'Nueva Campaña - ' + this.$moment().format('x'),
          contacts: this.listFor,
          vendorId: this.vendor.id,
          userId: this.user.id,
          isProgramming: this.isProgramming,
          programmingDate: this.programmingDate,
          programmingHour: this.programmingHour,
          programmingFullTime: parseInt(this.$moment(this.programmingDate + ' ' + this.programmingHour).format('x')),
          type: 'sms',
          date: this.$moment().format('YYYY-MM-DD'),
          hour: this.$moment().format('HH:mm:ss'),
          dateTime: parseInt(this.$moment().format('x')),
          content: this.message,
          total: 0,
          send: 0,
          active: 1
        };
        let totalItem = 0;
        this.listFor.forEach((i) => {
          totalItem = totalItem + i.contacts.length;
        });
        info.total = totalItem;
        serviceMarketing.post('marketing-campaign', info).then(({ data }) => {
          this.$buefy.toast.open({
            message: data.data,
            type: 'is-success',
            duration: 5000
          });
          this.loading = false;
          this.$emit('changeViewInt', {
            module: 'history',
            item: 'sms',
            show: true
          });
        }).catch((err) => {
          this.$buefy.toast.open({
            message: err.response.data,
            type: 'is-danger',
            duration: 5000
          });
          this.loading = false;
        });
      } else {
        setTimeout(() => {
          document.getElementById('message').focus();
        }, 300);
      }
    }
  },
  watch: {
    message () {
      this.count = (this.message.length > 0) ? this.message.length : 0;
      if (this.count >= this.maxLength) {
        this.$buefy.toast.open({
          message: 'Solo puedes escribir ' + this.characters + ' caracteres por mensaje!',
          type: 'is-danger',
          duration: 5000
        });
        this.limitChar = true;
      } else {
        this.limitChar = false;
      }
    },
    minT () {
      if (window.innerWidth <= 414) {
        if (this.minT === 0) {
          document.getElementById('campaign-sms').style.display = 'none';
        } else {
          document.getElementById('campaign-sms').style.display = 'grid';
        }
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss">
#campaign-sms {
    user-select: none;
    width: 465px;
    height: 403px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #C9C9C9;
    background: #FFF;
    padding: 30px 73px;
    @media screen and (max-height: 1024px) {
    }
    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 500px) {
      height: auto;
      width: 355px;
      padding: 30px 18px;
      margin-bottom: 40px;
    }
    .border-alert {
      border-color: #FF0101 !important;
      color: #FF0101 !important;
      &::placeholder {
        color: #FF0101 !important;
        font-weight: 300 !important;
      }
    }
    .campaign {
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .image {
          width: 55px;
          height: 60px;
          flex-shrink: 0;
        }
        .info {
            user-select: none;
            margin-left: 12px;
            &-title {
                color: #444B57;
                text-align: right;
                font-family: 'Source Sans Pro';
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                user-select: none;
            }
            &-number {
                margin-left: 5px;
                color: #444B57;
                font-family: 'Source Sans Pro';
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                user-select: none;
            }
            &-subtitle {
                color: #444B57;
                text-align: right;
                font-family: 'Source Sans Pro';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                user-select: none;
            }
        }
    }
    .destination {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        user-select: none;
        @media screen and (max-width: 500px) {
        }
        &-detail {
            margin-top: 12px;
            width: 100%;
            text-align: right;
            &-characters {
                user-select: none;
                color: #FF0101;
                text-align: right;
                font-family: 'Source Sans Pro';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media screen and (max-width: 500px) {
                }
                &-alert {
                  user-select: none;
                  color: #FF0101;
                  font-weight: 600;
                }
            }
        }
        &-form {
            user-select: none;
            display: flex;
            justify-content: stretch;
            align-items: center;
            width: 97%;
            &-for {
                user-select: none;
                margin: 0px 12px 0px -48px;
                color: #444B57;
                font-family: 'Source Sans Pro';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media screen and (max-width: 500px) {
                  margin: 0px 6px 0px 0px;
                }
            }
            &-input {
                user-select: none;
                width: 104%;
                height: 35px;
                flex-wrap: wrap;
                flex-shrink: 0;
                border-radius: 10px;
                border: 1px solid #C9C9C9;
                background: #FFF;
                margin-right: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px;
                overflow: auto;
                &::-webkit-scrollbar {
                  width: 3px !important;
                  height: 3px!important;
                }
                &::-webkit-scrollbar-thumb {
                  background: #444B57 !important;
                  border-color: #444B57 !important;
                  box-shadow: unset !important;
                  border-radius: 6px !important;
                }
                @media screen and (max-width: 768px) {
                  width: 104%;
                }
                @media screen and (max-width: 500px) {
                  margin-right: 6px;
                  width: 74%;
                }
                .is-dark {
                  background-color: #444B57;
                  color: white;
                  border-radius: 10px;
                }
            }
            &-action {
                width: 40px;
                user-select: none;
                height: 40px;
                flex-shrink: 0;
                margin-right: 30px;
                cursor: pointer;
                &:hover {
                  border-radius: 10px;
                  background-color: #444B57;
                  color: white;
                }
            }
        }
        &-text {
            user-select: none;
            padding: 10px;
            margin-top: 12px;
            width: 100%;
            height: 111px;
            max-height: 111px;
            min-height: 111px;
            flex-shrink: 0;
            border-radius: 10px;
            border: 1px solid #C9C9C9;
            background: #FFF;
        }
        &-action {
          user-select: none;
          cursor: pointer;
          width: 153px;
          height: 34px;
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid #444B57;
          background: #444B57;
          color: #FFF;
          text-align: center;
          font-family: 'Source Sans Pro';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          @media screen and (max-width: 500px) {
            width: calc(100% - 20px);
            margin-top: 5px;
            margin-bottom: 5px;
          }
          &:hover {
            user-select: none;
            background-color: #444B57 !important;
            color: #FFF !important;
          }
          &-disabled {
            user-select: none;
            cursor: pointer;
            opacity: 0.5;
            pointer-events: none;
            &:hover {
              background-color: #444B57 !important;
              user-select: none;
              color: #FFF !important;
            }
          }
          &-normal {
            background: #FFF;
            color: #444B57;
          }
        }
        &-all {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          margin: 20px 0px 0;
          @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: unset;
            width: 100%;
          }
        }
        textarea::placeholder {
            color: #C9C9C9;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        textarea:focus-visible {
          outline: none;
        }
    }
}
</style>
