<template>
  <div id="waitingListIndex">
    <div :class="{ active: listActive }" class="WaitingList-list active">
      <div class="rowHeaderWaiting">
        <div class="flexTitleWaiting">
          <i class="icon-mks listIcon gray-blue icon_waiting_list"></i>
          <h3 class="titleWaiting">
            Lista de espera
          </h3>
        </div>
        <i @click="CloseMenuL" class="icon-mks closeIcon gray-blue icon_close_fill"></i>
      </div>
      <div class="date-title">
        <i
          class="icon_arrow_left_map icon-mks black btn-dateter"
          :class="{ 'not-active-reservation': loadingReservation }"
          @click="prevDate()"
        ></i>
        <div class="iconTextDate">
          <div class="calendar_date">
            <i class="icon-mks black icon_calendar_voild"></i>
            <span class="the_calendar_day">{{ dateBar_day }}</span>
          </div>
          <span class="headerTitle">{{ selectedDate | moment("dddd DD[/]MMM") }}</span>
        </div>
        <i
          class="icon_arrow_right_map icon-mks black btn-dateter"
          :class="{ 'not-active-reservation': loadingReservation }"
          @click="nextDate()"
        ></i>
      </div>
      <div class="header-list">
        <div class="buttons">
          <div class="btn-multi" v-if="activeMulti">
            <div @click="modalShowTable()" class="multi-select dark">Asignar mesa</div>
            <div @click="noSelection()" class="multi-select">Cancelar</div>
          </div>
          <div class="btn-multi">
            <!-- <div
              @click="multiSelection()"
              class="multi-select large"
              v-if="!activeMulti"
            >Selección multiple</div> -->
          </div>
          <div style="display:flex; gap: 8px;">
            <a
              @click.prevent.stop="openModal('reservation')"
              class="btnReservation"
              href="javascript:void(0)"
              v-if="!activeMulti"
            >
              <span>Reserva</span>
              <i class="icon-mks icon_waiting_plus"></i>
            </a>
            <a @click.prevent.stop="openModal('reservation')" v-if="activeMulti" class="btnSmall">
              <i class="icon-mks icon_waiting_plus"></i>
            </a>
            <a
              @click.prevent.stop="openModal('Walk-In')"
              class="btnWalk"
              href="javascript:void(0)"
              v-if="!activeMulti && selectedDate == today"
            >
              <span>Walk-In</span>
              <i class="icon-mks icon_waiting_plus purple-walking"></i>
            </a>
            <a @click.prevent.stop="openModal('Walk-In')" v-if="activeMulti && selectedDate == today" class="btnSmall" style="background:#8A51B4;">
              <i class="icon-mks icon_waiting_plus"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="listing">
        <div class="waitingEmpty" v-if="waitingList.length <= 0">
          <i class="icon-mks alertIcon gray-blue icon_waiting_alert"></i>
          <span>No hay datos registrados para la lista de espera este día</span>
        </div>
        <div :key="index" v-for="(items, index) in waitingList">
          <div class="header-hour" v-show="Object.keys(items).length">
            <div class="side left">
              <div class="dot"></div>
              <div>{{ momentize(index) }}</div>
            </div>
            <div class="side right">
              <i class="icon-mks white icon_reservation"></i>
              <div class="number-top">{{ Object.keys(items).length }}</div>
            </div>
          </div>
          <itemList
            :arraySelection="arrySelected"
            :class="{'notClickeable': activeMulti && item.referrer=='reservation'}"
            :itemList="item"
            :key="index"
            :multiSelection="activeMulti"
            @itemList-delete="itemListDelete"
            @itemList-edit="itemListEdit"
            @itemList-selectTable="itemListSelectTable"
            @selected="addSelected($event)"
            v-for="(item, index) in items"
            v-show="waitingList"
          ></itemList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemList from '@/components/_shared/WaitingList/itemList';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'WaitingList',
  components: {
    itemList
  },
  data () {
    return {
      listActive: false,
      waitingList: [],
      listLength: 0,
      showModal: false,
      showModalTable: false,
      showModalConfirm: false,
      typeItem: null,
      edit: false,
      isCardModalActive: false,
      selectedListItem: {
        id: null,
        name: null,
        email: null,
        phone: null,
        waitingTime: null,
        comment: null,
        notificationType: null,
        indicativo: null,
        people: 2,
        date: null,
        hour: null,
        selectZone: [],
        selectTable: [],
        referrer: null
      },
      activeMulti: false,
      arrySelected: [],
      dateBar_day: null
    };
  },
  methods: {
    momentize (time) {
      return this.$moment(parseInt(time))
        .format('hh:mm a');
    },
    reloadList () {
      this.$store
        .dispatch({
          type: 'waitingList:getWaitingList',
          data: { vendorId: this.vendorId, selectedDate: this.selectedDate }
        })
        .then(response => {
          if (parseInt(response.code) === 200) {
            this.waitingList = response.data;
            let countList = 0;
            _.forEach(this.waitingList, function (value) {
              _.forEach(value, function () {
                countList++;
              });
            });
            this.listLength = window.localStorage.setItem('listLength', countList);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    resetSelected () {
      this.typeItem = null;
      this.selectedListItem = {
        id: null,
        name: null,
        email: null,
        phone: null,
        waitingTime: null,
        comment: null,
        notificationType: null,
        indicativo: null,
        people: 2,
        date: null,
        hour: null,
        selectZone: [],
        selectTable: [],
        referrer: null
      };
    },
    showList () {
      this.listActive = !this.listActive;
    },
    openModal (type) {
      this.$store.commit(reservationTypes.mutations.setSideLateral, false);
      this.typeItem = type;
      this.$emit('typeItem', type, true);
      this.showModal = !this.showModal;
    },
    itemListEdit (item) {
      this.edit = true;
      this.$emit('editItem', item);
      this.$emit('typeItem', item.referrer, false);
    },
    itemListSelectTable (item) {
      this.$emit('selectTable', item, item.date);
    },
    modalShowTable () {
      if (this.arrySelected.length > 0) {
        this.$emit('selectTableMulti', this.arrySelected);
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Seleccione al menos un Walk-In',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    },
    itemListDelete (item) {
      this.selectedListItem = item;
      this.showModalConfirm = !this.showModalConfirm;
      this.$emit('selectDelete', item);
    },
    multiSelection () {
      this.activeMulti = true;
    },
    noSelection () {
      this.activeMulti = false;
      this.arrySelected = [];
    },
    addSelected (item) {
      if (this.arrySelected.length > 0) {
        const data = _.findIndex(this.arrySelected, { id: item.id });
        if (data !== -1) {
          this.arrySelected.splice(data, 1);
        } else {
          this.arrySelected.push(item);
        }
      } else {
        this.arrySelected.push(item);
      }
    },
    CloseMenuL () {
      this.$store.commit(reservationTypes.mutations.setSideLateral, false);
    },
    prevDate () {
      const prev = this.$moment(this.dateComplete)
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, prev);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment(
        new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      ).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(
        reservationTypes.mutations.setLoadingReservation,
        true
      );
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store
        .dispatch({
          type: 'reservation:reservations',
          data: {
            vendorId: this.$store.state.vendorId,
            date: this.selectedDate
          }
        })
        .finally(() => {
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        });
    },
    nextDate () {
      const next = this.$moment(this.dateComplete)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, next);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment(
        new Date(this.currentYear, this.currentMonth, 1, 0, 0, 0)
      ).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(
        reservationTypes.mutations.setLoadingReservation,
        true
      );
      this.$store.dispatch({
        type: 'reservation:reservationsDay',
        data: {
          vendorId: this.$store.state.vendorId,
          month: this.selectedMonth
        }
      });
      this.$store
        .dispatch({
          type: 'reservation:reservations',
          data: {
            vendorId: this.$store.state.vendorId,
            date: this.selectedDate
          }
        })
        .finally(() => {
          this.$store.commit(
            reservationTypes.mutations.setLoadingReservation,
            false
          );
        });
    }
  },
  mounted () {
    this.reloadList();
    this.dateBar_day = this.$moment(this.selectedDate).format('DD');
  },
  watch: {
    selectedDate () {
      this.reloadList();
      this.resetSelected();
      this.dateBar_day = this.$moment(this.selectedDate).format('DD');
    }
  },
  computed: {
    ...mapGetters({
      loadingReservation: reservationTypes.getters.loadingReservation
    })
  }
};
</script>
<style lang="scss">
#waitingListIndex {
  .headerTitle {
    color: #444B57;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .zone-container {
    padding: 30px;
    background: red;
    border-radius: 8px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
  }
  .titleWaiting {
    color: #444B57;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .box-modal {
    background: #fff;
    height: 100%;
  }
  .icon-add {
    -webkit-mask-size: contain;
    width: 28px;
    height: 28px;
  }
  .listIcon {
    -webkit-mask-size: contain;
    width: 40px;
    height: 40px;
  }
  .closeIcon {
    -webkit-mask-size: contain;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
  .alertIcon {
    -webkit-mask-size: contain;
    width: 60px;
    height: 60px;
  }
  .alertIconTrash {
    -webkit-mask-size: contain;
    width: 70px;
    height: 70px;
    background: #FF0101;
  }
  .noShadow {
    box-shadow: none;
  }
  .confirm-button {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
  }
  .confirm-msg {
    color:#444B57;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .table-cont {
    padding: 0px 75px;
  }

  .header-hour {
    align-items: center;
    justify-content: space-between;
    background: #444b57;
    padding: 5px 10px;
    color: #fff;
    font-size: 11px;
    margin: 6px 0px;
    flex-direction: row;
    display: flex;
    border-radius: 5px;
    .side {
      display: flex;
      align-items: center;
      width: 50%;
      position: relative;
      &.left {
        justify-content: flex-start;
      }
      &.right {
        justify-content: flex-end;
        margin-bottom: -5px;
        i {
          margin-top: -1px;
        }
        .number-top {
          margin-top: -13px;
          font-size: 9px;
          font-weight: 700;
          margin-left: -3px;
        }
      }
    }
    .dot {
      height: 5px;
      width: 5px;
      background: green;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .icon-masked {
    display: flex;
    background: #000;
    margin-right: 5px;
    -webkit-mask-repeat: no-repeat;
    height: 10px;
    width: 10px;
    margin-top: 3px;
  }
  .mk-plus {
    -webkit-mask-image: url(../../../assets/icons/plus_res.svg);
    mask-image: url(../../../assets/icons/plus_res.svg);
    margin-top: 4px;
    background: #fff;
  }
  .table-convention {
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 5px;
    justify-content: flex-end;
  }
  .convention-msg {
    display: flex;
    align-items: center;
    margin-left: 5px !important;
    font-size: 10px;
    &-orange {
      color: #f6bc2b;
      font-size: 5px !important;
      margin-right: 5px !important;
    }
    &-green {
      color: #47cc27;
      font-size: 5px !important;
      margin-right: 5px !important;
    }
    &-red {
      color: #e44f38;
      font-size: 5px !important;
      margin-right: 5px !important;
    }
  }
  .lapding {
    padding: 0 15px;
  }

  .radius-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .item {
      margin-right: 5px;
      .rasio {
        margin-right: 5px;
      }
    }
  }

  .black-header {
    margin-left: -5px;
    margin-top: -20px;
    width: calc(100% + 10px);
    background: #000;
    color: #fff;
    font-weight: normal;
    display: flex;
    align-items: center;
    padding: 7px 25px;
    justify-content: space-between;
    .fa {
      margin-right: 5px !important;
    }
    .title-header {
      display: flex;
      align-items: center;
    }
    .close-header {
      display: flex;
      align-items: center;
    }
  }
  .white-header {
    margin-left: -5px;
    margin-top: -20px;
    width: calc(100% + 10px);
    background: #fff;
    color: #000;
    font-weight: normal;
    display: flex;
    align-items: center;
    padding: 7px 25px;
    justify-content: space-between;
    .fa {
      margin-right: 5px !important;
    }
    .title-header {
      display: flex;
      align-items: center;
    }
    .close-header {
      display: flex;
      align-items: center;
    }
  }

  .btn-add-espera {
    color: #fff;
    background: #9567fc;
    padding: 10px 20px;
    width: calc(100% - 30px);
    margin: 10px 15px 0px;
    font-weight: bold;
    border-radius: 10px;
    &:hover {
      background: #623fb7;
    }
  }
  .btn-add-reserva-espera {
    color: #fff;
    background: #00adc6;
    padding: 10px 20px;
    width: calc(100% - 30px);
    margin: 10px 15px 0px;
    font-weight: bold;
    border-radius: 10px;
    &:hover {
      background: #01616e;
    }

    &.comfirm {
      max-height: 50px;
      width: 70% !important;
      margin-left: 15% !important;
      margin-top: 40px;
    }
    &:disabled,
    &[disabled] {
      opacity: 0.5 !important;
      background: #00adc6 !important;
    }
  }
  .btn-sendtoRes {
    color: #fff;
    background: #444b57;
    padding: 10px 20px;
    width: calc(100% - 30px);
    margin: 10px 15px 0px;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #000;
    }
    &.comfirm {
      max-height: 50px;
      width: 70% !important;
      margin-left: 15% !important;
      margin-top: 40px;
    }
    &:disabled,
    &[disabled] {
      opacity: 0.5 !important;
      background: #00adc6 !important;
    }
    &-leftArrow {
      height: 15px;
      color: #fff;
      margin-right: 10px;
    }
  }
  .btn-add-walk-espera {
    color: #fff;
    background: #9567fc;
    padding: 10px 20px;
    width: calc(100% - 30px);
    margin: 10px 15px 0px;
    font-weight: bold;
    border-radius: 10px;
    &:hover {
      background: #623fb7;
    }

    &.comfirm {
      max-height: 70px;
      width: 70% !important;
      margin-left: 15% !important;
      margin-top: 40px;
    }
    &:disabled,
    &[disabled] {
      opacity: 0.5 !important;
      background: #9567fc !important;
    }
  }
  .btn-confirm-no {
    color: #444B57;
    background: #FFF;
    border: 1px solid #444B57;
    color: #444B57;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 6px 60px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 132px;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
    }
  }
  .btn-confirm-yes {
    color: #fff;
    background: #444B57;
    width: 132px;
    height: 30px;
    font-weight: bold;
    border-radius: 15px;
    padding: 6px 60px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }

  .purple-header {
    margin-left: -5px;
    width: calc(100% + 10px);
    background: #421c3a;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 5px 25px 5px 40px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .table-header {
    margin-left: -5px;
    width: calc(100% + 10px);
    display: flex;
    font-size: 20px;
    font-weight: bolder;
    align-items: center;
    padding: 5px 25px 5px 40px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .FLEX-content {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-top: 20px;
    .lateral {
      &:first-child {
        width: 60%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &.row,
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .buttons-res {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .WaitingList {
    .margin-quarter {
      margin: 0;
    }

    &-modal {
      &Body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px 5px;

        .panel-default > .panel-heading {
          color: #333;
          background-color: #f5f5f5;
          border-color: #ddd;
          padding: 0px !important;
          text-align: center;
        }

        .panel-default {
          margin-bottom: 0px !important;
        }
        .panel-hour {
          margin-bottom: 15px;
          .btn.btn-flat {
            width: 20% !important;
            max-width: 20% !important;
          }
        }

        .form-control {
          height: 30px;
          border-radius: 5px;
        }
        .form-group {
          margin-bottom: 10px;
        }

        .bandera_list {
          display: flex;
          margin-top: 0px;
          width: 25px;
          height: 17px;
          margin-right: 0;
        }

        .indicativo {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          .left-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            padding: 5px;
            border-radius: 5px 0 0 5px;
            border: 1px solid #d2d6de;
            border-right: 0;
          }
          .input-phone {
            width: 100%;
            padding: 5px 8px;
            border: 1px solid #d2d6de;
            border-radius: 0 5px 5px 0;
            outline: none;
          }
        }
        .multiselect {
          box-sizing: content-box;
          display: block;
          position: relative;
          width: 100%;
          text-align: left;
          color: #35495e;
          min-height: auto;
          &__tags {
            min-height: 20px;
            display: block;
            padding: 0;
            border-radius: 0;
            border: none;
            background: transparent;
            font-size: 6px;
            padding-left: 30px;
            margin-left: -32px;
          }

          &__select {
            display: none;
          }
          &__option--highlight:after {
            content: "";
            background: #41b883;
            color: #fff;
          }
          &__option:after {
            display: none !important;
          }

          &__input,
          &__single {
            padding-left: 5px;
            margin-bottom: 0px;
            font-size: 13px;
            font-weight: 600;
            line-height: 21px;
            background: transparent;
          }

          &__content-wrapper {
            position: absolute;

            display: block;
            background: #fff;
            width: 200px;
            max-height: 239px !important;
            overflow: auto;
            border: 1px solid #e8e8e8;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            z-index: 50;
            -webkit-overflow-scrolling: touch;
            -webkit-transform: translateZ(0px);
          }
        }

        .list-country {
          width: 100%;
        }
      }
      &Msg {
        font-size: 18px;
        text-align: center;
        margin-bottom: 50px;
      }
      &Btn {
        width: auto;
        padding: 0 80px;
        background-color: #b2bec9;
        font-weight: bold;
        color: #fff;
      }
      .content-modal {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    &-list {
      height: 100%;
      .header-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 12px;
        .icon-b {
          width: 13%;
          margin-top: 20px;
        }
        .buttons {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          .button {
            width: 23px;
            height: 23px;
            img {
              max-width: 100%;
              margin: 0;
            }
            margin-right: 5px;
          }
        }
      }
    }
  }
  .listing {
    overflow: auto;
    height: calc(100vh - 171px);
    padding: 0px 5px;
  }
  .btn-multi {
    display: flex;
    gap: 6px;
    .multi-select {
      height: 30px;
      border: 1px solid #707070;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 7px 10px;
      width: 118px;
      cursor: pointer;
      &.large {
        width: 118px;
      }
      &.dark {
        background: #444b57;
        color: #ffffff;
      }
    }
  }
  .rowHeaderWaiting {
    display: flex;
    height: 64px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    padding: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .flexTitleWaiting {
    display: flex;
    gap: 12;
    align-items: center;
  }
  .waitingEmpty {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 20px;
    & span {
      color: #444B57;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      line-height: normal;
    }
  }
  .date-title {
    padding: 12px 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 1;
    transition-delay: 0s;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    margin: 10px 12px;
  }
  .iconTextDate {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .calendar_date {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    i {
      height: 18px;
      width: 18px;
      background:#444B57;
    }
    span {
      color: #444B57;
      font-family: "Source Sans Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      top: 2.9px;
      left: 0;
      right: 5px;
      bottom: 0;
    }
  }
  .btn-dateter {
    font-size: 1rem;
    margin: 0px;
    width: 7px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btnReservation {
    width: 84px;
    height: 30px;
    padding: 8px 9px;
    border-radius: 6px;
    background:#00ADC6;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    span {
      color:#FFF;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    i {
      background-color: white;
      width: 12px;
      height: 12px;
    }
  }
  .btnWalk {
    width: 86px;
    height: 30px;
    padding: 8px 11px;
    border-radius: 6px;
    background:#8A51B4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    span {
      color:#FFF;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    i {
      background-color: white;
      width: 12px;
      height: 12px;
    }
  }
  .btnSmall {
    width: 34px;
    height: 30px;
    border-radius: 6px;
    background:#00ADC6;
    align-items: center;
    justify-content: center;
    display: flex;
    i {
      background-color: white;
      width: 12px;
      height: 12px;
      margin: 0px;
    }
  }
  @media screen and (max-width: 1400px) {
    .listing {
      height: calc(100vh - 260px);
    }
  }
  @media screen and (max-width: 600px) {
    .listing {
      height: calc(100vh - 220px);
    }
  }
  @media screen and (max-width: 440px) {
    .listing {
      height: calc(100vh - 260px);
    }
  }
}
.notClickeable {
  pointer-events: none;
  opacity: 0.5;
}
</style>
