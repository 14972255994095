<template>
  <div class="modal-card" style="min-width: 100%">
    <section class="modal-card-body">
      <div class="modal-cont">
        <b-field
          v-if="
            schedule.type !== 'dateFree' &&
            schedule.type !== 'datePay' &&
            schedule.type !== 'dateAtention' &&
            schedule.type !== 'dateZone' &&
            schedule.type !== 'dateZonePay'
          "
        >
          <div class="days_new">
            <div class="first_days_edit">
              <button :class="{ active: activeDay(1) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Lunes</label>
                </div>
              </button>
              <button :class="{ active: activeDay(2) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Martes</label>
                </div>
              </button>
              <button :class="{ active: activeDay(3) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Miércoles</label>
                </div>
              </button>
              <button :class="{ active: activeDay(4) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Jueves</label>
                </div>
              </button>
            </div>
            <div class="end_days_edit">
              <button :class="{ active: activeDay(5) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Viernes</label>
                </div>
              </button>
              <button :class="{ active: activeDay(6) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Sábado</label>
                </div>
              </button>
              <button :class="{ active: activeDay(0) }" class="button_day inactive">
                <div class="day_new">
                  <i class="icon-mks black-pre icon_days_schedule"></i>
                  <label>Domingo</label>
                </div>
              </button>
            </div>
          </div>
          <span class="warning" v-if="valDays">Escoger al menos un dia</span>
        </b-field>
        <div class="hour-cont">
          <div class="times_new">
            <div class="inicio_final_time">
              <div class="inicio_time_edit">
                <button class="button_time_des">
                  <div>
                    <label>Hora de inicio</label>
                  </div>
                </button>
                <button class="button_time">
                  <div class="time_select">
                    <i class="icon-mks black icon_hours_schedule"></i>
                    <select
                      @change="
                        generateTimeClose(
                          schedule.schedules.interval,
                          schedule.schedules
                        )
                      "
                      aria-placeholder="00 minutos"
                      class="select_new"
                      name="Hora Inicio"
                      v-model="schedule.schedules.open"
                      v-validate="'required'"
                    >
                      <option :key="index" :value="time" v-for="(time, index) in times">
                        {{
                        new Date(time)
                        | moment("timezone", timezone, "h:mm a")
                        }}
                      </option>
                    </select>
                    <span class="warning">
                      {{
                      errors.first("Hora Inicio")
                      }}
                    </span>
                  </div>
                </button>
              </div>
              <div class="end_time_edit">
                <button class="button_time_des">
                  <div>
                    <label>Hora final</label>
                  </div>
                </button>
                <button class="button_time">
                  <div class="time_select">
                    <i class="icon-mks black icon_hours_schedule"></i>
                    <select
                      aria-placeholder="00 minutos"
                      class="select_new"
                      name="Hora Fin"
                      v-model="schedule.schedules.close"
                      v-validate="'required'"
                    >
                      <option :key="index" :value="time" v-for="(time, index) in timeClose">
                        {{
                        new Date(time)
                        | moment("timezone", timezone, "h:mm a")
                        }}
                      </option>
                    </select>
                    <span class="warning">{{ errors.first("Hora Fin") }}</span>
                  </div>
                </button>
              </div>
            </div>
            <div class="interval_time">
              <button class="button_time_des">
                <div>
                  <label>Intervalo</label>
                </div>
              </button>
              <button class="button_time">
                <div class="time_select">
                  <i class="icon-mks black icon_hours_schedule"></i>
                  <select
                    @change="generateTimes(schedule.schedules.interval, 1)"
                    class="select_new"
                    name="Intervalo"
                    placeholder="00 minutos"
                    v-model="schedule.schedules.interval"
                    v-validate="'required'"
                  >
                    <option value="15">15 minutos</option>
                    <option value="30">30 minutos</option>
                    <option value="60">60 minutos</option>
                  </select>
                  <span class="warning">{{ errors.first("Intervalo") }}</span>
                </div>
              </button>
            </div>
          </div>
          <div
            class="dates_specific"
            v-if="
              schedule.type == 'dateFree' ||
              schedule.type == 'dateAtention' ||
              schedule.type == 'dateZone' ||
              schedule.type == 'zone'
            "
          >
            <div class="start_date" v-if="schedule.type == 'dateZone' || schedule.type == 'zone'">
              <button class="button_time_des">
                <div>
                  <label>Zona</label>
                </div>
              </button>
              <button class="button_time">
                <div class="time_select">
                  <select
                    class="select_new"
                    name="Zona"
                    placeholder="Zona"
                    v-model="schedule.schedules.seatingSectionId"
                    v-validate="'required'"
                  >
                    <option hidden selected value="null">Seleccione</option>
                    <option
                      :key="index"
                      :value="zone.id"
                      v-for="(zone, index) in zonas"
                    >{{ zone.displayName }}</option>
                  </select>
                  <span class="warning">{{ errors.first("Zona") }}</span>
                </div>
              </button>
            </div>
            <div class="start_date" v-if="!schedule.schedules.automatic && schedule.type != 'zone'">
              <button class="button_time_des">
                <div>
                  <label>Fecha</label>
                </div>
              </button>
              <button class="button_time">
                <div class="time_select">
                  <datetime-vue
                    :min-datetime="minDatetime"
                    format="yyyy-MM-dd"
                    input-id="date"
                    name="date"
                    type="date"
                    v-model="schedule.schedules.fecha"
                    v-validate="'required'"
                    value-zone="America/Bogota"
                  ></datetime-vue>
                  <span class="warning">{{ errors.first("date") }}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <span class="warning">* Recuerda: Escoger el intervalo del mismo valor en todos los horarios</span>
      </div>
    </section>
    <footer class="modal-card-foot update_schedule_footer">
      <button @click="updateSchedules()">
        <i class="icon-mks white icon_save_schedule"></i>
        <label>Guardar</label>
      </button>
      <button @click="$parent.close()">
        <i class="icon-mks white icon_delete_schedule"></i>
        <label>Cerrar</label>
      </button>
    </footer>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  name: 'ModalAddSchedules',
  props: ['info', 'publicTime', 'publicTimeFixed', 'general'],
  data () {
    return {
      schedule: {
        vendorId: null,
        type: '',
        schedules: {
          id: null,
          dayOfWeek: [],
          date: null,
          fecha: null,
          fechaCompleta: null,
          seatingSectionId: null,
          open: null,
          close: null,
          interval: null,
          purchase: null,
          purchaseAdult: null,
          purchaseBoy: null,
          cover: null
        }
      },
      requiredDayOfWeek: ['public', 'free', 'zone'],
      requiredZone: ['dateZone', 'zone'],
      requiredDate: ['dateZone', 'dateAtention', 'dateFree'],
      scheduleOld: null,
      valDays: false,
      errorValidation: false,
      times: [],
      timeClose: [],
      minDatetime: this.$moment().toISOString(),
      zonas: null
    };
  },
  mounted () {
    this.schedule.vendorId = this.info.vendorId;
    this.schedule.type = this.info.type;
    this.schedule.schedules.id = this.info.id;
    this.schedule.schedules.seatingSectionId = this.info.seatingSectionId;
    this.schedule.schedules.open = this.info.open;
    this.schedule.schedules.close = this.info.close;
    this.schedule.schedules.interval = this.info.interval;
    this.schedule.schedules.purchase = this.info.purchase;
    this.schedule.schedules.purchaseAdult = this.info.purchaseAdult || null;
    this.schedule.schedules.purchaseBoy = this.info.purchaseBoy || null;
    this.schedule.schedules.cover = this.info.cover || null;
    const oldDat = JSON.parse(JSON.stringify(this.info));
    this.scheduleOld = oldDat;
    if (
      this.schedule.type === 'dateFree' ||
      this.schedule.type === 'datePay' ||
      this.schedule.type === 'dateAtention' ||
      this.schedule.type === 'dateZone' ||
      this.schedule.type === 'dateZonePay'
    ) {
      this.schedule.schedules.date = this.info.date;
      this.schedule.schedules.fecha = this.info.fecha;
      this.schedule.schedules.fechaCompleta = this.info.fechaCompleta;
      let timeOpen = this.$moment(parseInt(this.schedule.schedules.open))
        .format('HH:mm:ss');
      timeOpen = new Date('1970-01-01 ' + timeOpen).getTime();
      this.schedule.schedules.open = timeOpen;
      let timeClose = this.$moment(parseInt(this.schedule.schedules.close))
        .format('HH:mm:ss');
      timeClose = new Date('1970-01-01 ' + timeClose).getTime();
      this.schedule.schedules.close = timeClose;
    }
    this.schedule.schedules.dayOfWeek.push(this.info.dayOfWeek);
    if (
      this.schedule.type === 'zone' ||
      this.schedule.type === 'dateZone' ||
      this.schedule.type === 'dateZonePay'
    ) {
      this.getZone(this.idVendor);
    }
    if (this.schedule.type === 'datePay') {
      this.schedule.schedules.purchaseAdult = this.info.costAdult || null;
      this.schedule.schedules.purchaseBoy = this.info.costBoy || null;
    }
    this.generateTimes(this.info.interval);
    this.generateTimeClose(this.info.interval, this.schedule.schedules);
  },
  methods: {
    getZone (vendorId) {
      serviceVendor.post('section/dashboard', { vendorId }).then(({ data }) => {
        this.zonas = data;
      });
    },
    validateTimestampFixed (type, item) {
      const thes = this;
      if (thes.requiredDate.includes(type)) {
        if (item.date) {
          const dateFormat = (Number.isInteger(item.date)) ? parseInt(item.date) : parseInt(thes.$moment(item.date).format('x'));
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.errorValidation = true;
            return item;
          } else {
            item.open = thes.$moment(
              thes.$moment(dateFormat).format('YYYY-MM-DD') +
              ' ' +
              thes.$moment(item.open).format('HH:mm:ss')
            ).format('x');
            item.close = thes.$moment(
              thes.$moment(dateFormat).format('YYYY-MM-DD') +
              ' ' +
              thes.$moment(item.close).format('HH:mm:ss')
            ).format('x');
            item.date = dateFormat;
          }
        } else {
          item.valDay = true;
          thes.errorValidation = true;
          return item;
        }
        if (item.dateRange) {
          const dateFormatTwo = (Number.isInteger(item.dateEnd)) ? parseInt(item.dateEnd) : parseInt(thes.$moment(item.dateEnd).format('x'));
          item.dateEnd = dateFormatTwo;
        } else {
          item.dateEnd = null;
        }
        /* if (type === 'dateAtention') {
          return thes.checkInRangeFixed(type, item);
        } else if (type === 'dateFree') {
          const resp = thes.checkValidateRangeFixed(item);
          if (resp !== false) {
            return thes.checkInRangeFixed(type, item);
          }
        } else if (type === 'dateZone') {
          const resp = thes.checkValidateRangeFixed(item);
          if (resp !== false) {
            return thes.checkInRangeFixed(type, item);
          }
        } */
        return item;
      }
      return item;
    },
    validateTimestampZone (type, item) {
      const thes = this;
      if (thes.requiredZone.includes(type)) {
        if (item.seatingSectionId == null) {
          thes.errorValidation = true;
          item.date = null;
          item.open = null;
          item.close = null;
          this.$buefy.toast.open({
            duration: 9000,
            message: 'Debe seleccionar una zona',
            type: 'is-danger'
          });
          return item;
        } else {
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.errorValidation = true;
            return item;
          }
          const resp = thes.checkValidateAtention(item);
          if (resp !== false) {
            return thes.checkInRangeZone(item);
          }
        }
      }
      return item;
    },
    validateTimestampDayOfWeek (type, item) {
      const thes = this;
      if (thes.requiredDayOfWeek.includes(type)) {
        if (item.dayOfWeek.length === 0) {
          thes.errorValidation = true;
          item.valDay = true;
          return item;
        } else {
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.errorValidation = true;
            return item;
          }
          if (type === 'public') {
            return thes.checkInRangeAtention(item);
          }
          if (type === 'free') {
            const response = thes.checkValidateAtention(item);
            if (response) {
              return thes.checkInRangeFree(item);
            }
          }
        }
      }
      return item;
    },
    checkInRangeAtention (item) {
      const thes = this;
      thes.errorValidation = false;
      let errorTime = 0;
      let response = false;
      let conflict = '';
      let details = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.publicTime !== undefined && thes.publicTime[day] !== undefined && thes.publicTime[day].length > 0) {
          thes.publicTime[day].forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<b>Atención:</b><br/>';
            details += '<b>Recuerda:</b><br/>';
            if (parseInt(initToday) !== parseInt(init) || parseInt(finiToday) !== parseInt(fini)) {
              conflict += '- Estas realizando un cambio de horario de atención.<br/>';
              details += '- Debes ajustar el horario de reservas.<br/>';
              errorTime = 1;
              if (parseInt(hour.interval) !== parseInt(item.interval)) {
                conflict += '- Estas realizando un cambio de intervalo.<br/>';
                details += '- Debes ajustar el intervalo en los horarios de reservas.<br/>';
                errorTime = 1;
              }
            } else if (parseInt(hour.interval) !== parseInt(item.interval)) {
              conflict += '- Estas realizando un cambio de intervalo.<br/>';
              details += '- Debes ajustar el intervalo en los horarios de reservas.<br/>';
              errorTime = 1;
            }
          });
        } else {
          return item;
        }
      });
      if (errorTime > 0) {
        this.$buefy.toast.open({
          duration: 9000,
          message: conflict + '<br/>' + details,
          position: 'is-top',
          type: 'is-warning'
        });
        response = item;
      }
      errorTime = 0;
      return response;
    },
    checkValidateAtention (item) {
      const thes = this;
      thes.errorValidation = false;
      let okTime = 0;
      let errorTime = 0;
      let response = false;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.publicTime[day] !== undefined && thes.publicTime[day].length > 0) {
          thes.publicTime[day].forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initHuman = thes.$moment(hour.open).add(parseInt(hour.interval), 'minutes').format('hh:mm a');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiHuman = thes.$moment(hour.close).subtract(parseInt(hour.interval), 'minutes').format('hh:mm a');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman + ' con intervalo de ' + parseInt(hour.interval) + ' minutos';
            if (parseInt(hour.interval) !== parseInt(item.interval)) {
              timesRang = 'Error: <br/> Se espera intervalo de ' + parseInt(hour.interval) + ' minutos y está enviando de ' + parseInt(item.interval) + ' minutos';
              thes.errorValidation = true;
              response = false;
              errorTime = errorTime + 1;
            } else if (parseInt(initToday) >= parseInt(init) && parseInt(initToday) <= parseInt(fini) && parseInt(finiToday) >= parseInt(init) && parseInt(finiToday) <= parseInt(fini)) {
              okTime = okTime + 1;
            } else {
              errorTime = errorTime + 1;
            }
          });
        } else {
          this.$buefy.toast.open({
            duration: 9000,
            message: 'No hay horarios disponibles',
            position: 'is-top',
            type: 'is-danger'
          });
          response = false;
          thes.errorValidation = true;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = true;
      }
      if (errorTime > 0 && okTime === 0) {
        timesRang += '<br/>Horarios disponibles: ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        response = false;
        thes.errorValidation = true;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeFree (item) {
      const thes = this;
      thes.errorValidation = false;
      let response = false;
      let okTime = 0;
      let errorTime = 0;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.general.free !== undefined && thes.general.free[day] !== undefined && thes.general.free[day].length > 0) {
          thes.general.free[day].forEach(hour => {
            if (hour.id !== item.id) {
              const init = thes.$moment(hour.open).format('x');
              const initHuman = thes.$moment(hour.open).format('hh:mm a');
              const initToday = thes.$moment(item.open).format('x');
              const fini = thes.$moment(hour.close).format('x');
              const finiHuman = thes.$moment(hour.close).format('hh:mm a');
              const finiToday = thes.$moment(item.close).format('x');
              conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
              if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
                okTime = okTime + 1;
              } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
                okTime = okTime + 1;
              } else {
                errorTime = errorTime + 1;
              }
            } else {
              if (okTime > 0 && errorTime === 0) {
                response = item;
                thes.errorValidation = false;
              }
            }
          });
        } else {
          return item;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada:  ' + conflict;
        response = false;
        okTime = 0;
        errorTime = 0;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.errorValidation = true;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeZone (item) {
      const thes = this;
      let response = false;
      let okTime = 0;
      let errorTime = 0;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.general.zone !== undefined && thes.general.zone[day] !== undefined && thes.general.zone[day].length > 0) {
          const data = thes.general.zone[day].filter(i => {
            if (i.seatingSectionId === item.seatingSectionId) {
              return i;
            }
          });
          if (data.length === 0) {
            return item;
          }
          data.forEach(hour => {
            if (hour.id !== item.id) {
              const init = thes.$moment(hour.open).format('x');
              const initHuman = thes.$moment(hour.open).format('hh:mm a');
              const initToday = thes.$moment(item.open).format('x');
              const fini = thes.$moment(hour.close).format('x');
              const finiHuman = thes.$moment(hour.close).format('hh:mm a');
              const finiToday = thes.$moment(item.close).format('x');
              conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
              if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
                okTime = okTime + 1;
              } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
                okTime = okTime + 1;
              } else {
                errorTime = errorTime + 1;
              }
            } else {
              if (okTime > 0 && errorTime === 0) {
                response = item;
                thes.errorValidation = false;
              }
            }
          });
        } else {
          return item;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada:  ' + conflict;
        response = false;
        okTime = 0;
        errorTime = 0;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.errorValidation = true;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    updateSchedules () {
      const data = { ...this.schedule.schedules };
      this.$validator.validateAll().then((result) => {
        if (
          result &&
          (data.dayOfWeek.length !== 0 ||
            this.schedule.type === 'dateFree' ||
            this.schedule.type === 'datePay' ||
            this.schedule.type === 'dateAtention' ||
            this.schedule.type === 'dateZone' ||
            this.schedule.type === 'dateZonePay')
        ) {
          if (
            this.schedule.type === 'dateFree' ||
            this.schedule.type === 'datePay' ||
            this.schedule.type === 'dateAtention' ||
            this.schedule.type === 'dateZone' ||
            this.schedule.type === 'dateZonePay'
          ) {
            data.date = parseInt(this
              .$moment(data.fecha)
              .format('x'));
            data.fecha = this
              .$moment(data.fecha)
              .format('YYYY-MM-DD');
            data.fechaCompleta = this
              .$moment(data.fecha)
              .format('YYYY-MM-DD HH:mm:ss');
            data.open = parseInt(this.$moment(
              this.$moment(data.fecha).format('YYYY-MM-DD') +
              ' ' +
              this.$moment(data.open).format('HH:mm:ss')
            ).format('x'));
            data.close = parseInt(this.$moment(
              this.$moment(data.fecha).format(
                'YYYY-MM-DD'
              ) +
              ' ' +
              this.$moment(data.close).format('HH:mm:ss')
            ).format('x'));
          }
          if (this.requiredDayOfWeek.includes(this.schedule.type)) {
            if (this.schedule.type !== 'zone') {
              // Entra solo public y free
              this.validateTimestampDayOfWeek(this.schedule.type, data);
            }
          }
          if (this.requiredZone.includes(this.schedule.type)) {
            if (this.schedule.type !== 'dateZone') {
              // Entra solo zone
              this.validateTimestampZone(this.schedule.type, data);
            }
          }
          if (this.requiredDate.includes(this.schedule.type)) {
            // Entra dateAtention dateZone y dateFree
            this.validateTimestampFixed(this.schedule.type, data);
          }
          if (!this.errorValidation) {
            this.valDays = true;
          } else {
            this.valDays = false;
          }
          if (this.valDays) {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            const formData = { schedules: data, vendorId: this.schedule.vendorId, type: this.schedule.type, userId: this.user.id };
            serviceVendor
              .put('admin/schedule/' + data.id, formData)
              .then(() => {
                this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
                this.$emit('update');
                this.$parent.close();
              })
              .catch(() => {
                this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
                this.$buefy.toast.open({
                  message: this.$t('errors.request'),
                  type: 'is-danger'
                });
              });
          }
        } else {
          if (this.schedule.schedules.dayOfWeek.length === 0) {
            this.valDays = true;
          }
        }
      });
    },
    activeDay (day) {
      for (var i = 0; i <= this.schedule.schedules.dayOfWeek.length; ++i) {
        if (this.schedule.schedules.dayOfWeek[i] === day) {
          return true;
        }
      }
      return false;
    },
    generateTimes (interval, update = 0) {
      if (update === 1) {
        this.schedule.schedules.close = null;
        this.schedule.schedules.open = null;
      }
      let initial = parseInt(
        this.$moment('1970-01-01 00:00').milliseconds(0).seconds(0).format('x')
      );
      const finish = parseInt(
        this.$moment('1970-01-01 23:50').milliseconds(0).seconds(0).format('x')
      );
      const data = [];
      this.times = data;
      while (initial <= finish) {
        data.push(initial);
        initial = initial + 60000 * parseInt(interval);
      }
      this.times = data;
    },
    generateTimeClose (interval, item) {
      if (item.open >= item.close) {
        item.close = null;
      }
      let initial = item.open;
      const finish = parseInt(
        this.$moment('1970-01-01 23:50').milliseconds(0).seconds(0).format('x')
      );
      const data = [];
      this.timeClose = data;
      while (initial <= finish) {
        data.push(initial);
        initial = initial + 60000 * parseInt(interval);
      }
      this.timeClose = data;
    }
  }
};
</script>
<style scoped lang="scss">
.modal-card {
  border-radius: 30px;
}
.days_new {
  justify-content: center;
}
.button_day {
  &:hover {
    background-color: #1cc9ed;
    color: white;
    i {
      background-color: white !important;
    }
  }
  &.active {
    background-color: #1cc9ed;
    color: white;
    i {
      background-color: white !important;
    }
  }
}
.update_schedule_footer {
  background: white;
  justify-content: center;
  border: none;
  padding: 0;
  button {
    margin: 30px 20px 30px 20px;
    width: 120px;
    height: 34px;
    background: #905eb5;
    box-shadow: 0px 3px 6px #0000004d !important;
    opacity: 1;
    border-radius: 37px;
    text-align: left;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    i {
      width: 40px;
      height: 40px;
      margin-left: -11px !important;
      margin-right: 0 !important;
      float: left;
    }
  }
}
.modal-cont {
  display: flex;
  background: white;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  .day-cont {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: 50px;
    align-items: center;
    .btn-day {
      border: 1px solid rgba(112, 112, 112, 1);
      width: 90px;
      display: flex;
      justify-content: center;
      color: #000;
      background: #fff;
      &:hover {
        background-color: #239fb8;
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        color: #fff;
      }
      &.active {
        background-color: #239fb8;
        color: #fff;
      }
    }
  }
  .field {
    &.has-addons {
      flex-direction: column;
    }
  }
  .control {
    display: flex;
    justify-content: center;
  }
  .hour-cont {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .btn-done {
    display: flex;
    width: 100px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  .label {
    text-align: center;
  }
  .btn-agregar {
    width: 100%;
    display: flex;
    justify-content: center;
    .button {
      width: 120px;
    }
  }
  .warning {
    color: red;
    text-align: center;
  }
}
.inactive {
  cursor: not-allowed;
  label {
    cursor: not-allowed;
  }
}
.date-cont {
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 1720px) {
  .days_new {
    button {
      width: 115px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 1425px) {
  .times_new {
    flex-direction: column;
  }
  .interval_time {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 1195px) {
  .update_schedule_footer {
    button {
      height: 32px;
      i {
        height: 35px;
        width: 35px;
      }
    }
  }
  .end_days_edit {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .days_new {
    margin-top: 0;
  }
  .end_days_edit {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 570px) {
  .hour-cont {
    .times_new {
      .inicio_final_time {
        flex-direction: column;
      }
    }
  }
  .first_days_edit {
    display: flex;
  }
}
@media only screen and (max-width: 455px) {
  .first_days_edit,
  .end_days_edit {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin-top: 10px;
    }
  }
  .end_days_edit {
    margin-top: 0;
  }
}
@media only screen and (max-width: 425px) {
  .modal-cont {
    padding-right: 0;
    padding-left: 0;
  }
  .days_new {
    button {
      width: 86px;
    }
  }
  .inicio_time_edit,
  .end_time_edit {
    display: flex;
  }
}
@media only screen and (max-width: 375px) {
  .first_days_edit {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin-top: 10px;
    }
  }
  .end_time_edit {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .end_days_edit {
    display: flex;
  }
}
</style>
