<template>
  <div id="taxes" :class="{openTax: closeTax, closeTax: !closeTax}">
    <div class="taxes-float" @click="closeTax = !closeTax">
      <span class="taxes-float-title">
        Tarifas
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none" style="margin-left: 12px; position: absolute; top: 14px;">
          <path d="M11 1L6 6L1 1" stroke="white" stroke-linecap="round" stroke-linejoin="round" v-if="closeTax"/>
          <path d="M11 6L6 1L1 6" stroke="white" stroke-linecap="round" stroke-linejoin="round" v-if="!closeTax"/>
        </svg>
      </span>
    </div>
    <div class="taxes-container" v-if="closeTax">
      <div class="taxes-item" v-for="(item, index) in items" :key="'item-taxes#' + index">
        <div class="taxes-title">
          <img src="@/assets/icons/marketing/sms.svg" class="icon" alt="precompro.com" v-if="item.type === 'sms'">
          <img src="@/assets/icons/marketing/email.svg" class="icon" alt="precompro.com" v-if="item.type === 'email'">
          <span class="title">
            {{ item.title }}
          </span>
        </div>
        <div class="taxes-description">
          <span class="description">{{ item.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TaxesVue',
  props: {
    type: {
      type: String
    },
    items: {
      type: Array
    },
    open: {
      type: Boolean
    }
  },
  data () {
    return {
      closeTax: false
    };
  },
  watch: {
    closeTax () {
      this.$emit('setOpen', this.closeTax);
    }
  }
};
</script>
<style lang="scss" scoped>
.openTax {
  height: 100px;
  @media screen and (max-width: 500px) {
    height: 145px;
  }
}
.closeTax {
  height: 33px;
}
#taxes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 1;
  flex-direction: column;
  overflow-y: auto;
  .taxes-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    background: #444B57;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #a1a5ab !important;
      border-color: #a1a5ab !important;
      box-shadow: unset !important;
      border-radius: 6px !important;
    }
    &::-webkit-scrollbar-track {
      background: unset !important;
    }
  }
  .taxes-float {
    cursor: pointer;
    width: 134px;
    background: #444B57;
    display: flex;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &-title {
      color: #FFF;
      text-align: center;
      font-family: Source Sans Pro;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 5px;
      margin-right: 12px;
    }
  }
  .taxes-item {
    width: 200px;
    background: #444B57;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    @media screen and (max-width: 500px) {
      width: 180px;
      margin: 5px;
    }
  }
  .taxes-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 26px;
    .icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    .title {
      color: #FFF;
      text-align: center;
      font-family: Source Sans Pro;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 5px;
      height: 26px;
    }
  }
  .taxes-description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .description {
      color: #FFF;
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
}
</style>
