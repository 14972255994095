<template>
  <div
    class="MainContainer"
    v-show="isInFilter"
    :style="{
      '--top': bountyClient.top + 'px',
      '--left': bountyClient.right + 'px',
    }"
  >
    <div class="extraInfoModal" v-if="extraModalOpen && !!reservationOftheDay && windowWidth>600">
      <span class="closeIcon" @click.stop="extraModalOpen = false"></span>
      <h2 class="titleForExtraModal">
        Este espacio de tiempo esta sobre Reservado
      </h2>
      <p class="text">¿Que deseas hacer con las reservaciones?</p>
      <section class="reservationsContainer" v-if="reservationOftheDay" :class="{moreThan:reservationsSorted.length>2}">
        <div
          v-for="reservation in reservationsSorted"
          :key="'reser-' + reservation.id"
          class="reservationsInExtra"
          :class="{typeWalk:reservation.referrer == 'Walk-In'}"
        >
          <div class="left" v-tooltip="{content: 'Alergia(s): ' + (reservationInformation.alergies.length > 15 ? reservationInformation.alergies.substring(0, 15) + '...'  : reservationInformation.alergies), placement: 'left'}">
            <div class="peopeQuantityIcon">
              <span class="personIconExtra" style="transform:translateY(-1px)"></span>
              <p>{{ reservation.people }}</p>
            </div>
            <p>{{ textFormatter(reservation.displayName,18 , true)  }}</p>
          </div>
          <div class="rigth">
            <div class="icon-box">
              <img
                :if="getUrlImageReferrer(reservation.referrer) !== null"
                :src="getUrlImageReferrer(reservation.referrer)"
                height="12"
                width="12"
                v-tooltip="{content: `Reserva ${reservation.referrer === 'dashboard' ? 'teléfono': reservation.referrer}`, placement: 'bottom'}"
              />
            </div>
            <button class="edit" @click="isShowViewReservation(reservation)">
              <span></span>
            </button>
            <button
              class="sitOrWake"
              v-show="
                validateSittingOnlyHour(reservation) &&
                reservation.status !== 'Cancelada'
              "
              :class="{
                up: !sittingValidator(reservation),
                down: sittingValidator(reservation),
              }"
              @click="rigthIconClicked(reservation)"
            >
              <span></span>
            </button>
          </div>
        </div>
      </section>
    </div>
    <portal selector="#mainContainer" v-else-if="extraModalOpen && !!reservationOftheDay && windowWidth<=600" >
    <div class="extraInfoModal" id="extraInfoModal" v-if="extraModalOpen && !!reservationOftheDay">
      <span class="closeIcon" @click.stop="extraModalOpen = false"></span>
      <h2 class="titleForExtraModal">
        Este espacio de tiempo esta sobre Reservado
      </h2>
      <p class="text">Que deseas hacer con las reservaciones?</p>
      <section class="reservationsContainer" v-if="reservationOftheDay" :class="{moreThan:reservationsSorted.length>2}">
        <div
          v-for="reservation in reservationsSorted"
          :key="'reser2-' + reservation.id"
          class="reservationsInExtra"
          :class="{typeWalk:reservation.referrer == 'Walk-In'}"
        >
          <div class="left">
            <div class="peopeQuantityIcon">
              <span class="personIconExtra" style="transform:translateY(-1px)"></span>
              <p>{{ reservation.people }}</p>
            </div>
            <p>{{ textFormatter(reservation.displayName,18 , true)  }}</p>
          </div>
          <div class="rigth">
            <div class="icon-box">
              <img
                :if="getUrlImageReferrer(reservation.referrer) !== null"
                :src="getUrlImageReferrer(reservation.referrer)"
                height="12"
                width="12"
                v-tooltip="{content: (reservation.referrer === 'whitelabel') ? 'página web' : (reservation.referrer === 'dashboard') ? 'telefono' : reservation.referrer, placement: 'right'}"
              />
            </div>
            <button class="edit" @click="isShowViewReservation(reservation)">
              <span></span>
            </button>
            <button
              class="sitOrWake"
              v-show="
                validateSittingOnlyHour(reservation) &&
                reservation.status !== 'Cancelada'
              "
              :class="{
                up: !sittingValidator(reservation),
                down: sittingValidator(reservation),
              }"
              @click.stop="rigthIconClicked(reservation)"
            >
              <span></span>
            </button>
          </div>
        </div>
      </section>
    </div>
    </portal>
    <div
      class="modalMultipleReservations"
      v-if="sobreReservationDifferentRestaurantsOpen"
      v-click-outside="() => (sobreReservationDifferentRestaurantsOpen = false)"
    >
      <span
        class="closeIcon"
        @click.stop="sobreReservationDifferentRestaurantsOpen = false"
      ></span>
      <h2>¡ATENCIÓN!</h2>
      <p>
        Este usuario tiene una reserva en otros restaurantes a la misma hora.
      </p>
      <section class="logosContainer">
        <div
          v-for="relatedReservation in reservationInformation.relatedReservations"
          :key="'related-' + relatedReservation.vendor"
        >
          <img
            v-if="relatedReservation.logo"
            :src="relatedReservation.logo"
            alt="logo"
          />
        </div>
      </section>
    </div>
    <article
      id="MapItemReservation"
      :class="{
        haveReservation: haveReservation,
        walking: typeWalking,
        ended:reservationInformation.isFinish || reservationInformation.status === 'No Llego',
        notHere:reservationInformation.status === 'No Llego',
        precompra: typePrecompra,
        noPaid:reservationInformation.codeStatus === 7,
        seated:
        !validateSitting & validateSittingOnlyHour(reservationInformation),
        canelled: reservationInformation.status === 'Cancelada',
        sobreHora: sobreHora,
        late:
        late &&
          !(!validateSitting & validateSittingOnlyHour(reservationInformation)),
      }"
      :style="{ '--rotation': rotationTime }"
      v-if="!moreThanOne"
      @click.self.stop="isShowViewReservation(reservationInformation)"
      ref="mapItemReservation"
    >
      <main>
        <section class="top">
          <div class="peopeQuantityIcon">
            <span class="personIcon"></span>
            <p>{{ reservationInformation.people }}</p>
          </div>
          <p class="displayName">
            {{textFormatter( reservationInformation.displayName, 9 * rotationTime ,true)}}
          </p>
          <div class="client-icon visit icon_star_fill_visit icon-mks black-pre"
            v-tooltip="{ content: `Asistencias: ${showUser}`, placement: 'bottom' }"
            v-if="showUser && showUser > 1">
            <span class="visit-number">{{ showUser }}</span>
          </div>
          <div class="client-icon no-show icon_rombo_no_show icon-mks red"
            v-tooltip="{ content: `No-show: ${notShowUser}`, placement: 'bottom' }" v-if="notShowUser && notShowUser > 1">
            <span class="visit-number">{{ notShowUser }}</span>
          </div>
          <div class="icon-box" v-if="reservationInformation.referrer == 'Walk-In'">
            <img
              class="icon-mks walk"
              v-if="reservationInformation.referrer == 'Walk-In'"
              src="@/assets/icons/allTimeLineIcons/walkIcon.svg"
              v-tooltip="{ content: 'Walking', placement: 'bottom' }"
            />
          </div>
          <div class="icon-box" v-else>
            <img v-if="getUrlImageReferrer(reservationInformation.referrer) !== null" :src="getUrlImageReferrer(reservationInformation.referrer)" style="height: 100%;" height="30" width="30" v-tooltip="{ classes: ['custom-class-fix-popover-global-referer'], content: `Reserva ${reservationInformation.referrer === 'dashboard' ? 'teléfono': reservationInformation.referrer}`, placement: 'bottom'}">
          </div>
        </section>
        <section class="bottom">
          <!-- cronología -->
          <div class="leftBottomIconsContainer">
            <img
              class="alertIcon"
              src="@/assets/icons/allTimeLineIcons/warningReservation.svg"
              v-if="reservationInformation.hasDuplicatedReservations"
              @click.stop="
                sobreReservationDifferentRestaurantsOpen =
                  !sobreReservationDifferentRestaurantsOpen
              "
            />
            <GeneralTags :infoReserva="reservationInformation" :existTag="reservationInformation.isExistsTag === 1" :customClass="'container-tags-reservation-global-in-cronology'"/>
          </div>
          <div
            class="rightIconsContainer"
            v-if="reservationInformation.status === 'Cancelada'"
          >
            <p class="cancelled">Cancelada</p>
            <img
              class="rightIcon cancelledIcon"
              src="@/assets/icons/allTimeLineIcons/cancelled.svg"
              alt=""
            />
          </div>
          <div
            class="rightIconsContainer"
            v-if="reservationInformation.codeStatus === 7"
          >
            <p class="nopayYet">Pendiente de pago</p>
          </div>
          <div
            class="rightIconsContainer notCome"
            v-else-if="reservationInformation.status === 'No Llego'"
          >
            <p class="notCome">No llego</p>
            <img
              class="rightIcon"
              src="@/assets/icons/allTimeLineIcons/no_asignados.svg"
              alt=""
            />
          </div>
          <div
            class="rightIconsContainer notCome"
            v-else-if="reservationInformation.isFinish"
          >
            <p class="notCome">Finalizada</p>
            <span
              class="material-icons"
            >
              task_alt
            </span>
          </div>
          <div
            class="rightIconsContainer"
            v-else-if="
              reservationInformation.services &&
              reservationInformation.status !== 'Cancelada'
            "
          >
            <div
              class="rightIcon"
            >
              <img src="@/assets/icons/allTimeLineIcons/comentarios.svg" v-if="reservationInformation.comments"/>
            </div>
            <div class="rightIcon" v-if="reservationInformation.isHotel">
              <i class="icon_hotel_habitation" :style="{'background-color' :'black','mask-size':'contain'}"></i>
            </div>
            <div class="rightIcon" v-if="reservationInformation.alergies != null">
              <i class="icon-mks black icon_alergy"></i>
            </div>
            <div
              class="rightIcon"
              v-for="(serv, index) in reservationInformation.services"
              :key="'reser-#' + index"
              v-show="index < maxIcons-1"
            >
              <img :src="serv.requirements.iconURL" />
            </div>
            <div
              class="rightIcon plus"
              style="color:black"
              v-if="reservationInformation.services.length > maxIcons"
            >
              +
            </div>
          </div>
        </section>
      </main>
      <div
        v-show="
          validateSittingOnlyHour(reservationInformation) &&
          reservationInformation.status !== 'Cancelada' && !reservationInformation.isFinish && reservationInformation.status !== 'No Llego' &&
          validatePermission('Finish')
        "
        class="buttonrigth"
        :class="{ up: !validateSitting, down: validateSitting }"
        @click.stop="rigthIconClicked(reservationInformation, $event)"
      >
        <span></span>
      </div>
    </article>
    <article
      id="MapItemReservationExtra"
      ref="itemTocreate"
      class="containerForExtraReservation"
      v-else-if="reservationsSorted"
      @click="extraModalOpen = true"
    >
      <main
        :style="{
          '--leftPush': firstReservationAheadFor || secondsReservationAheadFor,
          '--secondRotation':
            reservationsSorted[this.reservationsSorted.length - 1].hoursUsed,
        }"
      >
        <section
          class="extra firstExtra"
          :style="{ '--firstRotation': reservationsSorted[0].hoursUsed }"
          :class="{ moveLeft: firstReservationAheadFor > 0 }"
        >
          <div class="left">
            <div class="peopeQuantityIcon">
              <span class="personIcon"></span>
              <p>{{ reservationsSorted[0].people }}</p>
            </div>
            <p class="displayName">
              {{
                textFormatter(
                  reservationsSorted[0] && reservationsSorted[0].displayName,
                  10 * reservationsSorted[0].hoursUsed
                )
              }}
            </p>
          </div>
          <div class="right">
            <div class="icon-box" v-if="reservationOftheDay[hourSquare].reservations[0].referrer == 'Walk-In'">
              <img
                style="margin-right: 1px;"
                class="icon-mks walk"
                v-if="reservationOftheDay[hourSquare].reservations[0].referrer == 'Walk-In'"
                src="@/assets/icons/allTimeLineIcons/walkIcon.svg"
                v-tooltip="{ content: 'Walking', placement: 'bottom' }"
              />
            </div>
            <div class="icon-box" v-else>
              <img v-if="getUrlImageReferrer(reservationOftheDay[hourSquare].reservations[0].referrer) !== null" :src="getUrlImageReferrer(reservationOftheDay[hourSquare].reservations[0].referrer)" style="height: 100%;" height="30" width="30" v-tooltip="{ classes: ['custom-class-fix-popover-global-referer'], content: `Reserva ${reservationOftheDay[hourSquare].reservations[0].referrer === 'dashboard' ? 'teléfono': reservationOftheDay[hourSquare].reservations[0].referrer}`, placement: 'bottom'}">
            </div>
            <span :class="{realExtra:reservationsSorted.length>2}"></span>
          </div>
        </section>
        <section
          class="extra SecondExtra"
          :class="{ moveLeft: secondsReservationAheadFor > 0 }"
        >
          <div class="left">
            <div class="peopeQuantityIcon">
              <span class="personIcon"></span>
              <p>
                {{
                  reservationsSorted[reservationsSorted.length - 1] &&
                  reservationsSorted[reservationsSorted.length - 1].people
                }}
              </p>
            </div>
            <p class="displayName">
              {{
                textFormatter(
                  reservationsSorted[reservationsSorted.length - 1] &&
                    reservationsSorted[reservationsSorted.length - 1]
                      .displayName,
                  10 * reservationsSorted[this.reservationsSorted.length - 1].hoursUsed
                )
              }}
            </p>
          </div>
          <div class="right">
            <div class="icon-box" v-if="reservationOftheDay[hourSquare].reservations[0].referrer == 'Walk-In'">
              <img
                class="icon-mks walk"
                v-if="reservationOftheDay[hourSquare].reservations[0].referrer == 'Walk-In'"
                src="@/assets/icons/allTimeLineIcons/walkIcon.svg"
                v-tooltip="{ content: 'Walking', placement: 'bottom' }"
              />
            </div>
            <div class="icon-box" v-else>
              <img v-if="getUrlImageReferrer(reservationOftheDay[hourSquare].reservations[0].referrer) !== null" :src="getUrlImageReferrer(reservationOftheDay[hourSquare].reservations[0].referrer)" style="height: 100%;" height="30" width="30" v-tooltip="{ classes: ['custom-class-fix-popover-global-referer'], content: `Reserva ${reservationOftheDay[hourSquare].reservations[0].referrer === 'dashboard' ? 'teléfono': reservationOftheDay[hourSquare].reservations[0].referrer}`, placement: 'bottom'}">
            </div>
            <span :class="{realExtra:reservationsSorted.length>2}"></span>
          </div>
        </section>
      </main>
    </article>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import { Portal as portal } from '@linusborg/vue-simple-portal';
import moment from 'moment-timezone';
import GeneralTags from '@/components/Extends/GeneralTags';
export default {
  props: [
    'reservationOftheDay',
    'hourSquare',
    'keySquare',
    'nameTofilter',
    'lastScroll',
    'switchClickOnMask'
  ],
  components: {
    portal,
    GeneralTags
  },
  data () {
    return {
      localReservationOftheDay: { ...this.reservationOftheDay },
      now: null,
      haveReservation: false,
      typeWalking: false,
      reservationInformation: {},
      maxImageSize: 2,
      rotationSet: 2,
      executeAction: false,
      moreThanOne: false,
      firstReservationAheadFor: 0,
      secondsReservationAheadFor: 0,
      extraModalOpen: false,
      tableIdForObject: '',
      reservationsSorted: [],
      bountyClient: { top: 0 },
      typePrecompra: false,
      sobreReservationDifferentRestaurantsOpen: false,
      windowWidth: 0
    };
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    this.initComponent();
    this.now = this.$moment().valueOf();
    this.interval = setInterval(() => {
      this.now = this.$moment().valueOf();
    }, 1000);
  },
  destroyed () {
    clearInterval(this.interval);
  },
  methods: {
    initComponent () {
      const $moment = this.$moment;
      this.haveReservation = true;
      this.tableIdForObject = this.reservationOftheDay[this.hourSquare].table;
      if (this.reservationOftheDay[this.hourSquare].reservations.length === 1) {
        this.reservationInformation =
          this.reservationOftheDay[this.hourSquare].reservations[0];
        if (this.reservationInformation.referrer === 'Walk-In') {
          this.typeWalking = true;
          this.typePrecompra = false;
        } else {
          this.typeWalking = false;
          if (parseInt(this.reservationInformation.balancePaid) > 0) {
            this.typePrecompra = true;
          } else {
            this.typePrecompra = false;
          }
        }
        this.rotationSet = this.isConfigRotation(this.reservationInformation);
        this.$refs.mapItemReservation.setAttribute(
          'data-minutesReservation',
          moment.duration(this.rotationSet).hours()
            ? moment.duration(this.rotationSet).hours() * 60
            : 60
        );
      } else {
        const reservationsSort = this.localReservationOftheDay[
          this.hourSquare
        ].reservations.sort((a, b) => {
          if (parseInt(a.date) > parseInt(b.date)) {
            return 1;
          } else if (parseInt(a.date) < parseInt(b.date)) {
            return -1;
          } else {
            return 0;
          }
        });
        const dateReservation1 = parseInt(reservationsSort[0].date);
        const dateReservation2 = parseInt(
          reservationsSort[reservationsSort.length - 1].date
        );
        const differenceInMinutes = $moment(dateReservation1).diff(
          $moment(dateReservation2),
          'minutes'
        );

        this.reservationsSorted = reservationsSort;

        if (differenceInMinutes < 0) {
          this.secondsReservationAheadFor = Math.abs(differenceInMinutes);
        } else if (differenceInMinutes > 0) {
          this.firstReservationAheadFor = differenceInMinutes;
        }
        this.moreThanOne = true;
      }
    },
    textFormatter (word, maxSize, notExtra) {
      let max = window.innerWidth <= 600 ? maxSize - 1 : maxSize;
      if (notExtra && (!this.validateSittingOnlyHour(this.reservationInformation) ||
          this.reservationInformation.status === 'Cancelada')) {
        max += 2;
      }
      if (this.showUser > 1 && this.notShowUser > 1 && window.innerWidth <= 600) {
        max = 6;
      }
      if (word) {
        if (word.length > max) {
          return word.slice(0, max) + '..';
        }
      }
      return word;
    },
    isConfigRotation (reservation) {
      let response = null;
      const dayOfWeek = this.$moment(parseInt(reservation.date)).format('d');
      if (this.params.rotationTime) {
        response = this.params.rotationTime;
      }
      if (Object.values(this.rotation).length > 0) {
        this.rotation.map((item) => {
          if (item.dayOfWeek == null && item.people != null) {
            if (reservation.people === item.people) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people != null) {
            if (
              reservation.people === item.people &&
              item.dayOfWeek === dayOfWeek
            ) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people == null) {
            if (item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          }
          if (reservation.sectionId === item.zoneId + '') {
            response = item.rotationTime;
          }
        });
      }
      return response;
    },
    setReservationSeated (data) {
      this.executeAction = true;
      const userId = this.$store.getters['auth:user'].id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsSeated',
          data: { reservationId: data.id, userId: userId }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.executeAction = false;
          } else {
            this.executeAction = false;
          }
        })
        .catch(() => {
          this.executeAction = false;
        })
        .finally(() => {
          this.executeAction = false;
        });
    },
    validateSittingOnlyHour (reservation) {
      if (reservation.isSeated) {
        return true;
      }
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      } else {
        return true;
      }
      if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
        if (dif != null && dif <= 60) {
          return true;
        }
      } else {
        if (dif != null && dif <= 180) {
          return true;
        }
      }
      return false;
    },
    isShowViewReservation (data) {
      this.extraModalOpen = false;
      this.sobreReservationDifferentRestaurantsOpen = false;
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.executeAction = true;
      this.$store
        .dispatch({
          type: 'people:findByReservationId',
          data: { id: infoReserva.id }
        })
        .then((response) => {
          let type = '';
          if (infoReserva.referrer !== 'Walk-In') {
            type =
              infoReserva.balancePaid === null ||
              parseInt(infoReserva.balancePaid) === 0
                ? 'Reserva'
                : 'Precompra';
          } else {
            type = 'Walk-In';
          }
          infoReserva.edit = true;
          if (response.code === 200 && response.data != null) {
            infoReserva.subscribed = response.data.subscribed;
            infoReserva.peopleId = response.data._id;
            infoReserva.peopleMetrics = response.data.peopleMetrics;
          }
          this.executeAction = false;
          this.$store.commit(reservationTypes.mutations.setType, type);
          this.$store.commit(
            reservationTypes.mutations.setInfoReservation,
            infoReserva
          );
          this.$store.commit(
            reservationTypes.mutations.setViewReservation,
            true
          );
        })
        .catch(() => {
          this.executeAction = false;
        });
    },
    rigthIconClicked (reservation, event) {
      if (!this.sittingValidator(reservation)) {
        this.isShowViewReservation(reservation);
      } else {
        this.setReservationSeated(reservation);
      }
      this.extraModalOpen = false;
    },
    sittingValidator (reservation) {
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      }
      if (dif != null) {
        if (reservation.vendorId !== 784 && reservation.vendorId !== 786 && reservation.vendorId !== 1) {
          if (
            (this.selectedDate === this.systemDate.substr(0, 10) ||
              this.selectedDate === this.yesterday) &&
            (reservation.codeStatus === 1 || reservation.codeStatus === 0) &&
            dif <= 60
          ) {
            return true;
          }
        } else {
          if (
            (this.selectedDate === this.systemDate.substr(0, 10) ||
              this.selectedDate === this.yesterday) &&
            (reservation.codeStatus === 1 || reservation.codeStatus === 0) &&
            dif <= 180
          ) {
            return true;
          }
        }
        return false;
      }
      if (
        (this.selectedDate === this.systemDate.substr(0, 10) ||
          this.selectedDate === this.yesterday) &&
        (reservation.codeStatus === 1 || reservation.codeStatus === 0)
      ) {
        return true;
      }
      return false;
    },
    scrollParentToChild (parent, child) {
      // Where is the parent on page
      var parentRect = parent.getBoundingClientRect();
      // What can you see?

      // Where is the child
      var childRect = child.getBoundingClientRect();
      // Is the child viewable?

      // Should we scroll using top or bottom? Find the smaller ABS adjustment
      const scrollLeft = childRect.left - parentRect.left;
      // we're near the top of the list
      parent.scrollLeft += scrollLeft;
      return scrollLeft;
    }
  },
  computed: {
    showUser () {
      if (this.reservationInformation.peopleMetrics) {
        return this.reservationInformation.peopleMetrics.show;
      } else {
        return 0;
      }
    },
    notShowUser () {
      if (this.reservationInformation.peopleMetrics) {
        return this.reservationInformation.peopleMetrics.noShow;
      } else {
        return 0;
      }
    },
    late () {
      if (this.now > parseInt(this.reservationInformation.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1) &&
        this.reservationInformation.referrer !== 'Walk-In' &&
        (this.reservationInformation.codeStatus === 0 || this.reservationInformation.codeStatus === 1)) return true;
      return false;
    },
    sobreHora () {
      if (this.now > parseInt(this.reservationInformation.date) &&
        this.now < parseInt(this.reservationInformation.date) + 1000 * 60 * (parseInt(this.params.anticipationUserTime) + 1) &&
        this.reservationInformation.referrer !== 'Walk-In' &&
        (this.reservationInformation.codeStatus === 0 || this.reservationInformation.codeStatus === 1)
      ) return true;
      return false;
    },
    maxIcons () {
      if (this.windowWidth > 600) {
        if (this.reservationInformation.isHotel) {
          return 2;
        } else {
          return 3;
        }
      }
      if (this.reservationInformation.isHotel) {
        return 1;
      } else {
        return 2;
      }
    },
    rotationTime () {
      return moment.duration(this.rotationSet).hours()
        ? moment.duration(this.rotationSet).hours()
        : 1;
    },
    validateSitting () {
      return this.sittingValidator(this.reservationInformation);
    },
    isInFilter () {
      if (
        this.nameTofilter &&
        !this.moreThanOne &&
        this.reservationInformation.displayName
      ) {
        const isIN = this.reservationInformation.displayName
          .toLowerCase()
          .includes(this.nameTofilter);
        return isIN;
      } else if (
        this.nameTofilter &&
        this.moreThanOne &&
        this.reservationsSorted.length > 0
      ) {
        if (
          this.reservationsSorted.findIndex((reservation) =>
            reservation.displayName.toLowerCase().includes(this.nameTofilter)
          ) >= 0
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    ...mapGetters({
      systemDate: [globalTypes.getters.serverTime]
    })
  },
  updated () {
    const $moment = this.$moment;
    if (this.reservationsSorted && this.$refs.itemTocreate) {
      const lastDate =
        this.reservationsSorted[this.reservationsSorted.length - 1];
      const dateStart = parseInt(this.reservationsSorted[0].date);
      const dateEnd = this.$moment(parseInt(lastDate.date)).add(
        lastDate.hoursUsed,
        'hours'
      );
      this.$refs.itemTocreate.setAttribute(
        'data-minutesReservation',
        this.$moment(dateEnd).diff($moment(dateStart), 'minutes')
      );
    }
  },
  watch: {
    localReservationOftheDay () {
      this.moreThanOne = false;
      this.haveReservation = false;
      this.reservationInformation = {};
      this.initComponent();
    },
    extraModalOpen (value) {
      this.bountyClient = this.$refs.itemTocreate.getBoundingClientRect();
      if (value) {
        document.getElementById('Cronology').style.setProperty('--lastScrollYForExtraModal', document.getElementById('mainContainer').scrollTop + 'px');
      }
      if (window.innerWidth <= 600) {
        this.scrollParentToChild(document.getElementById('scrollXContainer'), this.$refs.itemTocreate);
        document.getElementById('Cronology').style.setProperty('--topExtraModal', this.bountyClient.top + 'px');
      }
      if (value) {
        this.$emit('needToHide', {
          state: value,
          key: this.keySquare,
          tableId: this.tableIdForObject
        });
      } else {
        this.$emit('needToHide', { state: '', tableId: '' });
      }
    },
    sobreReservationDifferentRestaurantsOpen (value) {
      this.bountyClient = this.$refs.mapItemReservation.getBoundingClientRect();
      if (value) {
        this.$emit('needToHide', {
          state: value,
          key: this.keySquare,
          tableId: this.tableIdForObject
        });
      } else {
        this.$emit('needToHide', { state: '', tableId: '' });
      }
    },
    lastScroll () {
      if (this.reservationOftheDay && this.extraModalOpen && window.innerWidth > 600) {
        this.extraModalOpen = false;
      }
      if (this.sobreReservationDifferentRestaurantsOpen && window.innerWidth > 600) {
        this.sobreReservationDifferentRestaurantsOpen = false;
      }
    },
    switchClickOnMask () {
      if (this.reservationOftheDay && this.extraModalOpen) {
        this.extraModalOpen = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin icon($url, $w: 15px, $h: 15px, $color: black) {
  width: $w;
  height: $h;
  background-color: $color;
  mask-image: url($url);
  mask-repeat: no-repeat;
  mask-position: center;
}
#MapItemReservation {
  cursor: pointer;
  --rotation: 1;
  --mainColor: #00adc6;
  --backGroundColor: white;
  --iconsColors: black;
  --rightButtonColor: #00adc6;
  position: absolute;
  left: 1px;
  z-index: 10;
  height: 100%;
  background-color: var(--backGroundColor);
  max-height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #00adc6;
  box-shadow: 6px 6px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: none;
  align-items: center;
  overflow: hidden;
  min-width: calc((160px * var(--rotation)) - 1px);
  margin-left: 1px;
  @media screen and (max-width:600px) {
    max-width: calc((120px  * var(--rotation)) - 1px);
    min-width: calc((120px * var(--rotation)) - 1px);
  }
  .icon-mks{
    margin-right: 2px;
    margin: auto;
  }
  &.seated {
    --backGroundColor: #00adc6;
    --mainColor: white;
    --iconsColors: white;
    --rightButtonColor: #02a0b8;
    &.ended{
      --backGroundColor: #4DC6D7;
    }
    &.notHere{
      --backGroundColor: white;
      --mainColor: black;
      --iconsColors: black;
      --mainColor: #02a0b8;
    }
  }
  &.walking.seated {
    --backGroundColor: #8a51b4;
    --rightButtonColor: #7e49a5;
  }
  &.precompra.seated {
    --rightButtonColor: #aeb40c;
    --backGroundColor: #bdc30e;
    &.ended{
      --backGroundColor: #D1D556;
    }
  }
  .notCome {
    color: #444B57;
    font-weight: 500;
    padding-right: 5px;
    font-size: 10px;
    @media screen and (max-width:600px) {
      font-size: 9px;
    }
  }
  &.haveReservation {
    display: flex;
    flex-direction: row;
  }
  &.late {
    --mainColor: #FF8A00 !important;
    --rightButtonColor: #FF8A00 !important;
    border: solid 1px #FF8A00 !important;
  }
  &.sobreHora{
    --mainColor: #FFD43B !important;
    --rightButtonColor: #FFD43B !important;
    border: solid 1px #FFD43B !important;
  }
  &.walking {
    --mainColor: #8a51b4;
    --rightButtonColor: #8a51b4;
    border: 1px solid #8a51b4;
    &.seated {
      --mainColor: white;
    }
    &.ended{
          --backGroundColor: #AD85CB;
    }
  }
  &.precompra {
    --mainColor: #bdc30e;
    --rightButtonColor: #bdc30e;
    border: 1px solid #bdc30e;
    &.seated {
      --mainColor: white;
    }
    &.noPaid{
      --mainColor:  #444B57;
      --backGroundColor: #E2E2E2;
      border: 1px solid #9FA3AA;
      --iconsColors:#444B57;;
    }
  }
  &.canelled {
    --backGroundColor: white !important;
    --mainColor: red !important;
    --iconsColors: black;
    border: 1px solid red;
  }
  main {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    cursor: pointer;
    flex: 1;
  }
  section {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    pointer-events: none;
    &:first-child::before {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      content: " ";
      display: block;
      width: calc(100% - 12px);
      border-radius: 2px;
      height: 1px;
      background-color: #d9d9d9;
      @media screen and (max-width:600px) {
        transform:translateY(0px);
      }
    }
    .peopeQuantityIcon {
      display: flex;
      align-items: center;
      height: 100%;
      p {
        color: var(--iconsColors);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        @media screen and (max-width:600px) {
          font-size: 9px;
        }
      }
    }
    .displayName {
      color: var(--mainColor);
      font-weight: 500;
      font-size: 13px;
      white-space: nowrap;
      padding-left: 5px;
      pointer-events: none;
      @media screen and (max-width:600px) {
        font-size: 9px;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
      }
    }
    .client-icon{
      width: 17px;
      height: 17px;
      margin: 0 5px 0 0;
      &.visit{
        width: 21px;
        height: 21px;
        margin-left: 5px;
        padding-top: 2px;
      }
      &.no-show{
        margin-top: 1px;
      }
      .visit-number{
        color: white;
        font-size: 10px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
      }
      @media screen and (max-width:600px) {
        &.visit{
          min-width: 15px;
          width: 15px;
          height: 15px;
          margin: 0 1px 0 3px;
          padding-top: 0;
          .visit-number{
            line-height: 1;
            padding-top: 1px;
          }
        }
        &.no-show{
          min-width: 13px;
          width: 13px;
          height: 13px;
          margin: 1px 1px 0 1px;
          .visit-number{
            padding-bottom: 1px;
          }
        }
        .visit-number{
          font-size: 8px;
        }
      }
    }
  }
  &.noPaid section:first-child::before{
    background-color: #444B57;
  }
  .personIcon {
    display: block;
    margin-left:2px;
    @include icon(
      "../../assets/icons/icon_dashboard_person.svg",
      13px,
      13px,
      var(--iconsColors)
    );
    @media screen and (max-width:600px) {
      margin-left:0px;
      @include icon(
        "../../assets/icons/icon_dashboard_person.svg",
        10px,
        10px,
        var(--iconsColors)
      );
    }
  }
  &.walking .lastTopIcon {
    mask-size: 80%;
    margin-left: auto;
    margin-right: 5px;
    @include icon(
      "../../assets/icons/allTimeLineIcons/walkIcon.svg",
      10px,
      18px,
      var(--iconsColors)
    );
  }
  .buttonrigth {
    width: 24px;
    height: 100%;
    background-color: var(--rightButtonColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    @media screen and (max-width:600px) {
      min-width: 18px;
      max-width: 18px;
    }
    span {
      pointer-events: auto;
    }
    &.down {
      span {
        display: block;
        @include icon(
          "../../assets/icons/icon_dashboard_seat.svg",
          10px,
          18px,
          white
        );
      }
      @media screen and (max-width:600px) {
        span {
          display: block;
          @include icon(
            "../../assets/icons/icon_dashboard_seat.svg",
            7px,
            18px,
            white
          );
        }
      }
    }
    &.up {
      span {
        display: block;
        mask-size: 80%;
        @include icon(
          "../../assets/icons/icon_estadofinalizar.svg",
          20px,
          23px,
          white
        );
        @media screen and (max-width:600px) {
          display: block;
          @include icon(
            "../../assets/icons/icon_dashboard_seat.svg",
            10px,
            18px,
            white
          );
        }
      }
    }
  }
  .icon-box {
    margin-left: auto;
    margin-right: 7px;
    height: 12px;
    width: 12px;
    display: flex;
    align-items: center;
    img {
      width: 12px;
      max-height: 13px;
      &.walk{
        position: relative;
        width: 13px;
        max-height: 15px;
        bottom: 1px;
        @media screen and (max-width:600px) {
          max-height: 10px;
          bottom: 0;
          transform: translateX(3px);
        }
      }
    }
  }
  &.seated {
    .icon-box {
      filter: invert(100%);
    }
  }
  .leftBottomIconsContainer {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    min-width: 41px;
  }
  i {
    width: 12px;
    height: 12px;
  }
  .leftBottomIcons {
    height: fit-content;
    padding: 1px 1px;
    border-radius: 50px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 5px;
    user-select: none;
    font-size: 7px;
    @media screen and (max-width:600px) {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-right: 3px;
      img{
        height:13px;
      }
    }
  }
  .alertIcon {
    margin-right: 4px;
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
    pointer-events: auto;
    @media screen and (max-width:600px) {
      width: 11px;
      height: 11px;
    }
  }
  .rightIconsContainer {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    @media screen and (max-width:600px) {
      margin-right: 7px;
    }
    &.notCome{
      filter: unset;
    }
  }
  &.seated .rightIconsContainer {
    filter: invert(100%);
    &.notCome{
      filter: unset;
      color: #444B57;
    }
  }
  .rightIcon {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    @media screen and (max-width:600px) {
      margin-right: 1px;
      width: 12px;
      height: 12px;
    }
    &.iconCancelled{
      width: 14px;
      height: 14px;
    }
    &.cancelledIcon{
      width: 14px;
      height: 14px;
      @media screen and (max-width:600px) {
        width: 10px;
        height: 10px;
        margin-right: 2px;
        margin-bottom: 1px;
      }
    }
    &.plus {
      font-weight: bolder;
      font-size: 18px;
      position: relative;
      bottom: 2px;
      @media screen and (max-width:600px) {
        font-size: 14px;
      }
    }
    img {
      margin-right: 2px;
      object-fit: contain;
    }
  }
  &.canelled {
    .rightIconsContainer {
      filter: none !important;
      .icon-box {
        filter: none !important;
      }
      .cancelled {
        font-size: 10px;
        color: red;
        margin-right: 5px;
        font-weight: 600;
        font-size: 10;
        @media screen and (max-width:600px) {
          font-size: 9px;
        }
      }
    }
  }
}
#MapItemReservationExtra {
  cursor: crosshair;
  position: absolute;
  left: 1px;
  z-index: 10;
  height: 100%;
  max-height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  pointer-events: auto;
  cursor: pointer;
  i {
    width: 10px;
    height: 10px;
    @media screen and (max-width:600px) {
      width: 8px;
      height: 8px;
    }
  }
  main {
    height: 100%;
    min-width: fit-content;
    width: calc((100% + (100% * var(--secondRotation))) + var(--moveLeft));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    pointer-events: none;
    pointer-events: none;
  }
  .extra {
    height: 48.5%;
    background-color: white;
    border: solid red 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: red;
    flex-direction: row;
    border-radius: 6px;
    .left {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      @media screen and (max-width:600px) {
        .peopeQuantityIcon{
          p{
            font-size: 10px;
          }
        }
      }
      & > p {
        padding-left: 5px;
        font-weight: 600;
        pointer-events: auto;
        cursor: pointer;
        @media screen and (max-width:600px) {
          font-size: 8px;
          overflow: hidden;
          white-space: nowrap;
          padding-left: 4px;
        }
      }
      .peopeQuantityIcon {
        display: flex;
        align-items: center;
        max-height: 100%;
      }
    }
    .right {
      display: flex;
      align-items: center;
      img{
        max-height: 14px;
      }
      .icon-box{
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 1px;
      }
      i {
        margin-right: 6px;
        @media screen and (max-width:600px) {
          margin-right: 4px;
        }
      }
      & > span {
        width: 17px;
        height: 17px;
        background-image: url("../../assets/icons/allTimeLineIcons/warningRed.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: auto;
        cursor: pointer;
        margin-right: 4px;
        @media screen and (max-width:600px) {
          width: 11px;
          height: 11px;
        }
        &.realExtra{
          background-image: url("../../assets/icons/allTimeLineIcons/plusWARNING.svg");
          width: 21px;
          height: 21.5px;
          transform: translateX(-4px);
          margin-right: 2px;
          @media screen and (max-width:600px) {
            width: 16px;
            height: 16px
          }
        }
      }
    }
    .personIcon {
      display: block;
      margin-left: 2px;
      @include icon(
        "../../assets/icons/icon_dashboard_person.svg",
        12px,
        12px,
        red
      );
      @media screen and (max-width:600px) {
        min-width: 10px;
        margin-left: 5px;
        margin-right: 4px;
        @include icon(
          "../../assets/icons/icon_dashboard_person.svg",
          10px,
          10px,
          red
        );
      }

    }
  }
  .firstExtra {
    min-width: calc(160px * var(--firstRotation));
    max-width: calc( 160px * var(--firstRotation));
    @media screen and (max-width:600px) {
      min-width: calc(120px * var(--firstRotation));
      max-width: calc(120px * var(--firstRotation));
    }
  }
  .SecondExtra {
    position: relative;
    min-width: calc(160px * var(--secondRotation));
    max-width: calc(160px * var(--secondRotation));
    @media screen and (max-width:600px) {
      min-width: calc(120px * var(--secondRotation));
      max-width: calc(120px * var(--secondRotation));
    }
  }
  .moveLeft {
    margin-left: (calc((var(--leftPush) / 30) * 80px));
    @media screen and (max-width:600px) {
      margin-left: (calc((var(--leftPush) / 30) * 60px));
    }
  }
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 6px;
  transform: rotate(45deg) scale(1.3);
  background-image: unset !important;
  @include icon(
    "../../assets/icons/allTimeLineIcons/plus.svg",
    18px,
    18px,
    #374957
  );
}
.extraInfoModal {
  pointer-events: auto;
  width: 250px;
  height: 200px;
  background-color: white;
  z-index: 1000000;
  border-radius: 8px;
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: fixed;
  right: 0;
  top: var(--top);
  left: var(--left);
  transform: translateX(calc(-100% + 16px - 57px));
  @media screen and (max-width: 1100px) {
    transform: translateX(calc(-100% + 16px - 57px)) translateY(-30px);
  }
  @media screen and (max-width: 600px) {
    position: fixed;
    width: 285px;
    height: 188px;
    padding: 14px;
    padding-bottom: 21px;
    top: calc(var(--topExtraModal) - 95px);
    left: 0;
    right: 0;
    margin: auto;
    bottom: unset;
    transform: translateX(-0%);
    z-index: 1000;
    justify-content: space-between;
  }
  &::after {
    display: block;
    background-color: white;
    height: 16px;
    width: 16px;
    content: "";
    position: absolute;
    top: -8px;
    right: 20px;
    transform: rotate(45deg);
    @media screen and (max-width:600px) {
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &::before {
    display: block;
    background-color: transparent;
    height: 16px;
    width: 200%;
    content: "";
    position: absolute;
    top: -8px;
  }
  .icon-box {
    .icon-mks{
      margin-right: 7px;
    }
    i {
      height: 14px;
      width: 14px;
    }
  }
  .peopeQuantityIcon {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2px;
    padding-right: 6px;
  }
  h2 {
    color: #444b57;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    max-width: 58%;
    transform: translateY(4px);
    @media screen and (max-width:600px) {
      max-width: unset;
    }
  }
  .text {
    color: #444b57;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .reservationsContainer {
    width: 98%;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    height: 105px;
    &.moreThan{
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #a8adb55f !important;
      border-radius: 3px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #606773 !important;
      border-radius: 4px !important;
      border: unset !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .reservationsInExtra {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 6px;
    border: solid 1px #00adc6;
    .sitOrWake {
      height: 100%;
      width: 24px;
      background-color: #00adc6;
      border: unset;
      outline: none;
      border-radius: 4px;
      margin-left: 4px;
      padding: unset;
      cursor: pointer;
      &.down {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin: unset;
          display: block;
          @include icon(
            "../../assets/icons/icon_dashboard_seat.svg",
            100%,
            18px,
            white
          );
          @media screen and (max-width:600px) {
            margin: unset;
            @include icon(
              "../../assets/icons/icon_dashboard_seat.svg",
              10.2px,
              18px,
              white
            );
          }
        }
      }
      &.up {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin: unset;
          display: block;
          mask-size: 70%;
          @include icon(
            "../../assets/icons/icon_estadofinalizar.svg",
            100%,
            18px,
            white
          );
          @media screen and (max-width:600px) {
            display: block;
            mask-size: 85%;
            margin: unset;
            margin-right: unset;
            @include icon(
              "../../assets/icons/icon_estadofinalizar.svg",
              16px,
              18px,
              white
            );
          }
        }
      }
    }
    .edit {
      padding: unset;
      height: 100%;
      width: 24px;
      border: unset;
      outline: none;
      background: #444b57;
      border-radius: 4px;
      cursor: pointer;
      span {
        display: block;
        @include icon("../../assets/icons/edit.svg", 100%, 23px, white);
        mask-size: 60%;
      }
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #00adc6;
      white-space: nowrap;
      .peopeQuantityIcon {
        color: black;
        margin-left: 6px;
        p{
          transform: translateY(2px);
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        .personIconExtra {
          background-image: unset;
          display: block;
          margin-left: 2px;
          @include icon(
            "../../assets/icons/icon_dashboard_person.svg",
            15px,
            15px,
            black
          );
        }
      }
      p {
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        position: relative;
        bottom: 2px;
      }
    }
    .rigth {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;
    }
  }
}
.modalMultipleReservations {
  pointer-events: auto;
  width: 240px;
  height: 100px;
  background-color: white;
  z-index: 100;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: fixed;
  right: 0;
  top:calc(var(--top) + 6px) ;
  left: var(--left);
  transform: translateX(calc(-100% + 7px));
  @media screen and (max-width: 1300px) {
    transform: translateX(calc(-100% + 5px));
  }
  @media screen and (max-width: 1100px) {
    transform: translateX(calc(-100% + 4px)) translateY(-36px);
  }
  @media screen and (max-width: 600px) {
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-100px);
  }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 6px;
    transform: rotate(45deg) scale(1.3);
    background-image: unset !important;
    @include icon(
      "../../assets/icons/allTimeLineIcons/plus.svg",
      18px,
      18px,
      #374957
    );
  }
  &::after {
    display: block;
    background-color: white;
    height: 16px;
    width: 16px;
    content: "";
    position: absolute;
    top: -8px;
    left: 20px;
    transform: rotate(45deg);
    @media screen and (max-width: 600px) {
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &::before {
    display: block;
    background-color: transparent;
    height: 16px;
    width: 200%;
    content: "";
    position: absolute;
    top: -8px;
  }
  h2 {
    color: #ffb904;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
  p {
    padding-bottom: 3px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .logosContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-height: 18px;
    img {
      max-height: 17px;
    }
  }
}

#MapItemReservation{
  .personIcon{
    @media screen and (max-width:600px) {
      min-width: 18px;
      margin-left: 2px;
    }
  }
}
#MapItemReservation.seated.notHere .icon-box{
  filter: unset;
}
#MapItemReservationExtra .extra .right img.walk{
  margin-right: 1px;
  @media screen and (max-width:600px) {
    max-height: 11px;
    margin-right: 0px;
    transform: translateX(3px);
  }
}
.extraInfoModal .reservationsInExtra.typeWalk .left > p {
  color: #8a51b4;
}
.extraInfoModal .reservationsInExtra.typeWalk{
  border-color: #8a51b4;
}

.extraInfoModal .reservationsInExtra.typeWalk .sitOrWake{
  background-color: #8a51b4;
}
.extraInfoModal .reservationsInExtra.typeWalk .icon-box img{
  margin-right: 4px;
}

.notCome span{
  font-size: 16px;
  @media screen and (max-width:600px){
    font-size: 11px;
    margin-top: 2px;
  }
}

.nopayYet{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding-right: 4px;
}
</style>
